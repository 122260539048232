/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailTemplate } from '../models/EmailTemplate';
import type { EmailTemplateSummaryCollection } from '../models/EmailTemplateSummaryCollection';
import type { SystemSettings } from '../models/SystemSettings';
import type { SystemSettingsUpdate } from '../models/SystemSettingsUpdate';
import type { UpdateEmailTemplateRequest } from '../models/UpdateEmailTemplateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class SystemSettingsService {

    /**
     * Fetches system settings
     * @returns SystemSettings successful operation
     * @throws ApiError
     */
    public static getSystemSettings(): CancelablePromise<SystemSettings> {
        return __request({
            method: 'GET',
            path: `/systemSettings`,
        });
    }

    /**
     * Updates system settings
     * @returns void
     * @throws ApiError
     */
    public static updateSystemSettings({
        requestBody,
    }: {
        /** System settings update **/
        requestBody?: SystemSettingsUpdate,
    }): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/systemSettings`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Lists email templates
     * @returns EmailTemplateSummaryCollection successful operation
     * @throws ApiError
     */
    public static listEmailTemplates({
        nextToken,
        pageSize,
    }: {
        /** next token **/
        nextToken?: string,
        /** page size **/
        pageSize?: number,
    }): CancelablePromise<EmailTemplateSummaryCollection> {
        return __request({
            method: 'GET',
            path: `/emailTemplates`,
            query: {
                'nextToken': nextToken,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Get email template
     * @returns EmailTemplate successful operation
     * @throws ApiError
     */
    public static getEmailTemplate({
        templateName,
    }: {
        /** Email template name **/
        templateName: string,
    }): CancelablePromise<EmailTemplate> {
        return __request({
            method: 'GET',
            path: `/emailTemplates/${templateName}`,
        });
    }

    /**
     * Updates email template
     * @returns void
     * @throws ApiError
     */
    public static updateEmailTemplate({
        templateName,
        requestBody,
    }: {
        /** Email template name **/
        templateName: string,
        /** Email template update **/
        requestBody?: UpdateEmailTemplateRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/emailTemplates/${templateName}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}