import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { DataTable } from "components/DataTable/DataTable";
import {
  JobRequestOrigination,
  JobRequestsService,
  JobRequestStatus,
  JobRequestSummary,
  JobRequestType
} from "gen/clients";
import { useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/dateUtils";
import { TokenBasedPaginator } from "components/TokenBasedPaginator/TokenBasedPaginator";
import CircularProgress from "@mui/material/CircularProgress";
import { JobRequestStatusText } from "../components/JobRequestStatusText/JobRequestStatusText";
import { describeRequestType, FINISHED_JOBREQUEST_STATUSES } from "../utils/JobRequestUtils";
import Stack from "@mui/material/Stack";
import { useDialogState } from "hooks/useDialogState";
import { DataTableRowAction } from "components/DataTable/types";
import { ChangeRequestTypeDialog } from "../components/ChangeRequestTypeDialog/ChangeRequestTypeDialog";
import { ReviewJobRequestPanel } from "../components/ReviewJobRequestPanel/ReviewJobRequestPanel";
import { useSnackbarAlert } from "hooks/useSnackbarAlert";
import { DeleteJobRequestDialog } from "../components/DeleteJobRequestDialog/DeleteJobRequestDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { ArchiveJobRequestDialog } from "../components/ArchiveJobRequestDialog/ArchiveJobRequestDialog";
import { ForwardOriginalEmailDialog } from "../components/ForwardOriginalEmailDialog/ForwardOriginalEmailDialog";
import { PagePath } from "../../../types/PagePath";
import {
  DEFAULT_JOB_REQEST_GROUP,
  JobRequestFilter,
  JobRequestFilterSearchParams
} from "../components/JobRequestFilter/JobRequestFilter";
import { ArchiveJobRequestListDialog } from "../components/ArchiveJobRequestListDialog/ArchiveJobRequestListDialog";
import { DeleteJobRequestListDialog } from "../components/DeleteJobRequestListDialog/DeleteJobRequestListDialog";
import { MoveJobRequestDialog } from "../components/MoveJobRequestDialog/MoveJobRequestDialog";
import Link from "@mui/material/Link";

export enum FieldNames {
  searchRequestById = "searchRequestById"
}

interface EmailJobRequestsPageSessionState {
  filterParams?: JobRequestFilterSearchParams;
  rowsPerPage?: number;
}

const PAGE_SESSION_STATE_VAR_NAME = "emailJobRequestsPageStateV1";
export const EMAIL_JOB_REQUEST_GROUPS = [
  { label: "Inbox", value: "INBOX" },
  { label: "Starts", value: "Starts" },
  { label: "Quote Starts", value: "Quote Starts" },
  { label: "Intake", value: "Intake" },
  { label: "On Hold", value: "On Hold" }
];

const EmailJobRequestsPage: React.FC = () => {
  const history = useHistory();
  const [selectedJobRequest, setSelectedJobRequest] = React.useState<JobRequestSummary>();
  const [changeRequestTypeDialogOpen, openChangeRequestTypeDialog, closeChangeRequestTypeDialog] = useDialogState();
  const [moveDialogOpen, openMoveDialog, closeMoveDialog] = useDialogState();
  const [moveMultipleRequestsDialogOpen, openMoveMultipleRequestsDialog, closeMoveMultipleRequestsDialog] =
    useDialogState();
  const [deleteJobRequestDialogOpen, openDeleteJobRequestDialog, closeDeleteJobRequestDialog] = useDialogState();
  const [deleteJobRequestListDialogOpen, openDeleteJobRequestListDialog, closeDeleteJobRequestListDialog] =
    useDialogState();
  const [archiveJobRequestDialogOpen, openArchiveJobRequestDialog, closeArchiveJobRequestDialog] = useDialogState();
  const [reviewJobRequestPanelOpen, openReviewJobRequestPanel, closeReviewJobRequestPanel] = useDialogState();
  const [forwardOriginalEmailDialogOpen, openForwardOriginalEmailDialog, closeForwardOriginalEmailDialog] =
    useDialogState();
  const [archiveJobRequestListDialogOpen, openArchiveJobRequestListDialog, closeArchiveJobRequestListDialog] =
    useDialogState();

  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });

  const { snackbarAlert, openSnackbarAlert, closeSnackbarAlert } = useSnackbarAlert({
    autoHideDuration: 5000,
    vertical: "bottom",
    horizontal: "left"
  });
  const pageSessionState: EmailJobRequestsPageSessionState | undefined = React.useMemo(
    () =>
      window.sessionStorage.getItem(PAGE_SESSION_STATE_VAR_NAME)
        ? JSON.parse(window.sessionStorage.getItem(PAGE_SESSION_STATE_VAR_NAME) as string)
        : undefined,
    []
  );
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(pageSessionState?.rowsPerPage || 10);
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const [selectedRows, setSelectedRows] = React.useState<JobRequestSummary[]>([]);
  const [filterParams, setFilterParams] = React.useState<JobRequestFilterSearchParams>(
    pageSessionState?.filterParams || { group: DEFAULT_JOB_REQEST_GROUP }
  );

  // Keep session state of the page updated
  React.useEffect(() => {
    const newPageSessionState: EmailJobRequestsPageSessionState = {
      rowsPerPage,
      filterParams
    };
    window.sessionStorage.setItem(PAGE_SESSION_STATE_VAR_NAME, JSON.stringify(newPageSessionState));
  }, [rowsPerPage, filterParams]);

  const {
    data: jobRequests,
    isLoading,
    error,
    refetch,
    isFetching
  } = useQuery(
    [
      "listEmailJobRequests",
      pageToken,
      rowsPerPage,
      filterParams?.requestType,
      filterParams?.status,
      filterParams?.requestId,
      filterParams?.group
    ],
    {
      queryFn: () =>
        JobRequestsService.listJobRequests({
          pageToken,
          pageSize: rowsPerPage,
          requestType: filterParams?.requestType,
          status: filterParams?.status,
          group: filterParams?.group,
          jobRequestId: filterParams?.requestId?.trim()
        }),
      onSuccess: collection => {
        setNextPageToken(collection.nextPageToken);
      },
      refetchInterval: 10000
    }
  );

  const requestedByCell = React.useCallback(
    (jobRequest: JobRequestSummary) =>
      `${jobRequest.requestorFirstName || ""} ${jobRequest.requestorLastName || ""} (${jobRequest.requestorEmail})`,
    []
  );

  const subjectCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Box maxWidth={200}>
        <Typography noWrap={true} title={jobRequest.subject} variant="body2">
          <Link onClick={e => e.preventDefault()} href={`/emailJobRequests/${jobRequest.id}`}>
            {jobRequest.subject}
          </Link>
        </Typography>
      </Box>
    ),
    []
  );

  const contentCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Stack direction="row" maxWidth={200} alignItems="center" justifyItems="center" spacing={1}>
        {jobRequest.summary && (
          <Icon color="info" title={jobRequest.summary}>
            <InfoIcon />
          </Icon>
        )}
        <Typography noWrap={true} title={jobRequest.description} variant="body2">
          {jobRequest.description}
        </Typography>
      </Stack>
    ),
    []
  );

  const requestTypeCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Stack direction="row" spacing={1}>
        <Typography>{jobRequest.requestType ? describeRequestType(jobRequest.requestType) : ""}</Typography>
      </Stack>
    ),
    []
  );

  const createTimeCell = React.useCallback((jobRequest: JobRequestSummary) => formatDate(jobRequest.createTime), []);

  const statusCell = React.useCallback(
    (jobRequest: JobRequestSummary) => <JobRequestStatusText status={jobRequest.status} />,
    []
  );

  const onRowClick = React.useCallback(
    (jobRequest: JobRequestSummary) => {
      history.push(`${PagePath.emailJobRequests}/${jobRequest.id}`);
    },
    [history]
  );

  const onRowsPerPageChange = React.useCallback(
    (newRowsPerPage: number) => {
      setRowsPerPage(newRowsPerPage);
      paginatorResetRef.current();
      refetch();
    },
    [refetch]
  );

  const onChangeRequestTypeSuccess = React.useCallback(() => {
    refetch();
    closeChangeRequestTypeDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeChangeRequestTypeDialog, openSnackbarAlert, refetch]);

  const onMoveSuccess = React.useCallback(() => {
    refetch();
    closeMoveDialog();
    closeMoveMultipleRequestsDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeMoveDialog, closeMoveMultipleRequestsDialog, openSnackbarAlert, refetch]);

  const onDeleteJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeDeleteJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeDeleteJobRequestDialog, openSnackbarAlert, refetch]);

  const onDeleteJobRequestListSuccess = React.useCallback(() => {
    refetch();
    closeDeleteJobRequestListDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeDeleteJobRequestListDialog, openSnackbarAlert, refetch]);

  const onArchiveJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeArchiveJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeArchiveJobRequestDialog, openSnackbarAlert, refetch]);

  const onArchiveJobRequestListSuccess = React.useCallback(() => {
    refetch();
    closeArchiveJobRequestListDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeArchiveJobRequestListDialog, openSnackbarAlert, refetch]);

  const onReviewSuccess = React.useCallback(() => {
    refetch();
    closeReviewJobRequestPanel();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeReviewJobRequestPanel, openSnackbarAlert, refetch]);

  const onForwardOriginalEmailSuccess = React.useCallback(() => {
    closeForwardOriginalEmailDialog();
    openSnackbarAlert({ message: "Email sent", severity: "success" });
  }, [closeForwardOriginalEmailDialog, openSnackbarAlert]);

  const rowActions = React.useCallback(
    (jobRequest: JobRequestSummary): DataTableRowAction[] => [
      {
        title: "View Details",
        action: () => history.push(`${PagePath.emailJobRequests}/${jobRequest.id}`)
      },
      {
        title: "Review Job Request",
        disabled:
          (jobRequest.requestType !== JobRequestType.QUOTE_REQUEST &&
            jobRequest.requestType !== JobRequestType.TRANSLATION_REQUEST) ||
          jobRequest.status !== JobRequestStatus.READY_FOR_INTERNAL_REVIEW,
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openReviewJobRequestPanel();
        }
      },
      {
        title: "Forward Original Email",
        disabled: jobRequest.origination !== JobRequestOrigination.EMAIL,
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openForwardOriginalEmailDialog();
        }
      },
      {
        type: "DIVIDER",
        title: "divider1"
      },
      {
        title: "Change Request Type",
        disabled:
          jobRequest.status === JobRequestStatus.COMPLETED ||
          jobRequest.status === JobRequestStatus.ANALYZING ||
          jobRequest.status === JobRequestStatus.SUBMITTING_TO_XTRF ||
          jobRequest.status === JobRequestStatus.ARCHIVED,
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openChangeRequestTypeDialog();
        }
      },
      {
        title: "Move",
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openMoveDialog();
        }
      },
      {
        title: "Archive Job Request",
        disabled: !FINISHED_JOBREQUEST_STATUSES.includes(jobRequest.status),
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openArchiveJobRequestDialog();
        }
      },
      {
        title: "Delete Job Request",
        disabled: ![...FINISHED_JOBREQUEST_STATUSES, JobRequestStatus.ARCHIVED].includes(jobRequest.status),
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openDeleteJobRequestDialog();
        },
        color: "error.main"
      }
    ],
    [
      history,
      closeSnackbarAlert,
      openReviewJobRequestPanel,
      openForwardOriginalEmailDialog,
      openChangeRequestTypeDialog,
      openMoveDialog,
      openArchiveJobRequestDialog,
      openDeleteJobRequestDialog
    ]
  );

  const onFilterSearch = React.useCallback(
    (searchParams: JobRequestFilterSearchParams) => {
      setFilterParams(searchParams);
      paginatorResetRef.current();
      refetch();
    },
    [refetch]
  );

  return (
    <PageLayout
      selectedNavMenu={NavMenuOption.EMAIL_JOB_REQUESTS}
      title={
        <Toolbar disableGutters={true} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Email Job Requests
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <Button
              disabled={selectedRows.length === 0}
              variant="outlined"
              onClick={() => {
                openMoveMultipleRequestsDialog();
                closeSnackbarAlert();
              }}
            >
              Move
            </Button>
            <Button
              disabled={
                selectedRows.length === 0 || selectedRows.some(r => !FINISHED_JOBREQUEST_STATUSES.includes(r.status))
              }
              variant="outlined"
              onClick={() => {
                openArchiveJobRequestListDialog();
                closeSnackbarAlert();
              }}
            >
              Archive
            </Button>
            <Button
              disabled={selectedRows.length === 0}
              variant="outlined"
              color="error"
              onClick={() => {
                openDeleteJobRequestListDialog();
                closeSnackbarAlert();
              }}
            >
              Delete
            </Button>
            <Box width={30} height={30}>
              <IconButton onClick={() => refetch()}>
                {!isFetching && <RefreshIcon />}
                {isFetching && <CircularProgress size={20} />}
              </IconButton>
            </Box>
          </Stack>
        </Toolbar>
      }
    >
      <Box sx={{ mb: 1 }}>
        <JobRequestFilter
          initialValues={filterParams}
          onSearch={onFilterSearch}
          folders={EMAIL_JOB_REQUEST_GROUPS}
          statuses={[
            JobRequestStatus.CREATED,
            JobRequestStatus.SCREENING,
            JobRequestStatus.REJECTED_BY_SCREENING,
            JobRequestStatus.ANALYZING,
            JobRequestStatus.READY_FOR_INTERNAL_REVIEW,
            JobRequestStatus.SUBMITTED_TO_XTRF,
            JobRequestStatus.SUBMITTING_TO_XTRF,
            JobRequestStatus.FAILED,
            JobRequestStatus.COMPLETED,
            JobRequestStatus.ARCHIVED
          ]}
        />
      </Box>

      <DataTable
        columns={[
          {
            id: "requestedBy",
            title: "Requested By",
            cell: requestedByCell
          },
          {
            id: "subject",
            title: "Subject",
            cell: subjectCell
          },
          {
            id: "content",
            title: "Content",
            cell: contentCell
          },
          {
            id: "createTime",
            title: "Created",
            cell: createTimeCell
          },
          {
            id: "requestType",
            title: "Request Type",
            cell: requestTypeCell
          },
          {
            id: "status",
            title: "Status",
            cell: statusCell
          }
        ]}
        data={jobRequests?.items || []}
        rowKey={(jobRequest: JobRequestSummary) => jobRequest.id}
        isLoading={isLoading}
        error={!!error && <ApiErrorMessage apiError={error} />}
        onRowClick={onRowClick}
        onSelectRows={setSelectedRows}
        rowActions={rowActions}
        dense={true}
      />
      <TokenBasedPaginator
        nextPageToken={nextPageToken}
        rowsPerPage={rowsPerPage}
        onPageTokenChange={setPageToken}
        currentPageDataRows={jobRequests?.items.length}
        onRowsPerPageChange={onRowsPerPageChange}
        resetRef={paginatorResetRef}
      />
      {changeRequestTypeDialogOpen && selectedJobRequest && (
        <ChangeRequestTypeDialog
          jobRequest={selectedJobRequest}
          onSuccess={onChangeRequestTypeSuccess}
          onClose={closeChangeRequestTypeDialog}
        />
      )}
      {moveDialogOpen && selectedJobRequest && (
        <MoveJobRequestDialog
          groupOptions={EMAIL_JOB_REQUEST_GROUPS}
          currentGroup={filterParams.group}
          jobRequestIds={[selectedJobRequest.id]}
          onSuccess={onMoveSuccess}
          onClose={closeMoveDialog}
        />
      )}
      {moveMultipleRequestsDialogOpen && (
        <MoveJobRequestDialog
          groupOptions={EMAIL_JOB_REQUEST_GROUPS}
          currentGroup={filterParams.group}
          jobRequestIds={selectedRows.map(r => r.id)}
          onSuccess={onMoveSuccess}
          onClose={closeMoveMultipleRequestsDialog}
        />
      )}
      {archiveJobRequestListDialogOpen && (
        <ArchiveJobRequestListDialog
          jobRequests={selectedRows}
          onSuccess={onArchiveJobRequestListSuccess}
          onClose={closeArchiveJobRequestListDialog}
        />
      )}
      {deleteJobRequestDialogOpen && selectedJobRequest && (
        <DeleteJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onDeleteJobRequestSuccess}
          onClose={closeDeleteJobRequestDialog}
        />
      )}
      {deleteJobRequestListDialogOpen && (
        <DeleteJobRequestListDialog
          jobRequests={selectedRows}
          onSuccess={onDeleteJobRequestListSuccess}
          onClose={closeDeleteJobRequestListDialog}
        />
      )}
      {archiveJobRequestDialogOpen && selectedJobRequest && (
        <ArchiveJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onArchiveJobRequestSuccess}
          onClose={closeArchiveJobRequestDialog}
        />
      )}
      {reviewJobRequestPanelOpen && selectedJobRequest && (
        <ReviewJobRequestPanel
          jobRequestId={selectedJobRequest.id}
          onClose={closeReviewJobRequestPanel}
          onSuccess={onReviewSuccess}
        />
      )}
      {forwardOriginalEmailDialogOpen && selectedJobRequest && (
        <ForwardOriginalEmailDialog
          jobRequestId={selectedJobRequest.id}
          onClose={closeForwardOriginalEmailDialog}
          onSuccess={onForwardOriginalEmailSuccess}
        />
      )}
      {snackbarAlert}
    </PageLayout>
  );
};

export { EmailJobRequestsPage };
