import * as React from "react";
import { Form, Formik } from "formik";
import { default as dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Box, Button, Stack } from "@mui/material";
import { DATE_PICKER_FORMAT, DatePickerField } from "components/formikFields/DatePickerField/DatePickerField";

interface Props {
  from: Date;
  to: Date;
  onChange: (from: Date, to: Date) => void;
}

enum FieldNames {
  from = "from",
  to = "to"
}

interface Values {
  [FieldNames.from]: string;
  [FieldNames.to]: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const Filter: React.FC<Props> = ({ from, to, onChange }) => {
  const initialValue = React.useMemo(
    () => ({
      from: dayjs(from).format(DATE_PICKER_FORMAT),
      to: dayjs(to).format(DATE_PICKER_FORMAT)
    }),
    [from, to]
  );

  const validate = React.useCallback((values: Values) => {
    const fromValue = dayjs(values[FieldNames.from], DATE_PICKER_FORMAT);
    const toValue = dayjs(values[FieldNames.to], DATE_PICKER_FORMAT);
    const errors: Record<string, string> = {};
    if (toValue.isBefore(fromValue)) {
      errors[FieldNames.to] = "Start date must be before end date";
    } else if (toValue.subtract(60, "day").isAfter(fromValue)) {
      errors[FieldNames.to] = "Search range cannot exceed 60 days";
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    (values: Values) => {
      window.console.log("onSubmit", { from: values[FieldNames.from], to: values[FieldNames.to] });
      const fromValue = dayjs
        .tz(values[FieldNames.from], DATE_PICKER_FORMAT, "America/Los_Angeles")
        .startOf("day")
        .toDate();
      const toValue = dayjs.tz(values[FieldNames.to], DATE_PICKER_FORMAT, "America/Los_Angeles").endOf("day").toDate();
      onChange(fromValue, toValue);
    },
    [onChange]
  );

  return (
    <Formik initialValues={initialValue} validate={validate} onSubmit={onSubmit}>
      <Form autoComplete="off" autoCorrect="off">
        <Stack direction="row" spacing={2}>
          <DatePickerField
            name={FieldNames.from}
            label="Start date"
            variant="outlined"
            size="small"
            disableFuture={true}
          />
          <DatePickerField name={FieldNames.to} label="End date" variant="outlined" size="small" disableFuture={true} />
          <Box>
            <Button type="submit" variant="contained">
              Search
            </Button>
          </Box>
        </Stack>
      </Form>
    </Formik>
  );
};

export { Filter };
