import * as React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { TextField } from "formik-mui";
import SearchIcon from "@mui/icons-material/Search";
import { JobRequestStatus, JobRequestType } from "../../../../gen/clients";
import { SelectOption } from "@mui/base";
import { describeRequestStatus, describeRequestType } from "../../utils/JobRequestUtils";
import { SelectOneField } from "../../../../components/formikFields/SelectOneField/SelectOneField";
import { FormFieldObserver } from "../../../../components/formikFields/FormFieldObserver/FormFieldObserver";
import Divider from "@mui/material/Divider";
import { SelectManyField } from "components/formikFields/SelectManyField/SelectManyField";

interface Props {
  initialValues?: JobRequestFilterSearchParams;
  onSearch: (searchParams: JobRequestFilterSearchParams) => void;
  folders?: SelectOption<string | number>[];
  statuses?: JobRequestStatus[];
  hideRequestTypeFilter?: boolean;
}

export interface JobRequestFilterSearchParams {
  requestType?: JobRequestType;
  status?: string;
  group: string;
  requestId?: string;
}

enum FieldNames {
  requestType = "requestType",
  status = "status",
  group = "group",
  requestId = "requestId"
}

const ALL_VALUE = "ALL";
export const DEFAULT_JOB_REQEST_GROUP = "INBOX";

interface FormValues {
  [FieldNames.requestType]: string;
  [FieldNames.status]: string[];
  [FieldNames.requestId]: string;
  [FieldNames.group]: string;
}

const NoneSelectOption = {
  label: <em>All</em>,
  value: ALL_VALUE
};

const JobRequestFilter: React.FC<Props> = ({ initialValues, onSearch, folders, statuses, hideRequestTypeFilter }) => {
  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const requestTypeOptions: SelectOption<string>[] = [
    NoneSelectOption,
    ...Object.values(JobRequestType)
      .map(value => ({
        label: describeRequestType(value),
        value
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  ];

  const requestStatusOptions: SelectOption<string>[] = React.useMemo(
    () => [
      ...Object.values(JobRequestStatus)
        .filter(s => !statuses || statuses.includes(s))
        .map(value => ({
          label: describeRequestStatus(value),
          value
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    ],
    [statuses]
  );

  const onSubmit = React.useCallback(
    (values: FormValues, formikHelpers?: FormikHelpers<FormValues>) => {
      const requestType = values[FieldNames.requestType];
      const group = values[FieldNames.group];
      const selectedStatuses = values[FieldNames.status]
        .filter((value): value is NonNullable<typeof value> => value !== null && value !== undefined) // Ensure we only have non-null values
        .map(status => status as JobRequestStatus);

      // Determine if all statuses are selected or none are selected
      const allStatusesSelected = selectedStatuses.length === requestStatusOptions.length;
      const noStatusesSelected = selectedStatuses.length === 0;

      onSearch({
        group,
        requestType: requestType === ALL_VALUE ? undefined : (requestType as JobRequestType),
        status: allStatusesSelected || noStatusesSelected ? undefined : selectedStatuses.join(","),
        requestId: values[FieldNames.requestId].trim() || undefined
      });
      formikHelpers?.setSubmitting(false);
    },
    [onSearch, requestStatusOptions.length]
  );

  const onFormFieldObserverChange = React.useCallback(() => {
    const formValues = formikRef?.current?.values;
    if (formValues) {
      onSubmit(formValues);
    }
  }, [onSubmit]);

  return (
    <Card sx={{ px: 2 }}>
      <Formik
        initialValues={{
          [FieldNames.requestType]: initialValues?.requestType || ALL_VALUE,
          [FieldNames.status]: initialValues?.status?.split(",") ?? [],
          [FieldNames.group]: initialValues?.group || DEFAULT_JOB_REQEST_GROUP,
          [FieldNames.requestId]: initialValues?.requestId || ""
        }}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <FormFieldObserver fieldName={FieldNames.requestType} onChange={onFormFieldObserverChange} />
          <FormFieldObserver fieldName={FieldNames.status} onChange={onFormFieldObserverChange} />
          <FormFieldObserver fieldName={FieldNames.group} onChange={onFormFieldObserverChange} />
          <Stack direction="row" whiteSpace="nowrap" spacing={2} paddingBottom={folders ? 0 : 1}>
            {folders && (
              <>
                <Grid item={true} py={1}>
                  <SelectOneField
                    name={FieldNames.group}
                    label="Folder"
                    options={folders}
                    variant="outlined"
                    size="small"
                    sx={{ width: 150 }}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem={true} />
              </>
            )}
            <Typography sx={{ alignSelf: "center" }}>Filter by:</Typography>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12} md={4} lg={3} xl={2}>
                <SelectManyField
                  name={FieldNames.status}
                  label="Status"
                  options={requestStatusOptions}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              {!hideRequestTypeFilter && (
                <Grid item={true} xs={12} md={4} lg={3} xl={2}>
                  <SelectOneField
                    name={FieldNames.requestType}
                    label="Request Type"
                    options={requestTypeOptions}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              )}
              <Grid item={true} xs={12} md={4} lg={6} xl={4}>
                <Stack direction="row" spacing={1}>
                  <Typography sx={{ alignSelf: "center" }}>OR</Typography>
                  <Field
                    name={FieldNames.requestId}
                    component={TextField}
                    label="Job Request ID"
                    placeholder="Search by request ID"
                    size="small"
                    fullWidth={true}
                    InputProps={{
                      style: {
                        padding: 0
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton type="submit">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Form>
      </Formik>
    </Card>
  );
};

export { JobRequestFilter };
