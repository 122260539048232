import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClientsPage } from "./pages/ClientsPage/ClientsPage";
import { SystemSettingsPage } from "./pages/SystemSettingsPage/SystemSettingsPage";
import { ClientDetailsPage } from "./pages/ClientsPage/ClientDetailsPage/ClientDetailsPage";
import { ApplicationState } from "./store/store";
import { RestrictedPage } from "./components/RestrictedPage/RestrictedPage";
import { NavMenuOption } from "./components/PageLayout/PageLayout";
import Button from "@mui/material/Button";
import { Auth } from "@aws-amplify/auth";
import { EmailJobRequestsPage } from "./pages/JobRequests/EmailJobRequestsPage/EmailJobRequestsPage";
import { EmailJobRequestDetailsPage } from "./pages/JobRequests/EmailJobRequestsPage/EmailJobRequestDetailsPage/EmailJobRequestDetailsPage";
import { QuoteJobRequestsPage } from "./pages/JobRequests/QuoteJobRequestsPage/QuoteJobRequestsPage";
import { PagePath } from "./types/PagePath";
import { JobRequestOrigination, UserGroup } from "./gen/clients";
import { AnalyticsPage } from "./pages/AnalyticsPage/AnalyticsPage";
import { MachineTranslationAnalyticsPage } from "./pages/AnalyticsPage/MachineTranslationAnalyticsPage/MachineTranslationAnalyticsPage";
import { MachineTranslationClientUsageAnalyticsPage } from "./pages/AnalyticsPage/MachineTranslationClientUsageAnalyticsPage/MachineTranslationClientUsageAnalyticsPage";
import { MTJobRequestsPage } from "./pages/JobRequests/MTJobRequestsPage/MTJobRequestsPage";
import { MTJobRequestDetailsPage } from "./pages/JobRequests/MTJobRequestsPage/MTJobRequestDetailsPage/MTJobRequestDetailsPage";
import { EnabledApplicationAnalyticsPage } from "pages/AnalyticsPage/EnabledApplicationsAnalyticsPage/EnabledApplicationsAnalyticsPage";
import { QuoteJobRequestDetailsPage } from "./pages/JobRequests/QuoteJobRequestsPage/QuoteJobRequestDetailsPage/QuoteJobRequestDetailsPage";
import { AutoQuoteRejectionReasonsAnalyticsPage } from "pages/AnalyticsPage/AutoQuoteRejectionReasonsAnalyticsPage/AutoQuoteRejectionReasonsAnalyticsPage";

const Routes: React.FC = () => {
  const { username, roles } = useSelector((state: ApplicationState) => ({
    roles: state.userSession.roles || [],
    username: state.userSession.username
  }));
  const isAdminUser = roles.includes(UserGroup.ADMIN) || roles.includes(UserGroup.SUPER_USER);
  const isOperatorUser = roles.includes(UserGroup.OPERATOR);
  const adminGroups = [UserGroup.ADMIN, UserGroup.SUPER_USER];
  const operatorGroups = [UserGroup.OPERATOR, UserGroup.ADMIN, UserGroup.SUPER_USER];

  const onSignOutClick = React.useCallback(() => Auth.signOut(), []);

  if (isAdminUser || isOperatorUser) {
    return (
      <Switch>
        <Route path={`${PagePath.clients}`} exact={true}>
          <RestrictedPage allowedRoles={adminGroups} navMenuOption={NavMenuOption.CLIENTS}>
            <ClientsPage />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.clients}/:clientId`}>
          <RestrictedPage allowedRoles={adminGroups} navMenuOption={NavMenuOption.CLIENTS}>
            <ClientDetailsPage />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.emailJobRequests}`} exact={true}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.EMAIL_JOB_REQUESTS}>
            <EmailJobRequestsPage />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.emailJobRequests}/:jobRequestId`}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.EMAIL_JOB_REQUESTS}>
            <EmailJobRequestDetailsPage origination={JobRequestOrigination.EMAIL} />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.mtJobRequests}`} exact={true}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.EMAIL_JOB_REQUESTS}>
            <MTJobRequestsPage />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.mtJobRequests}/:jobRequestId`}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.MT_JOB_REQUESTS}>
            <MTJobRequestDetailsPage />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.quoteJobRequests}`} exact={true}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.QUOTE_JOB_REQUESTS}>
            <QuoteJobRequestsPage />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.quoteJobRequests}/:jobRequestId`}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.QUOTE_JOB_REQUESTS}>
            <QuoteJobRequestDetailsPage />
          </RestrictedPage>
        </Route>
        <Route path="/analytics/machineTranslationRequestCount" exact={true}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.ANALYTICS}>
            <MachineTranslationAnalyticsPage />
          </RestrictedPage>
        </Route>
        <Route path="/analytics/machineTranslationClientUsageAnalytics" exact={true}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.ANALYTICS}>
            <MachineTranslationClientUsageAnalyticsPage />
          </RestrictedPage>
        </Route>
        <Route path="/analytics/enabledApplicationsAnalytics" exact={true}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.ANALYTICS}>
            <EnabledApplicationAnalyticsPage />
          </RestrictedPage>
        </Route>
        <Route path="/analytics/quoteRejectionReasonsAnalytics" exact={true}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.ANALYTICS}>
            <AutoQuoteRejectionReasonsAnalyticsPage />
          </RestrictedPage>
        </Route>
        <Route path={PagePath.analytics} exact={false}>
          <RestrictedPage allowedRoles={operatorGroups} navMenuOption={NavMenuOption.ANALYTICS}>
            <AnalyticsPage />
          </RestrictedPage>
        </Route>
        <Route path={`${PagePath.systemSettings}/:tabId`}>
          <RestrictedPage allowedRoles={[UserGroup.SUPER_USER]} navMenuOption={NavMenuOption.SYSTEM_SETTINGS}>
            <SystemSettingsPage />
          </RestrictedPage>
        </Route>
        <Route path={PagePath.systemSettings}>
          <RestrictedPage allowedRoles={[UserGroup.SUPER_USER]} navMenuOption={NavMenuOption.SYSTEM_SETTINGS}>
            <SystemSettingsPage />
          </RestrictedPage>
        </Route>
        {isAdminUser && <Route path="/" component={ClientsPage} />}
        {isOperatorUser && <Route path="/" component={EmailJobRequestsPage} />}
      </Switch>
    );
  }
  return (
    <div style={{ marginLeft: 20 }}>
      {`You signed in as "${username}". Your account does not have sufficient permissions to access this application.`}
      <Button variant="text" onClick={onSignOutClick}>
        Sign out
      </Button>
    </div>
  );
};

export default Routes;
