import * as React from "react";
import { DrawerHeader } from "../../../../../components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "../../../../../components/DrawerBody/DrawerBody";
import Drawer from "@mui/material/Drawer";
import { useMutation, useQuery } from "react-query";
import { SystemSettingsService } from "../../../../../gen/clients";
import { PageLoading } from "../../../../../components/PageLoading/PageLoading";
import { ApiErrorMessage } from "../../../../../components/ApiErrorMessage/ApiErrorMessage";
import { Form, Formik } from "formik";
import { LabelValue } from "../../../../../components/LabelValue/LabelValue";
import Stack from "@mui/material/Stack";
import { TextInputField } from "../../../../../components/formikFields/TextInputField/TextInputField";
import Box from "@mui/material/Box";
import { LoadingButton } from "../../../../../components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import { SnackbarApiError } from "../../../../../components/SnackbarApiError/SnackbarApiError";

interface Props {
  templateName: string;
  onClose: () => void;
  onSuccess: () => void;
}

enum FieldNames {
  templateName = "templateName",
  subject = "subject",
  text = "text",
  html = "html"
}

const EditEmailTemplatePanel: React.FC<Props> = ({ templateName, onClose, onSuccess }) => {
  const {
    data: template,
    isFetching: isTemplateLoading,
    error: templateError
  } = useQuery(["getEmailTemplate", templateName], {
    queryFn: () => SystemSettingsService.getEmailTemplate({ templateName })
  });
  const {
    mutate,
    error: submitError,
    isLoading: isSubmitting
  } = useMutation({
    mutationFn: SystemSettingsService.updateEmailTemplate,
    onSuccess
  });

  const onSubmit = React.useCallback(
    values => {
      mutate({
        templateName,
        requestBody: {
          subject: values[FieldNames.subject],
          text: values[FieldNames.text],
          html: values[FieldNames.html]
        }
      });
    },
    [templateName, mutate]
  );

  return (
    <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 1000 } }}>
      <DrawerHeader title="Edit Email Template" onClose={onClose} />
      <DrawerBody>
        {isTemplateLoading && <PageLoading />}
        {templateError && <ApiErrorMessage apiError={templateError} />}
        {template && !isTemplateLoading && (
          <>
            <Formik
              initialValues={{
                [FieldNames.subject]: template?.content.subject,
                [FieldNames.text]: template?.content.text,
                [FieldNames.html]: template?.content.html
              }}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              <Form>
                <Stack spacing={1}>
                  <LabelValue label="Email Template Name" value={templateName} />
                  <LabelValue label="Description" value={template?.description} />
                  <LabelValue label="Supported Variables" value={template?.variableNames?.join(", ")} />
                </Stack>
                <Stack spacing={3} mt={3}>
                  <TextInputField name={FieldNames.subject} label="Subject" required={true} variant="outlined" />
                  <TextInputField
                    name={FieldNames.text}
                    label="Text Content"
                    helperText="Text content of the email displayed in email clients that do not support HTML"
                    variant="outlined"
                    multiline={true}
                    rows={10}
                    required={true}
                  />
                  <TextInputField
                    name={FieldNames.html}
                    label="HTML Content"
                    helperText="HTML code of the email content displayed in email clients that support HTML"
                    multiline={true}
                    variant="outlined"
                    rows={10}
                    required={true}
                  />
                </Stack>
                <Box sx={{ mt: 5 }}>
                  <LoadingButton isLoading={isSubmitting}>Save</LoadingButton>
                  <Button color="secondary" onClick={onClose} disabled={isSubmitting} sx={{ ml: 1 }}>
                    Cancel
                  </Button>
                </Box>
              </Form>
            </Formik>
          </>
        )}

        {submitError && <SnackbarApiError error={submitError} />}
      </DrawerBody>
    </Drawer>
  );
};

export { EditEmailTemplatePanel };
