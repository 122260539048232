import * as React from "react";
import { Field, Form, useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import { TextInputField } from "../../../../../../../components/formikFields/TextInputField/TextInputField";
import { SelectOneField } from "../../../../../../../components/formikFields/SelectOneField/SelectOneField";
import Stack from "@mui/material/Stack";
import { DataTable } from "../../../../../../../components/DataTable/DataTable";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { CheckboxWithLabel } from "formik-mui";
import Box from "@mui/material/Box";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { SelectOption, SelectOptionGroup } from "@mui/base";
import { DataTableRowAction } from "components/DataTable/types";
import { useDialogState } from "hooks/useDialogState";
import { AddOptionDialog } from "../../AddOptionDialog";
import { CustomFieldDefinition } from "gen/clients";
import { SelectOneGroupOptionField } from "components/formikFields/SelectOneGroupOptionField/SelectOneGroupOptionField";

interface Props {
  options: SelectOption<string>[];
  setOptions: (options: SelectOption<string>[]) => void;
  mappingOptions: SelectOptionGroup<string>[];
  onCancel: () => void;
  allowTextArea: boolean;
}

const CustomFieldForm: React.FC<Props> = ({ options, setOptions, onCancel, mappingOptions, allowTextArea }) => {
  const { values, isSubmitting } = useFormikContext<Record<string, unknown>>();
  const [isAddOptionDialogOpen, openAddOptionDialog, closeAddOptionDialog] = useDialogState();

  const addOption = React.useCallback(
    (labelValue: SelectOption<string>) => {
      setOptions([...options, labelValue]);
      closeAddOptionDialog();
    },
    [options, setOptions, closeAddOptionDialog]
  );

  const onDeleteOptionClick = React.useCallback(
    (optionIndex: number) => {
      const optionsUpdate = [...options];
      optionsUpdate.splice(optionIndex, 1);
      setOptions(optionsUpdate);
    },
    [options, setOptions]
  );

  const onMoveOptionUpClick = React.useCallback(
    (optionIndex: number) => {
      const optionsUpdate = [...options];
      const option = optionsUpdate[optionIndex];
      optionsUpdate.splice(optionIndex, 1);
      optionsUpdate.splice(optionIndex - 1, 0, option);
      setOptions(optionsUpdate);
    },
    [options, setOptions]
  );

  const onMoveOptionDownClick = React.useCallback(
    (optionIndex: number) => {
      const optionsUpdate = [...options];
      const option = optionsUpdate[optionIndex];
      optionsUpdate.splice(optionIndex, 1);
      optionsUpdate.splice(optionIndex + 1, 0, option);
      setOptions(optionsUpdate);
    },
    [options, setOptions]
  );

  const optionRowActions = React.useCallback(
    (labelValue: SelectOption<string>, index: number) => {
      const actions: DataTableRowAction[] = [];
      if (index > 0) {
        actions.push({
          title: "Move Up",
          action: () => onMoveOptionUpClick(index)
        });
      }
      if (index < options.length - 1) {
        actions.push({
          title: "Move Down",
          action: () => onMoveOptionDownClick(index)
        });
      }
      actions.push({
        title: "Delete",
        action: () => onDeleteOptionClick(index),
        color: "error.light"
      });
      return actions;
    },
    [onMoveOptionUpClick, onMoveOptionDownClick, onDeleteOptionClick, options.length]
  );

  return (
    <>
      <Form noValidate={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TextInputField name="name" label="Field Name" required={true} />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <SelectOneField
              name="type"
              label="Type"
              required={true}
              options={[
                {
                  label: "Input text",
                  value: CustomFieldDefinition.type.INPUT_TEXT
                },
                {
                  label: "Select list",
                  value: CustomFieldDefinition.type.LISTONLY
                },
                {
                  label: "Select list with free text",
                  value: CustomFieldDefinition.type.LIST
                },
                {
                  label: "Select-many list",
                  value: CustomFieldDefinition.type.SELECT_MANY_LIST
                },
                { label: "Date", value: CustomFieldDefinition.type.DATE },
                ...(allowTextArea
                  ? [
                      {
                        label: "Text area",
                        value: CustomFieldDefinition.type.TEXT_AREA
                      }
                    ]
                  : [])
              ]}
              helperText={
                values["type"] === CustomFieldDefinition.type.TEXT_AREA ? (
                  <Box color="warning.main">
                    Note: Text area can be used only with mappings that support multi-line input in XTRF, such as
                    "Internal Notes" and "Vendor Instructions."
                  </Box>
                ) : undefined
              }
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            {["LISTONLY", "LIST", "SELECT_MANY_LIST"].includes(values.type as string) && (
              <Stack spacing={1}>
                <DataTable
                  data={options}
                  columns={[
                    {
                      id: "option",
                      title: "Options",
                      cell: option => (
                        <>
                          Label: {option.label}, Value: {option.value}
                        </>
                      )
                    }
                  ]}
                  rowKey={option => option.label}
                  rowActions={optionRowActions}
                  noData="No options defined"
                />
                <Button onClick={openAddOptionDialog}>Add Option</Button>
              </Stack>
            )}
          </Grid>
          <Grid item={true} xs={12}>
            <SelectOneGroupOptionField name="mapping" label="Mapping" options={mappingOptions} required={true} />
          </Grid>
          <Grid item={true} xs={12}>
            <TextInputField
              name="defaultValue"
              label="Default Value"
              multiline={values.type === CustomFieldDefinition.type.TEXT_AREA}
              rows={values.type === CustomFieldDefinition.type.TEXT_AREA ? 5 : undefined}
              variant={values.type === CustomFieldDefinition.type.TEXT_AREA ? "outlined" : undefined}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <FormControl>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="required"
                Label={{
                  label: "Required"
                }}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <FormControl>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="visible"
                Label={{
                  label: "Visible"
                }}
              />
            </FormControl>
          </Grid>
          <Grid item={true} xs={12}>
            <TextInputField name="validationRegex" label="Validation Regex" />
          </Grid>
          <Grid item={true} xs={12}>
            <TextInputField name="validationMessage" label="Validation Message" />
          </Grid>
          <Grid item={true} xs={12}>
            <TextInputField name="description" label="Description" />
          </Grid>
        </Grid>

        <Box sx={{ mt: 5 }}>
          <LoadingButton isLoading={isSubmitting}>Save</LoadingButton>
          <Button color="secondary" onClick={onCancel} disabled={isSubmitting} sx={{ ml: 1 }}>
            Cancel
          </Button>
        </Box>
      </Form>

      {isAddOptionDialogOpen && (
        <AddOptionDialog
          onClose={closeAddOptionDialog}
          onAdd={addOption}
          useLabelAsValue={values.type === "LIST"}
          valueSeparator={values.type === "SELECT_MANY_LIST" ? "|" : undefined}
        />
      )}
    </>
  );
};

export { CustomFieldForm };
