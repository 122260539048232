import * as React from "react";
import { useMutation } from "react-query";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { ApplicationsService } from "gen/clients";
import { Button, Drawer, FormControl, FormHelperText, Stack } from "@mui/material";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { CheckboxWithLabel } from "formik-mui";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { ApiErrorMessage } from "../../../../../components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const CreateApplicationPanel: React.FC<Props> = ({ open, onClose, onSuccess }) => {
  const { mutate, error } = useMutation("createApplication", {
    onSuccess,
    mutationFn: ApplicationsService.createApplication
  });

  const validationSchema = Yup.object().shape({
    id: Yup.string().required("Required"),
    name: Yup.string().required("Required")
  });

  const onSubmit = React.useCallback(
    formValues => {
      mutate({
        requestBody: {
          id: formValues.id,
          name: formValues.name,
          description: formValues.description,
          isStandard: formValues.isStandard
        }
      });
    },
    [mutate]
  );

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader title="Add Application" onClose={onClose} />
      <DrawerBody>
        <Formik
          initialValues={{
            id: "",
            name: "",
            description: "",
            isStandard: false
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form noValidate={true}>
              <Stack spacing={3}>
                <TextInputField
                  name="id"
                  label="Application ID"
                  helperText="Application ID must be unique across all applications"
                  required={true}
                />
                <TextInputField name="name" label="Application Name" required={true} />
                <TextInputField name="description" label="Description" />
                <FormControl>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="isStandard"
                    helperText="Standard applications are displayed to all clients"
                    Label={{
                      label: "Standard application"
                    }}
                  />
                  <FormHelperText>Standard applications are displayed to all clients</FormHelperText>
                </FormControl>
              </Stack>

              <Stack spacing={1} direction="row" marginTop={4}>
                {!!error && <ApiErrorMessage apiError={error} />}
                <LoadingButton isLoading={isSubmitting}>Add Application</LoadingButton>
                <Button color="secondary" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { CreateApplicationPanel };
