import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { useMutation } from "react-query";
import { UserAccountsService } from "gen/clients";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";

interface Props {
  username: string;
  xtrfClientId?: string;
  xtrfClientContactId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  onSuccess: () => void;
  onClose: () => void;
}

enum FieldName {
  xtrfClientId = "xtrfClientId",
  xtrfClientContactId = "xtrfClientContactId",
  firstName = "firstName",
  lastName = "lastName",
  email = "email"
}

interface FormValues {
  [FieldName.xtrfClientId]: string;
  [FieldName.xtrfClientContactId]: string;
  [FieldName.firstName]: string;
  [FieldName.lastName]: string;
  [FieldName.email]: string;
}

const EditAttributesDialog: React.FC<Props> = ({
  username,
  xtrfClientId,
  xtrfClientContactId,
  firstName,
  lastName,
  email,
  onSuccess,
  onClose
}) => {
  const { mutateAsync, error } = useMutation(UserAccountsService.updateCognitoUserAttributes, { onSuccess });

  const initialValues = React.useMemo<FormValues>(
    () => ({
      [FieldName.xtrfClientId]: xtrfClientId || "",
      [FieldName.xtrfClientContactId]: xtrfClientContactId || "",
      [FieldName.firstName]: firstName || "",
      [FieldName.lastName]: lastName || "",
      [FieldName.email]: email || ""
    }),
    [xtrfClientId, xtrfClientContactId, firstName, lastName, email]
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutateAsync({
        cognitoUsername: encodeURIComponent(username),
        requestBody: {
          xtrfClientId: `${values[FieldName.xtrfClientId]}`,
          xtrfClientContactId: `${values[FieldName.xtrfClientContactId]}`,
          firstName: values[FieldName.firstName],
          lastName: values[FieldName.lastName],
          email: values[FieldName.email]
        }
      });
    },
    [mutateAsync, username]
  );

  return (
    <>
      {error && <SnackbarApiError error={error} />}
      <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Edit Attributes</DialogTitle>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnMount={false}>
          {({ isSubmitting }) => (
            <Form noValidate={true} autoComplete="off" autoCorrect="off">
              <DialogContent>
                <Stack spacing={3}>
                  <TextInputField name={FieldName.xtrfClientId} required={true} label="xtrfClientId" type="number" />
                  <TextInputField
                    name={FieldName.xtrfClientContactId}
                    required={true}
                    label="xtrfClientContactId"
                    type="number"
                  />
                  <TextInputField name={FieldName.firstName} required={true} label="First Name" />
                  <TextInputField name={FieldName.lastName} required={true} label="Last Name" />
                  <TextInputField name={FieldName.email} required={true} label="Email" />
                </Stack>
              </DialogContent>
              <DialogActions>
                <LoadingButton type="submit" color="primary" variant="contained" isLoading={isSubmitting}>
                  Save
                </LoadingButton>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export { EditAttributesDialog };
