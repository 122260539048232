/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomFieldValue } from './CustomFieldValue';
import type { JobRequestFileLink } from './JobRequestFileLink';
import type { JobRequestLanguage } from './JobRequestLanguage';

export type SubmitJobRequestToXtrfRequest = {
    projectName?: string;
    clientId: string;
    clientContactId: number;
    jobRequestType: SubmitJobRequestToXtrfRequest.jobRequestType;
    requestorEmail: string;
    sourceLanguage: JobRequestLanguage;
    targetLanguages: Array<JobRequestLanguage>;
    notes?: string;
    customFieldValues?: Array<CustomFieldValue>;
    provideStandardAndRushQuotes?: boolean;
    fileLinks?: Array<JobRequestFileLink>;
    /**
     * If true, the client will not be notified of the project/quote creation
     */
    suppressClientNotification?: boolean;
    xtrfServiceId?: number;
}

export namespace SubmitJobRequestToXtrfRequest {

    export enum jobRequestType {
        TRANSLATION_REQUEST = 'TRANSLATION_REQUEST',
        QUOTE_REQUEST = 'QUOTE_REQUEST',
    }


}
