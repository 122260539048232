import * as React from "react";
import { Field, useFormikContext } from "formik";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

interface Props extends Omit<SwitchProps, "value | checked"> {
  name: string;
  label?: string;
  helperText?: string;
}

const SwitchField: React.FC<Props> = ({ name, label, helperText, ...other }) => {
  const { values, setFieldValue, touched, errors } = useFormikContext<Record<string, boolean>>();
  const isChecked = values[name] || false;
  const onToggle = () => {
    setFieldValue(name, !isChecked);
  };
  return (
    <>
      <FormControlLabel
        control={<Field name={name} render={() => <Switch checked={isChecked} onChange={onToggle} {...other} />} />}
        label={label}
      />
      <FormHelperText error={touched[name] && !!errors[name]}>
        {(touched[name] && errors[name]) || helperText}
      </FormHelperText>
    </>
  );
};

export { SwitchField };
