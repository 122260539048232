import * as React from "react";
import { JobRequest, JobRequestStatus } from "gen/clients";
import { LabelValue } from "components/LabelValue/LabelValue";
import { Typography } from "@mui/material";

interface Props {
  jobRequest: JobRequest;
}

const SourceLanguageLabel: React.FC<Props> = ({ jobRequest }) => {
  if (!jobRequest.sourceLanguage && !jobRequest.aiAnalysis) {
    return <span />;
  }

  const useAIValue = [JobRequestStatus.READY_FOR_INTERNAL_REVIEW].includes(jobRequest.status) && jobRequest.aiAnalysis;

  return (
    <LabelValue
      label="Source Language"
      value={
        <>
          {!useAIValue && <Typography>{jobRequest.sourceLanguage?.name}</Typography>}
          {useAIValue && (
            <Typography
              sx={theme => ({
                color: jobRequest.aiAnalysis?.sourceLanguage?.xtrfLangId
                  ? theme.palette.success.main
                  : theme.palette.error.main
              })}
            >
              {jobRequest.aiAnalysis?.sourceLanguage?.name || jobRequest.sourceLanguage?.name}
            </Typography>
          )}
        </>
      }
    />
  );
};

export { SourceLanguageLabel };
