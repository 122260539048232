/**
 * Sanitizes a formik field name.  Removes periods and apostrophes that cause problems.
 * @param name filed name
 * @return formik safe name
 */
export function sanitizeFieldName(name: string): string {
  let sanitizedFieldName = name.replaceAll(".", "");
  sanitizedFieldName = sanitizedFieldName.replaceAll("'", "");
  return sanitizedFieldName;
}
