import { Language, MtLanguage } from "gen/clients";
import { SelectOption } from "@mui/base";

// Type guard function to check if the object is of type Language
function isLanguage(obj: Language | MtLanguage): obj is Language {
  return (obj as Language).id !== undefined;
}
// Function to create a SelectOption with MtLanguage or Language
export function createSelectOptionWithLanguage(language: Language | MtLanguage): { label: string; value: string } {
  if (isLanguage(language)) {
    return {
      label: `${language.name} [${language.id}]`,
      value: `${language.id}`
    };
  }
  return { label: `${language.name} [${language.code}]`, value: language.code };
}

// Function to get an array of SelectOptions from an array of MtLanguage or Language
export function getLanguageOptions(languages: (Language | MtLanguage)[]): SelectOption<string>[] {
  return languages.map(l => createSelectOptionWithLanguage(l)).sort((o1, o2) => o1.label.localeCompare(o2.label));
}
