import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "../../../../components/LoadingButton/LoadingButton";
import { useMutation } from "react-query";
import { JobRequestsService } from "../../../../gen/clients";
import { validateEmailFormat } from "../../../../utils/stringUtils";
import { ApiErrorMessage } from "../../../../components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  jobRequestId: string;
  onSuccess: () => void;
  onClose: () => void;
}

enum FieldNames {
  email = "email"
}

const ForwardOriginalEmailDialog: React.FC<Props> = ({ jobRequestId, onSuccess, onClose }) => {
  const { mutate, isLoading, error, isSuccess } = useMutation(JobRequestsService.forwardJobRequestEmail);

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const validate = React.useCallback(values => {
    const errors: Record<string, string> = {};
    const email = values[FieldNames.email];
    if (!validateEmailFormat(email)) {
      errors[FieldNames.email] = "Invalid email format";
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    values => {
      mutate({
        jobRequestId,
        requestBody: {
          emailTo: values[FieldNames.email]
        }
      });
    },
    [jobRequestId, mutate]
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Forward Original Email</DialogTitle>
      <Formik
        initialValues={{
          [FieldNames.email]: ""
        }}
        onSubmit={onSubmit}
        validate={validate}
        validateOnMount={false}
      >
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <DialogContent>
            {error && <ApiErrorMessage apiError={error} />}
            <Box>Please enter email address where you would like to forward the original email:</Box>
            <TextInputField name={FieldNames.email} label="Email address" required={true} />
          </DialogContent>
          <DialogActions>
            <LoadingButton type="submit" color="primary" variant="contained" isLoading={isLoading}>
              Send
            </LoadingButton>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { ForwardOriginalEmailDialog };
