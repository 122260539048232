import * as React from "react";
import { DrawerHeader } from "../../../../../components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "../../../../../components/DrawerBody/DrawerBody";
import Drawer from "@mui/material/Drawer";
import { useMutation } from "react-query";
import {
  AiProcessingService,
  OpenAIRequest,
  SystemSettings,
  SystemSettingsService,
  SystemSettingsUpdate
} from "../../../../../gen/clients";
import { Form, Formik, FormikProps } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "../../../../../components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "../../../../../components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import { Accordion, AccordionDetails, AccordionSummary, Box, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { DEFAULT_TEST_INPUT } from "./defaultTestInput";

interface Props {
  systemSettings: SystemSettings;
  onSuccess: () => void;
  onClose: () => void;
}

enum FieldNames {
  instructions = "instructions",
  testData = "testData"
}

interface FormValues {
  [FieldNames.instructions]: string;
  [FieldNames.testData]: string;
}

const EditInstructionsPanel: React.FC<Props> = ({ systemSettings, onClose, onSuccess }) => {
  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const [aiOutput, setAIOutput] = React.useState<string>("");
  const [isManualTesting, setIsManualTesting] = React.useState<boolean>(false);
  const [validateWithDefaults, setValidateWithDefaults] = React.useState(true);
  const [isValidating, setIsValidating] = React.useState(false);

  const { mutateAsync: updateSystemSettings, error: saveError } = useMutation(
    SystemSettingsService.updateSystemSettings
  );

  const { mutateAsync: processTextByOpenAi } = useMutation(AiProcessingService.processTextByOpenAi);

  const onTest = React.useCallback(() => {
    setIsManualTesting(true);
    setValidateWithDefaults(false);

    // Call validateForm asynchronously to make sure the state has updated.
    setTimeout(() => {
      formikRef.current?.validateForm();
      setValidateWithDefaults(true);
      setIsManualTesting(false);
    }, 0);
  }, [setValidateWithDefaults, setIsManualTesting]);

  const validate = React.useCallback(
    async formValues => {
      setIsValidating(true);
      const text = validateWithDefaults ? DEFAULT_TEST_INPUT : formValues[FieldNames.testData] || "";
      const errors: Record<string, string> = {};
      const response = await processTextByOpenAi({
        requestBody: {
          type: OpenAIRequest.type.REJECTION_REASONS,
          text,
          instructions: formValues[FieldNames.instructions] || ""
        }
      });

      if (!validateWithDefaults) {
        // fill ai output only for custom data testing
        setAIOutput(response.output || "");
      }

      if (response.output === undefined) {
        errors.instructions = "AI output doesn't match the expected structure";
      }
      setIsValidating(false);
      return errors;
    },
    [validateWithDefaults, setIsValidating, processTextByOpenAi]
  );

  const onSubmit = React.useCallback(
    async values => {
      const updatedSettings: SystemSettingsUpdate = {
        ...systemSettings,
        autoQuoteSettings: {
          ...systemSettings.autoQuoteSettings,
          rejectedReasonsAnalyticsAIPrompt: values[FieldNames.instructions]
        }
      };
      await updateSystemSettings({ requestBody: updatedSettings });
      onSuccess();
    },
    [onSuccess, systemSettings, updateSystemSettings]
  );

  const isTesting = React.useMemo(() => isValidating && !formikRef.current?.isSubmitting, [isValidating]);
  const isSubmitting = React.useMemo(
    () => (formikRef.current?.isSubmitting && (isTesting || !isManualTesting)) || false,
    [isTesting, isManualTesting]
  );

  return (
    <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: "50vw" } }}>
      <DrawerHeader title="Edit Auto-Quote Rejection Reason Analytics Instructions" onClose={onClose} />
      <DrawerBody>
        <Formik
          innerRef={formikRef}
          initialValues={{
            [FieldNames.instructions]: systemSettings?.autoQuoteSettings.rejectedReasonsAnalyticsAIPrompt || "",
            [FieldNames.testData]: DEFAULT_TEST_INPUT
          }}
          validateOnMount={false}
          validateOnChange={false}
          validateOnBlur={false}
          validate={validate}
          onSubmit={onSubmit}
        >
          <Form noValidate={true}>
            <Stack spacing={3} mt={3}>
              <TextInputField
                name={FieldNames.instructions}
                label="Instructions"
                helperText="Instructions for auto-quote rejection reasons analytics"
                variant="outlined"
                multiline={true}
                rows={10}
              />
              <Box sx={{ pt: 2 }}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" fontWeight="bold">
                      Test Instructions
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={3} mt={3}>
                      <TextInputField
                        name={FieldNames.testData}
                        label="Sample text"
                        helperText="Enter a sample list of auto-quote rejection reasons"
                        variant="outlined"
                        multiline={true}
                        rows={5}
                      />
                      <TextField
                        label="AI Output"
                        variant="outlined"
                        multiline={true}
                        rows={5}
                        aria-readonly
                        disabled
                        value={aiOutput}
                        fullWidth
                      />
                    </Stack>
                    <Stack direction="row" spacing={1} mt={3}>
                      <LoadingButton
                        color="secondary"
                        variant="outlined"
                        onClick={onTest}
                        type="button"
                        isLoading={isTesting}
                        disabled={isSubmitting}
                      >
                        Test
                      </LoadingButton>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Box>
              {saveError && <SnackbarApiError error={saveError} />}
            </Stack>
            <Stack direction="row" spacing={1} mt={3}>
              <LoadingButton isLoading={isSubmitting} disabled={isTesting}>
                Save
              </LoadingButton>
              <Button color="secondary" onClick={onClose} disabled={isTesting || isSubmitting}>
                Cancel
              </Button>
            </Stack>
          </Form>
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { EditInstructionsPanel };
