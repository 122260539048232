import * as React from "react";
import { Box } from "@mui/material";
import { PageLoading } from "../PageLoading/PageLoading";

interface Props {
  isLoading?: boolean;
  children: React.ReactNode;
}

const DrawerBody: React.FC<Props> = ({ isLoading, children }) => (
  <Box sx={{ px: 3, py: 1 }}>{isLoading ? <PageLoading /> : children}</Box>
);

export { DrawerBody };
