import * as React from "react";
import { useMutation, useQuery } from "react-query";
import {
  ApiError,
  ApplicationCollection,
  ApplicationsService,
  ClientConfig,
  ClientsService
} from "../../../../../gen/clients";
import { Form, Formik } from "formik";
import { Box, Card, Typography } from "@mui/material";
import { ApiErrorMessage } from "../../../../../components/ApiErrorMessage/ApiErrorMessage";
import { LoadingButton } from "../../../../../components/LoadingButton/LoadingButton";
import { PageLoading } from "../../../../../components/PageLoading/PageLoading";
import { SelectOption } from "@mui/base";
import { ApplicationSettingsPanel } from "./ApplicationSettingsPanel/ApplicationSettingsPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import { CheckboxesField } from "../../../../../components/formikFields/CheckboxesField/CheckboxesField";
import { ApplicationId } from "../../../../../types/ApplicationId";

interface Props {
  clientConfig: ClientConfig;
  onSaveSuccess: () => void;
}

enum TabId {
  enabledApplications = "enabledApplications",
  applicationSettings = "applicationSettings"
}

const AppsWithSettings: string[] = [ApplicationId.reports, ApplicationId.machineTranslation];

const ApplicationsTab: React.FC<Props> = ({ clientConfig, onSaveSuccess }) => {
  const [selectedTabId, setSelectedTabId] = React.useState<string | null>(TabId.enabledApplications);

  const {
    data: applications,
    isLoading: areApplicationsLoading,
    error: applicationsError
  } = useQuery<ApplicationCollection, ApiError>("listApplications", {
    queryFn: ApplicationsService.listApplications,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  const {
    mutate,
    isError: isMutateError,
    error: mutateError,
    isLoading: isMutating
  } = useMutation("updateClientConfig", {
    mutationFn: clientConfig ? ClientsService.updateClientConfig : ClientsService.createClientConfig,
    onSuccess: onSaveSuccess
  });

  const applicationOptions = React.useMemo<SelectOption<string>[]>(() => {
    if (!applications) {
      return [];
    }
    return applications.items
      .sort((app1, app2) => {
        if (app1.isStandard && !app2.isStandard) {
          return -1;
        }
        if (!app1.isStandard && app2.isStandard) {
          return 1;
        }
        return (app1.name || app1.id).localeCompare(app2.name || app2.id);
      })
      .map(app => ({ label: app.name, value: app.id, disabled: app.isStandard }));
  }, [applications]);

  const initialAppValues = React.useMemo(() => {
    const checkedApps: string[] =
      applications?.items
        .filter(app => app.isStandard || clientConfig?.enabledApplications?.includes(app.id))
        .map(app => app.id) || [];
    return checkedApps;
  }, [clientConfig, applications?.items]);

  const isApplicationSettingsVisible = React.useMemo(
    () => initialAppValues.some(a => AppsWithSettings.includes(a)),
    [initialAppValues]
  );

  const onSubmit = React.useCallback(
    async formValues => {
      const clientConfigUpdate: ClientConfig = { ...clientConfig };
      // Do not submit standard applications
      clientConfigUpdate.enabledApplications = formValues.applications.filter(
        (appId: string) => !applications?.items.find(a => a.id === appId)?.isStandard
      );
      await mutate({
        id: `${clientConfig.id}`,
        requestBody: clientConfigUpdate
      });
    },
    [mutate, clientConfig, applications]
  );

  if (areApplicationsLoading) {
    return <PageLoading />;
  }

  if (applicationsError) {
    return <ApiErrorMessage apiError={applicationsError} />;
  }

  if (!applications?.items.length) {
    return <Typography>Applications are not configured</Typography>;
  }

  return (
    <>
      {applications && (
        <Card sx={{ p: 2 }}>
          <Stack direction="row">
            <Tabs
              orientation="vertical"
              value={selectedTabId || undefined}
              onChange={(event, value) => setSelectedTabId(value)}
              sx={{ borderRight: 1, borderColor: "divider", minWidth: 220 }}
            >
              <Tab value={TabId.enabledApplications} label="Enabled Applications" sx={{ alignItems: "start" }} />
              {isApplicationSettingsVisible && (
                <Tab value={TabId.applicationSettings} label="Application Settings" sx={{ alignItems: "start" }} />
              )}
            </Tabs>
            {selectedTabId === TabId.enabledApplications && (
              <Box sx={{ px: 3, flexGrow: 1 }}>
                <Formik
                  initialValues={{
                    applications: initialAppValues,
                    emailDomains: clientConfig?.allowedEmailDomains?.join(", ") || "",
                    xtrfServiceId: clientConfig?.xtrfServiceId || ""
                  }}
                  onSubmit={onSubmit}
                  validateOnChange={false}
                  validateOnBlur={true}
                >
                  <Form>
                    <CheckboxesField name="applications" options={applicationOptions} />
                    <Box sx={{ mt: 2 }}>
                      {isMutateError && <ApiErrorMessage apiError={mutateError as ApiError} />}
                      <LoadingButton isLoading={isMutating}>Save</LoadingButton>
                    </Box>
                  </Form>
                </Formik>
              </Box>
            )}
            {selectedTabId === TabId.applicationSettings && (
              <ApplicationSettingsPanel
                enabledApplications={initialAppValues}
                clientConfig={clientConfig}
                onSaveSuccess={onSaveSuccess}
              />
            )}
          </Stack>
        </Card>
      )}
    </>
  );
};

export { ApplicationsTab };
