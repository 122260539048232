import * as React from "react";
import { useQuery } from "react-query";
import {
  ErrorResponse,
  Language,
  LanguagesService,
  LltsLanguage,
  MtLanguage,
  MtServiceProvider,
  UtilsService
} from "gen/clients";
import { useDialogState } from "hooks/useDialogState";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataTable } from "components/DataTable/DataTable";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { CreateLanguagePanel } from "./components/CreateLanguagePanel";
import { EditLanguagePanel } from "./components/EditLanguagePanel";
import { TablePagination } from "@mui/material";

const LanguagesTab: React.FC = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selectedLanguage, setSelectedLanguage] = React.useState<LltsLanguage | undefined>();
  const [isCreatePanelOpen, openCreatePanel, closeCreatePanel] = useDialogState();
  const [isEditPanelOpen, openEditPanel, closeEditPanel] = useDialogState();

  const {
    data: lltsLanguages,
    isLoading: areLltsLanguagesLoading,
    error: lltsLanguagesError,
    refetch: refetchLltsLanguages
  } = useQuery<LltsLanguage[], ErrorResponse>(["listLltsLanguages"], {
    queryFn: () => LanguagesService.listLltsLanguages({ includeDisabled: true }),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
  const { data: xtrfLanguages, isLoading: areXtrfLanguagesLoading } = useQuery<Language[], ErrorResponse>(
    ["listXtrfLanguages"],
    UtilsService.listLanguages,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
  const { data: intentoLanguages, isLoading: areIntentoLanguagesLoading } = useQuery<MtLanguage[], ErrorResponse>(
    ["listIntentoLanguages"],
    {
      queryFn: () =>
        UtilsService.listMachineTranslationLanguages({
          provider: MtServiceProvider.INTENTO
        }),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
  const { data: smartMateLanguages, isLoading: areSmartMateLanguagesLoading } = useQuery<MtLanguage[], ErrorResponse>(
    ["listSmartMateLanguages"],
    {
      queryFn: () =>
        UtilsService.listMachineTranslationLanguages({
          provider: MtServiceProvider.SMART_MATE
        }),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );
  const { data: azureLanguages, isLoading: areAzureLanguagesLoading } = useQuery<MtLanguage[], ErrorResponse>(
    ["listAzureLanguages"],
    {
      queryFn: () =>
        UtilsService.listMachineTranslationLanguages({
          provider: MtServiceProvider.AZURE
        }),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  const languageIds = React.useMemo(() => lltsLanguages?.map(l => l.id) || [], [lltsLanguages]);

  const sortedLltsLanguages = React.useMemo(
    () => lltsLanguages?.sort((l1, l2) => l1.name.toLowerCase().localeCompare(l2.name.toLowerCase())) || [],
    [lltsLanguages]
  );

  const paginatedLltsLanguages = React.useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const pageItems = sortedLltsLanguages.slice(startIndex, endIndex);
    return pageItems;
  }, [sortedLltsLanguages, page, rowsPerPage]);

  const onEditClick = React.useCallback(
    (language: LltsLanguage) => {
      setSelectedLanguage(language);
      openEditPanel();
    },
    [setSelectedLanguage, openEditPanel]
  );

  const rowActions = React.useCallback(
    () => [
      {
        title: "Edit",
        action: onEditClick
      }
    ],
    [onEditClick]
  );

  const onPageChange = React.useCallback((event, selectedPage: number) => {
    setPage(selectedPage);
  }, []);

  const onRowsPerPageChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  }, []);

  const onCreateSuccess = React.useCallback(() => {
    refetchLltsLanguages();
    closeCreatePanel();
  }, [refetchLltsLanguages, closeCreatePanel]);

  const onEditSuccess = React.useCallback(() => {
    refetchLltsLanguages();
    closeEditPanel();
  }, [refetchLltsLanguages, closeEditPanel]);

  const xtrfCell = React.useCallback(
    (lang: LltsLanguage) => {
      const langMapping = xtrfLanguages?.find(l => l.id === lang.xtrfId);
      return langMapping ? `${langMapping?.name} [${langMapping.id}]` : lang.xtrfId;
    },
    [xtrfLanguages]
  );

  const intentoCell = React.useCallback(
    (lang: LltsLanguage) => {
      const langMapping = intentoLanguages?.find(l => l.code === lang.intentoId);
      return langMapping ? `${langMapping?.name} [${langMapping.code}]` : lang.intentoId;
    },
    [intentoLanguages]
  );

  const smartMateCell = React.useCallback(
    (lang: LltsLanguage) => {
      const langMapping = smartMateLanguages?.find(l => l.code === lang.smartMateId);
      return langMapping ? `${langMapping?.name} [${langMapping.code}]` : lang.smartMateId;
    },
    [smartMateLanguages]
  );

  const azureCell = React.useCallback(
    (lang: LltsLanguage) => {
      const langMapping = azureLanguages?.find(l => l.code === lang.azureId);
      return langMapping ? `${langMapping?.name} [${langMapping.code}]` : lang.azureId;
    },
    [azureLanguages]
  );

  const statusCell = React.useCallback((lang: LltsLanguage) => (lang.isDisabled ? "Disabled" : "Enabled"), []);

  return (
    <>
      <Box sx={{ mt: 3, mb: 2 }}>
        <Button variant="contained" color="primary" size="large" onClick={openCreatePanel}>
          Add Language
        </Button>
      </Box>
      <DataTable
        columns={[
          {
            id: "id",
            title: "ID",
            cell: (lang: LltsLanguage) => <div>{lang.id}</div>
          },
          {
            id: "name",
            title: "Name",
            cell: (lang: LltsLanguage) => <div>{lang.name}</div>
          },
          {
            id: "iso",
            title: "ISO Code",
            cell: (lang: LltsLanguage) => <div>{lang.iso}</div>
          },
          {
            id: "regionCode",
            title: "Region Code",
            cell: (lang: LltsLanguage) => <div>{lang.regionCode}</div>
          },
          {
            id: "xtrfId",
            title: "XTRF",
            cell: xtrfCell
          },
          {
            id: "intentoId",
            title: "Intento",
            cell: intentoCell
          },
          {
            id: "azureId",
            title: "Azure",
            cell: azureCell
          },
          {
            id: "smartMateId",
            title: "SmartMate",
            cell: smartMateCell
          },
          {
            id: "isEnabled",
            title: "Status",
            cell: statusCell
          }
        ]}
        data={paginatedLltsLanguages}
        isLoading={
          areLltsLanguagesLoading ||
          areXtrfLanguagesLoading ||
          areIntentoLanguagesLoading ||
          areSmartMateLanguagesLoading ||
          areAzureLanguagesLoading
        }
        error={lltsLanguagesError && <ApiErrorMessage apiError={lltsLanguagesError} />}
        rowKey={(lang: LltsLanguage) => lang.id}
        rowActions={rowActions}
        dense={true}
      />

      {sortedLltsLanguages.length > 0 && (
        <TablePagination
          component="div"
          count={sortedLltsLanguages.length}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}

      <CreateLanguagePanel
        open={isCreatePanelOpen}
        onClose={closeCreatePanel}
        onSuccess={onCreateSuccess}
        lltsLanguageIds={languageIds}
        xtrfLanguages={xtrfLanguages || []}
        intentoLanguages={intentoLanguages || []}
        smartMateLanguages={smartMateLanguages || []}
        azureLanguages={azureLanguages || []}
      />

      {selectedLanguage && (
        <EditLanguagePanel
          language={selectedLanguage}
          open={isEditPanelOpen}
          onClose={closeEditPanel}
          onSuccess={onEditSuccess}
          xtrfLanguages={xtrfLanguages || []}
          intentoLanguages={intentoLanguages || []}
          smartMateLanguages={smartMateLanguages || []}
          azureLanguages={azureLanguages || []}
        />
      )}
    </>
  );
};

export { LanguagesTab };
