import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableRowAction } from "components/DataTable/types";
import { AddFileLinkDialog } from "./AddFileLinkDialog";
import { JobRequestFileLink } from "gen/clients";
import { useDialogState } from "hooks/useDialogState";

interface FileLinksFieldProps {
  name: string;
}

const FileLinksField: React.FC<FileLinksFieldProps> = ({ name }) => {
  const { values, setFieldValue, errors, setFieldError, touched } = useFormikContext<Record<string, unknown>>();

  const [isAddFileLinkDialogOpen, openAddFileLinkDialog, closeAddFileLinkDialog] = useDialogState();

  const fileLinkList = useMemo(() => (values[name] || []) as JobRequestFileLink[], [name, values]);

  const updateFileLinkList = React.useCallback(
    (fileLinks: JobRequestFileLink[]) => {
      setFieldValue(name, fileLinks);
    },
    [name, setFieldValue]
  );

  const rowKey = React.useCallback((fileLink: JobRequestFileLink) => fileLink.url, []);
  const urlCell = React.useCallback((fileLink: JobRequestFileLink) => fileLink.url, []);
  const nameCell = React.useCallback((fileLink: JobRequestFileLink) => fileLink.name, []);

  const rowActions = React.useCallback(
    (fileLink: JobRequestFileLink): DataTableRowAction[] => [
      {
        title: "Delete",
        color: "error.light",
        action: () => {
          const indexOfSelectedFilelink = fileLinkList.findIndex(link => link.url === fileLink.url);
          if (indexOfSelectedFilelink !== -1) {
            fileLinkList.splice(indexOfSelectedFilelink, 1);
            updateFileLinkList(fileLinkList);
          }
        }
      }
    ],
    [fileLinkList, updateFileLinkList]
  );

  const onAddFileLink = React.useCallback(
    (fileLink: JobRequestFileLink) => {
      closeAddFileLinkDialog();
      updateFileLinkList([...fileLinkList, fileLink]);
      setFieldError(name, undefined);
    },
    [closeAddFileLinkDialog, fileLinkList, name, setFieldError, updateFileLinkList]
  );

  return (
    <div>
      <Button type="button" variant="contained" onClick={() => openAddFileLinkDialog()} sx={{ mb: 1 }}>
        Add File Link
      </Button>
      {fileLinkList.length > 0 && (
        <DataTable
          columns={[
            {
              id: "url",
              title: "URL",
              cell: urlCell
            },
            {
              id: "name",
              title: "Name",
              cell: nameCell
            }
          ]}
          data={fileLinkList}
          rowKey={rowKey}
          rowActions={rowActions}
          dense={true}
        />
      )}

      {touched[name] && errors[name] && <FormHelperText error={true}>{errors[name]}</FormHelperText>}

      {isAddFileLinkDialogOpen && (
        <AddFileLinkDialog uniqueLinks={fileLinkList} onAdd={onAddFileLink} onClose={closeAddFileLinkDialog} />
      )}
    </div>
  );
};

export default FileLinksField;
