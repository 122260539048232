import * as React from "react";
import { useMutation } from "react-query";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Language, LanguagesService, MtLanguage } from "gen/clients";
import { Button, Drawer, Stack } from "@mui/material";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { ApiErrorMessage } from "../../../../../components/ApiErrorMessage/ApiErrorMessage";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import { getLanguageOptions } from "./languageUtils";

interface Props {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  lltsLanguageIds: string[];
  xtrfLanguages?: Language[];
  intentoLanguages?: MtLanguage[];
  smartMateLanguages?: MtLanguage[];
  azureLanguages?: MtLanguage[];
}

const isUniqueId = (existingIds: string[]) =>
  Yup.string().test({
    name: "unique-iso",
    exclusive: true,
    message: "Language ID must be unique.",
    test: value => {
      if (!value) {
        return true;
      } // If value is undefined or null, treat it as valid
      return !existingIds.includes(value);
    }
  });

const CreateLanguagePanel: React.FC<Props> = ({
  open,
  onClose,
  onSuccess,
  lltsLanguageIds,
  xtrfLanguages,
  intentoLanguages,
  azureLanguages,
  smartMateLanguages
}) => {
  const { mutate, error } = useMutation("createLltsLanguage", {
    onSuccess,
    mutationFn: LanguagesService.createLltsLanguage
  });

  const validationSchema = Yup.object().shape({
    id: isUniqueId(lltsLanguageIds).required("Required"),
    name: Yup.string().required("Required"),
    iso: Yup.string()
      .required("Required")
      .matches(/^[A-Za-z]+$/, {
        message: "Language ISO code should contain only letters."
      })
  });

  const onSubmit = React.useCallback(
    formValues => {
      mutate({
        requestBody: {
          id: formValues.id,
          name: formValues.name,
          iso: formValues.iso,
          regionCode: formValues.regionCode,
          xtrfId: +formValues.xtrfId?.value,
          intentoId: formValues.intentoId?.value,
          smartMateId: formValues.smartMateId?.value,
          azureId: formValues.azureId?.value
        }
      });
    },
    [mutate]
  );

  const xtrfLanguageOptions = React.useMemo(() => getLanguageOptions(xtrfLanguages || []), [xtrfLanguages]);

  const intentoLanguageOptions = React.useMemo(() => getLanguageOptions(intentoLanguages || []), [intentoLanguages]);

  const smartMateLanguageOptions = React.useMemo(
    () => getLanguageOptions(smartMateLanguages || []),
    [smartMateLanguages]
  );

  const azureLanguageOptions = React.useMemo(() => getLanguageOptions(azureLanguages || []), [azureLanguages]);

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader title="Add Language" onClose={onClose} />
      <DrawerBody>
        <Formik
          initialValues={{
            id: "",
            name: "",
            iso: "",
            regionCode: "",
            xtrfId: undefined,
            intentoId: "",
            smartMateID: ""
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form noValidate={true}>
              <Stack spacing={3}>
                <TextInputField
                  name="id"
                  label="Language ID"
                  helperText="Language ID must be unique across all languages"
                  required={true}
                />
                <TextInputField name="name" label="Language Name" required={true} />
                <Stack direction="row" spacing={1}>
                  <TextInputField name="iso" label="ISO Code" required={true} />
                  <TextInputField name="regionCode" label="Region Code" />
                </Stack>
                <SelectOneAutocompleteField name="xtrfId" label="XTRF" options={xtrfLanguageOptions} />
                <SelectOneAutocompleteField name="intentoId" label="Intento" options={intentoLanguageOptions} />
                <SelectOneAutocompleteField name="azureId" label="Azure" options={azureLanguageOptions} />
                <SelectOneAutocompleteField name="smartMateId" label="SmartMate" options={smartMateLanguageOptions} />
              </Stack>

              <Stack spacing={1} direction="row" marginTop={4}>
                {!!error && <ApiErrorMessage apiError={error} />}
                <LoadingButton isLoading={isSubmitting}>Add Language</LoadingButton>
                <Button color="secondary" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { CreateLanguagePanel };
