import * as React from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

interface Props {
  standardRange?: StandardMetricTimeRange;
  onStandardRangeChange: (hours: StandardMetricTimeRange) => void;
}

export type StandardMetricTimeRange = 1 | 3 | 12 | 24 | 72 | 168 | 336 | 744 | 1488;

const TimeRangeSelector: React.FC<Props> = ({ standardRange, onStandardRangeChange }) => (
  <ToggleButtonGroup
    color="primary"
    value={standardRange}
    exclusive
    onChange={(e, v) => {
      if (v !== null) {
        onStandardRangeChange(v);
      }
    }}
    aria-label="Platform"
    size="small"
  >
    <ToggleButton value={1}>1h</ToggleButton>
    <ToggleButton value={3}>3h</ToggleButton>
    <ToggleButton value={12}>12h</ToggleButton>
    <ToggleButton value={24}>1d</ToggleButton>
    <ToggleButton value={3 * 24}>3d</ToggleButton>
    <ToggleButton value={7 * 24}>1w</ToggleButton>
    <ToggleButton value={14 * 24}>2w</ToggleButton>
    <ToggleButton value={31 * 24}>1m</ToggleButton>
    <ToggleButton value={62 * 24}>2m</ToggleButton>
  </ToggleButtonGroup>
);

export { TimeRangeSelector };
