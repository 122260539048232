/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReportDefinition = {
    id: string;
    viewId: number;
    name: string;
    description?: string;
    type: ReportDefinition.type;
    viewParams?: string;
    visibleFilters?: Array<string>;
}

export namespace ReportDefinition {

    export enum type {
        PROJECT = 'PROJECT',
        QUOTE = 'QUOTE',
        TASK = 'TASK',
        CLIENT_CONTACT = 'CLIENT_CONTACT',
    }


}
