import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { ClientContactSummary } from "gen/clients";
import { Tabs, Tab, Box } from "@mui/material";
import { AccountSettingsTab } from "./tabs/AccountSettingsTab/AccountSettingsTab";
import Divider from "@mui/material/Divider";
import { LinkedClientsTab } from "./tabs/LinkedClientsTab/LinkedClientsTab";

interface Props {
  clientContact: ClientContactSummary;
  onClose: () => void;
}

enum TabId {
  ACCOUNT_SETTINGS = "ACCOUNT_SETTINGS",
  LINKED_ORGS = "LINKED_ORGS"
}

const ClientContactDetailsPanel: React.FC<Props> = ({ clientContact, onClose }) => {
  // State for the selected tab
  const [selectedTab, setSelectedTab] = React.useState(TabId.ACCOUNT_SETTINGS);

  // Handler for tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: TabId) => {
    setSelectedTab(newValue);
  };

  return (
    <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: "60vw" } }}>
      <DrawerHeader
        onClose={onClose}
        title={`Client Contact Details: ${clientContact.firstName} ${clientContact.lastName} (${clientContact.email})`}
      />
      <DrawerBody>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Client Contact Tabs">
          <Tab value={TabId.ACCOUNT_SETTINGS} label="Account Settings" />
          <Tab value={TabId.LINKED_ORGS} label="Linked Clients" />
        </Tabs>
        <Divider />
        <Box sx={{ p: 2 }}>
          {selectedTab === TabId.ACCOUNT_SETTINGS && <AccountSettingsTab clientContact={clientContact} />}
          {selectedTab === TabId.LINKED_ORGS && (
            <LinkedClientsTab clientContactId={clientContact.id} clientId={clientContact.clientId} />
          )}
        </Box>
      </DrawerBody>
    </Drawer>
  );
};

export { ClientContactDetailsPanel };
