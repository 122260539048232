import * as React from "react";
import { DataTable } from "components/DataTable/DataTable";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { AddLanguageGroupDialog } from "./AddLanguageGroupDialog";
import { EditLanguageGroupDialog } from "./EditLanguageGroupDialog";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { useMutation } from "react-query";
import { useDialogState } from "hooks/useDialogState";
import { ClientConfig, ClientsService, LanguageGroup } from "gen/clients";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  clientConfig: ClientConfig;
  viewOnly?: boolean;
  onChange: () => void;
}

const LanguageGroupsTable: React.FC<Props> = ({ clientConfig, viewOnly, onChange }) => {
  const [selectedLanguageGroup, setSelectedLanguageGroup] = React.useState<LanguageGroup | null>(null);
  const [selectedLanguageGroupIndex, setSelectedLanguageGroupIndex] = React.useState<number>(-1);
  const [isAddDialogOpen, openAddDialog, closeAddDialog] = useDialogState();
  const [isEditDialogOpen, openEditDialog, closeEditDialog] = useDialogState();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();

  const { mutate, isLoading: isSaving, error: saveError } = useMutation(ClientsService.updateClientConfig);

  const languageGroups = React.useMemo(
    () => (clientConfig.languageGroups || []).sort((a, b) => a.name.localeCompare(b.name)),
    [clientConfig]
  );

  const nameCell = React.useCallback((row: LanguageGroup) => {
    return <div>{row.name}</div>;
  }, []);

  const languagesCell = React.useCallback((row: LanguageGroup) => {
    return (
      <div>
        {row.languages
          ?.map(l => l.name)
          .sort((a, b) => a.localeCompare(b))
          .join(", ")}
      </div>
    );
  }, []);

  const onEditClick = React.useCallback(
    (languageGroup: LanguageGroup, index: number) => {
      setSelectedLanguageGroup(languageGroup);
      setSelectedLanguageGroupIndex(index);
      openEditDialog();
    },
    [openEditDialog]
  );

  const onDeleteClick = React.useCallback(
    (languageGroup: LanguageGroup, index: number) => {
      setSelectedLanguageGroup(languageGroup);
      setSelectedLanguageGroupIndex(index);
      openDeleteDialog();
    },
    [openDeleteDialog]
  );

  const onDeleteConfirm = React.useCallback(() => {
    if (selectedLanguageGroup && selectedLanguageGroupIndex >= 0) {
      mutate(
        {
          id: clientConfig.id,
          requestBody: {
            ...clientConfig,
            languageGroups: languageGroups.filter((_, i) => i !== selectedLanguageGroupIndex)
          }
        },
        {
          onSuccess: () => {
            onChange();
            closeDeleteDialog();
          }
        }
      );
    }
  }, [
    selectedLanguageGroup,
    selectedLanguageGroupIndex,
    mutate,
    clientConfig,
    languageGroups,
    onChange,
    closeDeleteDialog
  ]);

  const onAddLanguageGroupSuccess = React.useCallback(() => {
    onChange();
    closeAddDialog();
  }, [onChange, closeAddDialog]);

  const onEditLanguageGroupSuccess = React.useCallback(() => {
    onChange();
    closeEditDialog();
  }, [onChange, closeEditDialog]);

  return (
    <>
      <Stack spacing={1}>
        {!viewOnly && (
          <div>
            <Button variant="contained" color="primary" onClick={openAddDialog}>
              Add Language Group
            </Button>
          </div>
        )}
        <DataTable
          columns={[
            {
              id: "name",
              title: <strong>Group Name</strong>,
              cell: nameCell
            },
            {
              id: "languages",
              title: <strong>Languages</strong>,
              cell: languagesCell
            }
          ]}
          data={languageGroups}
          rowKey={(row: LanguageGroup) => row.name}
          rowActions={
            viewOnly
              ? undefined
              : [
                  {
                    title: "Edit",
                    color: "primary.main",
                    action: onEditClick
                  },
                  {
                    title: "Delete",
                    color: "error.main",
                    action: onDeleteClick
                  }
                ]
          }
          dense={true}
          elevation={0}
        />
      </Stack>
      {isAddDialogOpen && (
        <AddLanguageGroupDialog
          clientConfig={clientConfig}
          onClose={closeAddDialog}
          onSuccess={onAddLanguageGroupSuccess}
        />
      )}
      {isEditDialogOpen && selectedLanguageGroupIndex >= 0 && (
        <EditLanguageGroupDialog
          clientConfig={clientConfig}
          onClose={closeEditDialog}
          languageGroupIndex={selectedLanguageGroupIndex}
          onSuccess={onEditLanguageGroupSuccess}
        />
      )}
      {isDeleteDialogOpen && selectedLanguageGroup && selectedLanguageGroupIndex >= 0 && (
        <ConfirmationDialog
          title="Delete Language Group"
          content={`Are you sure you want to delete the "${selectedLanguageGroup.name}" language group?`}
          onClose={closeDeleteDialog}
          onConfirm={onDeleteConfirm}
          isLoading={isSaving}
          error={saveError ? <ApiErrorMessage apiError={saveError} /> : undefined}
        />
      )}
    </>
  );
};

export { LanguageGroupsTable };
