import { Hub } from "aws-amplify";
import { HubCapsule } from "@aws-amplify/core";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import { Store } from "redux";
import { amplifyLogger } from "./AmplifyLogger";
import { signIn } from "./store/userSession/userSessionActions";
import { UserGroup } from "./gen/clients";

export class AuthEventListener {
  public static async init(store: Store): Promise<void> {
    const listener = (data: HubCapsule) => {
      switch (data.payload.event) {
        case "signIn":
          {
            amplifyLogger.info("user signed in");
            const user: CognitoUser = data.payload.data;
            const username = user.getUsername();
            const email = this.getEmail(user);
            const userGroups = this.getUserGroups(user);
            store.dispatch(signIn(username, email || "", userGroups));
          }
          break;
        case "signUp":
          amplifyLogger.info("user signed up");
          break;
        case "signOut":
          amplifyLogger.info("user signed out");
          break;
        case "signIn_failure":
          amplifyLogger.error("user sign in failed");
          break;
        case "tokenRefresh":
          amplifyLogger.info("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          amplifyLogger.error("token refresh failed");
          break;
        case "configured":
          amplifyLogger.info("the Auth module is configured");
          break;
        default: {
          // do nothing
        }
      }
    };

    Hub.listen("auth", listener);

    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      if (user) {
        const userGroups = this.getUserGroups(user);
        const email = this.getEmail(user);
        store.dispatch(signIn(user.getUsername(), email || "", userGroups));
      }
    } catch (e) {
      amplifyLogger.info("User is not authenticated.", e);
    }
  }

  private static getUserGroups(user: CognitoUser): UserGroup[] {
    const signInUserSession = user.getSignInUserSession();
    if (signInUserSession) {
      // eslint-disable-next-line
      // If the user is authenticated using a 3-rd party identity provider, then assume it is a client employee.
      if (signInUserSession.getIdToken().decodePayload().identities?.length > 0) {
        return [UserGroup.CLIENT_EMPLOYEE];
      }
      return signInUserSession.getIdToken().decodePayload()["cognito:groups"] || [];
    }
    return [];
  }

  private static getEmail(user: CognitoUser): string | undefined {
    const signInUserSession = user.getSignInUserSession();
    const email = signInUserSession?.getIdToken().decodePayload().email;
    if (!email) {
      amplifyLogger.error("Email is not provided.");
    }
    return email;
  }
}

export default AuthEventListener;
