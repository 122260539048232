import * as React from "react";
import { Form, useFormikContext } from "formik";
import Grid from "@mui/material/Grid";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { ReportDefinition } from "gen/clients";
import { CircularProgress } from "@mui/material";
import { CheckboxesField } from "components/formikFields/CheckboxesField/CheckboxesField";
import { SelectOption } from "@mui/base";

interface Props {
  isEdit?: boolean;
  filters?: {
    isLoading: boolean;
    options: SelectOption<string | number>[];
  };
  onCancel: () => void;
}

const ReportForm: React.FC<Props> = ({ onCancel, isEdit, filters }) => {
  const { isSubmitting } = useFormikContext<Record<string, unknown>>();

  return (
    <>
      <Form noValidate={true}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <TextInputField name="name" label="Report Name" required={true} />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <SelectOneField
              name="type"
              label="Type"
              required={true}
              options={[
                {
                  label: "Project Report",
                  value: ReportDefinition.type.PROJECT
                },
                {
                  label: "Quote Report",
                  value: ReportDefinition.type.QUOTE
                },
                {
                  label: "Task Report",
                  value: ReportDefinition.type.TASK
                },
                {
                  label: "Client Contact Report",
                  value: ReportDefinition.type.CLIENT_CONTACT
                }
              ]}
            />
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <TextInputField name="viewId" label="View ID" required={true} disabled={isEdit} />
          </Grid>
          <Grid item={true} xs={12}>
            <TextInputField
              name="viewParams"
              label="View Parameters"
              helperText="XTRF view query parameters.  Example: q.projectTextProperty=eq(test)&q.status=anyOf(OPENED)"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextInputField name="description" label="Description" />
          </Grid>

          {isEdit && !filters?.isLoading && (
            <Grid item={true} xs={12}>
              <CheckboxesField name="visibleFilters" label="Filters" options={filters?.options || []} />
            </Grid>
          )}
          {isEdit && filters?.isLoading && (
            <Grid item={true} xs={12}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>

        <Box sx={{ mt: 5 }}>
          <LoadingButton isLoading={isSubmitting}>Save</LoadingButton>
          <Button color="secondary" onClick={onCancel} disabled={isSubmitting} sx={{ ml: 1 }}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
};

export { ReportForm };
