import * as React from "react";
import { JobRequest, JobRequestFileCategory } from "gen/clients";
import { LabelValue } from "components/LabelValue/LabelValue";
import { Typography } from "@mui/material";

interface Props {
  jobRequest: JobRequest;
}

const TotalCharactersChargedLabel: React.FC<Props> = ({ jobRequest }) => {
  const totalCharacters = React.useMemo(
    () =>
      jobRequest.files
        ?.filter(file => file.category === JobRequestFileCategory.TRANSLATED_DOCUMENT)
        .map(file => file.charactersCharged || 0)
        .reduce((f1, f2) => f1 + f2, 0),
    [jobRequest]
  );

  if (!totalCharacters) {
    return <span />;
  }

  return (
    <LabelValue
      label="Total Characters Charged"
      value={
        <>
          <Typography>{totalCharacters.toLocaleString()}</Typography>
        </>
      }
    />
  );
};

export { TotalCharactersChargedLabel };
