import * as React from "react";
import { DataTable } from "components/DataTable/DataTable";
import {
  ApiError,
  ApplicationCollection,
  ApplicationsService,
  ClientsByEnabledApplicationReport,
  EnabledApplicationsReportItem,
  ErrorResponse,
  MetricsService
} from "gen/clients";
import { useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { TokenBasedPaginator } from "components/TokenBasedPaginator/TokenBasedPaginator";
import { Box, Stack, Typography } from "@mui/material";

interface Props {
  applicationId?: string;
}

function createColumnDescription(
  apps: ApplicationCollection,
  appId: string
): {
  id: string;
  title: React.ReactNode;
  cell: (row: EnabledApplicationsReportItem) => React.ReactNode;
} {
  return {
    id: appId,
    title: <Box textAlign="center">{apps.items.find(item => item.id === appId)?.name || ""}</Box>,
    cell: (row: EnabledApplicationsReportItem) =>
      row.enabledApplications.includes(appId) ? (
        <Box textAlign="center">
          <CheckCircleOutlineIcon sx={{ color: "success.main" }} />
        </Box>
      ) : (
        ""
      )
  };
}

const ClientsByEnabledApplicationReportTable: React.FC<Props> = ({ applicationId }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });

  React.useEffect(() => {
    paginatorResetRef?.current();
  }, [applicationId, rowsPerPage]);

  const { data: apps, error: appsError } = useQuery<ApplicationCollection, ErrorResponse>(
    ["applications"],
    ApplicationsService.listApplications,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  const isStandardApp = React.useMemo(() => {
    const selectedApp = apps?.items.find(app => app.id === applicationId);
    return selectedApp?.isStandard;
  }, [apps, applicationId]);

  const {
    data,
    isLoading,
    error: reportError
  } = useQuery<ClientsByEnabledApplicationReport, ApiError>(
    ["clientsByEnabledApplicationReport", applicationId, isStandardApp, pageToken, rowsPerPage],
    {
      enabled: applicationId !== undefined && !isStandardApp,
      queryFn: () =>
        MetricsService.getClientsByEnabledApplicationReport({
          applicationId: applicationId || "",
          pageToken,
          pageSize: rowsPerPage
        }),
      onSuccess: collection => {
        setNextPageToken(collection.nextPageToken);
      }
    }
  );

  const onRowsPerPageChange = React.useCallback((newRowsPerChange: number) => {
    setRowsPerPage(newRowsPerChange);
    setPageToken(undefined);
    setNextPageToken(undefined);
    paginatorResetRef?.current();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        id: "clientName",
        title: "Client Name",
        cell: (row: EnabledApplicationsReportItem) => row.clientName
      },
      ...(apps?.items
        .sort((app1, app2) => (app1?.name || app1.id).localeCompare(app2?.name || app2.id))
        .map(item => createColumnDescription(apps, item.id)) || [])
    ],
    [apps]
  );

  return (
    <div style={{ overflowX: "auto" }}>
      {isStandardApp && (
        <Stack direction="row" spacing={1}>
          <CheckCircleOutlineIcon sx={{ color: "success.main" }} />
          <Typography color="success.main">
            The selected application is a standard application. It is enabled for all clients.
          </Typography>
        </Stack>
      )}
      {!isStandardApp && (
        <>
          <DataTable
            isLoading={isLoading}
            error={!!appsError || !!reportError ? <ApiErrorMessage apiError={appsError || reportError} /> : null}
            columns={columns}
            data={data?.items || []}
            rowKey={(row: EnabledApplicationsReportItem) => row.clientId}
          />
          <TokenBasedPaginator
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageTokenChange={setPageToken}
            nextPageToken={nextPageToken}
            currentPageDataRows={data?.items.length}
            rowsPerPageOptions={[10, 20, 50, 100]}
            resetRef={paginatorResetRef}
          />
        </>
      )}
    </div>
  );
};

export { ClientsByEnabledApplicationReportTable };
