import * as React from "react";
import { Box, CircularProgress } from "@mui/material";

const PageLoading: React.FC = () => (
  <Box sx={{ display: "flex" }} justifyContent="center" mt={5}>
    <CircularProgress />
  </Box>
);

export { PageLoading };
