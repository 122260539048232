import * as React from "react";
import { Alert, Snackbar } from "@mui/material";
import { parseApiErrorMessage } from "../ApiErrorMessage/ApiErrorMessage";
import { useDialogState } from "../../hooks/useDialogState";

interface Props {
  error: unknown;
}

const SnackbarApiError: React.FC<Props> = ({ error }) => {
  const [isOpen, , close] = useDialogState(true);

  return (
    <Snackbar open={isOpen} autoHideDuration={30000} onClose={close}>
      <div>
        <Alert onClose={close} severity="error" sx={{ width: "100%" }}>
          {parseApiErrorMessage(error)}
        </Alert>
      </div>
    </Snackbar>
  );
};

export { SnackbarApiError };
