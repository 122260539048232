import { FormControlLabel, Switch } from "@mui/material";
import { CognitoUser, UserAccountsService } from "gen/clients";
import * as React from "react";
import { useMutation } from "react-query";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";

interface Props {
  cognitoUser: CognitoUser;
  onSuccess: () => void;
}

const CognitoStateSwitch: React.FC<Props> = ({ cognitoUser, onSuccess }) => {
  const {
    mutate,
    error: mutateError,
    isLoading: isMutateLoading
  } = useMutation({
    mutationFn: UserAccountsService.changeCognitoUserState,
    onSuccess
  });

  const onClick = React.useCallback(
    event => {
      mutate({
        cognitoUsername: encodeURIComponent(cognitoUser.username),
        requestBody: {
          enabled: event.target.checked
        }
      });
    },
    [mutate, cognitoUser]
  );

  return (
    <>
      {mutateError && <SnackbarApiError error={mutateError} />}
      <FormControlLabel
        control={
          <Switch
            checked={cognitoUser?.enabled || false}
            onChange={onClick}
            disabled={isMutateLoading || !cognitoUser}
            size="small"
          />
        }
        label={cognitoUser?.enabled ? "Yes" : "No"}
        labelPlacement="end"
        sx={{ ml: 1 }}
      />
    </>
  );
};

export { CognitoStateSwitch };
