import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store/store";
import { NavMenuOption, PageLayout } from "../PageLayout/PageLayout";
import { intersect } from "../../utils/arrayUtils";

interface Props {
  allowedRoles: string[];
  navMenuOption: NavMenuOption;
  children: React.ReactNode;
}

const RestrictedPage: React.FC<Props> = ({ allowedRoles, navMenuOption, children }) => {
  const userRoles = useSelector((state: ApplicationState) => state.userSession.roles || []);
  const accessAllowed = React.useMemo(() => intersect(userRoles, allowedRoles), [userRoles, allowedRoles]);

  if (accessAllowed) {
    return <>{children}</>;
  }

  return (
    <PageLayout selectedNavMenu={navMenuOption}>Access restricted. Please contact system administrator.</PageLayout>
  );
};

export { RestrictedPage };
