import * as React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardContent, CardHeader, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { JobRequest, JobRequestFile, JobRequestFileCategory } from "gen/clients";
import Link from "@mui/material/Link";
import { SelectInput } from "components/SelectInput/SelectInput";
import { SelectOption } from "@mui/base";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { downloadJobRequestFile } from "../../utils/JobRequestUtils";

interface Props {
  jobRequest: JobRequest;
}

const FilesSection: React.FC<Props> = ({ jobRequest }) => {
  const [selectedTargetLanguageId, setSelectedTargetLanguageId] = React.useState<string>();

  const targetLanguageOptions: SelectOption<string>[] = React.useMemo(
    () =>
      jobRequest.targetLanguages
        ?.map(l => ({
          label: l.name,
          value: l.id as string
        }))
        .sort(SELECT_OPTION_COMPARATOR) || [],
    [jobRequest.targetLanguages]
  );

  React.useEffect(() => {
    if (targetLanguageOptions && targetLanguageOptions.length) {
      setSelectedTargetLanguageId(targetLanguageOptions[0].value);
    }
  }, [targetLanguageOptions]);

  const translatedFiles = React.useMemo(
    () => selectedTargetLanguageId && jobRequest.files?.filter(f => f.languageId === selectedTargetLanguageId),
    [selectedTargetLanguageId, jobRequest]
  );

  const onFileLinkClick = React.useCallback(
    async (file: JobRequestFile) => {
      await downloadJobRequestFile(jobRequest.id, file);
    },
    [jobRequest.id]
  );

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} sm={6}>
        <Card sx={{ mt: 2, height: 400 }}>
          <CardHeader
            title={
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Source Files</Typography>
                <Box sx={{ height: 40, paddingTop: "5px" }}>
                  <Typography variant="body1">{jobRequest.sourceLanguage?.name}</Typography>
                </Box>
              </Stack>
            }
          />
          <CardContent sx={{ overflowY: "auto", pt: 0 }}>
            <Stack spacing={0.5}>
              {jobRequest.files
                ?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT)
                .map(f => (
                  <Typography variant="body2">
                    <Link href="#" onClick={() => onFileLinkClick(f)}>
                      {f.fileName}
                    </Link>
                  </Typography>
                ))}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item={true} xs={12} sm={6}>
        <Card sx={{ mt: 2, height: 400 }}>
          <CardHeader
            title={
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Machine Translated Files</Typography>
                <SelectInput
                  options={targetLanguageOptions}
                  onChange={setSelectedTargetLanguageId}
                  value={selectedTargetLanguageId}
                  size="small"
                  SelectProps={{
                    native: true
                  }}
                />
              </Stack>
            }
          />
          <CardContent sx={{ overflowY: "auto", pt: 0 }}>
            <Stack spacing={0.5}>
              {(!translatedFiles || translatedFiles.length === 0) && <Typography variant="body2">No files</Typography>}
              {translatedFiles &&
                translatedFiles.map(f => (
                  <Typography variant="body2">
                    <Link href="#" onClick={() => onFileLinkClick(f)}>
                      {f.fileName}
                    </Link>
                  </Typography>
                ))}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export { FilesSection };
