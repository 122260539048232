import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { DialogBody } from "components/DialogBody/DialogBody";
import { ClientCompanySelectField } from "components/ClientCompanySelectField/ClientCompanySelectField";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { ClientsContactsService, LinkedClient } from "gen/clients";
import { SelectOption } from "@mui/base";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { LoadingButton } from "components/LoadingButton/LoadingButton";

interface Props {
  clientContactId: number;
  clientId: string;
  linkedClients: LinkedClient[];
  onClose: () => void;
  onSuccess: () => void;
}

enum FormFields {
  CLIENT_COMPANY = "clientCompany"
}

interface FormValues {
  [FormFields.CLIENT_COMPANY]: SelectOption<string> | null;
}

const AddLinkedClientDialog: React.FC<Props> = ({ clientContactId, clientId, linkedClients, onClose, onSuccess }) => {
  const { mutateAsync, error } = useMutation(ClientsContactsService.updateLinkedClients);

  const validate = React.useCallback(
    (values: FormValues) => {
      const errors: Record<string, string> = {};
      const selectedClientId = values[FormFields.CLIENT_COMPANY]?.value;
      if (selectedClientId === clientId) {
        errors[FormFields.CLIENT_COMPANY] = "The client contact belongs to this client";
      } else if (linkedClients.some(linkedClient => linkedClient.clientId === selectedClientId)) {
        errors[FormFields.CLIENT_COMPANY] = "This client is already linked to this client contact";
      }
      return errors;
    },
    [clientId, linkedClients]
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const newClientId = values[FormFields.CLIENT_COMPANY]!.value;
      const clientIds = [...linkedClients.map(client => client.clientId), newClientId];
      await mutateAsync(
        {
          clientContactId,
          requestBody: clientIds
        },
        { onSuccess }
      );
      onClose();
    },
    [clientContactId, linkedClients, mutateAsync, onClose, onSuccess]
  );

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={true} onClose={onClose}>
      <DialogTitle>Add Linked Client</DialogTitle>
      <DialogBody>
        <Formik
          initialValues={{
            [FormFields.CLIENT_COMPANY]: null
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent>
                <Stack spacing={2}>
                  <ClientCompanySelectField name={FormFields.CLIENT_COMPANY} />
                  {error && <ApiErrorMessage apiError={error} />}
                </Stack>
              </DialogContent>
              <DialogActions>
                <LoadingButton isLoading={isSubmitting}>Add</LoadingButton>
                <Button onClick={onClose}>Cancel</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export { AddLinkedClientDialog };
