import * as React from "react";
import Grid from "@mui/material/Grid";
import { Button, CardContent, CardHeader, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { JobRequest, JobRequestFile, JobRequestFileCategory } from "gen/clients";
import Link from "@mui/material/Link";
import { downloadJobRequestFile } from "../../../../utils/JobRequestUtils";
import DownloadIcon from "@mui/icons-material/Download";
import { useDialogState } from "hooks/useDialogState";
import { DownloadSourceFilesDialog } from "./DownloadSourceFilesDialog/DownloadSourceFilesDialog";

interface Props {
  jobRequest: JobRequest;
}

const FilesTab: React.FC<Props> = ({ jobRequest }) => {
  const [isDownloadFilesDialogOpen, openDownloadFilesDialog, closeDownloadFilesDialog] = useDialogState();

  const sourceFileLinks = React.useMemo(
    () => jobRequest.fileLinks?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT) || [],
    [jobRequest.fileLinks]
  );

  const onFileLinkClick = React.useCallback(
    async (file: JobRequestFile) => {
      await downloadJobRequestFile(jobRequest.id, file);
    },
    [jobRequest.id]
  );

  const onDownloadAllClick = React.useCallback(async () => {
    openDownloadFilesDialog();
  }, [openDownloadFilesDialog]);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Box>
          <CardHeader
            title={
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Source Files</Typography>
                <Box sx={{ height: 40, paddingTop: "5px" }}>
                  <Typography variant="body1">{jobRequest.sourceLanguage?.name}</Typography>
                </Box>
              </Stack>
            }
          />
          <CardContent sx={{ overflowY: "auto", pt: 0 }}>
            <Stack spacing={0.5}>
              {jobRequest.files
                ?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT)
                .map(f => (
                  <Typography variant="body2">
                    <Link href="#" onClick={() => onFileLinkClick(f)}>
                      {f.fileName}
                    </Link>
                  </Typography>
                ))}
            </Stack>
            {(jobRequest.files?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT)?.length || 0) >
              0 && (
              <Box textAlign="center">
                <Button startIcon={<DownloadIcon />} variant="outlined" onClick={onDownloadAllClick} size="small">
                  Download All
                </Button>
              </Box>
            )}
            {sourceFileLinks.length > 0 && (
              <>
                <Typography variant="body1" sx={{ pt: 1 }}>
                  Source file links:
                </Typography>
                {sourceFileLinks.map(f => (
                  <Typography variant="body2">
                    <Link href={f.url} target="_blank" rel="noreferrer">
                      {f.name}
                    </Link>
                  </Typography>
                ))}
              </>
            )}
          </CardContent>
          {isDownloadFilesDialogOpen && (
            <DownloadSourceFilesDialog
              jobRequestId={jobRequest.id}
              zipFileName={`autoquote-${jobRequest.id}-source-files.zip`}
              onClose={closeDownloadFilesDialog}
              onSuccess={closeDownloadFilesDialog}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export { FilesTab };
