/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Address } from './Address';
import type { AIAnalysis } from './AIAnalysis';
import type { CustomFieldValue } from './CustomFieldValue';
import type { JobRequestFile } from './JobRequestFile';
import type { JobRequestFileLink } from './JobRequestFileLink';
import type { JobRequestSummary } from './JobRequestSummary';

export type JobRequest = (JobRequestSummary & {
    clientContactId?: number;
    requestorPhone?: string;
    files?: Array<JobRequestFile>;
    fileLinks?: Array<JobRequestFileLink>;
    notes?: string;
    provideStandardAndRushQuotes?: boolean;
    xtrfServiceId?: number;
    contactAddress?: Address;
    paymentPreferences?: {
        paymentType: JobRequest.paymentType;
        billingAccount?: {
            clientIdNumber: string;
            personalCode?: string;
        };
        billingContact?: Address;
    };
    customFieldValues?: Array<CustomFieldValue>;
    originalFileKey?: string;
    referencedEmails?: Array<string>;
    aiAnalysis?: AIAnalysis;
    statusDetails?: string;
    externalReferenceId?: string;
    quote?: {
        totalAgreed?: number;
        rejectionReason?: string;
        quoteResponseTime?: string;
    };
});

export namespace JobRequest {

    export enum paymentType {
        CREDIT_CARD = 'CREDIT_CARD',
        BILL_TO_ACCOUNT = 'BILL_TO_ACCOUNT',
        INVOICE_DIRECTLY = 'INVOICE_DIRECTLY',
    }


}
