import * as React from "react";
import { useQuery } from "react-query";
import { ApplicationSummary, ApplicationCollection, ApplicationsService, ErrorResponse } from "gen/clients";
import { CreateApplicationPanel } from "./components/CreateApplicationPanel";
import { useDialogState } from "hooks/useDialogState";
import { EditApplicationPanel } from "./components/EditApplicationPanel";
import { DeleteApplicationDialog } from "./components/DeleteApplicationDialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataTable } from "components/DataTable/DataTable";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { ApplicationSettingsPanel } from "./components/ApplicationSettingsPanel/ApplicationSettingsPanel";
import { ApplicationId } from "types/ApplicationId";

const ApplicationsTab: React.FC = () => {
  const { data, isLoading, error, refetch } = useQuery<ApplicationCollection, ErrorResponse>(
    ["applications"],
    ApplicationsService.listApplications,
    { retry: false, refetchOnMount: false, refetchOnWindowFocus: false, cacheTime: 0 }
  );
  const [selectedApplication, setSelectedApplication] = React.useState<ApplicationSummary | undefined>();
  const [isCreatePanelOpen, openCreatePanel, closeCreatePanel] = useDialogState();
  const [isEditPanelOpen, openEditPanel, closeEditPanel] = useDialogState();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();
  const [isSettingsPanelOpen, openSettingsPanel, closeSettingsPanel] = useDialogState();

  const applications = React.useMemo(
    () => data?.items.sort((app1, app2) => app1.id.localeCompare(app2.id)) || [],
    [data?.items]
  );

  const onEditClick = React.useCallback(
    (app: ApplicationSummary) => {
      setSelectedApplication(app);
      openEditPanel();
    },
    [setSelectedApplication, openEditPanel]
  );

  const onDeleteClick = React.useCallback(
    (app: ApplicationSummary) => {
      setSelectedApplication(app);
      openDeleteDialog();
    },
    [setSelectedApplication, openDeleteDialog]
  );

  const onAppSettingsClick = React.useCallback(
    (app: ApplicationSummary) => {
      setSelectedApplication(app);
      openSettingsPanel();
    },
    [openSettingsPanel]
  );

  const rowActions = React.useCallback(
    (app: ApplicationSummary) => [
      {
        title: "Edit",
        action: onEditClick
      },
      ...(app.id === ApplicationId.machineTranslation
        ? [
            {
              title: "App Settings",
              action: onAppSettingsClick
            }
          ]
        : []),
      {
        title: "Delete",
        action: onDeleteClick,
        color: "error.light"
      }
    ],
    [onAppSettingsClick, onDeleteClick, onEditClick]
  );

  const onCreateSuccess = React.useCallback(() => {
    refetch();
    closeCreatePanel();
  }, [refetch, closeCreatePanel]);

  const onEditSuccess = React.useCallback(() => {
    refetch();
    closeEditPanel();
  }, [refetch, closeEditPanel]);

  const onDeleteSuccess = React.useCallback(() => {
    refetch();
    closeDeleteDialog();
  }, [refetch, closeDeleteDialog]);

  const onChangeSettingsSuccess = React.useCallback(() => {
    refetch();
    closeSettingsPanel();
  }, [refetch, closeSettingsPanel]);

  return (
    <>
      <Box sx={{ mt: 3, mb: 2 }}>
        <Button variant="contained" color="primary" size="large" onClick={openCreatePanel}>
          Add Application
        </Button>
      </Box>
      <DataTable
        columns={[
          {
            id: "id",
            title: "Application ID",
            cell: (app: ApplicationSummary) => <div>{app.id}</div>
          },
          {
            id: "name",
            title: "Name",
            cell: (app: ApplicationSummary) => <div>{app.name}</div>
          },
          {
            id: "description",
            title: "Description",
            cell: (app: ApplicationSummary) => <div>{app.description}</div>
          },
          {
            id: "standard",
            title: "Standard",
            cell: (app: ApplicationSummary) => (app.isStandard ? "Yes" : "No")
          }
        ]}
        data={applications}
        isLoading={isLoading}
        error={error && <ApiErrorMessage apiError={error} />}
        rowKey={(app: ApplicationSummary) => app.id}
        rowActions={rowActions}
        dense={true}
      />

      <CreateApplicationPanel open={isCreatePanelOpen} onClose={closeCreatePanel} onSuccess={onCreateSuccess} />

      {selectedApplication && isEditPanelOpen && (
        <EditApplicationPanel
          applicationSummary={selectedApplication}
          open={isEditPanelOpen}
          onClose={closeEditPanel}
          onSuccess={onEditSuccess}
        />
      )}

      {selectedApplication && isDeleteDialogOpen && (
        <DeleteApplicationDialog
          application={selectedApplication}
          onClose={closeDeleteDialog}
          onSuccess={onDeleteSuccess}
        />
      )}

      {selectedApplication && isSettingsPanelOpen && (
        <ApplicationSettingsPanel
          application={selectedApplication}
          onClose={closeSettingsPanel}
          onSuccess={onChangeSettingsSuccess}
        />
      )}
    </>
  );
};

export { ApplicationsTab };
