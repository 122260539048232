/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Application } from '../models/Application';
import type { ApplicationCollection } from '../models/ApplicationCollection';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ApplicationsService {

    /**
     * Lists applications
     * @returns ApplicationCollection A 200 response.
     * @throws ApiError
     */
    public static listApplications(): CancelablePromise<ApplicationCollection> {
        return __request({
            method: 'GET',
            path: `/applications`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Creates application
     * @returns any successful operation
     * @throws ApiError
     */
    public static createApplication({
        requestBody,
    }: {
        /** Application to create **/
        requestBody?: Application,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/applications`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

    /**
     * Fetches application by ID
     * @returns Application successful operation
     * @throws ApiError
     */
    public static getApplication({
        id,
    }: {
        /** application ID **/
        id: string,
    }): CancelablePromise<Application> {
        return __request({
            method: 'GET',
            path: `/applications/${id}`,
            errors: {
                400: `Invalid parameters`,
                404: `Application not found`,
            },
        });
    }

    /**
     * Updates application
     * @returns any successful operation
     * @throws ApiError
     */
    public static updateApplication({
        id,
        requestBody,
    }: {
        /** ID of the application that is updated **/
        id: string,
        /** Updated application **/
        requestBody?: Application,
    }): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/applications/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
                404: `Application not found`,
            },
        });
    }

    /**
     * Deletes application
     * @returns any successful operation
     * @throws ApiError
     */
    public static deleteApplication({
        id,
    }: {
        /** application ID **/
        id: string,
    }): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/applications/${id}`,
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

}