import * as React from "react";
import { DataTable } from "components/DataTable/DataTable";
import {
  ApiError,
  ApplicationCollection,
  ApplicationsService,
  EnabledApplicationsByClientReport,
  EnabledApplicationsReportItem,
  ErrorResponse,
  MetricsService
} from "gen/clients";
import { useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box } from "@mui/material";
import { TokenBasedPaginator } from "components/TokenBasedPaginator/TokenBasedPaginator";

interface Props {
  clientId?: string;
}

const EnabledApplicationsByClientReportTable: React.FC<Props> = ({ clientId }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });

  React.useEffect(() => {
    paginatorResetRef?.current();
  }, [clientId, rowsPerPage]);

  const { data: apps, error: appsError } = useQuery<ApplicationCollection, ErrorResponse>(
    ["applications"],
    ApplicationsService.listApplications,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  const {
    data,
    isLoading,
    error: reportError
  } = useQuery<EnabledApplicationsByClientReport, ApiError>(
    ["enabledApplicationsByClientReport", clientId, pageToken, rowsPerPage],
    {
      queryFn: () =>
        MetricsService.getEnabledApplicationsByClientReport({
          clientId,
          pageToken,
          pageSize: rowsPerPage
        }),
      onSuccess: collection => {
        setNextPageToken(collection.nextPageToken);
      }
    }
  );

  const onRowsPerPageChange = React.useCallback((newRowsPerChange: number) => {
    setRowsPerPage(newRowsPerChange);
    setPageToken(undefined);
    setNextPageToken(undefined);
    paginatorResetRef?.current();
  }, []);

  return (
    <div style={{ overflowX: "auto" }}>
      <DataTable
        isLoading={isLoading}
        error={!!appsError || !!reportError ? <ApiErrorMessage apiError={appsError || reportError} /> : null}
        columns={[
          {
            id: "clientName",
            title: "Client Name",
            cell: (row: EnabledApplicationsReportItem) => row.clientName
          },
          ...(apps?.items
            .sort((app1, app2) => (app1?.name || app1.id).localeCompare(app2?.name || app2.id))
            .map(item => ({
              id: item.id,
              title: <Box textAlign="center">{item.name}</Box> || "",
              cell: (row: EnabledApplicationsReportItem) =>
                row.enabledApplications.includes(item.id) ? (
                  <Box textAlign="center">
                    <CheckCircleOutlineIcon sx={{ color: "success.main" }} />
                  </Box>
                ) : (
                  ""
                )
            })) || [])
        ]}
        data={data?.items || []}
        rowKey={(row: EnabledApplicationsReportItem) => row.clientId}
      />
      <TokenBasedPaginator
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageTokenChange={setPageToken}
        nextPageToken={nextPageToken}
        currentPageDataRows={data?.items.length}
        rowsPerPageOptions={[10, 20, 50, 100]}
        resetRef={paginatorResetRef}
      />
    </div>
  );
};

export { EnabledApplicationsByClientReportTable };
