import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { useMutation, useQuery } from "react-query";
import { ClientsContactsService, CustomFieldDefinition, CustomFieldValue, UtilsService } from "gen/clients";
import Grid from "@mui/material/Grid";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { CustomField } from "components/formikFields/CustomField/CustomField";
import { validateEmailFormat } from "utils/stringUtils";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { sanitizeFieldName } from "components/formikFields/formikUtils";

interface Props {
  clientId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  address1?: string;
  address2?: string;
  city?: string;
  province?: number | string;
  zip?: string;
  phone?: string;
  country?: number | string;
  allowedEmailDomains?: string[];
  userCustomFieldDefinitions?: CustomFieldDefinition[];
  onSuccess: (clientContactId: number) => void;
  onClose: () => void;
}

export enum FieldNames {
  firstName = "firstName",
  lastName = "lastName",
  address1 = "address1",
  address2 = "address2",
  city = "city",
  province = "province",
  country = "country",
  zip = "zip",
  email = "email",
  phone = "phone"
}

const CreateClientContactDialog: React.FC<Props> = ({
  clientId,
  email,
  firstName,
  lastName,
  address1,
  address2,
  city,
  province,
  zip,
  phone,
  country,
  allowedEmailDomains,
  userCustomFieldDefinitions,
  onSuccess,
  onClose
}) => {
  const [selectedCountryId, setSelectedCountryId] = React.useState<string>(country as string);
  const {
    data: countries,
    isLoading: isCountriesLoading,
    error: countriesError
  } = useQuery("listCountries", {
    queryFn: UtilsService.listCountries
  });
  const countryOptions = React.useMemo(
    () => countries?.sort((l1, l2) => l1.name.localeCompare(l2.name)).map(l => ({ label: l.name, value: l.id })) || [],
    [countries]
  );
  const {
    data: provinces,
    isLoading: isProvincesLoading,
    error: provincesError
  } = useQuery("listProvinces", {
    queryFn: UtilsService.listProvinces
  });
  const provinceOptions = React.useMemo(
    () =>
      (selectedCountryId &&
        provinces
          ?.filter(p => p.countryId === +selectedCountryId)
          .sort((l1, l2) => l1.name.localeCompare(l2.name))
          .map(l => ({ label: l.name, value: l.id }))) ||
      [],
    [provinces, selectedCountryId]
  );

  const {
    mutate: createClientContact,
    data: createdClientContactResponse,
    isLoading: isCreating,
    error: createError
  } = useMutation(ClientsContactsService.createClientContact);

  React.useEffect(() => {
    if (createdClientContactResponse) {
      onSuccess(createdClientContactResponse.clientContactId);
    }
  }, [createdClientContactResponse, onSuccess]);

  const initialValues = React.useMemo(() => {
    const standardFieldValues = {
      [FieldNames.email]: email || "",
      [FieldNames.firstName]: firstName || "",
      [FieldNames.lastName]: lastName || "",
      [FieldNames.address1]: address1 || "",
      [FieldNames.address2]: address2 || "",
      [FieldNames.city]: city || "",
      [FieldNames.province]: province || "",
      [FieldNames.zip]: zip || "",
      [FieldNames.phone]: phone || "",
      [FieldNames.country]: country || ""
    };
    const customFieldValues: Record<string, string | string[]> = {};
    userCustomFieldDefinitions?.forEach((customFieldDefinition: CustomFieldDefinition) => {
      customFieldValues[sanitizeFieldName(customFieldDefinition.name)] = customFieldDefinition.defaultValue || "";
    });
    return {
      ...standardFieldValues,
      ...customFieldValues
    };
  }, [address1, address2, city, country, email, firstName, lastName, phone, province, userCustomFieldDefinitions, zip]);

  const validate = React.useCallback(
    values => {
      const errors: Record<string, string> = {};
      const emailValue = values[FieldNames.email];
      if (!emailValue) {
        errors[FieldNames.email] = "Required";
      } else if (!validateEmailFormat(emailValue)) {
        errors[FieldNames.email] = "Invalid email format";
      } else {
        const emailDomain = emailValue.substring(emailValue.indexOf("@") + 1);
        if (allowedEmailDomains && !allowedEmailDomains.includes(emailDomain)) {
          errors.email = `Email domain "${emailDomain}" is not in the allowed list of the client`;
        }
      }
      return errors;
    },
    [allowedEmailDomains]
  );

  const onSubmit = React.useCallback(
    async values => {
      const customFieldValues: CustomFieldValue[] | undefined = userCustomFieldDefinitions?.map(fieldDefinition => ({
        fieldName: fieldDefinition.name,
        mapping: fieldDefinition.mapping || "",
        value: values[sanitizeFieldName(fieldDefinition.name)]
      }));

      const provinceId = values[FieldNames.province];
      const selectedProvince = provinceId
        ? {
            id: provinceId,
            name: provinceOptions.find(p => p.value === provinceId)?.label || ""
          }
        : undefined;

      const countryId = values[FieldNames.country];
      const selectedCountry = countryId
        ? {
            id: countryId,
            name: countryOptions.find(c => c.value === countryId)?.label || ""
          }
        : undefined;

      await createClientContact({
        requestBody: {
          clientId,
          email: values[FieldNames.email],
          firstName: values[FieldNames.firstName],
          lastName: values[FieldNames.lastName],
          address1: values[FieldNames.address1],
          address2: values[FieldNames.address2],
          city: values[FieldNames.city],
          province: selectedProvince,
          country: selectedCountry,
          zipCode: values[FieldNames.zip],
          phoneNumber: values[FieldNames.phone],
          customFields: customFieldValues
        }
      });
    },
    [clientId, countryOptions, createClientContact, provinceOptions, userCustomFieldDefinitions]
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>Create Client Contact</DialogTitle>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} validateOnMount={false}>
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <DialogContent>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextInputField name={FieldNames.email} label="Email Address" type="email" required={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField name={FieldNames.phone} label="Phone Number" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField name={FieldNames.firstName} label="First Name" required={true} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField name={FieldNames.lastName} label="Last Name" required={true} />
              </Grid>
              <Grid item xs={12}>
                <TextInputField name={FieldNames.address1} label="Address 1" />
              </Grid>
              <Grid item xs={12}>
                <TextInputField name={FieldNames.address2} label="Address 2" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField name={FieldNames.city} label="City" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField name={FieldNames.zip} label="Zip / Postal Code" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectOneField
                  name={FieldNames.country}
                  label="Country"
                  options={countryOptions}
                  isLoading={isCountriesLoading}
                  isError={!!countriesError}
                  onChange={setSelectedCountryId}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectOneField
                  name={FieldNames.province}
                  label="State / Province / Region"
                  options={provinceOptions}
                  isLoading={isProvincesLoading}
                  isError={!!provincesError}
                />
              </Grid>
              {/* User custom fields */}
              {userCustomFieldDefinitions &&
                userCustomFieldDefinitions.map(customFieldDefinition => (
                  <React.Fragment key={customFieldDefinition.name}>
                    {customFieldDefinition.visible !== false && (
                      <Grid item xs={12}>
                        <CustomField customFieldDefinition={customFieldDefinition} />
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton type="submit" color="primary" variant="contained" isLoading={isCreating}>
              Create Client Contact
            </LoadingButton>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
      {createError && <SnackbarApiError error={createError} />}
    </Dialog>
  );
};

export { CreateClientContactDialog };
