import { Action } from "redux";
import { UserGroup } from "../../gen/clients";

export interface UserSessionState {
  username?: string;
  email?: string;
  roles?: UserGroup[];
}

export enum UserSessionActionType {
  SIGN_IN = "USER_SESSION/SIGN_IN",
  SIGN_OUT = "USER_SESSION/SIGN_OUT"
}

export interface SignInAction extends Action {
  type: UserSessionActionType.SIGN_IN;
  username: string;
  email: string;
  roles: UserGroup[];
}

export interface SignOutAction extends Action {
  type: UserSessionActionType.SIGN_OUT;
}

export type UserSessionActions = SignInAction | SignOutAction;
