import { UserSessionActions, UserSessionActionType, UserSessionState } from "./types";

const initialState: UserSessionState = {
  username: undefined,
  roles: undefined
};

const userSessionReducer = (state = initialState, action: UserSessionActions): UserSessionState => {
  switch (action.type) {
    case UserSessionActionType.SIGN_IN:
      return {
        ...state,
        username: action.username,
        email: action.email,
        roles: action.roles
      };
    case UserSessionActionType.SIGN_OUT:
      return {
        ...state,
        username: undefined,
        email: undefined,
        roles: undefined
      };
    default:
      return state;
  }
};

export default userSessionReducer;
