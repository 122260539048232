import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { DialogBody } from "components/DialogBody/DialogBody";
import { useMutation } from "react-query";
import { ClientsContactsService, LinkedClient } from "gen/clients";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { Typography } from "@mui/material";

interface Props {
  clientContactId: number;
  removeAtIndex: number;
  linkedClients: LinkedClient[];
  onClose: () => void;
  onSuccess: () => void;
}

const RemoveLinkedClientDialog: React.FC<Props> = ({
  clientContactId,
  removeAtIndex,
  linkedClients,
  onClose,
  onSuccess
}) => {
  const { mutateAsync, isLoading, error } = useMutation(ClientsContactsService.updateLinkedClients);

  const onRemoveClick = React.useCallback(async () => {
    const clientIds = linkedClients.filter((_, index) => index !== removeAtIndex).map(client => client.clientId);
    await mutateAsync(
      {
        clientContactId,
        requestBody: clientIds
      },
      { onSuccess }
    );
    onClose();
  }, [clientContactId, linkedClients, mutateAsync, onClose, onSuccess, removeAtIndex]);

  return (
    <Dialog maxWidth="sm" fullWidth={true} open={true} onClose={onClose}>
      <DialogTitle>Removed Linked Client</DialogTitle>
      <DialogBody>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>{`Are you sure you want to remove "${linkedClients[removeAtIndex].clientName}" from linked clients?`}</Typography>
            {error && <ApiErrorMessage apiError={error} />}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton isLoading={isLoading} onClick={onRemoveClick}>
            Remove
          </LoadingButton>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </DialogBody>
    </Dialog>
  );
};

export { RemoveLinkedClientDialog };
