import * as React from "react";
import { useMutation } from "react-query";
import { ApplicationSummary, ApplicationsService } from "gen/clients";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  application: ApplicationSummary;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteApplicationDialog: React.FC<Props> = ({ application, onClose, onSuccess }) => {
  const { mutate, isError, error, isLoading } = useMutation("deleteApplication", {
    onSuccess,
    mutationFn: ApplicationsService.deleteApplication
  });

  const onDeleteClick = React.useCallback(() => {
    mutate({
      id: application.id
    });
  }, [application.id, mutate]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Delete Application</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            <div>
              Are you sure you want to delete application: <strong>{application.name}</strong>?
            </div>
            <div>Deleting the application will remove it from the Client Portal for all clients.</div>
          </Stack>
        </DialogContentText>
        {isError && <ApiErrorMessage apiError={error} />}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <LoadingButton color="error" onClick={onDeleteClick} isLoading={isLoading}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteApplicationDialog };
