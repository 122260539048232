/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OpenAIRequest } from '../models/OpenAIRequest';
import type { OpenAIResponse } from '../models/OpenAIResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AiProcessingService {

    /**
     * @returns OpenAIResponse successful operation
     * @throws ApiError
     */
    public static processTextByOpenAi({
        requestBody,
    }: {
        requestBody?: OpenAIRequest,
    }): CancelablePromise<OpenAIResponse> {
        return __request({
            method: 'POST',
            path: `/openAI/processText`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}