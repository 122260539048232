import * as React from "react";
import { useMutation } from "react-query";
import { ClientConfig, ClientsService, ReportDefinition, UtilsService } from "gen/clients";
import { Formik } from "formik";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import Drawer from "@mui/material/Drawer";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { ReportForm } from "./components/ReportForm/ReportForm";

interface Props {
  clientConfig: ClientConfig;
  reportDefinitions: ReportDefinition[];
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function isConvertibleToNumber(str: string): boolean {
  return /^\d*\.?\d+$/.test(str);
}

const CreateReportPanel: React.FC<Props> = ({ clientConfig, reportDefinitions, open, onSuccess, onClose }) => {
  const { mutate, error } = useMutation({
    onSuccess,
    mutationFn: ClientsService.updateClientConfig
  });

  const validate = React.useCallback(async formValues => {
    const errors: Record<string, string> = {};
    if (!isConvertibleToNumber(formValues.viewId)) {
      errors.viewId = "View ID must contain only numbers";
    } else {
      try {
        await UtilsService.listXtrfViewFilters({ viewId: +formValues.viewId });
      } catch (e) {
        errors.viewId = "Specified View ID does not exist in XTRF";
      }
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    formValues => {
      const createdReportDefintions: ReportDefinition[] = reportDefinitions || [];
      createdReportDefintions.push({
        id: new Date().getTime().toString(),
        name: formValues.name.trim(),
        viewId: parseInt(formValues.viewId.trim(), 10),
        viewParams: formValues.viewParams.trim() || undefined,
        type: formValues.type,
        description: formValues.description.trim() || undefined
      });
      const clientConfigUpdate = { ...clientConfig, reports: createdReportDefintions };
      mutate({
        id: clientConfig.id,
        requestBody: clientConfigUpdate
      });
    },
    [reportDefinitions, mutate, clientConfig]
  );

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader onClose={onClose} title="Add Report" />
      <DrawerBody>
        {!!error && <SnackbarApiError error={error} />}
        <Formik
          initialValues={{
            name: "",
            description: "",
            viewId: "",
            viewParams: "",
            type: ReportDefinition.type.PROJECT
          }}
          onSubmit={onSubmit}
          validate={validate}
          validateOnChange={false}
        >
          <ReportForm onCancel={onClose} />
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { CreateReportPanel };
