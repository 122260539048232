import * as React from "react";
import { NavMenuOption, PageLayout } from "../../../components/PageLayout/PageLayout";
import { Box, CircularProgress, Typography } from "@mui/material";
import { BreadcrumbItem } from "../../../components/Breadcrumbs/Breadcrumbs";
import { Filter } from "./Filter/Filter";
import { useQuery } from "react-query";
import { MetricsService } from "../../../gen/clients";

const breadcrumbs: BreadcrumbItem[] = [
  {
    path: "/analytics",
    label: "Analytics"
  },
  {
    path: "/analytics/quoteRejectedReasonsAnalytics",
    label: "Quote Rejected Reasons Analytics",
    active: true
  }
];

const AutoQuoteRejectionReasonsAnalyticsPage: React.FC = () => {
  const now = new Date();
  const [from, setFrom] = React.useState<Date>(new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000));
  const [to, setTo] = React.useState<Date>(now);

  const { data, isLoading, isError, refetch, isFetching } = useQuery(["getQuoteRejectionReasonsReport", from, to], {
    queryFn: () =>
      MetricsService.getQuoteRejectionReasonsReport({
        fromDate: from.toISOString(),
        toDate: to.toISOString()
      }),
    cacheTime: 0
  });

  const onFilterChange = React.useCallback(
    (newFrom: Date, newTo: Date) => {
      setFrom(newFrom);
      setTo(newTo);
      refetch();
    },
    [refetch]
  );

  const formatData = (text: string) => {
    const cleanText = text.replace(/"/g, "").replace(/\\n/g, "\n");
    const splitText = cleanText.split("\n");
    return splitText.map((line, index) => (
      <Typography key={index} paragraph>
        {line}
      </Typography>
    ));
  };

  const dataIsLoading = React.useMemo(() => {
    return isLoading || isFetching;
  }, [isLoading, isFetching]);

  return (
    <PageLayout
      selectedNavMenu={NavMenuOption.ANALYTICS}
      title={
        <Typography variant="h6" sx={{ mb: 2 }}>
          Auto-Quote Rejection Reasons Analytics
        </Typography>
      }
      breadcrumbs={breadcrumbs}
    >
      <Filter from={from} to={to} onChange={onFilterChange} />

      <Box sx={{ mt: 4 }}>
        {dataIsLoading && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        )}

        {isError && <Typography color="error">Error loading data. Please try again.</Typography>}

        {!dataIsLoading && !isError && data && !data.message && (
          <Typography>No rejection reasons found within selected date range.</Typography>
        )}

        {!dataIsLoading && !isError && data && data.message && <Box>{formatData(data.message)}</Box>}
      </Box>
    </PageLayout>
  );
};

export { AutoQuoteRejectionReasonsAnalyticsPage };
