import * as React from "react";
import { NavMenuOption, PageLayout } from "../../../components/PageLayout/PageLayout";
import { Box, Typography } from "@mui/material";
import { BreadcrumbItem } from "../../../components/Breadcrumbs/Breadcrumbs";
import { MtClientUsageReportTable } from "./MtClientUsageReportTable/MtClientUsageReportTable";
import { Filter } from "./Filter/Filter";
import { useQuery } from "react-query";
import { MetricsService } from "../../../gen/clients";
import { ExportAnalyticsButton } from "./ExportAnalyticsButton/ExportAnalyticsButton";

const breadcrumbs: BreadcrumbItem[] = [
  {
    path: "/analytics",
    label: "Analytics"
  },
  {
    path: "/analytics/machineTranslation",
    label: "Machine Translation Usage Analytics",
    active: true
  }
];

const MachineTranslationClientUsageAnalyticsPage: React.FC = () => {
  const now = new Date();
  const [from, setFrom] = React.useState<Date>(new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000));
  const [to, setTo] = React.useState<Date>(now);

  const { data, isFetching, error, refetch } = useQuery(["getClientMtUsageReport", from, to], {
    queryFn: () =>
      MetricsService.getClientMtUsageReport({
        fromDate: from.toISOString(),
        toDate: to.toISOString()
      }),
    cacheTime: 0
  });

  const onFilterChange = React.useCallback(
    (newFrom: Date, newTo: Date) => {
      setFrom(newFrom);
      setTo(newTo);
      refetch();
    },
    [refetch]
  );

  return (
    <PageLayout
      selectedNavMenu={NavMenuOption.ANALYTICS}
      title={
        <Typography variant="h6" sx={{ mb: 2 }}>
          Machine Translation Usage Analytics
        </Typography>
      }
      breadcrumbs={breadcrumbs}
    >
      <Filter from={from} to={to} onChange={onFilterChange} />

      {from && to && (
        <Box>
          <Box textAlign="right">{data?.items.length && <ExportAnalyticsButton from={from} to={to} data={data} />}</Box>
          <MtClientUsageReportTable data={data} isLoading={isFetching} error={error} />
        </Box>
      )}
    </PageLayout>
  );
};

export { MachineTranslationClientUsageAnalyticsPage };
