import React from "react";
import { ClientMtUsageReport } from "gen/clients";
import { ExportButton } from "components/ExportButton/ExportButton";
import { default as dayjs } from "dayjs";
import { describeMtBillingType } from "../../../SystemSettingsPage/tabs/ApplcationsPage/utils/MtUtils";

interface Props {
  from: Date;
  to: Date;
  data: ClientMtUsageReport;
}

interface ExportedDataRow {
  clientName: string;
  clientId: string;
  userCount: number;
  maxCharactersPerRequest: number;
  maxRequestsPerUserPerDay: number;
  requestCount: number;
}

const columnHeaders = {
  clientName: "Client",
  clientId: "Client ID",
  userCount: "User Count",
  billingType: "Billing Type",
  maxCharactersPerRequest: "Max Characters Per Request",
  maxRequestsPerUserPerDay: "Max Requests Per User Per Day",
  requestCount: "Translation Request Count",
  charactersCharged: "Characters Charged",
  charactersChargedByAzure: "Characters Charged By Azure",
  charactersChargedByIntento: "Characters Charged By Intento"
};

const ExportAnalyticsButton: React.FC<Props> = ({ from, to, data }) => {
  const getExportData = React.useCallback(
    async (): Promise<ExportedDataRow[]> =>
      data.items.map(row => ({
        clientName: row.clientName,
        clientId: row.clientId,
        userCount: row.usernames.length,
        billingType: describeMtBillingType(row.billingType),
        maxCharactersPerRequest: row.maxCharactersPerRequest,
        maxRequestsPerUserPerDay: row.maxRequestsPerUserPerDay,
        requestCount: row.requestCount,
        charactersCharged:
          (row.charactersChargedByMtService?.AZURE || 0) + (row.charactersChargedByMtService?.INTENTO || 0) || 1,
        charactersChargedByAzure: row.charactersChargedByMtService?.AZURE || 0,
        charactersChargedByIntento: row.charactersChargedByMtService?.INTENTO || 0
      })),
    [data]
  );

  const fileName = `mt-usage-${dayjs(from).format("MM-DD-YYYY")}-${dayjs(to).format("MM-DD-YYYY")}.csv`;

  return (
    <ExportButton<ExportedDataRow>
      filename={fileName}
      columnHeaders={columnHeaders}
      getExportData={getExportData}
      exportingMessage="Exporting records"
    />
  );
};

export { ExportAnalyticsButton };
