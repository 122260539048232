import * as React from "react";
import { ClientConfig } from "gen/clients";
import { LanguageGroupsTable } from "./LanguageGroupsTable";

interface Props {
  clientConfig: ClientConfig;
  onSaveSuccess: () => void;
}

const LanguageGroupsTab: React.FC<Props> = ({ clientConfig, onSaveSuccess }) => {
  return <LanguageGroupsTable clientConfig={clientConfig} viewOnly={false} onChange={onSaveSuccess} />;
};

export { LanguageGroupsTab };
