import * as React from "react";
import { Box } from "@mui/material";
import { PageLoading } from "../PageLoading/PageLoading";
import { ApiErrorMessage } from "../ApiErrorMessage/ApiErrorMessage";

interface Props {
  isLoading?: boolean;
  error?: any;
  children?: React.ReactNode;
}

const DialogBody: React.FC<Props> = ({ isLoading, error, children }) => {
  if (isLoading) {
    return (
      <Box minHeight={150}>
        <PageLoading />
      </Box>
    );
  }

  if (error) {
    return <ApiErrorMessage apiError={error} />;
  }

  return <>{children}</>;
};

export { DialogBody };
