import * as React from "react";
import { LabelValue } from "components/LabelValue/LabelValue";
import { ClientConfig, ClientDataType, ClientsService, ProjectSubmissionMode } from "gen/clients";
import { useMutation } from "react-query";
import { useDialogState } from "hooks/useDialogState";
import { Formik, Form } from "formik";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { UploadImageDialog } from "./UploadImageDialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { CopyToClipboardButton } from "../../../../../components/CopyToClipboardButton/CopyToClipboardButton";
import { SelectOneField } from "../../../../../components/formikFields/SelectOneField/SelectOneField";

interface Props {
  clientConfig: ClientConfig;
  onSaveSuccess: () => void;
}

const CONTENT_ROOT_URL = process.env.REACT_APP_STATIC_CONTENT_ROOT_URL;

const PortalSettingsTab: React.FC<Props> = ({ clientConfig, onSaveSuccess }) => {
  const [signUpToken, setSignUpToken] = React.useState<string | undefined>(clientConfig.signUpToken);
  const [projectCreationToken, setProjectCreationToken] = React.useState<string | undefined>(
    clientConfig.createProjectToken
  );
  const [isLogoUploadDialogOpen, openLogoUploadDialog, closeLogoUploadDialog] = useDialogState();
  const [isBannerUploadDialogOpen, openBannerUploadDialog, closeBannerUploadDialog] = useDialogState();
  const [isDeleteSignUpConfirmationDialogOpen, openDeleteSignUpConfirmationDialog, closeSignUpConfirmationDialog] =
    useDialogState();
  const [isDeleteUrlConfirmationDialogOpen, openDeleteUrlConfirmationDialog, closeDeleteUrlConfirmationDialog] =
    useDialogState();

  const {
    mutate,
    error: mutateError,
    isLoading
  } = useMutation("updateClientConfig", {
    onSuccess: onSaveSuccess,
    mutationFn: ClientsService.updateClientConfig
  });

  const onDeleteLogoClick = React.useCallback(() => {
    const clientConfigUpdate = {
      ...clientConfig,
      logoKey: undefined
    };
    mutate({ id: clientConfigUpdate.id, requestBody: clientConfigUpdate });
  }, [clientConfig, mutate]);

  const onDeleteBannerClick = React.useCallback(() => {
    const clientConfigUpdate = {
      ...clientConfig,
      bannerKey: undefined
    };
    mutate({ id: clientConfigUpdate.id, requestBody: clientConfigUpdate });
  }, [clientConfig, mutate]);

  const onGenerateSignUpUrlClick = React.useCallback(() => {
    let token = "";
    for (let i = 0; i < 8; i++) {
      token += Math.floor(Math.random() * 10);
    }
    setSignUpToken(token);
    mutate({
      id: clientConfig.id,
      requestBody: {
        ...clientConfig,
        signUpToken: token
      }
    });
  }, [setSignUpToken, clientConfig, mutate]);

  const onDeleteSignUpUrlConfirmation = React.useCallback(() => {
    setSignUpToken("");
    mutate({
      id: clientConfig.id,
      requestBody: {
        ...clientConfig,
        signUpToken: undefined
      }
    });
  }, [setSignUpToken, clientConfig, mutate]);

  const onGenerateProjectCreationUrlClick = React.useCallback(() => {
    let token = "";
    for (let i = 0; i < 8; i++) {
      token += Math.floor(Math.random() * 10);
    }
    setProjectCreationToken(token);
    mutate({
      id: clientConfig.id,
      requestBody: {
        ...clientConfig,
        createProjectToken: token
      }
    });
  }, [setProjectCreationToken, clientConfig, mutate]);

  const onDeleteProjectCreationUrlConfirmation = React.useCallback(() => {
    setProjectCreationToken("");
    mutate({
      id: clientConfig.id,
      requestBody: {
        ...clientConfig,
        createProjectToken: undefined
      }
    });
  }, [setProjectCreationToken, clientConfig, mutate]);

  const onSubmit = React.useCallback(
    async formValues => {
      const clientConfigUpdate: ClientConfig = {
        ...clientConfig,
        instructionalText: formValues.instructionalText || undefined,
        projectSubmissionMode: formValues.projectSubmissionMode,
        projectSubmissionFooterMessage: formValues.footerMessage
      };
      await mutate({ id: clientConfigUpdate.id, requestBody: clientConfigUpdate });
    },
    [clientConfig, mutate]
  );

  return (
    <>
      <div>
        <Stack spacing={2} sx={{ mb: 2 }}>
          <LabelValue
            label="Logo"
            value={
              <div>
                {!clientConfig.logoKey && (
                  <Button size="small" variant="outlined" onClick={openLogoUploadDialog} disabled={isLoading}>
                    Add
                  </Button>
                )}
                {clientConfig.logoKey && (
                  <span>
                    <Link href={`${CONTENT_ROOT_URL}/${clientConfig.logoKey}`} target="_blank">
                      {clientConfig.logoKey?.substring(clientConfig.logoKey.lastIndexOf("/") + 1)}
                    </Link>
                    <Button
                      onClick={onDeleteLogoClick}
                      size="small"
                      variant="outlined"
                      disabled={isLoading}
                      sx={{ ml: 1 }}
                    >
                      Delete
                    </Button>
                  </span>
                )}
              </div>
            }
          />
          <LabelValue
            label="Banner"
            value={
              <div>
                {!clientConfig.bannerKey && (
                  <>
                    <Button size="small" variant="outlined" onClick={openBannerUploadDialog} disabled={isLoading}>
                      Add
                    </Button>
                    <FormHelperText sx={{ display: "inline", ml: 1 }}>
                      (Recommended image dimensions are 900x200 pixels)
                    </FormHelperText>
                  </>
                )}
                {clientConfig.bannerKey && (
                  <span>
                    <div>
                      <Link href={`${CONTENT_ROOT_URL}/${clientConfig.bannerKey}`} target="_blank">
                        {clientConfig.bannerKey.substring(clientConfig.bannerKey.lastIndexOf("/") + 1)}
                      </Link>
                      <Button
                        onClick={onDeleteBannerClick}
                        size="small"
                        variant="outlined"
                        disabled={isLoading}
                        sx={{ ml: 1 }}
                      >
                        Delete
                      </Button>
                    </div>
                  </span>
                )}
              </div>
            }
          />
          <LabelValue
            label="Sign-Up URL"
            value={
              <>
                <div>
                  {signUpToken && (
                    <span>
                      {`${process.env.REACT_APP_PUBLIC_PORTAL_URL}/signUp/${encodeURIComponent(
                        btoa(clientConfig.id)
                      )}/${signUpToken}`}
                    </span>
                  )}
                  {!signUpToken && (
                    <Button variant="outlined" size="small" onClick={onGenerateSignUpUrlClick}>
                      <>Generate</>
                    </Button>
                  )}
                  {signUpToken && (
                    <>
                      <CopyToClipboardButton
                        variant="outlined"
                        size="small"
                        sx={{ ml: 1 }}
                        textToCopy={`${process.env.REACT_APP_PUBLIC_PORTAL_URL}/signUp/${encodeURIComponent(
                          btoa(clientConfig.id)
                        )}/${signUpToken}`}
                      >
                        Copy
                      </CopyToClipboardButton>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={openDeleteSignUpConfirmationDialog}
                        sx={{ ml: 1 }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>
              </>
            }
          />
          <LabelValue
            label="Project Creation URL"
            value={
              <>
                <div>
                  {projectCreationToken && (
                    <span>
                      {`${process.env.REACT_APP_PUBLIC_PORTAL_URL}/createProject/${encodeURIComponent(
                        btoa(clientConfig.id)
                      )}/${projectCreationToken}`}
                    </span>
                  )}
                  {!projectCreationToken && (
                    <Button variant="outlined" size="small" onClick={onGenerateProjectCreationUrlClick}>
                      <>Generate</>
                    </Button>
                  )}
                  {projectCreationToken && (
                    <>
                      <CopyToClipboardButton
                        variant="outlined"
                        size="small"
                        sx={{ ml: 1 }}
                        textToCopy={`${process.env.REACT_APP_PUBLIC_PORTAL_URL}/createProject/${encodeURIComponent(
                          btoa(clientConfig.id)
                        )}/${projectCreationToken}`}
                      >
                        Copy
                      </CopyToClipboardButton>
                      <Button variant="outlined" size="small" onClick={openDeleteUrlConfirmationDialog} sx={{ ml: 1 }}>
                        Delete
                      </Button>
                    </>
                  )}
                </div>
              </>
            }
          />
        </Stack>

        <Formik
          initialValues={{
            instructionalText: clientConfig?.instructionalText || "",
            footerMessage: clientConfig?.projectSubmissionFooterMessage || "",
            projectSubmissionMode: clientConfig?.projectSubmissionMode || ProjectSubmissionMode.BOTH
          }}
          onSubmit={onSubmit}
        >
          <Form>
            <Stack spacing={2}>
              <LabelValue
                label="Project Submission Mode"
                value={
                  <SelectOneField
                    name="projectSubmissionMode"
                    options={[
                      { label: "Send for estimation or translation", value: ProjectSubmissionMode.BOTH },
                      { label: "Send for estimation only", value: ProjectSubmissionMode.SUBMIT_FOR_ESTIMATION },
                      { label: "Send to translation only", value: ProjectSubmissionMode.SUBMIT_FOR_TRANSLATION }
                    ]}
                  />
                }
              />

              <TextInputField
                label="Instructional Text"
                helperText="Instructional text is displayed at the top of the portal's project creation form"
                name="instructionalText"
                rows={3}
                multiline={true}
                variant="outlined"
              />
              <TextInputField
                label="Footer message"
                helperText="Footer message is displayed at the bottom of the portal's project creation form"
                name="footerMessage"
                rows={3}
                multiline={true}
                variant="outlined"
              />
              <Box>
                <LoadingButton isLoading={isLoading}>Save</LoadingButton>
              </Box>
            </Stack>
          </Form>
        </Formik>
      </div>

      {mutateError && <SnackbarApiError error={mutateError} />}

      {isLogoUploadDialogOpen && (
        <UploadImageDialog
          clientConfig={clientConfig}
          dataType={ClientDataType.LOGO}
          onSuccess={() => {
            closeLogoUploadDialog();
            onSaveSuccess();
          }}
          onClose={closeLogoUploadDialog}
        />
      )}

      {isBannerUploadDialogOpen && (
        <UploadImageDialog
          clientConfig={clientConfig}
          dataType={ClientDataType.BANNER}
          onSuccess={() => {
            closeBannerUploadDialog();
            onSaveSuccess();
          }}
          onClose={closeBannerUploadDialog}
        />
      )}

      {isDeleteSignUpConfirmationDialogOpen && (
        <ConfirmationDialog
          title="Delete Sign-Up URL?"
          content={
            <DialogContentText>
              Are you sure you want to delete Sign-Up URL? This will make the custom Sign-Up page unavailable to users
              who bookmarked it.
            </DialogContentText>
          }
          onClose={closeSignUpConfirmationDialog}
          onConfirm={() => {
            closeSignUpConfirmationDialog();
            onDeleteSignUpUrlConfirmation();
          }}
        />
      )}

      {isDeleteUrlConfirmationDialogOpen && (
        <ConfirmationDialog
          title="Delete Project Creation URL?"
          content={
            <DialogContentText>
              Are you sure you want to delete Project Creation URL? This will make the Project Creation page unavailable
              to users who bookmarked it.
            </DialogContentText>
          }
          onClose={closeDeleteUrlConfirmationDialog}
          onConfirm={onDeleteProjectCreationUrlConfirmation}
        />
      )}
    </>
  );
};

export { PortalSettingsTab };
