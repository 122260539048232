import * as React from "react";
import { AdditionalProjectContact, Client, ClientContactSummary } from "gen/clients";
import { Box, Button } from "@mui/material";
import { DataTable } from "components/DataTable/DataTable";
import { useDialogState } from "hooks/useDialogState";
import { AddContactDialog } from "./AddContactDialog";
import { DataTableRowAction } from "components/DataTable/types";

interface Props {
  client: Client;
  contactList: AdditionalProjectContact[];
  setContactList: (contacts: AdditionalProjectContact[]) => void;
}

const ProjectContactsPanel: React.FC<Props> = ({ client, contactList, setContactList }) => {
  const [isAddContactDialogOpen, openAddContactDialog, closeAddContactDialog] = useDialogState();

  const sortedClientContacts = React.useMemo(
    () => contactList.sort((o1, o2) => (o1.lastName && o2.lastName ? o1.lastName.localeCompare(o2.lastName) : 0)) || [],
    [contactList]
  );

  const rowKey = React.useCallback((contact: AdditionalProjectContact) => contact.clientContactId.toString(), []);
  const contactFirstNameCell = React.useCallback((contact: AdditionalProjectContact) => contact.firstName, []);
  const contactLastNameCell = React.useCallback((contact: AdditionalProjectContact) => contact.lastName, []);
  const contactEmailCell = React.useCallback((contact: AdditionalProjectContact) => contact.email, []);

  const rowActions = React.useCallback(
    (contact: AdditionalProjectContact): DataTableRowAction[] => [
      {
        title: "Delete",
        action: () => {
          const contacts = contactList;
          const indexOfSelectedContact = contacts.findIndex(cc => cc.clientContactId === contact.clientContactId);
          if (indexOfSelectedContact >= 0) {
            contacts.splice(indexOfSelectedContact, 1);
            setContactList(contacts);
          }
        }
      }
    ],
    [contactList, setContactList]
  );

  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Button variant="outlined" color="primary" size="medium" onClick={openAddContactDialog}>
          Add Contact
        </Button>
      </Box>
      <DataTable
        columns={[
          {
            id: "firstName",
            title: "First Name",
            cell: contactFirstNameCell
          },
          {
            id: "lastName",
            title: "Last Name",
            cell: contactLastNameCell
          },
          {
            id: "email",
            title: "Email",
            cell: contactEmailCell
          }
        ]}
        data={sortedClientContacts}
        rowKey={rowKey}
        rowActions={rowActions}
      />

      {isAddContactDialogOpen && (
        <AddContactDialog
          client={client}
          usedContactIds={[...contactList.map(cc => cc.clientContactId)]}
          onClose={closeAddContactDialog}
          onSuccess={(clientContact: ClientContactSummary) => {
            closeAddContactDialog();
            setContactList([
              ...contactList,
              {
                clientContactId: clientContact.id,
                firstName: clientContact.firstName,
                lastName: clientContact.lastName,
                email: clientContact.email
              }
            ]);
          }}
        />
      )}
    </>
  );
};
export { ProjectContactsPanel };
