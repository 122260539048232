import * as React from "react";
import { useMutation } from "react-query";
import { ClientsService, CustomFieldDefinition } from "gen/clients";
import * as Yup from "yup";
import { Formik } from "formik";
import Drawer from "@mui/material/Drawer";
import { DrawerHeader } from "../../../../../components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "../../../../../components/DrawerBody/DrawerBody";
import { CustomFieldForm } from "./components/CustomFieldForm/CustomFieldForm";
import { SelectOption, SelectOptionGroup } from "@mui/base";
import { SnackbarApiError } from "../../../../../components/SnackbarApiError/SnackbarApiError";

interface Props {
  clientId: string;
  customFieldDefinitions: CustomFieldDefinition[];
  editedFieldIndex: number;
  open: boolean;
  mappingOptions: SelectOptionGroup<string>[];
  onClose: () => void;
  onSuccess: () => void;
}

const EditCustomFieldPanel: React.FC<Props> = ({
  clientId,
  customFieldDefinitions,
  editedFieldIndex,
  open,
  mappingOptions,
  onSuccess,
  onClose
}) => {
  const editedField: CustomFieldDefinition | undefined = customFieldDefinitions[editedFieldIndex];
  if (!editedField) {
    throw new Error(`Invalid field index: ${editedFieldIndex}`);
  }

  const { mutate, error } = useMutation("updateCustomFieldDefinitions", {
    onSuccess,
    mutationFn: ClientsService.updateCustomFieldDefinitions
  });
  const [options, setOptions] = React.useState<SelectOption<string>[]>(editedField.options || []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    mapping: Yup.string().trim().required("Required")
  });

  const validate = React.useCallback(
    formValues => {
      const errors: Record<string, string> = {};
      if (
        formValues.name !== editedField.name &&
        customFieldDefinitions.find(cfd => cfd.name === formValues.name.trim())
      ) {
        errors.name =
          "A field with this name already exists. Name must be unique across all Project and User custom fields.";
      }
      return errors;
    },
    [customFieldDefinitions, editedField.name]
  );

  const onSubmit = React.useCallback(
    formValues => {
      const projectCustomFieldDefinitions: CustomFieldDefinition[] = customFieldDefinitions || [];
      const fieldDefinition = projectCustomFieldDefinitions[editedFieldIndex];
      if (!fieldDefinition) {
        throw new Error(`Invalid field index: ${editedFieldIndex}`);
      }
      fieldDefinition.name = formValues.name.trim();
      fieldDefinition.type = formValues.type;
      fieldDefinition.required = formValues.required;
      fieldDefinition.options =
        options.length > 0
          ? options.map(o => ({
              label: o.label as string,
              value: o.value
            }))
          : undefined;
      fieldDefinition.defaultValue = formValues.defaultValue.trim() || undefined;
      fieldDefinition.mapping = formValues.mapping.trim() || undefined;
      fieldDefinition.visible = formValues.visible;
      fieldDefinition.validationRegex = formValues.validationRegex.trim() || undefined;
      fieldDefinition.validationMessage = formValues.validationMessage.trim() || undefined;
      fieldDefinition.description = formValues.description.trim() || undefined;

      mutate({
        clientId,
        requestBody: projectCustomFieldDefinitions
      });
    },
    [options, editedFieldIndex, clientId, mutate, customFieldDefinitions]
  );

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader title="Edit Custom Field" onClose={onClose} />
      <DrawerBody>
        {!!error && <SnackbarApiError error={error} />}
        <Formik
          initialValues={{
            name: editedField.name,
            type: editedField.type,
            required: editedField.required || false,
            defaultValue: editedField.defaultValue || "",
            mapping: editedField.mapping || "",
            externalClientId: editedField.externalClientId || "",
            visible: editedField.visible,
            validationRegex: editedField.validationRegex || "",
            validationMessage: editedField.validationMessage || "",
            description: editedField.description || ""
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validate={validate}
        >
          <CustomFieldForm
            options={options}
            setOptions={setOptions}
            mappingOptions={mappingOptions}
            onCancel={onClose}
            allowTextArea={editedField.category === CustomFieldDefinition.category.PROJECT}
          />
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { EditCustomFieldPanel };
