import { Chip, CircularProgress, SxProps, Theme, Tooltip } from "@mui/material";
import React from "react";
import { JobRequestFile, JobRequestsService } from "gen/clients";

interface Props {
  jobRequestId?: string;
  file: JobRequestFile;
  sx?: SxProps<Theme>;
  label: string | JSX.Element;
  onClick?: (file: JobRequestFile) => void;
  onDeleteClick?: (file: JobRequestFile) => void;
}

function bufferToBase64(buffer: ArrayBuffer) {
  const bytes = new Uint8Array(buffer);
  let binary = "";
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export const MIME_TYPE_BY_FILE_EXTENSION: Record<string, string> = {
  ".png": "image/png",
  ".jpg": "image/jpeg",
  ".jpeg": "image/jpeg",
  ".gif": "image/gif",
  ".tiff": "image/tiff",
  ".bmp": "image/bmp",
  ".webp": "image/webp"
};

const MAX_WIDTH = 250;
const MAX_HEIGHT = 250;

const ImageChip = ({ jobRequestId, file, sx, label, onClick, onDeleteClick }: Props): React.ReactElement => {
  const [hovering, setHovering] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState("");

  const getFileDownloadUrl = React.useCallback(async (): Promise<string | undefined> => {
    if (jobRequestId) {
      const response = await JobRequestsService.getJobRequestFileDownloadUrl({
        jobRequestId,
        fileKey: encodeURIComponent(file.fileKey)
      });
      return response.downloadUrl;
    }

    return undefined;
  }, [jobRequestId, file]);

  const handleMouseEnter = async () => {
    setHovering(true);
    if (!imageSrc) {
      const downloadUrl = await getFileDownloadUrl();
      if (downloadUrl) {
        const response = await fetch(downloadUrl);
        const buffer = await response.arrayBuffer();
        const extension = Object.keys(MIME_TYPE_BY_FILE_EXTENSION).find(v => file.fileName.includes(v));
        if (extension) {
          const contentType = MIME_TYPE_BY_FILE_EXTENSION[extension];
          const dataUrl = `data:${contentType};base64,${bufferToBase64(buffer)}`;
          setImageSrc(dataUrl);
        }
      }
    }
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  return (
    <Tooltip
      open={hovering}
      title={
        !imageSrc ? (
          <CircularProgress />
        ) : (
          <img
            src={imageSrc}
            alt={file.fileName}
            style={{
              maxWidth: MAX_WIDTH,
              maxHeight: MAX_HEIGHT,
              width: "auto",
              height: "auto"
            }}
          />
        )
      }
      arrow
    >
      <Chip
        sx={sx}
        label={label}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick ? () => onClick(file) : undefined}
        onDelete={onDeleteClick ? () => onDeleteClick(file) : undefined}
      />
    </Tooltip>
  );
};

export { ImageChip };
