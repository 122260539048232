import * as React from "react";
import { ApplicationSummary } from "gen/clients";
import { MachineTranslationSettingsPanel } from "./MachineTranslationSettingsPanel";
import { ApplicationId } from "types/ApplicationId";

interface Props {
  application: ApplicationSummary;
  onClose: () => void;
  onSuccess: () => void;
}

const ApplicationSettingsPanel: React.FC<Props> = ({ application, onClose, onSuccess }) => {
  if (application.id === ApplicationId.machineTranslation) {
    return <MachineTranslationSettingsPanel applicationId={application.id} onClose={onClose} onSuccess={onSuccess} />;
  }
  throw new Error(`Unsupported applications id [${application.id}]`);
};

export { ApplicationSettingsPanel };
