import * as React from "react";
import { SelectOption } from "@mui/base";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import { useQuery } from "react-query";
import { ClientsService } from "gen/clients";

interface Props {
  /**
   * Field name
   */
  name: string;
  onChange: (clientOption: SelectOption<string>) => void;
}

const ClientCompanySelect: React.FC<Props> = ({ name, onChange }) => {
  const [searchText, setSearchText] = React.useState<string>();

  const { data: matchingClients, isLoading } = useQuery(["listClients", searchText], {
    queryFn: () => ClientsService.listClients({ companyName: searchText, limit: 30 })
  });

  const options: SelectOption<string>[] = React.useMemo(() => {
    if (!matchingClients?.items.length) {
      return [];
    }
    return matchingClients.items.map(client => ({
      label: client.companyName,
      value: client.id
    }));
  }, [matchingClients?.items]);

  const onSelect = React.useCallback(
    (selectedOption: SelectOption<string>) => {
      onChange(selectedOption);
    },
    [onChange]
  );

  return (
    <SelectOneAutocompleteField
      name={name}
      options={options}
      onInputTextChange={setSearchText}
      onSelect={onSelect}
      areOptionsLoading={isLoading}
      suppressFilterOptions={true}
      required={true}
      placeholder="Type company name"
      label="Company Name"
    />
  );
};

export { ClientCompanySelect };
