import { SignInAction, SignOutAction, UserSessionActionType } from "./types";
import { UserGroup } from "../../gen/clients";

export const signIn = (username: string, email: string, roles: UserGroup[]): SignInAction => ({
  username,
  email,
  roles,
  type: UserSessionActionType.SIGN_IN
});

export const signOut = (): SignOutAction => ({
  type: UserSessionActionType.SIGN_OUT
});
