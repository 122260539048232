import * as React from "react";
import { ClientContactSummary, ClientsService, UserAccountsService, UserGroup } from "gen/clients";
import { DataTable } from "components/DataTable/DataTable";
import { useQueries, useQuery } from "react-query";
import { Form, Formik } from "formik";
import { IconButton, InputBase, Paper, Skeleton, TablePagination, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { DataTableRowAction } from "components/DataTable/types";
import { useDialogState } from "hooks/useDialogState";
import { ClientContactDetailsPanel } from "./ClientContactDetailsPanel/ClientContactDetailsPanel";
import Stack from "@mui/material/Stack";
import { formatEpoch } from "utils/dateUtils";

interface Props {
  clientId: string;
}

const ClientContactsTab: React.FC<Props> = ({ clientId }) => {
  const [clientContactsFilter, setClientContactsFilter] = React.useState("");
  const [applyClientContactsFilter, setApplyClientContactsFilter] = React.useState("");
  const [selectedContact, setSelectedContact] = React.useState<ClientContactSummary>();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isClientContactDetailsPanelOpen, openClientContactDetailsPanel, closeClientContactDetailsPanel] =
    useDialogState();

  const {
    data: clientContacts,
    error,
    isLoading
  } = useQuery(["listClientContacts", clientId], {
    queryFn: () => ClientsService.listClientContacts({ clientId }),
    onSuccess: () => window.console.log("listClientContacts query succeeded")
  });

  const filteredClientContacts = React.useMemo(() => {
    const filterTokens = applyClientContactsFilter.toLowerCase().split(" ");
    return (
      clientContacts?.items.filter(contact => {
        for (let i = 0; i < filterTokens.length; i++) {
          const filterToken = filterTokens[i];
          if (
            !(contact.firstName || "").toLowerCase().includes(filterToken) &&
            !(contact.lastName || "").toLowerCase().includes(filterToken) &&
            !contact.email.toLowerCase().includes(filterToken)
          ) {
            return false;
          }
        }
        return true;
      }) || []
    );
  }, [clientContacts?.items, applyClientContactsFilter]);

  const sortedClientContacts = React.useMemo(
    () =>
      filteredClientContacts?.sort((o1, o2) =>
        (o1.lastName || "").toLowerCase().localeCompare((o2.lastName || "").toLowerCase())
      ) || [],
    [filteredClientContacts]
  );

  const paginatedClientContacts = React.useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return sortedClientContacts.slice(startIndex, endIndex);
  }, [sortedClientContacts, page, rowsPerPage]);

  const cognitoUserSummaryResults = useQueries(
    paginatedClientContacts.map(clientContact => ({
      queryKey: ["listCognitoUsers", clientContact.email],
      queryFn: () => UserAccountsService.listCognitoUsers({ email: clientContact.email })
    }))
  );

  const cognitoUserDetailsResults = useQueries(
    cognitoUserSummaryResults.map(cognitoUserSummaryResult => ({
      enabled: !!cognitoUserSummaryResult.data?.items[0]?.username,
      queryKey: ["getCognitoUser", cognitoUserSummaryResult.data?.items[0]?.username],
      queryFn: () =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        UserAccountsService.getCognitoUser({ cognitoUsername: cognitoUserSummaryResult.data?.items[0]?.username! })
    }))
  );

  const rowKey = React.useCallback((contact: ClientContactSummary) => contact.id.toString(), []);
  const contactFirstNameCell = React.useCallback((contact: ClientContactSummary) => contact.firstName, []);
  const contactLastNameCell = React.useCallback((contact: ClientContactSummary) => contact.lastName, []);
  const contactEmailCell = React.useCallback((contact: ClientContactSummary) => contact.email, []);
  const xtrfIdCell = React.useCallback((contact: ClientContactSummary) => contact.id, []);
  const contactXtrfStatusCell = React.useCallback(
    (contact: ClientContactSummary) => (contact.xtrfAccountStatus ? "Enabled" : "Disabled"),
    []
  );

  const contactCognitoStatusCell = React.useCallback(
    (contact: ClientContactSummary) => {
      const index = paginatedClientContacts.findIndex(c => c.id === contact.id);
      const { data: cognitoUser, isLoading: isCognitoUserLoading } = cognitoUserSummaryResults[index];
      if (isCognitoUserLoading) {
        return <Skeleton variant="rounded" animation="wave" />;
      }
      switch (cognitoUser?.items[0]?.enabled) {
        case true:
          return "Enabled";
        case false:
          return "Disabled";
        default:
          return "";
      }
    },
    [cognitoUserSummaryResults, paginatedClientContacts]
  );

  const cognitoAttributesCell = React.useCallback(
    (contact: ClientContactSummary) => {
      const index = paginatedClientContacts.findIndex(c => c.id === contact.id);
      const { data: cognitoUserCollection, isLoading: isCognitoUserLoading } = cognitoUserSummaryResults[index];
      if (isCognitoUserLoading) {
        return <Skeleton variant="rounded" animation="wave" />;
      }
      const cognitoUser = cognitoUserCollection?.items[0];
      if (!cognitoUser || (!cognitoUser.xtrfClientId && !cognitoUser.xtrfClientContactId)) {
        return "";
      }
      return (
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Typography
            variant="body2"
            sx={theme =>
              cognitoUser.xtrfClientId !== `${contact.clientId}`
                ? { color: theme.palette.error.main, fontWeight: "bold" }
                : {}
            }
          >
            {cognitoUser.xtrfClientId}
          </Typography>
          <span>/</span>
          <Typography
            variant="body2"
            sx={theme =>
              cognitoUser.xtrfClientContactId !== `${contact.id}`
                ? { color: theme.palette.error.main, fontWeight: "bold" }
                : {}
            }
          >
            {cognitoUser.xtrfClientContactId}
          </Typography>
        </Stack>
      );
    },
    [cognitoUserSummaryResults, paginatedClientContacts]
  );

  const lastVisitCell = React.useCallback(
    (contact: ClientContactSummary) => {
      const index = paginatedClientContacts.findIndex(c => c.id === contact.id);
      const { data: cognitoUser, isLoading: isCognitoUserLoading } = cognitoUserDetailsResults[index];
      if (isCognitoUserLoading) {
        return <Skeleton variant="rounded" animation="wave" />;
      }
      return cognitoUser?.lastVisit ? formatEpoch(cognitoUser.lastVisit) : "";
    },
    [cognitoUserDetailsResults, paginatedClientContacts]
  );

  const isOrgAdminCell = React.useCallback(
    (contact: ClientContactSummary) => {
      const index = paginatedClientContacts.findIndex(c => c.id === contact.id);
      const { data: cognitoUser, isLoading: isCognitoUserLoading } = cognitoUserDetailsResults[index];
      if (isCognitoUserLoading) {
        return <Skeleton variant="rounded" animation="wave" />;
      }
      return cognitoUser?.groups ? (cognitoUser.groups.includes(UserGroup.CLIENT) ? "Yes" : "No") : "";
    },
    [cognitoUserDetailsResults, paginatedClientContacts]
  );

  const onPageChange = React.useCallback((_, selectedPage: number) => {
    setPage(selectedPage);
  }, []);

  const onRowsPerPageChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  }, []);

  const onRowClick = React.useCallback(
    (contact: ClientContactSummary) => {
      setSelectedContact(contact);
      openClientContactDetailsPanel();
    },
    [openClientContactDetailsPanel]
  );

  const rowActions = React.useCallback(
    (contact: ClientContactSummary): DataTableRowAction[] => [
      {
        title: "View Details",
        action: () => {
          setSelectedContact(contact);
          openClientContactDetailsPanel();
        }
      }
    ],
    [openClientContactDetailsPanel]
  );

  const onCloseClientContactDetailsPanel = React.useCallback(() => {
    closeClientContactDetailsPanel();
  }, [closeClientContactDetailsPanel]);

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          setPage(0);
          setApplyClientContactsFilter(clientContactsFilter);
        }}
      >
        <Form>
          <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, my: 2 }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search client contact ..."
              onChange={event => setClientContactsFilter(event.currentTarget.value)}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Form>
      </Formik>
      <DataTable
        columns={[
          {
            id: "firstName",
            title: "First Name",
            cell: contactFirstNameCell
          },
          {
            id: "lastName",
            title: "Last Name",
            cell: contactLastNameCell
          },
          {
            id: "email",
            title: "Email",
            cell: contactEmailCell
          },
          {
            id: "xtrfStatus",
            title: "XTRF Status",
            cell: contactXtrfStatusCell
          },
          {
            id: "xtrfId",
            title: "XTRF ID",
            cell: xtrfIdCell
          },
          {
            id: "cognitoStatus",
            title: "Cognito Status",
            cell: contactCognitoStatusCell
          },
          {
            id: "cognitoAttrs",
            title: (
              <Stack textAlign="center">
                <div>Cognito Attributes</div>
                <div>(xtrfClientId / xtrfClientContactId)</div>
              </Stack>
            ),
            cell: cognitoAttributesCell
          },
          {
            id: "isOrgAdmin",
            title: "Org Admin",
            cell: isOrgAdminCell
          },
          {
            id: "lastVisit",
            title: "Last Visit",
            cell: lastVisitCell
          }
        ]}
        data={paginatedClientContacts}
        isLoading={isLoading}
        error={!!error && <ApiErrorMessage apiError={error} />}
        rowKey={rowKey}
        rowActions={rowActions}
        onRowClick={onRowClick}
        dense={true}
      />

      {filteredClientContacts.length > 0 && (
        <TablePagination
          component="div"
          count={filteredClientContacts.length}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}

      {isClientContactDetailsPanelOpen && selectedContact !== undefined && (
        <ClientContactDetailsPanel clientContact={selectedContact} onClose={onCloseClientContactDetailsPanel} />
      )}
    </>
  );
};

export { ClientContactsTab };
