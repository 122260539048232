import { Card, InputLabel, Grid, Stack, Button, TextField } from "@mui/material";
import { SystemSettings } from "gen/clients";
import { useDialogState } from "hooks/useDialogState";
import { EditInstructionsPanel } from "./EditInstructionsPanel";
import { TestInstructionsPanel } from "./TestInstructionsPanel";
import React from "react";

interface Props {
  systemSettings: SystemSettings;
  systemSettingsRefetch: () => void;
}

const OpenAISettingsCard: React.FC<Props> = ({ systemSettings, systemSettingsRefetch }) => {
  const [isEditAnalysisPanelOpen, openEditAnalysisPanel, closeEditAnalysisPanel] = useDialogState();
  const [isEditScreeningPanelOpen, openEditScreeningPanel, closeEditScreeningPanel] = useDialogState();
  const [isTestAnalysisPanelOpen, openTestAnalysisPanel, closeTestAnalysisPanel] = useDialogState();
  const [isTestScreeningPanelOpen, openTestScreeningPanel, closeTestScreeningPanel] = useDialogState();

  const onInstructionsUpdated = React.useCallback(
    (analysis: boolean) => {
      systemSettingsRefetch();
      if (analysis) {
        closeEditAnalysisPanel();
      } else {
        closeEditScreeningPanel();
      }
    },
    [closeEditAnalysisPanel, closeEditScreeningPanel, systemSettingsRefetch]
  );

  if (systemSettings) {
    return (
      <>
        <Card sx={{ mt: 2, p: 2 }}>
          <InputLabel>OpenAI Settings</InputLabel>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12}>
              <TextField
                label="Screening Instructions"
                helperText="Instructions for AI about how to screen text of incoming emails"
                variant="outlined"
                value={systemSettings.emailProcessorSettings?.openAI?.prompts.emailScreening || ""}
                multiline
                disabled
                rows={10}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="primary" onClick={openEditScreeningPanel}>
                  Edit
                </Button>
                <Button variant="outlined" color="primary" onClick={openTestScreeningPanel}>
                  Test
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Analysis Instructions"
                helperText="AI analysis instructions to be applied on jobrequest email content"
                variant="outlined"
                value={systemSettings.emailProcessorSettings?.openAI?.prompts.emailAnalysis || ""}
                multiline
                disabled
                rows={10}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="primary" onClick={openEditAnalysisPanel}>
                  Edit
                </Button>
                <Button variant="outlined" color="primary" onClick={openTestAnalysisPanel}>
                  Test
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {isTestAnalysisPanelOpen && <TestInstructionsPanel isAnalysis={true} onClose={closeTestAnalysisPanel} />}
          {isTestScreeningPanelOpen && <TestInstructionsPanel isAnalysis={false} onClose={closeTestScreeningPanel} />}
          {isEditAnalysisPanelOpen && (
            <EditInstructionsPanel
              isAnalysis={true}
              systemSettings={systemSettings}
              onSuccess={() => onInstructionsUpdated(true)}
              onClose={closeEditAnalysisPanel}
            />
          )}
          {isEditScreeningPanelOpen && (
            <EditInstructionsPanel
              isAnalysis={false}
              systemSettings={systemSettings}
              onSuccess={() => onInstructionsUpdated(false)}
              onClose={closeEditScreeningPanel}
            />
          )}
        </Card>
      </>
    );
  }
  return <></>;
};

export { OpenAISettingsCard };
