import { MtBillingType } from "gen/clients/models/MtBillingType";

export function describeMtBillingType(type: MtBillingType): string {
  switch (type) {
    case MtBillingType.MONTHLY:
      return "Monthly Subscription";
    case MtBillingType.PER_WORD:
      return "Per-Word Usage";
    case MtBillingType.FREE_TRIAL:
      return "Free Trial";
    default:
      return "";
  }
}

export const mtBillingTypeOptions = Object.keys(MtBillingType)
  .map(type => ({
    label: describeMtBillingType(type as MtBillingType),
    value: type
  }))
  .sort((o1, o2) => o1.label.localeCompare(o2.label));
