/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type OpenAIRequest = {
    text: string;
    instructions?: string;
    type?: OpenAIRequest.type;
}

export namespace OpenAIRequest {

    export enum type {
        ANALYSIS = 'ANALYSIS',
        SCREENING = 'SCREENING',
        REJECTION_REASONS = 'REJECTION_REASONS',
    }


}
