import * as React from "react";
import { useDialogState } from "hooks/useDialogState";
import { DropzoneDialog } from "react-mui-dropzone";
import { UtilsService } from "gen/clients";
import { FilesUploadProgressDialog } from "components/FilesUploadProgressDialog/FilesUploadProgressDialog";
import { useQuery } from "react-query";
import { LoadingButton } from "../LoadingButton/LoadingButton";
import styles from "./UploadFilesButton.module.scss";

interface Props {
  buttonText?: string;
  onSuccess: (uploadedFiles: UploadedFile[]) => void;
  isLoading?: boolean;
}

export interface UploadedFile {
  file: File;
  fileKey: string;
}

const MAX_FILE_SIZE = 40 * 1024 * 1024;

const UploadFilesButton: React.FC<Props> = ({ buttonText = "Upload Files", onSuccess, isLoading }) => {
  const [addFilesDialogOpen, openAddFilesDialog, closeAddFilesDialog] = useDialogState();
  const [filesUploadDialogOpen, openFilesUploadDialog, closeFilesUploadDialog] = useDialogState();
  const [files, setFiles] = React.useState<File[]>();

  const {
    data: presignedPost,
    error: presignedPostError,
    isFetching: presignedPostFetching
  } = useQuery(["createPresignedPost", files], {
    enabled: !!files && files.length > 0,
    queryFn: UtilsService.createFileUploadPresignedPost
  });

  const onFilesSelected = React.useCallback(
    (selectedFiles: File[]) => {
      setFiles(selectedFiles);
      closeAddFilesDialog();
      openFilesUploadDialog();
    },
    [closeAddFilesDialog, openFilesUploadDialog]
  );

  const onUploadSuccess = React.useCallback(() => {
    closeFilesUploadDialog();
    if (!presignedPost || !files) {
      return;
    }
    const uploadedFiles: UploadedFile[] = files.map(file => ({
      file,
      // eslint-disable-next-line no-template-curly-in-string
      fileKey: presignedPost.fields.key.replaceAll("${filename}", file.name)
    }));
    onSuccess(uploadedFiles);
  }, [closeFilesUploadDialog, files, onSuccess, presignedPost]);

  return (
    <>
      <LoadingButton onClick={openAddFilesDialog} variant="outlined" isLoading={!!isLoading} type="button">
        {buttonText}
      </LoadingButton>
      {addFilesDialogOpen && (
        <DropzoneDialog
          dialogTitle="Upload Files"
          open={true}
          filesLimit={20}
          maxFileSize={MAX_FILE_SIZE}
          dropzoneText="Drag and drop files here or click"
          showFileNames={true}
          showPreviews={true}
          showFileNamesInPreview={true}
          useChipsForPreview={true}
          onClose={closeAddFilesDialog}
          onSave={onFilesSelected}
          dropzoneClass={styles.dropzone}
          previewText=""
          submitButtonText="Upload"
        />
      )}
      {filesUploadDialogOpen && files && (
        <FilesUploadProgressDialog
          files={files}
          presignedPost={presignedPost}
          isPresignedPostLoading={presignedPostFetching}
          presignedPostError={presignedPostError}
          onSuccess={onUploadSuccess}
        />
      )}
    </>
  );
};

export { UploadFilesButton };
