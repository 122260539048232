import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { ApiError, SystemSettings, SystemSettingsService } from "gen/clients";
import { Form, Formik } from "formik";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "../../../../components/LoadingButton/LoadingButton";
import { SnackbarApiError } from "../../../../components/SnackbarApiError/SnackbarApiError";
import { SnackbarAlert } from "../../../../components/SnackbarAlert/SnackbarAlert";
import { validateEmails } from "../../../../utils/stringUtils";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import { OpenAISettingsCard } from "./components/OpenAISettingsCard";

enum FieldNames {
  junkRequestNotificationEmails = "junkRequestNotificationEmails",
  translationRequestNotificationEmails = "translationRequestNotificationEmails",
  quoteRequestNotificationEmails = "quoteRequestNotificationEmails",
  quoteUpdateRequestNotificationEmails = "quoteUpdateRequestNotificationEmails",
  employmentRequestNotificationEmails = "employmentRequestNotificationEmails",
  jobStatusRequestNotificationEmails = "jobStatusRequestNotificationEmails",
  salesInquiryRequestNotificationEmails = "salesInquiryRequestNotificationEmails",
  billingRequestNotificationEmails = "billingRequestNotificationEmails",
  approvalRequestNotificationEmails = "approvalRequestNotificationEmails",
  otherRequestNotificationEmails = "otherRequestNotificationEmails"
}

const EmailProcessorSettingsTab: React.FC = () => {
  const {
    data: systemSettings,
    isLoading,
    error,
    refetch
  } = useQuery<SystemSettings, ApiError>(["getSystemSettings"], {
    queryFn: SystemSettingsService.getSystemSettings
  });

  const {
    mutateAsync: save,
    isLoading: isSaving,
    error: saveError,
    isSuccess: saveSuccess
  } = useMutation(SystemSettingsService.updateSystemSettings);

  const validate = React.useCallback(values => {
    const errors: Record<string, string> = {};

    const validateEmailSettings = (setting: string) => {
      const settingValue = values[setting] as string | undefined;
      if (settingValue && !validateEmails(settingValue.split(/\s*,\s*/).filter((d: string) => d.length > 0))) {
        errors[setting] = "Invalid email format";
      }
    };

    Object.keys(FieldNames)
      .filter(key => key.endsWith("Emails"))
      .forEach(key => {
        validateEmailSettings(key);
      });

    return errors;
  }, []);

  const onSubmit = React.useCallback(
    async values => {
      if (!systemSettings) {
        return;
      }
      const newData: { [key: string]: string[] } = {};

      Object.keys(FieldNames)
        .filter(key => key.endsWith("Emails") && values[key] !== undefined)
        .forEach(key => {
          newData[key] = values[key].split(/\s*,\s*/).filter((d: string) => d.length > 0);
        });

      await save({
        requestBody: {
          ...systemSettings,
          emailProcessorSettings: {
            ...(systemSettings.emailProcessorSettings || {}),
            notificationEmailAddresses: {
              ...(systemSettings.emailProcessorSettings?.notificationEmailAddresses || {}),
              JUNK_REQUEST: newData[FieldNames.junkRequestNotificationEmails],
              TRANSLATION_REQUEST: newData[FieldNames.translationRequestNotificationEmails],
              QUOTE_REQUEST: newData[FieldNames.quoteRequestNotificationEmails],
              OTHER_REQUEST: newData[FieldNames.otherRequestNotificationEmails]
            }
          }
        }
      });
      refetch();
    },
    [refetch, save, systemSettings]
  );

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ApiErrorMessage apiError={error} />;
  }

  if (systemSettings) {
    return (
      <>
        <Formik
          initialValues={{
            [FieldNames.junkRequestNotificationEmails]:
              systemSettings?.emailProcessorSettings?.notificationEmailAddresses?.JUNK_REQUEST?.join(", ") || "",
            [FieldNames.otherRequestNotificationEmails]:
              systemSettings?.emailProcessorSettings?.notificationEmailAddresses?.OTHER_REQUEST?.join(", ") || "",
            [FieldNames.translationRequestNotificationEmails]:
              systemSettings?.emailProcessorSettings?.notificationEmailAddresses?.TRANSLATION_REQUEST?.join(", ") || "",
            [FieldNames.quoteRequestNotificationEmails]:
              systemSettings?.emailProcessorSettings?.notificationEmailAddresses?.QUOTE_REQUEST?.join(", ") || ""
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          <Form>
            <Stack spacing={3} mt={2}>
              <Card sx={{ p: 2 }}>
                <InputLabel>Set who to notify for each category of incoming emails:</InputLabel>
                <FormControl fullWidth sx={{ p: 2 }}>
                  <FormGroup>
                    <TextInputField
                      name={FieldNames.junkRequestNotificationEmails}
                      label="Junk Emails"
                      helperText="Comma separated list of emails"
                    />
                    <TextInputField
                      name={FieldNames.translationRequestNotificationEmails}
                      label="Translation Requests"
                      helperText="Comma separated list of emails"
                    />
                    <TextInputField
                      name={FieldNames.quoteRequestNotificationEmails}
                      label="Quote Requests"
                      helperText="Comma separated list of emails"
                    />
                    <TextInputField
                      name={FieldNames.otherRequestNotificationEmails}
                      label="Other Requests"
                      helperText="Comma separated list of emails"
                    />
                  </FormGroup>
                </FormControl>
                <Box>
                  <LoadingButton isLoading={isSaving}>Save</LoadingButton>
                </Box>
              </Card>
            </Stack>
          </Form>
        </Formik>
        {saveError && <SnackbarApiError error={saveError} />}
        {saveSuccess && <SnackbarAlert message="Operation completed successfully" severity="success" />}

        <OpenAISettingsCard systemSettings={systemSettings} systemSettingsRefetch={refetch} />
      </>
    );
  }

  return <></>;
};

export { EmailProcessorSettingsTab };
