import * as React from "react";
import { JobRequestFile } from "gen/clients";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import { ImageChip, MIME_TYPE_BY_FILE_EXTENSION } from "./ImageChip";
import { downloadJobRequestFile } from "../../../../../utils/JobRequestUtils";

interface Props {
  jobRequestId?: string;
  isPreviewEnabled?: boolean;
  files?: JobRequestFile[];
  onClick?: (file: JobRequestFile) => void;
  onDeleteClick?: (file: JobRequestFile) => void;
}

const FileChips = ({ jobRequestId, files, onClick, onDeleteClick, isPreviewEnabled }: Props): React.ReactElement => {
  const onFileLinkClick = React.useCallback(
    async (file: JobRequestFile) => {
      if (!jobRequestId) {
        return;
      }
      await downloadJobRequestFile(jobRequestId, file);
    },
    [jobRequestId]
  );

  return (
    <>
      {files?.map(file =>
        isPreviewEnabled && Object.keys(MIME_TYPE_BY_FILE_EXTENSION).some(v => file.fileName.includes(v)) ? (
          <ImageChip
            sx={{ mr: 0.5, mb: 0.5 }}
            jobRequestId={jobRequestId}
            file={file}
            key={file.fileName}
            label={
              jobRequestId ? (
                <Link
                  href={file.fileName}
                  onClick={event => {
                    event.preventDefault();
                    onFileLinkClick(file);
                  }}
                >
                  {file.fileName}
                </Link>
              ) : (
                file.fileName
              )
            }
            onClick={onClick ? () => onClick(file) : undefined}
            onDeleteClick={onDeleteClick ? () => onDeleteClick(file) : undefined}
          />
        ) : (
          <Chip
            sx={{ mr: 0.5, mb: 0.5 }}
            key={file.fileName}
            label={
              jobRequestId ? (
                <Link
                  href={file.fileName}
                  onClick={event => {
                    event.preventDefault();
                    onFileLinkClick(file);
                  }}
                >
                  {file.fileName}
                </Link>
              ) : (
                file.fileName
              )
            }
            onClick={onClick ? () => onClick(file) : undefined}
            onDelete={onDeleteClick ? () => onDeleteClick(file) : undefined}
          />
        )
      )}
    </>
  );
};

export { FileChips };
