import { ContentInputMethod, FieldNames, FormValues } from "./JobRequestForm";
import { JobRequestFileLink } from "gen/clients";

export class JobRequestFormValidator {
  public static validate(values: FormValues): Record<string, string> {
    let errors: Record<string, string> = {};
    // Validate files
    if (values[FieldNames.inputMethod] === ContentInputMethod.FILES) {
      errors = { ...errors, ...this.validateFiles(values) };
    }
    if (values[FieldNames.inputMethod] === ContentInputMethod.FILELINKS) {
      errors = { ...errors, ...this.validateFileLinks(values) };
    }
    // Validate text input
    if (values[FieldNames.inputMethod] === ContentInputMethod.TEXT) {
      if (!(values[FieldNames.textInput] as string).trim()) {
        errors[FieldNames.textInput] = "Required";
      }
    }

    if (values[FieldNames.notes] && (values[FieldNames.notes] as string).trim().length > 5000) {
      errors[FieldNames.notes] = "Notes cannot exceed 5,000 characters";
    }

    return errors;
  }

  private static validateFiles(values: FormValues): Record<string, string> {
    const errors: Record<string, string> = {};
    const files = values[FieldNames.sourceDocumentFiles];
    if (!files || files.length === 0) {
      errors[FieldNames.sourceDocumentFiles] = "Required. Add at least one file.";
    } else if (new Set(files.map(file => file.fileName)).size !== files.length) {
      errors[FieldNames.sourceDocumentFiles] =
        "Warning!  Your submission contains duplicate files.  Please remove duplicates before submitting.";
    }
    // If reference files are present, validate them
    const referenceFiles = values[FieldNames.referenceFiles];
    if (values[FieldNames.includeReferenceFiles] && referenceFiles && referenceFiles.length > 0) {
      if (new Set(referenceFiles.map(file => file.fileName)).size !== referenceFiles.length) {
        errors[FieldNames.referenceFiles] =
          "Warning!  Your submission contains duplicate files.  Please remove duplicates before submitting.";
      } else {
        const projectFileNames = (files || []).map(file => file.fileName);
        const referenceFileNames = referenceFiles.map(file => file.fileName);
        if (projectFileNames.filter(fileName => referenceFileNames.includes(fileName)).length > 0) {
          errors[FieldNames.referenceFiles] =
            "Warning!  At least one of your reference files is a duplicate of a project file.  Please remove duplicates before submitting.";
        }
      }
    }
    return errors;
  }

  private static validateFileLinks(values: FormValues): Record<string, string> {
    const errors: Record<string, string> = {};
    const links = values[FieldNames.sourceFileLinks] as JobRequestFileLink[] | undefined;
    if (!links || links.length === 0) {
      errors[FieldNames.sourceFileLinks] = "Required. Add at least one file link.";
    } else if (new Set(links.map(link => link.url)).size !== links.length) {
      errors[FieldNames.sourceFileLinks] =
        "Warning! Your submission contains file links with duplicate URLs.  Please remove duplicates before submitting.";
    }
    return errors;
  }
}
