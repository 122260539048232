import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { useMutation } from "react-query";
import { UserAccountsService } from "gen/clients";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  xtrfClientId?: string;
  xtrfClientContactId?: string;
  onSuccess: () => void;
  onClose: () => void;
}

enum FieldName {
  userName = "userName",
  password = "password",
  repeatPassword = "repeatPassword"
}

interface FormValues {
  [FieldName.userName]: string;
  [FieldName.password]: string;
  [FieldName.repeatPassword]: string;
}

const validationSchema = Yup.object().shape({
  [FieldName.password]: Yup.string().required("Password is required"),
  [FieldName.repeatPassword]: Yup.string()
    .oneOf([Yup.ref(FieldName.password), null], "Passwords must match")
    .required("Please repeat the password")
});

const CreateCognitoUserDialog: React.FC<Props> = ({
  username,
  firstName,
  lastName,
  email,
  xtrfClientId,
  xtrfClientContactId,
  onSuccess,
  onClose
}) => {
  const { mutateAsync, error } = useMutation(UserAccountsService.createCognitoUser, { onSuccess });

  const initialValues = React.useMemo<FormValues>(
    () => ({
      [FieldName.userName]: username,
      [FieldName.password]: "",
      [FieldName.repeatPassword]: ""
    }),
    [username]
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutateAsync({
        requestBody: {
          username,
          firstName,
          lastName,
          email,
          xtrfClientId,
          xtrfClientContactId,
          temporaryPassword: values[FieldName.password]
        }
      });
    },
    [mutateAsync, username, firstName, lastName, email, xtrfClientId, xtrfClientContactId]
  );

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Create Cognito Account</DialogTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount={false}
          validateOnChange={true}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form noValidate={true} autoComplete="off" autoCorrect="off">
              <DialogContent>
                <Stack spacing={3}>
                  <TextInputField name={FieldName.userName} disabled={true} label="User Name" />
                  <TextInputField name={FieldName.password} required={true} label="Password" type="password" />
                  <TextInputField
                    name={FieldName.repeatPassword}
                    required={true}
                    label="Repeat password"
                    type="password"
                  />
                </Stack>
                {error && <ApiErrorMessage apiError={error} />}
              </DialogContent>
              <DialogActions>
                <LoadingButton type="submit" color="primary" variant="contained" isLoading={isSubmitting}>
                  Create
                </LoadingButton>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export { CreateCognitoUserDialog };
