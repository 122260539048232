import * as React from "react";
import { PresignedPost } from "../../gen/clients";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { ApiErrorMessage } from "../ApiErrorMessage/ApiErrorMessage";
import Box from "@mui/material/Box";
import { PageLoading } from "../PageLoading/PageLoading";

interface Props {
  title?: string;
  files: File[];
  onSuccess: () => void;
  isPresignedPostLoading?: boolean;
  presignedPostError: unknown;
  presignedPost: PresignedPost | undefined;
}

async function uploadFile(url: string, fields: Record<string, string>, file: File): Promise<void> {
  const formData = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", file);
  await fetch(url, {
    method: "POST",
    body: formData
  });
}

const FilesUploadProgressDialog: React.FC<Props> = ({
  title = "Uploading...",
  files,
  presignedPost,
  onSuccess,
  isPresignedPostLoading,
  presignedPostError
}) => {
  const [currentFile, setCurrentFile] = React.useState<File>();
  const [error, setError] = React.useState<string>();

  const uploadAll = React.useCallback(async () => {
    if (!presignedPost) {
      return;
    }
    const { url } = presignedPost;
    if (!url) {
      throw new Error("url in presigned post is not set");
    }
    let file: File | undefined;
    try {
      // eslint-disable-next-line no-restricted-syntax
      for (file of files) {
        setCurrentFile(file);
        // eslint-disable-next-line no-await-in-loop
        await uploadFile(url, presignedPost.fields || {}, file);
      }
      onSuccess();
    } catch (e) {
      window.console.error("File upload failed", { file, e });
      setError("File upload failed");
      setCurrentFile(undefined);
    }
  }, [files, onSuccess, presignedPost]);

  React.useEffect(() => {
    uploadAll();
  }, [uploadAll]);

  return (
    <Dialog open={true} fullWidth={true} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>Uploading files. Please wait.</DialogContentText>
        {isPresignedPostLoading && <PageLoading />}
        {presignedPostError && <ApiErrorMessage apiError={presignedPostError} />}
        <Stack sx={{ my: 2 }} spacing={1}>
          {error && <Box color="error">{error}</Box>}
          {files.map(file => (
            <Stack key={file.name} direction="row" spacing={1} alignItems="center">
              <Typography>{file.name}</Typography>
              {file === currentFile && <LinearProgress variant="indeterminate" sx={{ width: 100, height: 5 }} />}
            </Stack>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export { FilesUploadProgressDialog };
