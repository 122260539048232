import * as React from "react";
import { useHistory } from "react-router-dom";
import { BreadcrumbItem, Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";
import {
  AppBar,
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import AppHeader from "../AppHeader/AppHeader";
import Grid2 from "@mui/material/Unstable_Grid2";
import BusinessIcon from "@mui/icons-material/Business";
import SettingsIcon from "@mui/icons-material/Settings";
import InsightsIcon from "@mui/icons-material/Insights";
import MailIcon from "@mui/icons-material/Mail";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ArticleIcon from "@mui/icons-material/Article";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store/store";
import { intersect } from "../../utils/arrayUtils";
import { isString } from "../../utils/stringUtils";
import { PagePath } from "../../types/PagePath";
import { UserGroup } from "../../gen/clients";

interface Props {
  title?: React.ReactNode;
  selectedNavMenu: NavMenuOption;
  breadcrumbs?: BreadcrumbItem[];
  children: React.ReactNode;
}

export enum NavMenuOption {
  CLIENTS = "CLIENTS",
  ANALYTICS = "ANALYTICS",
  EMAIL_JOB_REQUESTS = "EMAIL_JOB_REQUESTS",
  MT_JOB_REQUESTS = "MT_JOB_REQUESTS",
  QUOTE_JOB_REQUESTS = "QUOTE_JOB_REQUESTS",
  SYSTEM_SETTINGS = "SYSTEM_SETTINGS"
}

interface MenuItem {
  id: NavMenuOption;
  title: string;
  link: string;
  icon: React.ReactElement;
  allowedRoles: UserGroup[];
}

const MENU_OPTIONS: MenuItem[] = [
  {
    id: NavMenuOption.CLIENTS,
    title: "Clients",
    link: PagePath.clients,
    icon: <BusinessIcon />,
    allowedRoles: [UserGroup.ADMIN, UserGroup.SUPER_USER]
  },
  {
    id: NavMenuOption.QUOTE_JOB_REQUESTS,
    title: "Quote Requests",
    link: PagePath.quoteJobRequests,
    icon: <ArticleIcon />,
    allowedRoles: [UserGroup.ADMIN, UserGroup.SUPER_USER, UserGroup.OPERATOR]
  },
  {
    id: NavMenuOption.MT_JOB_REQUESTS,
    title: "MT Job Requests",
    link: PagePath.mtJobRequests,
    icon: <PrecisionManufacturingIcon />,
    allowedRoles: [UserGroup.ADMIN, UserGroup.SUPER_USER, UserGroup.OPERATOR]
  },
  {
    id: NavMenuOption.EMAIL_JOB_REQUESTS,
    title: "Email Job Requests",
    link: PagePath.emailJobRequests,
    icon: <MailIcon />,
    allowedRoles: [UserGroup.ADMIN, UserGroup.SUPER_USER, UserGroup.OPERATOR]
  },
  {
    id: NavMenuOption.ANALYTICS,
    title: "Analytics",
    link: "/analytics",
    icon: <InsightsIcon />,
    allowedRoles: [UserGroup.ADMIN, UserGroup.SUPER_USER]
  },
  {
    id: NavMenuOption.SYSTEM_SETTINGS,
    title: "System Settings",
    link: PagePath.systemSettings,
    icon: <SettingsIcon />,
    allowedRoles: [UserGroup.SUPER_USER]
  }
];

const PageLayout: React.FC<Props> = ({ title, selectedNavMenu, breadcrumbs, children }) => {
  const history = useHistory();
  const roles = useSelector((state: ApplicationState) => state.userSession.roles || []);

  const menuItems = React.useMemo(
    () => MENU_OPTIONS.filter(menuOption => intersect(roles, menuOption.allowedRoles)),
    [roles]
  );

  const onMenuItemClick = React.useCallback(
    (menuItem: MenuItem) => {
      history.push(menuItem.link);
    },
    [history]
  );

  return (
    <Container maxWidth={false} disableGutters={true}>
      <AppBar position="sticky">
        <AppHeader />
      </AppBar>
      <Grid2 container={true} sx={{ mt: 2 }}>
        <Grid2 xs={2}>
          <List>
            {menuItems.map(menuItem => (
              <ListItem key={menuItem.id} onClick={() => onMenuItemClick(menuItem)}>
                <ListItemButton selected={menuItem.id === selectedNavMenu}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid2>
        <Grid2 xs={10}>
          <Box sx={{ m: 2 }}>
            {breadcrumbs && (
              <Box sx={{ mt: 1, mb: 2 }}>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </Box>
            )}
            {isString(title) && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  {title}
                </Typography>
              </Box>
            )}
            {!isString(title) && title}
            {children}
          </Box>
        </Grid2>
      </Grid2>
    </Container>
  );
};
export { PageLayout };
