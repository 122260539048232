import * as React from "react";
import { Formik, Form } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions } from "@mui/material";
import Grid from "@mui/material/Grid";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { validateURLFormat } from "utils/stringUtils";
import { JobRequestFileCategory, JobRequestFileLink } from "gen/clients";

interface Props {
  uniqueLinks?: JobRequestFileLink[];
  onClose: () => void;
  onAdd: (fileLink: JobRequestFileLink) => void;
}

interface FormValues {
  url: string;
  name: string;
}

const AddFileLinkDialog: React.FC<Props> = ({ uniqueLinks, onAdd, onClose }) => {
  const onSubmit = React.useCallback(
    values => {
      onAdd({ url: values.url, name: values.name, category: JobRequestFileCategory.SOURCE_DOCUMENT });
    },
    [onAdd]
  );

  const validate = React.useCallback(
    (formValues: FormValues) => {
      const errors: Record<string, string> = {};
      if (uniqueLinks?.map(link => link.url).includes(formValues.url.trim())) {
        errors.url = "A file link with this URL was already added";
      } else if (!validateURLFormat(formValues.url.trim())) {
        errors.url = "Invalid URL format";
      }
      if (uniqueLinks?.map(link => link.name).includes(formValues.name.trim())) {
        errors.name = "A file link with this Name was already added";
      }
      return errors;
    },
    [uniqueLinks]
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Add File Link</DialogTitle>
      <Formik
        initialValues={{
          url: "",
          name: ""
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        <Form noValidate={true}>
          <DialogContent>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <TextInputField name="url" label="URL" required={true} />
              </Grid>
              <Grid item={true} xs={12}>
                <TextInputField name="name" label="Name" required={true} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit" variant="contained">
              Add
            </Button>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { AddFileLinkDialog };
