import * as React from "react";
import { useDialogState } from "./useDialogState";
import { SnackbarAlert, SnackbarAlertProps } from "../components/SnackbarAlert/SnackbarAlert";

interface UseSnackbarAlertResponse {
  isSnackbarAlertOpen: boolean;
  openSnackbarAlert: (snackbarAlertProps: SnackbarAlertProps) => void;
  closeSnackbarAlert: () => void;
  snackbarAlert?: React.ReactElement;
}

type Props = Omit<SnackbarAlertProps, "message">;

export const useSnackbarAlert = (props: Props): UseSnackbarAlertResponse => {
  const [isSnackbarAlertOpen, openAlert, closeSnackbarAlert] = useDialogState();
  const [snackbarAlertProps, setSnackbarAlertProps] = React.useState<SnackbarAlertProps>({ message: "" });

  const openSnackbarAlert = React.useCallback(
    (alertProps: SnackbarAlertProps) => {
      setSnackbarAlertProps(alertProps);
      openAlert();
    },
    [openAlert]
  );

  const snackbarAlert = React.useMemo(() => {
    if (isSnackbarAlertOpen) {
      return <SnackbarAlert {...props} {...snackbarAlertProps} />;
    }
    return undefined;
  }, [isSnackbarAlertOpen, props, snackbarAlertProps]);

  return {
    isSnackbarAlertOpen,
    openSnackbarAlert,
    closeSnackbarAlert,
    snackbarAlert
  };
};
