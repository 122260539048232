import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { StoreContext } from "redux-react-hook";
import { createBrowserHistory } from "history";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { useEffect } from "react";
import { configureStore } from "store/store";
import logging from "config/logging";
import AuthEventListener from "./AuthEventListener";
import Routes from "./Routes";
import { CssBaseline } from "@mui/material";

const awsconfigOverride = {
  aws_user_pools_id: process.env.REACT_APP_USERPOOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: ["email", "openid"],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "code"
  },
  federationTarget: "COGNITO_USER_POOLS"
};
Amplify.configure(awsconfigOverride);

const history = createBrowserHistory();
const store = configureStore(history);
// noinspection JSIgnoredPromiseFromCall
AuthEventListener.init(store);

const App: React.FC = () => {
  useEffect(() => {
    logging.info("Loading application.");
  }, []);
  return (
    <div>
      <CssBaseline />
      <Provider store={store}>
        <StoreContext.Provider value={store}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </StoreContext.Provider>
      </Provider>
    </div>
  );
};

export default App;
