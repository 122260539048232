import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Field, Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { useMutation } from "react-query";
import { UserAccountsService } from "gen/clients";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import { CheckboxWithLabel } from "formik-mui";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  username: string;
  onSuccess: () => void;
  onClose: () => void;
}

enum FieldName {
  password = "password",
  repeatPassword = "repeatPassword",
  isTemporary = "temporaryPassword"
}

interface FormValues {
  [FieldName.password]: string;
  [FieldName.repeatPassword]: string;
  [FieldName.isTemporary]: boolean;
}

const validationSchema = Yup.object().shape({
  [FieldName.password]: Yup.string().required("Password is required"),
  [FieldName.repeatPassword]: Yup.string()
    .oneOf([Yup.ref(FieldName.password), null], "Passwords must match")
    .required("Please repeat the password"),
  [FieldName.isTemporary]: Yup.boolean().required("Please specify if the password is temporary")
});

const SetPasswordDialog: React.FC<Props> = ({ username, onSuccess, onClose }) => {
  const { mutateAsync, error } = useMutation(UserAccountsService.setCognitoUserPassword, { onSuccess });

  const initialValues = React.useMemo<FormValues>(
    () => ({
      [FieldName.password]: "",
      [FieldName.repeatPassword]: "",
      [FieldName.isTemporary]: true
    }),
    []
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutateAsync({
        cognitoUsername: encodeURIComponent(username),
        requestBody: {
          password: values[FieldName.password],
          temporary: values[FieldName.isTemporary]
        }
      });
    },
    [mutateAsync, username]
  );

  return (
    <>
      <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Set Password</DialogTitle>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount={false}
          validateOnChange={true}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form noValidate={true} autoComplete="off" autoCorrect="off">
              <DialogContent>
                <Stack spacing={3}>
                  <TextInputField name={FieldName.password} required={true} label="Password" type="password" />
                  <TextInputField
                    name={FieldName.repeatPassword}
                    required={true}
                    label="Repeat password"
                    type="password"
                  />
                  <Field
                    name={FieldName.isTemporary}
                    type="checkbox"
                    component={CheckboxWithLabel}
                    Label={{ label: "Is temporary password" }}
                  />
                </Stack>
                {error && <ApiErrorMessage apiError={error} />}
              </DialogContent>
              <DialogActions>
                <LoadingButton type="submit" color="primary" variant="contained" isLoading={isSubmitting}>
                  Save
                </LoadingButton>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export { SetPasswordDialog };
