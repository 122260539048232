import * as React from "react";
import { FormControl, ListSubheader, MenuItem } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import { SelectOption, SelectOptionGroup } from "@mui/base";

interface Props {
  name: string;
  label?: string;
  options: SelectOptionGroup<string | number>[];
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  helperText?: string;
  variant?: "filled" | "outlined" | "standard";
}

const SelectOneGroupOptionField: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  disabled,
  isLoading,
  isError,
  helperText,
  variant = "standard"
}) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <Field
        component={TextField}
        type="text"
        name={name}
        label={label}
        helperText={helperText}
        placeholder={isLoading ? "Loading..." : (isError && "Error") || ""}
        select={true}
        variant={variant}
        disabled={disabled || isLoading || isError}
        required={required}
        validate={(value: string) =>
          required && (!value || !value.toString().trim().length) ? t("common.validation.required") : undefined
        }
      >
        {
          options.map(optionGroup => [
            <ListSubheader color="default">{optionGroup.label?.toString().toLocaleUpperCase()}</ListSubheader>,
            ...optionGroup.options.map(option => (
              <MenuItem sx={{ ml: 2 }} value={(option as SelectOption<string>).value}>
                {option.label}
              </MenuItem>
            ))
          ])
          // </Select>
        }
      </Field>
    </FormControl>
  );
};

export { SelectOneGroupOptionField };
