import * as React from "react";
import { Client, ClientsService, UtilsService, WorkflowOption } from "gen/clients";
import { useMutation, useQuery } from "react-query";
import { useDialogState } from "hooks/useDialogState";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DataTable } from "components/DataTable/DataTable";
import { SelectOption } from "@mui/base";
import { CreateWorkflowPanel } from "./CreateWorkflowPanel";
import { EditWorkflowPanel } from "./EditWorkflowPanel";
import { Form, Formik, FormikValues } from "formik";
import { Divider, Stack, Typography } from "@mui/material";
import { LabelValue } from "components/LabelValue/LabelValue";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { DeleteWorkflowDialog } from "./DeleteWorkflowDialog";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  client: Client;
  onSaveSuccess: () => void;
}

const WorkflowSettingsTab: React.FC<Props> = ({ client, onSaveSuccess }) => {
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<WorkflowOption | undefined>();
  const [isCreatePanelOpen, openCreatePanel, closeCreatePanel] = useDialogState();
  const [isEditPanelOpen, openEditPanel, closeEditPanel] = useDialogState();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useDialogState();

  const { data: xtrfServices } = useQuery("listXtrfServices", {
    queryFn: UtilsService.listXtrfServices
  });

  const {
    data: clientConfig,
    error: clientConfigError,
    isLoading: isClientConfigLoading,
    refetch: refetchClientConfig
  } = useQuery(["getClientConfig", client?.id], {
    enabled: !!client?.id,
    queryFn: () => ClientsService.getClientConfig({ id: client?.id || "" })
  });

  const { mutateAsync } = useMutation({
    onSuccess: onSaveSuccess,
    mutationFn: ClientsService.updateClientConfig
  });

  const xtrfServiceOptions: SelectOption<number>[] = React.useMemo(
    () =>
      xtrfServices
        ?.filter(s => s.active)
        .sort((s1, s2) => s1.name.localeCompare(s2.name))
        .map(service => ({
          label: service.name,
          value: service.id,
          disabled: !service.active
        })) || [],
    [xtrfServices]
  );

  const onEditClick = React.useCallback(
    (workflow: WorkflowOption) => {
      setSelectedWorkflow(workflow);
      openEditPanel();
    },
    [setSelectedWorkflow, openEditPanel]
  );

  const onDeleteClick = React.useCallback(
    (workflow: WorkflowOption) => {
      setSelectedWorkflow(workflow);
      openDeleteDialog();
    },
    [setSelectedWorkflow, openDeleteDialog]
  );

  const onCreateSuccess = React.useCallback(async () => {
    closeCreatePanel();
    refetchClientConfig();
  }, [closeCreatePanel, refetchClientConfig]);

  const onEditSuccess = React.useCallback(async () => {
    closeEditPanel();
    refetchClientConfig();
  }, [closeEditPanel, refetchClientConfig]);

  const onDeleteSuccess = React.useCallback(async () => {
    closeDeleteDialog();
    refetchClientConfig();
  }, [closeDeleteDialog, refetchClientConfig]);

  const xtrfCell = React.useCallback(
    (workflow: WorkflowOption) => {
      const mapping = xtrfServices?.find(w => w.id === workflow.xtrfServiceId);
      return mapping ? `${mapping?.name} [${mapping.id}]` : workflow.xtrfServiceId;
    },
    [xtrfServices]
  );

  const validate = React.useCallback((formValues: FormikValues) => {
    const errors: Record<string, string> = {};
    if (formValues.xtrfServiceId && Number.isNaN(+formValues.xtrfServiceId)) {
      errors.xtrfServiceId = "Service ID must be a number";
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    async formValues => {
      const clientConfigUpdate = { ...(clientConfig || { id: client.id }) };
      if (formValues.xtrfServiceId) {
        clientConfigUpdate.xtrfServiceId = +formValues.xtrfServiceId;
      }
      await mutateAsync(
        {
          id: client.id,
          requestBody: clientConfigUpdate
        },
        { onSuccess: refetchClientConfig }
      );
    },
    [clientConfig, client.id, mutateAsync, refetchClientConfig]
  );

  return (
    <>
      <Formik
        initialValues={{
          xtrfServiceId: clientConfig?.xtrfServiceId || ""
        }}
        onSubmit={onSubmit}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={true}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack spacing={2}>
              <LabelValue
                label="Default XTRF Service"
                value={<SelectOneField name="xtrfServiceId" options={xtrfServiceOptions} />}
              />
            </Stack>
            <Box sx={{ mt: 2 }}>
              <LoadingButton isLoading={isSubmitting}>Save</LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>

      <Stack spacing={2} sx={{ mt: 5 }}>
        <Divider />
        <Typography sx={{ fontWeight: "bold" }}>User Selectable Workflows:</Typography>
        <Box sx={{ mt: 3, mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={openCreatePanel}
            disabled={isClientConfigLoading}
          >
            Add Workflow
          </Button>
        </Box>
        <DataTable
          columns={[
            {
              id: "name",
              title: "Name",
              cell: (workflow: WorkflowOption) => <div>{workflow.label}</div>
            },
            {
              id: "service",
              title: "XTRF Service",
              cell: xtrfCell
            }
          ]}
          data={clientConfig?.selectableWorkflows || []}
          rowKey={(workflow: WorkflowOption) => `${workflow.xtrfServiceId}`}
          rowActions={[
            {
              title: "Edit",
              action: onEditClick
            },
            {
              title: "Delete",
              action: onDeleteClick
            }
          ]}
          dense={true}
          isLoading={isClientConfigLoading}
          error={!!clientConfigError && <ApiErrorMessage apiError={clientConfigError} />}
        />
      </Stack>

      <CreateWorkflowPanel
        open={isCreatePanelOpen}
        clientConfig={clientConfig || { id: client.id }}
        onClose={closeCreatePanel}
        onSuccess={onCreateSuccess}
        xtrfServices={xtrfServiceOptions}
      />

      {selectedWorkflow && (
        <EditWorkflowPanel
          workflow={selectedWorkflow}
          clientConfig={clientConfig || { id: client.id }}
          open={isEditPanelOpen}
          onClose={closeEditPanel}
          onSuccess={onEditSuccess}
          xtrfServices={xtrfServiceOptions}
        />
      )}

      {selectedWorkflow && isDeleteDialogOpen && (
        <DeleteWorkflowDialog
          workflow={selectedWorkflow}
          clientConfig={clientConfig || { id: client.id }}
          onClose={closeDeleteDialog}
          onSuccess={onDeleteSuccess}
        />
      )}
    </>
  );
};

export { WorkflowSettingsTab };
