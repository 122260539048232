import * as React from "react";
import { useDialogState } from "hooks/useDialogState";
import { useMutation, useQuery } from "react-query";
import { ApiError, SystemSettings, SystemSettingsService, UtilsService } from "../../../../gen/clients";
import { SelectOption } from "@mui/base";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LabelValue } from "components/LabelValue/LabelValue";
import { Field, Form, Formik } from "formik";
import Grid from "@mui/material/Grid";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import Box from "@mui/material/Box";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { FormControlLabel } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Switch } from "formik-mui";

enum FieldName {
  xtrfServiceId = "xtrfServiceId",
  notAllowedEmailDomains = "notAllowedEmailDomains",
  emailFrom = "emailFrom",
  quoteRequestNotificationEmails = "quoteRequestNotificationEmails",
  translationRequestNotificationEmails = "translationRequestNotificationEmails",
  redirectAllNotificationsEnabled = "redirectAllNotificationsEnabled",
  redirectAllNotificationsTo = "redirectAllNotificationsTo"
}

interface Values {
  [FieldName.xtrfServiceId]: string | number;
  [FieldName.notAllowedEmailDomains]: string;
  [FieldName.emailFrom]: string;
  [FieldName.quoteRequestNotificationEmails]: string;
  [FieldName.translationRequestNotificationEmails]: string;
  [FieldName.redirectAllNotificationsEnabled]: boolean;
  [FieldName.redirectAllNotificationsTo]: string;
}

const ConfigurationTab: React.FC = () => {
  const [successToastVisible, showSuccessToast, closeSuccessToast] = useDialogState();
  const {
    data: systemSettings,
    isFetching,
    error
  } = useQuery<SystemSettings, ApiError>(["getSystemSettings"], {
    queryFn: SystemSettingsService.getSystemSettings,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  const { data: xtrfServices } = useQuery("listXtrfServices", {
    queryFn: UtilsService.listXtrfServices
  });
  const xtrfServiceOptions: SelectOption<number>[] = React.useMemo(
    () =>
      xtrfServices
        ?.filter(s => s.active)
        .sort((s1, s2) => s1.name.localeCompare(s2.name))
        .map(s => ({ label: s.name, value: s.id, disabled: !s.active })) || [],
    [xtrfServices]
  );

  const { mutate, isLoading: isMutating, error: mutateError } = useMutation(SystemSettingsService.updateSystemSettings);

  const onSubmit = React.useCallback(
    async (values: Values) => {
      if (!values[FieldName.xtrfServiceId]) {
        return;
      }
      const notAllowedEmailDomains = values[FieldName.notAllowedEmailDomains]
        .split(/\s*,\s*/)
        .filter(d => d.length > 0);
      const quoteRequestNotificationEmails = values[FieldName.quoteRequestNotificationEmails]
        .split(/\s*,\s*/)
        .filter(d => d.length > 0);
      const translationRequestNotificationEmails = values[FieldName.translationRequestNotificationEmails]
        .split(/\s*,\s*/)
        .filter(d => d.length > 0);
      await mutate(
        {
          requestBody: {
            ...systemSettings,
            defaultXtrfServiceId: +values[FieldName.xtrfServiceId],
            notAllowedEmailDomains: notAllowedEmailDomains.length > 0 ? notAllowedEmailDomains : undefined,
            emailFrom: values[FieldName.emailFrom],
            quoteRequestNotificationEmails:
              quoteRequestNotificationEmails.length > 0 ? quoteRequestNotificationEmails : undefined,
            translationRequestNotificationEmails:
              translationRequestNotificationEmails.length > 0 ? translationRequestNotificationEmails : undefined,
            redirectAllNotificationsTo: values[FieldName.redirectAllNotificationsEnabled]
              ? values[FieldName.redirectAllNotificationsTo]
              : undefined
          }
        },
        { onSuccess: showSuccessToast }
      );
    },
    [mutate, showSuccessToast, systemSettings]
  );

  const validate = React.useCallback((values: Values) => {
    const errors: Record<string, string> = {};
    if (!values[FieldName.xtrfServiceId]) {
      errors[FieldName.xtrfServiceId] = "Required";
    }
    if (values[FieldName.notAllowedEmailDomains]) {
      const domains = (values[FieldName.notAllowedEmailDomains] as string).split(/\s*,\s*/);
      domains.forEach(domain => {
        if (!domain.includes(".")) {
          errors[FieldName.notAllowedEmailDomains] = `Invalid domain name "${domain}"`;
        }
      });
    }
    return errors;
  }, []);

  if (isFetching) {
    return <PageLoading />;
  }

  if (error) {
    return <ApiErrorMessage apiError={error} />;
  }

  return (
    <>
      {systemSettings && (
        <>
          <Snackbar open={successToastVisible} autoHideDuration={10000} onClose={closeSuccessToast}>
            <Alert onClose={closeSuccessToast} severity="success" sx={{ width: "100%" }}>
              Settings saved successfully
            </Alert>
          </Snackbar>

          <Box sx={{ mt: 4 }}>
            <LabelValue
              label="XTRF Home API Access Token"
              value={<span>{systemSettings.xtrfHomeApiAccessToken || "Not set"}</span>}
            />
          </Box>

          <Formik
            initialValues={{
              [FieldName.xtrfServiceId]: systemSettings?.defaultXtrfServiceId || "",
              [FieldName.notAllowedEmailDomains]: systemSettings?.notAllowedEmailDomains?.join(", ") || "",
              [FieldName.emailFrom]: systemSettings?.emailFrom || "",
              [FieldName.redirectAllNotificationsEnabled]: !!systemSettings?.redirectAllNotificationsTo,
              [FieldName.redirectAllNotificationsTo]: systemSettings?.redirectAllNotificationsTo || "",
              [FieldName.quoteRequestNotificationEmails]:
                systemSettings?.quoteRequestNotificationEmails?.join(", ") || "",
              [FieldName.translationRequestNotificationEmails]:
                systemSettings?.translationRequestNotificationEmails?.join(", ") || ""
            }}
            onSubmit={onSubmit}
            validate={validate}
            validateOnChange={false}
            validateOnBlur={true}
          >
            {({ values }) => (
              <Form>
                <Grid container={true} spacing={1} sx={{ mt: 1 }}>
                  <Grid xs={12} sm={6} item={true}>
                    <SelectOneField
                      name={FieldName.xtrfServiceId}
                      options={xtrfServiceOptions}
                      label="Default XTRF Service"
                      required={true}
                    />
                  </Grid>
                </Grid>
                <Grid container={true} spacing={1} sx={{ mt: 1 }}>
                  <Grid xs={12} sm={6} item={true}>
                    <TextInputField
                      name={FieldName.notAllowedEmailDomains}
                      label="Not Allowed Email Domains"
                      helperText="Comma-separated list of domains. Example: gmail.com, hotmail.com, yahoo.com"
                    />
                  </Grid>
                </Grid>
                <Grid container={true} spacing={1} sx={{ mt: 1 }}>
                  <Grid xs={12} sm={6} item={true}>
                    <TextInputField
                      name={FieldName.emailFrom}
                      label="Email Notifications 'From' Value"
                      required={true}
                      helperText="Name and Email address that will be used in the From field of email notifications. Example: LLTS Portal <donotreply@llts.com>"
                    />
                  </Grid>
                </Grid>
                {!values[FieldName.redirectAllNotificationsEnabled] && (
                  <>
                    <Grid container={true} spacing={1} sx={{ mt: 1 }}>
                      <Grid xs={12} sm={6} item={true}>
                        <TextInputField
                          name={FieldName.quoteRequestNotificationEmails}
                          label="Send Quote Request notifications to"
                          helperText="Comma-separated list of emails"
                        />
                      </Grid>
                    </Grid>
                    <Grid container={true} spacing={1} sx={{ mt: 1 }}>
                      <Grid xs={12} sm={6} item={true}>
                        <TextInputField
                          name={FieldName.translationRequestNotificationEmails}
                          label="Send Translation Request notifications to"
                          helperText="Comma-separated list of emails"
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container={true} spacing={1} sx={{ mt: 1 }}>
                  <Grid xs={12} sm={6} item={true}>
                    <FormControlLabel
                      control={
                        <Field name={FieldName.redirectAllNotificationsEnabled} component={Switch} type="checkbox" />
                      }
                      label={
                        <Stack direction="row" alignContent="center" alignItems="center" spacing={2} width={500}>
                          <Box whiteSpace="nowrap">Redirect all notifications</Box>
                          {values[FieldName.redirectAllNotificationsEnabled] && (
                            <TextInputField
                              name={FieldName.redirectAllNotificationsTo}
                              required={true}
                              placeholder="Enter email address"
                              helperText="Email address where all notifications will be redirected to"
                            />
                          )}
                        </Stack>
                      }
                      labelPlacement="end"
                    />
                  </Grid>
                </Grid>
                <Box sx={{ mt: 4 }}>
                  {mutateError && <ApiErrorMessage apiError={mutateError as ApiError} />}
                  <LoadingButton isLoading={isMutating}>Save</LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export { ConfigurationTab };
