/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CustomFieldDefinition = {
    name: string;
    category: CustomFieldDefinition.category;
    type: CustomFieldDefinition.type;
    required?: boolean;
    defaultValue?: string;
    options?: Array<{
        label: string;
        value: string;
    }>;
    mapping: string;
    externalClientId?: string;
    visible?: boolean;
    disabled?: boolean;
    validationRegex?: string;
    validationMessage?: string;
    description?: string;
}

export namespace CustomFieldDefinition {

    export enum category {
        PROJECT = 'PROJECT',
        USER = 'USER',
    }

    export enum type {
        INPUT_TEXT = 'INPUT_TEXT',
        LISTONLY = 'LISTONLY',
        LIST = 'LIST',
        SELECT_MANY_LIST = 'SELECT_MANY_LIST',
        DATE = 'DATE',
        TEXT_AREA = 'TEXT_AREA',
    }


}
