import * as React from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Language, LanguagesService, LltsLanguage, MtLanguage } from "gen/clients";
import { Button, Drawer, FormControl, Stack } from "@mui/material";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import { createSelectOptionWithLanguage, getLanguageOptions } from "./languageUtils";
import { SelectOption } from "@mui/base";
import { SwitchField } from "components/formikFields/SwitchField/SwitchField";

interface Props {
  language: LltsLanguage;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  xtrfLanguages: Language[];
  intentoLanguages: MtLanguage[];
  smartMateLanguages: MtLanguage[];
  azureLanguages: MtLanguage[];
}

enum FieldName {
  id = "id",
  name = "name",
  iso = "iso",
  regionCode = "regionCode",
  xtrfId = "xtrfId",
  intentoId = "intentoId",
  smartMateId = "smartMateId",
  azureId = "azureId",
  isEnabled = "isEnabled"
}

interface Values {
  [FieldName.id]: string;
  [FieldName.name]: string;
  [FieldName.iso]: string;
  [FieldName.regionCode]: string | undefined;
  [FieldName.xtrfId]: SelectOption<string> | undefined;
  [FieldName.intentoId]: SelectOption<string> | undefined;
  [FieldName.smartMateId]: SelectOption<string> | undefined;
  [FieldName.azureId]: SelectOption<string> | undefined;
  [FieldName.isEnabled]: boolean | undefined;
}

const EditLanguagePanel: React.FC<Props> = ({
  language,
  open,
  onClose,
  onSuccess,
  xtrfLanguages,
  intentoLanguages,
  smartMateLanguages,
  azureLanguages
}) => {
  const { mutate, error } = useMutation("updateLltsLanguage", {
    onSuccess,
    mutationFn: LanguagesService.updateLltsLanguage
  });

  // Validation schema for Formik
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    iso: Yup.string()
      .required("Required")
      .matches(/^[A-Za-z]+$/, {
        message: "Language ISO code should contain only letters."
      })
  });

  // Submit handler for Formik
  const onSubmit = React.useCallback(
    (formValues: Values) => {
      const xtrfIdValue = formValues[FieldName.xtrfId]?.value;
      mutate({
        languageId: language.id,
        requestBody: {
          id: language.id,
          name: formValues[FieldName.name],
          iso: formValues[FieldName.iso],
          regionCode: formValues[FieldName.regionCode],
          xtrfId: xtrfIdValue ? +xtrfIdValue : undefined,
          intentoId: formValues[FieldName.intentoId]?.value,
          smartMateId: formValues[FieldName.smartMateId]?.value,
          azureId: formValues[FieldName.azureId]?.value,
          isDisabled: !formValues[FieldName.isEnabled]
        }
      });
    },
    [mutate, language]
  );

  const xtrfLanguageOptions = React.useMemo(() => getLanguageOptions(xtrfLanguages), [xtrfLanguages]);

  const intentoLanguageOptions = React.useMemo(() => getLanguageOptions(intentoLanguages), [intentoLanguages]);

  const smartMateLanguageOptions = React.useMemo(() => getLanguageOptions(smartMateLanguages), [smartMateLanguages]);

  const azureLanguageOptions = React.useMemo(() => getLanguageOptions(azureLanguages), [azureLanguages]);

  const initialXtrfIdSelectOption = React.useMemo(() => {
    const selectedLanguage = xtrfLanguages.find(l => l.id === language.xtrfId);
    if (selectedLanguage) {
      return createSelectOptionWithLanguage(selectedLanguage);
    }
    return undefined;
  }, [xtrfLanguages, language.xtrfId]);

  const initialIntentoIdSelectOption = React.useMemo(() => {
    const selectedLanguage = intentoLanguages.find(l => l.code === language.intentoId);
    if (selectedLanguage) {
      return createSelectOptionWithLanguage(selectedLanguage);
    }
    return undefined;
  }, [intentoLanguages, language.intentoId]);

  const initialSmartMateIdSelectOption = React.useMemo(() => {
    const selectedLanguage = smartMateLanguages.find(l => l.code === language.smartMateId);
    if (selectedLanguage) {
      return createSelectOptionWithLanguage(selectedLanguage);
    }
    return undefined;
  }, [smartMateLanguages, language.smartMateId]);

  const initialAzureIdSelectOption = React.useMemo(() => {
    const selectedLanguage = azureLanguages.find(l => l.code === language.azureId);
    if (selectedLanguage) {
      return createSelectOptionWithLanguage(selectedLanguage);
    }
    return undefined;
  }, [azureLanguages, language.azureId]);

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader title="Update Language" onClose={onClose} />
      <DrawerBody>
        <Formik
          initialValues={{
            id: language.id,
            name: language.name,
            iso: language.iso,
            regionCode: language.regionCode,
            xtrfId: initialXtrfIdSelectOption,
            intentoId: initialIntentoIdSelectOption,
            smartMateId: initialSmartMateIdSelectOption,
            azureId: initialAzureIdSelectOption,
            isEnabled: !language.isDisabled
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values }) => (
            <Form noValidate={true}>
              <Stack spacing={3}>
                <TextInputField name="id" label="Language ID" disabled={true} />
                <TextInputField name="name" label="Language Name" required={true} />
                <Stack direction="row" spacing={1}>
                  <TextInputField name="iso" label="ISO Code" required={true} />
                  <TextInputField name="regionCode" label="Region Code" />
                </Stack>
                <SelectOneAutocompleteField name="xtrfId" label="XTRF" options={xtrfLanguageOptions} />
                <SelectOneAutocompleteField name="intentoId" label="Intento" options={intentoLanguageOptions} />
                <SelectOneAutocompleteField name="azureId" label="Azure" options={azureLanguageOptions} />
                <SelectOneAutocompleteField name="smartMateId" label="SmartMate" options={smartMateLanguageOptions} />
                <FormControl>
                  <SwitchField
                    name={FieldName.isEnabled}
                    label={values[FieldName.isEnabled] ? "Enabled" : "Disabled"}
                    helperText="Disabled languages are not displayed to clients"
                  />
                </FormControl>
              </Stack>
              <Stack spacing={1} direction="row" marginTop={4}>
                {!!error && <ApiErrorMessage apiError={error} />}
                <LoadingButton isLoading={isSubmitting}>Apply</LoadingButton>
                <Button color="secondary" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { EditLanguagePanel };
