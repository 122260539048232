import * as React from "react";
import { Formik, Form } from "formik";
import { LabelValue } from "./LabelValue";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, DialogActions, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { TextInputField } from "../../../../../components/formikFields/TextInputField/TextInputField";

interface Props {
  onClose: () => void;
  onAdd: (labelValue: LabelValue) => void;
  useLabelAsValue?: boolean;
  valueSeparator?: string;
}

const AddOptionDialog: React.FC<Props> = ({ onAdd, onClose, valueSeparator, useLabelAsValue = false }) => {
  const onSubmit = React.useCallback(
    values => {
      if (useLabelAsValue) {
        onAdd({ label: values.label, value: values.label });
      } else {
        onAdd({ label: values.label, value: values.value });
      }
    },
    [onAdd, useLabelAsValue]
  );

  const validate = React.useCallback(
    values => {
      const errors: Record<string, string> = {};
      if (valueSeparator) {
        if (useLabelAsValue && (values.label as string).includes(valueSeparator)) {
          errors.label = "Label must not contain pipe (|) symbol";
        } else if ((values.value as string).includes(valueSeparator)) {
          errors.value = "Value must not contain pipe (|) symbol";
        }
      }
      return errors;
    },
    [valueSeparator, useLabelAsValue]
  );

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Add Option</DialogTitle>
      <Formik
        initialValues={{
          label: "",
          value: ""
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ values }) => (
          <Form noValidate={true}>
            <DialogContent>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <TextInputField name="label" label="Label" required={true} />
                </Grid>
                <Grid item={true} xs={12}>
                  {!useLabelAsValue && (
                    <TextInputField name="value" label="Value" required={true} disabled={useLabelAsValue} />
                  )}
                  {useLabelAsValue && (
                    <TextField label="Value" value={values.label} disabled={true} variant="standard" fullWidth={true} />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit" variant="contained">
                Add
              </Button>
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export { AddOptionDialog };
