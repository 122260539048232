import * as React from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { NavMenuOption, PageLayout } from "../../components/PageLayout/PageLayout";
import { ClientSummaryCollection, ClientsService, ApiError, ClientSummary } from "../../gen/clients";
import { ApiErrorMessage } from "../../components/ApiErrorMessage/ApiErrorMessage";
import { DataTable } from "../../components/DataTable/DataTable";
import SearchIcon from "@mui/icons-material/Search";
import { CssBaseline, IconButton, InputBase, Paper, TablePagination } from "@mui/material";
import { Form, Formik } from "formik";
import { PagePath } from "../../types/PagePath";
import Link from "@mui/material/Link";

const ClientsPage: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [companyNameSearch, setCompanyNameSearch] = React.useState("");

  const { data, isLoading, isFetching, error, refetch } = useQuery<ClientSummaryCollection, ApiError>(
    ["listClients", page, rowsPerPage],
    {
      queryFn: () =>
        ClientsService.listClients({
          page: page + 1,
          limit: rowsPerPage,
          companyName: companyNameSearch || undefined
        }),
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }
  );

  const onRowClick = React.useCallback(
    (client: ClientSummary) => {
      history.push(`${PagePath.clients}/${client.id}`);
    },
    [history]
  );

  const clientIdCell = React.useCallback(
    (client: ClientSummary) => (
      <Link onClick={e => e.preventDefault()} href={`/clients/${client.id}`}>
        {client.id}
      </Link>
    ),
    []
  );

  const clientNameCell = React.useCallback((client: ClientSummary) => client.companyName, []);

  const rowKey = React.useCallback((client: ClientSummary) => client.id, []);

  const onPageChange = React.useCallback((event, selectedPage: number) => {
    setPage(selectedPage);
  }, []);

  const onRowsPerPageChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  }, []);

  return (
    <PageLayout selectedNavMenu={NavMenuOption.CLIENTS} title="Clients">
      <CssBaseline />
      <Formik initialValues={{}} onSubmit={() => refetch()}>
        <Form>
          <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, my: 2 }}>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by client name or ID"
              onChange={event => setCompanyNameSearch(event.currentTarget.value)}
            />
            <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </Form>
      </Formik>
      <DataTable
        columns={[
          {
            id: "clientId",
            title: "XTRF Client ID",
            cell: clientIdCell
          },
          {
            id: "clientName",
            title: "Name",
            cell: clientNameCell
          }
        ]}
        data={data?.items || []}
        isLoading={isLoading || isFetching}
        error={error && <ApiErrorMessage apiError={error} />}
        rowKey={rowKey}
        onRowClick={onRowClick}
      />

      {data?.totalResults !== undefined && data?.totalResults > 0 && (
        <TablePagination
          component="div"
          count={data.totalResults}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </PageLayout>
  );
};

export { ClientsPage };
