import * as React from "react";
import { JobRequest } from "gen/clients";
import { LabelValue } from "components/LabelValue/LabelValue";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

interface Props {
  jobRequest: JobRequest;
}

const ContactDetailsSection: React.FC<Props> = ({ jobRequest }) => {
  const { contactAddress } = jobRequest;
  if (!contactAddress) {
    return <span />;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h5" mt={2}>
        Contact Details
      </Typography>
      {jobRequest.companyName && <LabelValue label="Company" value={jobRequest.companyName} />}
      <Stack direction="row" spacing={5}>
        {jobRequest.requestorFirstName && <LabelValue label="First Name" value={jobRequest.requestorFirstName} />}
        {jobRequest.requestorLastName && <LabelValue label="Last Name" value={jobRequest.requestorLastName} />}
      </Stack>
      {contactAddress?.email && <LabelValue label="Email" value={contactAddress.email} />}
      {contactAddress?.phoneNumber && <LabelValue label="Phone Number" value={contactAddress.phoneNumber} />}
      {contactAddress?.address1 && <LabelValue label="Address 1" value={contactAddress.address1} />}
      {contactAddress?.address2 && <LabelValue label="Address 2" value={contactAddress.address2} />}
      {contactAddress?.city && <LabelValue label="City" value={contactAddress.city} />}
      {contactAddress?.zipCode && <LabelValue label="Zip / Postal Code" value={contactAddress.zipCode} />}
      {contactAddress?.province && <LabelValue label="State" value={contactAddress.province.name} />}
      {contactAddress?.country && <LabelValue label="Country" value={contactAddress.country.name} />}
    </Stack>
  );
};

export { ContactDetailsSection };
