import * as React from "react";
import { ClientConfig, ClientDataType, ClientsService } from "../../../../../gen/clients";
import { LoadingButton } from "../../../../../components/LoadingButton/LoadingButton";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { SnackbarApiError } from "../../../../../components/SnackbarApiError/SnackbarApiError";

interface Props {
  clientConfig: ClientConfig;
  dataType: ClientDataType;
  onSuccess: () => void;
  onClose: () => void;
}

const UploadImageDialog: React.FC<Props> = ({ clientConfig, dataType, onSuccess, onClose }) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [uploadError, setUploadError] = React.useState<string>();
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onSaveClick = React.useCallback(async () => {
    setLoading(true);
    try {
      const file = (fileInputRef.current?.files || [])[0];
      if (!file) {
        return;
      }
      // Fetch signed upload URL
      const uploadUrlResponse = await ClientsService.getClientContentUploadUrl({
        id: clientConfig.id,
        dataType,
        fileName: file.name,
        mimeType: file.type
      });
      // Upload the file to S3 bucket
      await fetch(uploadUrlResponse.uploadUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type
        }
      });
      // Update client config
      const clientConfigUpdate = { ...clientConfig };
      if (dataType === ClientDataType.LOGO) {
        clientConfigUpdate.logoKey = uploadUrlResponse.key;
      }
      if (dataType === ClientDataType.BANNER) {
        clientConfigUpdate.bannerKey = uploadUrlResponse.key;
      }
      await ClientsService.updateClientConfig({
        id: clientConfig.id,
        requestBody: clientConfigUpdate
      });
      setLoading(false);
      onSuccess();
    } catch (e) {
      setUploadError("Oops... Something went wrong.  Please try again.");
      window.console.log("Error during file upload attempt", e);
      setLoading(false);
    }
  }, [setLoading, clientConfig, dataType, fileInputRef, onSuccess]);

  const onDialogClose = React.useCallback(
    (event, reason: string) => {
      if (reason !== "backdropClick") {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Dialog open={true} onClose={onDialogClose}>
      <DialogTitle>Upload Image</DialogTitle>
      <DialogContent>
        <DialogContentText>Select file to upload:</DialogContentText>
        <input type="file" ref={fileInputRef} />
        {uploadError && <SnackbarApiError error={uploadError} />}
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onSaveClick} isLoading={isLoading}>
          Save
        </LoadingButton>
        <Button color="secondary" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { UploadImageDialog };
