import { FormControlLabel, Switch } from "@mui/material";
import { CognitoUser, UserAccountsService } from "gen/clients";
import * as React from "react";
import { useMutation } from "react-query";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { useDialogState } from "hooks/useDialogState";
import { AlertDialog } from "components/AlertDialog/AlertDialog";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  cognitoUser: CognitoUser;
  onSuccess: () => void;
}

const MfaEnabledSwitch: React.FC<Props> = ({ cognitoUser, onSuccess }) => {
  const [isEnableMfaAlertDialogOpen, openEnableMfaAlertDialog, closeEnableMfaAlertDialog] = useDialogState();
  const [isDisableMfaAlertDialogOpen, openDisableMfaAlertDialog, closeDisableMfaAlertDialog] = useDialogState();
  const {
    mutate,
    error: mutateError,
    isLoading: isMutateLoading
  } = useMutation({
    mutationFn: UserAccountsService.disableCognitoUserMfa,
    onSuccess: () => {
      closeDisableMfaAlertDialog();
      onSuccess();
    }
  });

  const onClick = React.useCallback(
    event => {
      const isEnablingMfa = event.target.checked;
      if (isEnablingMfa) {
        openEnableMfaAlertDialog();
      } else {
        openDisableMfaAlertDialog();
      }
    },
    [openEnableMfaAlertDialog, openDisableMfaAlertDialog]
  );

  return (
    <>
      {mutateError && <SnackbarApiError error={mutateError} />}
      <FormControlLabel
        control={
          <Switch
            checked={cognitoUser?.isMfaEnabled || false}
            onChange={onClick}
            disabled={isMutateLoading || !cognitoUser}
            size="small"
          />
        }
        label={cognitoUser?.isMfaEnabled ? "Yes" : "No"}
        labelPlacement="end"
        sx={{ ml: 1 }}
      />
      {isEnableMfaAlertDialogOpen && (
        <AlertDialog onClose={closeEnableMfaAlertDialog} title="Enable MFA">
          <div>
            MFA cannot be enabled for client contacts from the Admin Console. Users must enable MFA for themselves using
            the user account settings in the portal.
          </div>
        </AlertDialog>
      )}
      {isDisableMfaAlertDialogOpen && (
        <ConfirmationDialog
          title="Disable MFA"
          content={<div>Are you sure you want to disable MFA for this user?</div>}
          onClose={closeDisableMfaAlertDialog}
          onConfirm={() => {
            mutate({ cognitoUsername: cognitoUser.username });
          }}
          isLoading={isMutateLoading}
          error={!!mutateError && <ApiErrorMessage apiError={mutateError} />}
        />
      )}
    </>
  );
};

export { MfaEnabledSwitch };
