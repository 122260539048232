import * as React from "react";
import { JobRequest } from "gen/clients";
import { Alert, Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { LabelValue } from "components/LabelValue/LabelValue";
import { formatPrice } from "utils/numberUtils";
import { formatDate } from "utils/dateUtils";
import { DataTable } from "components/DataTable/DataTable";
import { AutoQuoteStatus } from "./AutoQuoteStatus";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

interface Props {
  jobRequest: JobRequest;
}

const AutoQuoteTab: React.FC<Props> = ({ jobRequest }) => {
  const { autoQuote } = jobRequest;

  const pageCountByFileName = autoQuote?.pageCountByFileName;
  const pageCountByFileNameArray = React.useMemo(() => {
    if (!pageCountByFileName) {
      return [];
    }
    return Object.keys(pageCountByFileName).map(fileName => ({
      fileName,
      pageCount: pageCountByFileName[fileName]
    }));
  }, [pageCountByFileName]);

  if (!autoQuote) {
    return (
      <Box p={3}>
        <Alert severity="info">No auto-quote available for this job request. Please check later.</Alert>
      </Box>
    );
  }

  return (
    <Stack spacing={1} p={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5">Auto-Quote Summary</Typography>
        <Button
          variant="outlined"
          href={`${process.env.REACT_APP_PUBLIC_PORTAL_URL}/quotes/${jobRequest.id}`}
          target="_blank"
        >
          View Quote
        </Button>
      </Stack>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} sm={6}>
          <Stack spacing={1}>
            {autoQuote.acceptanceStatus && (
              <LabelValue label="Auto-Quote Status" value={<AutoQuoteStatus status={autoQuote.acceptanceStatus} />} />
            )}
            {autoQuote.rejectionReason && <LabelValue label="Rejection Reason" value={autoQuote.rejectionReason} />}
            {autoQuote.totalPages && (jobRequest.targetLanguages?.length || 0) > 0 && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <LabelValue
                label="Total Source Pages"
                value={jobRequest.targetLanguages ? autoQuote.totalPages / jobRequest.targetLanguages.length : ""}
              />
            )}
            {(autoQuote.totalPages || autoQuote.totalPages === 0) && (
              <LabelValue label="Total Target Pages" value={autoQuote.totalPages} />
            )}
            {autoQuote.pricePerPage && (
              <LabelValue label="Price per Page" value={formatPrice(autoQuote.pricePerPage)} />
            )}
            {(autoQuote.price || autoQuote.price === 0) && (
              <LabelValue label="Total Cost" value={formatPrice(autoQuote.price)} />
            )}
          </Stack>
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <Stack spacing={1}>
            {autoQuote.creationDate && <LabelValue label="Generated" value={formatDate(autoQuote.creationDate)} />}
            {autoQuote.acceptanceDeadline && !autoQuote.acceptanceStatus && (
              <LabelValue label="Quote Expires" value={formatDate(autoQuote.acceptanceDeadline)} />
            )}
            {autoQuote.acceptanceDate && (
              <LabelValue label="Client Responded" value={formatDate(autoQuote.acceptanceDate)} />
            )}
          </Stack>
        </Grid>
      </Grid>

      {autoQuote.pageCountByFileName && (
        <Stack pt={2} spacing={1}>
          <Typography variant="h5">Source File Page Counts</Typography>
          <DataTable
            columns={[
              {
                id: "fileName",
                title: "File Name",
                cell: row => row.fileName
              },
              {
                id: "pageCount",
                title: "Page Count",
                cell: row => row.pageCount
              }
            ]}
            data={pageCountByFileNameArray}
            rowKey={row => row.fileName}
          />
        </Stack>
      )}
    </Stack>
  );
};

export { AutoQuoteTab };
