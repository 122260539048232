import * as React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { ClientMtUsageReportItem, ClientMtUsageReport, CharactersChargedByMtService } from "gen/clients";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { Stack, TablePagination, Tooltip, Typography } from "@mui/material";
import { describeMtBillingType } from "pages/SystemSettingsPage/tabs/ApplcationsPage/utils/MtUtils";

interface Props {
  data?: ClientMtUsageReport;
  isLoading: boolean;
  error?: unknown;
}

const MtClientUsageReportTable: React.FC<Props> = ({ data, isLoading, error }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [page, setPage] = React.useState(0);
  const pageData: ClientMtUsageReportItem[] = React.useMemo(
    () => data?.items.slice(page * rowsPerPage, (page + 1) * rowsPerPage) || [],
    [data, page, rowsPerPage]
  );

  const charactersChargedCell = React.useCallback((row: ClientMtUsageReportItem) => {
    const { charactersChargedByMtService } = row;
    const tooltipItems: string[] = charactersChargedByMtService
      ? Object.keys(charactersChargedByMtService).map(
          serviceId =>
            `${serviceId}: ${charactersChargedByMtService[serviceId as keyof CharactersChargedByMtService] || 0}`
        )
      : [];
    return (
      <Tooltip title={tooltipItems.join(", ")}>
        <Typography
          variant="body2"
          component="span"
          sx={{
            textDecoration: "underline",
            textDecorationStyle: "dotted", // Apply dotted style
            textDecorationColor: "currentcolor", // Use the current text color
            textDecorationThickness: "2px", // Set the thickness of the underline
            textUnderlineOffset: "3px" // Adjust the offset of the underline
          }}
        >
          {(charactersChargedByMtService?.AZURE || 0) + (charactersChargedByMtService?.INTENTO || 0)}
        </Typography>
      </Tooltip>
    );
  }, []);

  return (
    <Stack>
      <DataTable
        isLoading={isLoading}
        error={!!error && <ApiErrorMessage apiError={error} />}
        columns={[
          {
            id: "clientName",
            title: "Client Name",
            cell: (row: ClientMtUsageReportItem) => row.clientName
          },
          {
            id: "clientId",
            title: "Client ID",
            cell: (row: ClientMtUsageReportItem) => row.clientId
          },
          {
            id: "clientContacts",
            title: "User Count",
            cell: (row: ClientMtUsageReportItem) => row.usernames.length
          },
          {
            id: "billingType",
            title: "Billing Type",
            cell: (row: ClientMtUsageReportItem) => describeMtBillingType(row.billingType)
          },
          {
            id: "maxCharactersPerRequest",
            title: "Max Characters Per Request",
            cell: (row: ClientMtUsageReportItem) => row.maxCharactersPerRequest
          },
          {
            id: "maxRequestsPerUserPerDay",
            title: "Max Requests Per User Per Day",
            cell: (row: ClientMtUsageReportItem) => row.maxRequestsPerUserPerDay
          },
          {
            id: "requestCount",
            title: "Translation Request Count",
            cell: (row: ClientMtUsageReportItem) => row.requestCount
          },
          {
            id: "charactersCharged",
            title: "Characters Charged",
            cell: charactersChargedCell
          }
        ]}
        data={pageData}
        rowKey={(row: ClientMtUsageReportItem) => row.clientId}
      />
      <TablePagination
        count={data?.items.length || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={e => setRowsPerPage(+e.target.value)}
        component="div"
      />
    </Stack>
  );
};

export { MtClientUsageReportTable };
