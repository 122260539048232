import * as React from "react";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import Drawer from "@mui/material/Drawer";
import { useMutation, useQuery } from "react-query";
import { AiProcessingService, ApiError, OpenAIRequest, SystemSettings, SystemSettingsService } from "gen/clients";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { DEFAULT_TEST_INPUT } from "./defaultTestInput";

interface Props {
  onClose: () => void;
}

enum FieldNames {
  input = "input"
}

interface FormValues {
  [FieldNames.input]: string;
}

const TestInstructionsPanel: React.FC<Props> = ({ onClose }) => {
  const {
    data: systemSettings,
    isLoading,
    error
  } = useQuery<SystemSettings, ApiError>(["getSystemSettings"], {
    queryFn: SystemSettingsService.getSystemSettings
  });

  const {
    data: aiOutput,
    mutateAsync: processByOpenAI,
    isLoading: isProcessing
  } = useMutation({
    mutationFn: AiProcessingService.processTextByOpenAi
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await processByOpenAI({
        requestBody: {
          type: OpenAIRequest.type.REJECTION_REASONS,
          text: values[FieldNames.input],
          instructions: systemSettings?.autoQuoteSettings.rejectedReasonsAnalyticsAIPrompt || ""
        }
      });
    },
    [processByOpenAI, systemSettings?.autoQuoteSettings.rejectedReasonsAnalyticsAIPrompt]
  );

  if (isLoading || error) {
    return (
      <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: "50vw" } }}>
        <DrawerHeader title="Test Auto-Quote Rejection Reasons Analytics Instructions" onClose={onClose} />
        <DrawerBody>
          {isLoading && <PageLoading />}
          {error && <ApiErrorMessage apiError={error} />}
        </DrawerBody>
      </Drawer>
    );
  }

  return (
    <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: "50vw" } }}>
      <DrawerHeader title="Test Auto-Quote Rejection Reasons Analytics Instructions" onClose={onClose} />
      <DrawerBody>
        <Formik
          initialValues={{
            [FieldNames.input]: DEFAULT_TEST_INPUT
          }}
          validateOnMount={false}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          <Form noValidate={true}>
            <Stack spacing={3} mt={3}>
              <TextInputField
                name={FieldNames.input}
                label="Test input"
                helperText="Enter list of auto-quote rejection reasons"
                variant="outlined"
                multiline={true}
                rows={10}
              />
              <TextField
                label="AI output"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                multiline={true}
                disabled
                rows={10}
                value={aiOutput?.output}
              />
            </Stack>
            <Stack direction="row" spacing={1} mt={3}>
              <LoadingButton isLoading={isProcessing}>Test</LoadingButton>
              <Button color="secondary" onClick={onClose} disabled={isProcessing} sx={{ ml: 1 }}>
                Close
              </Button>
            </Stack>
          </Form>
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { TestInstructionsPanel };
