import * as React from "react";
import { useQuery } from "react-query";
import { CharactersChargedByMtService, MetricResult, MetricsService } from "gen/clients";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { DataTable } from "components/DataTable/DataTable";
import { Tooltip, Typography } from "@mui/material";

interface Props {
  clientId: string;
  durationMinutes: number;
}

const ClientContactMtRequestsTable: React.FC<Props> = ({ clientId, durationMinutes }) => {
  const { data, isLoading, error } = useQuery(["listMtRequestCounts", clientId, durationMinutes], {
    queryFn: () =>
      MetricsService.getRequestCounts({
        clientId,
        durationMinutes
      })
  });

  const stats = React.useMemo(
    () => data?.results?.sort((r1, r2) => r2.values[0].reqCount - r1.values[0].reqCount) || [],
    [data?.results]
  );

  const charactersChargedCell = React.useCallback((m: MetricResult) => {
    const { charactersCharged } = m.values[0];
    const tooltipItems: string[] = Object.keys(charactersCharged).map(
      serviceId => `${serviceId}: ${charactersCharged[serviceId as keyof CharactersChargedByMtService] || 0}`
    );

    return (
      <Tooltip title={tooltipItems.join(", ")}>
        <Typography
          variant="body2"
          component="span"
          sx={{
            textDecoration: "underline",
            textDecorationStyle: "dotted", // Apply dotted style
            textDecorationColor: "currentcolor", // Use the current text color
            textDecorationThickness: "2px", // Set the thickness of the underline
            textUnderlineOffset: "3px" // Adjust the offset of the underline
          }}
        >
          {(charactersCharged.AZURE || 0) + (charactersCharged.INTENTO || 0)}
        </Typography>
      </Tooltip>
    );
  }, []);

  if (isLoading) {
    return <PageLoading />;
  }
  if (error) {
    return <ApiErrorMessage apiError={error} />;
  }

  return (
    <DataTable
      columns={[
        {
          id: "username",
          title: "Username",
          cell: (m: MetricResult) => m.label
        },
        {
          id: "count",
          title: "Request Count",
          cell: (m: MetricResult) => m.values[0].reqCount
        },
        {
          id: "charactersCharged",
          title: "Characters Charged",
          cell: charactersChargedCell
        }
      ]}
      data={stats}
      rowKey={(m: MetricResult) => m.id}
    />
  );
};

export { ClientContactMtRequestsTable };
