/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { CustomFieldDefinition } from '../models/CustomFieldDefinition';
import type { Language } from '../models/Language';
import type { MtLanguage } from '../models/MtLanguage';
import type { MtServiceProvider } from '../models/MtServiceProvider';
import type { PresignedPost } from '../models/PresignedPost';
import type { Province } from '../models/Province';
import type { XtrfService } from '../models/XtrfService';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UtilsService {

    /**
     * Returns a list of all XTRF services (workflows)
     * @returns XtrfService successful operation
     * @throws ApiError
     */
    public static listXtrfServices(): CancelablePromise<Array<XtrfService>> {
        return __request({
            method: 'GET',
            path: `/utils/xtrfServices`,
        });
    }

    /**
     * Returns a list of XTRF view filters
     * @returns CustomFieldDefinition successful operation
     * @throws ApiError
     */
    public static listXtrfViewFilters({
        viewId,
    }: {
        /** xtrf view identifier **/
        viewId: number,
    }): CancelablePromise<Array<CustomFieldDefinition>> {
        return __request({
            method: 'GET',
            path: `/utils/xtrfViewFilters/${viewId}`,
        });
    }

    /**
     * Returns a list of all active languages
     * @returns Language successful operation
     * @throws ApiError
     */
    public static listLanguages(): CancelablePromise<Array<Language>> {
        return __request({
            method: 'GET',
            path: `/utils/languages`,
        });
    }

    /**
     * Returns a list of all active countries
     * @returns Country successful operation
     * @throws ApiError
     */
    public static listCountries(): CancelablePromise<Array<Country>> {
        return __request({
            method: 'GET',
            path: `/utils/countries`,
        });
    }

    /**
     * Returns a list of all active provinces
     * @returns Province successful operation
     * @throws ApiError
     */
    public static listProvinces(): CancelablePromise<Array<Province>> {
        return __request({
            method: 'GET',
            path: `/utils/provinces`,
        });
    }

    /**
     * Creates a presigned URL for uploading files to temp S3 bucket
     * @returns PresignedPost successful operation
     * @throws ApiError
     */
    public static createFileUploadPresignedPost(): CancelablePromise<PresignedPost> {
        return __request({
            method: 'POST',
            path: `/utils/tempFiles/actions/createPresignedPost`,
        });
    }

    /**
     * Returns a list of all languages available for machine translation provider
     * @returns MtLanguage successful operation
     * @throws ApiError
     */
    public static listMachineTranslationLanguages({
        provider,
    }: {
        provider: MtServiceProvider,
    }): CancelablePromise<Array<MtLanguage>> {
        return __request({
            method: 'GET',
            path: `/utils/mtLanguages`,
            query: {
                'provider': provider,
            },
        });
    }

}