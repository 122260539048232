import * as React from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { ClientsService, Client, ApiError, ClientConfig, CustomFieldDefinition } from "gen/clients";
import { ApiErrorMessage } from "../../../components/ApiErrorMessage/ApiErrorMessage";
import { useDialogState } from "../../../hooks/useDialogState";
import { GeneralInformationTab } from "./tabs/GeneralInformationTab/GeneralInformationTab";
import { CustomFieldsTab } from "./tabs/CustomFieldsTab/CustomFieldsTab";
import { PortalSettingsTab } from "./tabs/PortalSettingsTab/PortalSettingsTab";
import { Alert, Box, Paper, Snackbar, Stack, Tab, Tabs, Toolbar } from "@mui/material";
import { ApplicationsTab } from "./tabs/ApplicationsTab/ApplicationsTab";
import { PageLoading } from "../../../components/PageLoading/PageLoading";
import { ClientContactsTab } from "./tabs/ClientContactsTab/ClientContactsTab";
import { WorkflowSettingsTab } from "./tabs/WorkflowSettingsTab/WorkflowSettingsTab";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { LanguageGroupsTab } from "./tabs/LanguageGroupsTab/LanguageGroupsTab";

interface PathParams {
  clientId: string;
}

enum TabId {
  general = "general",
  portalSettings = "portalSettings",
  projectCustomFields = "projectCustomFields",
  userCustomFields = "userCustomFields",
  applications = "applications",
  clientContacts = "clientContacts",
  workflows = "workflows",
  languageGroups = "languageGroups"
}

const ClientDetailsPage: React.FC = () => {
  const { clientId } = useParams<PathParams>();
  const [successToastVisible, showSuccessToast, closeSuccessToast] = useDialogState();
  const [syncToastVisible, showSyncToast, closeSyncToast] = useDialogState();
  const [selectedTabId, setSelectedTabId] = React.useState<string | null>(TabId.general);

  const {
    data: client,
    isLoading: isClientLoading,
    isError: isClientError,
    error: clientError
  } = useQuery<Client, ApiError>(["getClient", clientId], {
    queryFn: () => ClientsService.getClient({ clientId }),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  const {
    data: existingClientConfig,
    isLoading: isClientConfigLoading,
    isError: isClientConfigError,
    error: clientConfigError,
    refetch: refetchClientConfig
  } = useQuery<ClientConfig, ApiError>(["getClientConfig"], {
    queryFn: () => ClientsService.getClientConfig({ id: clientId }),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    cacheTime: 0
  });

  const {
    mutate: syncClientConfigWithXtrf,
    isLoading: isSyncLoading,
    error: xtrfSyncError
  } = useMutation({
    mutationFn: (clientId: string) => ClientsService.syncClientConfigWithXtrf({ id: clientId }),
    onSuccess: () => {
      showSyncToast();
      refetchClientConfig();
    }
  });

  const onSaveSuccess = React.useCallback(() => {
    showSuccessToast();
    refetchClientConfig();
  }, [showSuccessToast, refetchClientConfig]);

  const clientConfig = React.useMemo<ClientConfig>(
    () => existingClientConfig || { id: clientId },
    [existingClientConfig, clientId]
  );
  const isLoading = isClientLoading || isClientConfigLoading;
  const isError = isClientError || (isClientConfigError && clientConfigError?.status !== 404);
  const error = clientError || clientConfigError;

  return (
    <>
      {!!xtrfSyncError && <SnackbarApiError error={xtrfSyncError} />}
      <Snackbar open={successToastVisible} autoHideDuration={10000} onClose={closeSuccessToast}>
        <Alert onClose={closeSuccessToast} severity="success" sx={{ width: "100%" }}>
          Settings saved successfully
        </Alert>
      </Snackbar>
      <Snackbar open={syncToastVisible} autoHideDuration={10000} onClose={closeSyncToast}>
        <Alert onClose={closeSyncToast} severity="success" sx={{ width: "100%" }}>
          Synchronization with XTRF succeeded
        </Alert>
      </Snackbar>

      <PageLayout
        title="Client Details"
        selectedNavMenu={NavMenuOption.CLIENTS}
        breadcrumbs={[
          {
            label: "Clients",
            path: "/clients"
          },
          {
            label: "Client Details",
            active: true
          }
        ]}
      >
        {isLoading && <PageLoading />}
        {isError && error && <ApiErrorMessage apiError={error} />}
        {!isLoading && !isError && client && clientConfig && (
          <div>
            <Toolbar disableGutters={true} variant="dense">
              <Box flexGrow={1}>
                <h3>
                  {client.id} - {clientConfig.companyName}
                </h3>
              </Box>
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  onClick={() => syncClientConfigWithXtrf(client.id)}
                  isLoading={isSyncLoading}
                >
                  Sync with Xtrf
                </LoadingButton>
              </Stack>
            </Toolbar>
            <Paper sx={{ p: 2 }}>
              <Tabs
                variant="scrollable"
                value={selectedTabId || undefined}
                onChange={(event, value) => setSelectedTabId(value)}
              >
                <Tab value={TabId.general} label="General Information" />
                <Tab value={TabId.applications} label="Applications" />
                <Tab value={TabId.portalSettings} label="Portal Settings" />
                <Tab value={TabId.workflows} label="Workflows" />
                <Tab value={TabId.projectCustomFields} label="Project Custom Fields" />
                <Tab value={TabId.userCustomFields} label="User Custom Fields" />
                <Tab value={TabId.clientContacts} label="Client Contacts" />
                <Tab value={TabId.languageGroups} label="Language Groups" />
              </Tabs>
              <Box sx={{ mt: 2 }}>
                {selectedTabId === TabId.general && (
                  <GeneralInformationTab client={client} clientConfig={clientConfig} onSaveSuccess={onSaveSuccess} />
                )}
                {selectedTabId === TabId.portalSettings && (
                  <PortalSettingsTab clientConfig={clientConfig} onSaveSuccess={onSaveSuccess} />
                )}
                {selectedTabId === TabId.workflows && (
                  <WorkflowSettingsTab client={client} onSaveSuccess={onSaveSuccess} />
                )}
                {selectedTabId === TabId.projectCustomFields && (
                  <CustomFieldsTab
                    clientId={clientId}
                    category={CustomFieldDefinition.category.PROJECT}
                    onSaveSuccess={onSaveSuccess}
                  />
                )}
                {selectedTabId === TabId.userCustomFields && (
                  <CustomFieldsTab
                    clientId={clientId}
                    category={CustomFieldDefinition.category.USER}
                    onSaveSuccess={onSaveSuccess}
                  />
                )}
                {selectedTabId === TabId.clientContacts && <ClientContactsTab clientId={clientId} />}
                {selectedTabId === TabId.applications && (
                  <ApplicationsTab clientConfig={clientConfig} onSaveSuccess={onSaveSuccess} />
                )}
                {selectedTabId === TabId.languageGroups && (
                  <LanguageGroupsTab clientConfig={clientConfig} onSaveSuccess={onSaveSuccess} />
                )}
              </Box>
            </Paper>
          </div>
        )}
      </PageLayout>
    </>
  );
};

export { ClientDetailsPage };
