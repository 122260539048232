import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { ConfigurationTab } from "./tabs/ConfigurationTab/ConfigurationTab";
import { ApplicationsTab } from "./tabs/ApplcationsPage/ApplicationsTab";
import { useHistory, useParams } from "react-router-dom";
import { EmailTemplatesTab } from "./tabs/EmailTemplatesTab/EmailTemplatesTab";
import { EmailProcessorSettingsTab } from "./tabs/EmailProcessorSettingsTab/EmailProcessorSettingsTab";
import { PagePath } from "../../types/PagePath";
import { LanguagesTab } from "./tabs/LanguagesPage/LanguagesTab";
import { AutoQuoteSettingsTab } from "./tabs/AutoQuoteSettingsTab/AutoQuoteSettingsTab";

enum TabId {
  configuration = "configuration",
  applications = "applications",
  emailTemplates = "emailTemplates",
  emailProcessorSettings = "emailProcessorSettings",
  languages = "languages",
  autoQuote = "autoQuote"
}

const SystemSettingsPage: React.FC = () => {
  const { tabId = TabId.configuration } = useParams<{ tabId: string }>();
  const [selectedTabId, setSelectedTabId] = React.useState(tabId);
  const history = useHistory();

  return (
    <PageLayout title="System Settings" selectedNavMenu={NavMenuOption.SYSTEM_SETTINGS}>
      <Tabs
        variant="scrollable"
        value={selectedTabId || undefined}
        onChange={(event, value) => {
          setSelectedTabId(value);
          history.push(`${PagePath.systemSettings}/${value}`);
        }}
      >
        <Tab value={TabId.configuration} label="Configuration" />
        <Tab value={TabId.applications} label="Applications" />
        <Tab value={TabId.emailTemplates} label="Email Templates" />
        <Tab value={TabId.emailProcessorSettings} label="Email Processor Settings" />
        <Tab value={TabId.languages} label="Languages" />
        <Tab value={TabId.autoQuote} label="Auto-Quote Settings" />
      </Tabs>
      {selectedTabId === TabId.configuration && <ConfigurationTab />}
      {selectedTabId === TabId.applications && <ApplicationsTab />}
      {selectedTabId === TabId.emailTemplates && <EmailTemplatesTab />}
      {selectedTabId === TabId.emailProcessorSettings && <EmailProcessorSettingsTab />}
      {selectedTabId === TabId.languages && <LanguagesTab />}
      {selectedTabId === TabId.autoQuote && <AutoQuoteSettingsTab />}
    </PageLayout>
  );
};

export { SystemSettingsPage };
