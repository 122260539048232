import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { ClientConfig, ClientsService, ReportDefinition, UtilsService } from "gen/clients";
import { Formik } from "formik";
import Drawer from "@mui/material/Drawer";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { ReportForm } from "./components/ReportForm/ReportForm";
import { SelectOption } from "@mui/base";

interface Props {
  clientConfig: ClientConfig;
  reportDefinitions: ReportDefinition[];
  editedReportIndex: number;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function isConvertibleToNumber(str: string): boolean {
  return /^\d*\.?\d+$/.test(str);
}

const EditReportPanel: React.FC<Props> = ({
  clientConfig,
  reportDefinitions,
  editedReportIndex,
  open,
  onSuccess,
  onClose
}) => {
  const editedReport: ReportDefinition | undefined = reportDefinitions[editedReportIndex];
  if (!editedReport) {
    throw new Error(`Invalid report index: ${editedReportIndex}`);
  }

  const { data: viewFilters, isLoading: isViewFiltersLoading } = useQuery([editedReport.viewId], {
    queryFn: () => UtilsService.listXtrfViewFilters({ viewId: editedReport.viewId })
  });

  const initialFilterValues = React.useMemo(() => editedReport.visibleFilters, [editedReport.visibleFilters]);

  const { mutate, error } = useMutation({
    onSuccess,
    mutationFn: ClientsService.updateClientConfig
  });

  const validate = React.useCallback(formValues => {
    const errors: Record<string, string> = {};
    if (!isConvertibleToNumber(formValues.viewId)) {
      errors.viewId = "Use number digits only";
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    formValues => {
      const modifiedReportDefinitions: ReportDefinition[] = reportDefinitions || [];
      const reportDefinition = modifiedReportDefinitions[editedReportIndex];
      if (!reportDefinition) {
        throw new Error(`Invalid report index: ${editedReportIndex}`);
      }
      reportDefinition.name = formValues.name.trim();
      reportDefinition.type = formValues.type;
      reportDefinition.viewId = parseInt(formValues.viewId, 10);
      reportDefinition.viewParams = formValues.viewParams?.trim() || undefined;
      reportDefinition.description = formValues.description?.trim() || undefined;
      reportDefinition.visibleFilters = formValues.visibleFilters;

      window.console.log("onSubmit", { reportDefinition });

      mutate({
        id: clientConfig.id,
        requestBody: { ...clientConfig, reports: modifiedReportDefinitions }
      });
    },
    [editedReportIndex, clientConfig, mutate, reportDefinitions]
  );

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader title="Edit Report" onClose={onClose} />
      <DrawerBody>
        {!!error && <SnackbarApiError error={error} />}
        <Formik
          initialValues={{
            name: editedReport.name,
            type: editedReport.type,
            viewId: editedReport.viewId,
            viewParams: editedReport.viewParams,
            description: editedReport.description || "",
            visibleFilters: initialFilterValues
          }}
          onSubmit={onSubmit}
          validate={validate}
        >
          <ReportForm
            isEdit={true}
            filters={{
              isLoading: isViewFiltersLoading,
              options:
                viewFilters?.map(
                  f =>
                    ({
                      label: f.name,
                      value: f.mapping,
                      disable: !f.visible
                    }) as SelectOption<string | number>
                ) || []
            }}
            onCancel={onClose}
          />
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { EditReportPanel };
