/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateClientContactRequest } from '../models/CreateClientContactRequest';
import type { CreateClientContactResponse } from '../models/CreateClientContactResponse';
import type { LinkedClient } from '../models/LinkedClient';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ClientsContactsService {

    /**
     * Creates client contact
     * @returns CreateClientContactResponse successful operation
     * @throws ApiError
     */
    public static createClientContact({
        requestBody,
    }: {
        /** New client contact **/
        requestBody?: CreateClientContactRequest,
    }): CancelablePromise<CreateClientContactResponse> {
        return __request({
            method: 'POST',
            path: `/clientContacts`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

    /**
     * Returns a list of linked clients for a client contact
     * @returns LinkedClient successful operation
     * @throws ApiError
     */
    public static listLinkedClients({
        clientContactId,
    }: {
        clientContactId: number,
    }): CancelablePromise<Array<LinkedClient>> {
        return __request({
            method: 'GET',
            path: `/clientContacts/${clientContactId}/linkedClients`,
        });
    }

    /**
     * Updates linked clients for a client contact
     * @returns void
     * @throws ApiError
     */
    public static updateLinkedClients({
        clientContactId,
        requestBody,
    }: {
        clientContactId: number,
        requestBody?: Array<string>,
    }): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/clientContacts/${clientContactId}/linkedClients`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

}