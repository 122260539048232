import * as React from "react";
import { DataTable } from "../../../../components/DataTable/DataTable";
import { useQuery } from "react-query";
import { EmailTemplateSummary, SystemSettingsService } from "../../../../gen/clients";
import { parseApiErrorMessage } from "../../../../components/ApiErrorMessage/ApiErrorMessage";
import { useDialogState } from "../../../../hooks/useDialogState";
import { EditEmailTemplatePanel } from "./EditEmailTemplatePanel/EditEmailTemplatePanel";
import { SnackbarAlert } from "../../../../components/SnackbarAlert/SnackbarAlert";

const EmailTemplatesTab: React.FC = () => {
  const [isEditPanelOpen, openEditPanel, closeEditPanel] = useDialogState();
  const [isSuccessAlertOpen, openSuccessAlert, closeSuccessAlert] = useDialogState();
  const [selectedTemplateName, setSelectedTemplateName] = React.useState<string>();
  const { data, isLoading, error } = useQuery(["listEmailTemp"], {
    queryFn: () => SystemSettingsService.listEmailTemplates({ pageSize: 100 })
  });

  const sortedTemplates = React.useMemo(
    () => data?.items.sort((t1, t2) => t1.templateName.localeCompare(t2.templateName)) || [],
    [data]
  );

  const onRowEdit = React.useCallback(
    (template: EmailTemplateSummary) => {
      setSelectedTemplateName(template.templateName);
      closeSuccessAlert();
      openEditPanel();
    },
    [closeSuccessAlert, openEditPanel]
  );

  const onEditSuccess = React.useCallback(() => {
    closeEditPanel();
    openSuccessAlert();
  }, [closeEditPanel, openSuccessAlert]);

  return (
    <>
      <DataTable
        columns={[
          {
            id: "templateName",
            title: "Template Name",
            cell: (t: EmailTemplateSummary) => t.templateName
          },
          {
            id: "description",
            title: "Description",
            cell: (t: EmailTemplateSummary) => t.description
          }
        ]}
        data={sortedTemplates}
        rowKey={(t: EmailTemplateSummary) => t.templateName}
        isLoading={isLoading}
        error={error && parseApiErrorMessage(error)}
        rowActions={[
          {
            title: "Edit",
            action: onRowEdit
          }
        ]}
      />

      {selectedTemplateName && isEditPanelOpen && (
        <EditEmailTemplatePanel
          templateName={selectedTemplateName}
          onClose={closeEditPanel}
          onSuccess={onEditSuccess}
        />
      )}
      {isSuccessAlertOpen && (
        <SnackbarAlert
          message="Email Template saved successfully"
          severity="success"
          horizontal="left"
          vertical="bottom"
        />
      )}
    </>
  );
};

export { EmailTemplatesTab };
