import * as React from "react";
import { SelectOption } from "@mui/base";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import { useQuery } from "react-query";
import { ApplicationCollection, ApplicationsService, ErrorResponse } from "gen/clients";

interface Props {
  /**
   * Field name
   */
  name: string;
  onChange: (application: SelectOption<string>) => void;
}

const ApplicationSelect: React.FC<Props> = ({ name, onChange }) => {
  const [, setSearchText] = React.useState<string>();

  const { data, isLoading } = useQuery<ApplicationCollection, ErrorResponse>(
    ["applications"],
    ApplicationsService.listApplications,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  );

  const sortedOptions = React.useMemo<SelectOption<string>[]>(() => {
    if (!data) {
      return [];
    }
    return data.items
      .sort((app1, app2) => (app1.name || app1.id).localeCompare(app2.name || app2.id))
      .map(app => ({
        label: app.name,
        value: app.id
      }));
  }, [data]);

  const options: SelectOption<string>[] = React.useMemo(() => {
    if (!sortedOptions.length) {
      return [];
    }
    return sortedOptions;
  }, [sortedOptions]);

  const onSelect = React.useCallback(
    (selectedOption: SelectOption<string>) => {
      onChange(selectedOption);
    },
    [onChange]
  );

  return (
    <SelectOneAutocompleteField
      name={name}
      options={options}
      onInputTextChange={setSearchText}
      onSelect={onSelect}
      areOptionsLoading={isLoading}
      suppressFilterOptions={true}
      placeholder="Type application name"
      label="Application Name"
      required={true}
    />
  );
};

export { ApplicationSelect };
