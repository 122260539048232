// noinspection JSIgnoredPromiseFromCall

import * as React from "react";
import { useSelector } from "react-redux";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import logoImage from "./llts-logo.png";
import styles from "./AppHeader.module.scss";
import { ApplicationState } from "../../store/store";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Logout } from "@mui/icons-material";

const AppHeader: React.FC = () => {
  const { username, email } = useSelector((state: ApplicationState) => ({
    username: state.userSession.username,
    email: state.userSession.email
  }));
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const signIn = React.useCallback(() => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
  }, []);

  const signOut = React.useCallback(() => {
    Auth.signOut();
  }, []);

  const onAvatarClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = React.useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white" }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={5}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <img src={logoImage} alt="Language Line Solutions" />
          <Typography variant="h5" color="black">
            Admin Console
          </Typography>
        </Stack>
        <Box sx={{ pr: 5 }}>
          {!username && (
            <Button onClick={signIn} className={styles.signInBtn}>
              Sign in
            </Button>
          )}
          {username && (
            <>
              <IconButton onClick={onAvatarClick}>
                <Avatar>{username?.substring(0, 1).toUpperCase() || email?.substring(0, 1).toUpperCase()}</Avatar>
              </IconButton>
            </>
          )}
        </Box>
      </Stack>
      <div className={styles.headerDividerContainer}>
        <div className={styles.headerDividerBox} />
      </div>

      <Menu
        anchorEl={menuAnchorEl}
        id="account-menu"
        open={!!menuAnchorEl}
        onClose={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            }
          }
        }}
      >
        <MenuItem disabled={true}>
          <Avatar /> {username || email}
        </MenuItem>
        <MenuItem onClick={signOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default AppHeader;
