import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { DataTable } from "components/DataTable/DataTable";
import {
  JobRequestOrigination,
  JobRequestsService,
  JobRequestStatus,
  JobRequestSummary,
  JobRequestType
} from "gen/clients";
import { useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/dateUtils";
import { TokenBasedPaginator } from "components/TokenBasedPaginator/TokenBasedPaginator";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useDialogState } from "hooks/useDialogState";
import { DataTableRowAction } from "components/DataTable/types";
import { useSnackbarAlert } from "hooks/useSnackbarAlert";
import { formatPrice } from "utils/numberUtils";
import { JobRequestStatusText } from "../components/JobRequestStatusText/JobRequestStatusText";
import { DeleteJobRequestDialog } from "../components/DeleteJobRequestDialog/DeleteJobRequestDialog";
import { PagePath } from "../../../types/PagePath";
import { ArchiveJobRequestDialog } from "../components/ArchiveJobRequestDialog/ArchiveJobRequestDialog";
import {
  DEFAULT_JOB_REQEST_GROUP,
  JobRequestFilter,
  JobRequestFilterSearchParams
} from "../components/JobRequestFilter/JobRequestFilter";
import { ArchiveJobRequestListDialog } from "../components/ArchiveJobRequestListDialog/ArchiveJobRequestListDialog";
import { DeleteJobRequestListDialog } from "../components/DeleteJobRequestListDialog/DeleteJobRequestListDialog";
import { InfoIcon } from "components/InfoIcon/InfoIcon";
import Link from "@mui/material/Link";

interface WebFormJobRequestsPageSessionState {
  filterParams?: JobRequestFilterSearchParams;
  rowsPerPage?: number;
}

const PAGE_SESSION_STATE_VAR_NAME = "webFormJobRequestsPageStateV1";
const FILTER_STATUSES = [
  JobRequestStatus.CREATED,
  JobRequestStatus.READY_FOR_INTERNAL_REVIEW,
  JobRequestStatus.QUOTE_READY_FOR_CLIENT_REVIEW,
  JobRequestStatus.QUOTE_ACCEPTED,
  JobRequestStatus.QUOTE_REJECTED,
  JobRequestStatus.QUOTE_EXPIRED,
  JobRequestStatus.ARCHIVED,
  JobRequestStatus.SUBMITTING_TO_XTRF,
  JobRequestStatus.SUBMITTED_TO_XTRF
];

const QuoteJobRequestsPage: React.FC = () => {
  const history = useHistory();
  const [selectedJobRequest, setSelectedJobRequest] = React.useState<JobRequestSummary>();
  const [deleteJobRequestDialogOpen, openDeleteJobRequestDialog, closeDeleteJobRequestDialog] = useDialogState();
  const [deleteJobRequestListDialogOpen, openDeleteJobRequestListDialog, closeDeleteJobRequestListDialog] =
    useDialogState();
  const [archiveJobRequestDialogOpen, openArchiveJobRequestDialog, closeArchiveJobRequestDialog] = useDialogState();
  const [archiveJobRequestListDialogOpen, openArchiveJobRequestListDialog, closeArchiveJobRequestListDialog] =
    useDialogState();

  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });

  const { snackbarAlert, openSnackbarAlert, closeSnackbarAlert } = useSnackbarAlert({
    autoHideDuration: 5000,
    vertical: "bottom",
    horizontal: "left"
  });
  const pageSessionState: WebFormJobRequestsPageSessionState | undefined = React.useMemo(
    () =>
      window.sessionStorage.getItem(PAGE_SESSION_STATE_VAR_NAME)
        ? JSON.parse(window.sessionStorage.getItem(PAGE_SESSION_STATE_VAR_NAME) as string)
        : undefined,
    []
  );
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(pageSessionState?.rowsPerPage || 10);
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const [selectedRows, setSelectedRows] = React.useState<JobRequestSummary[]>([]);
  const [filterParams, setFilterParams] = React.useState<JobRequestFilterSearchParams>(
    pageSessionState?.filterParams || { group: DEFAULT_JOB_REQEST_GROUP, requestType: JobRequestType.QUOTE_REQUEST }
  );

  // Keep session state of the page updated
  React.useEffect(() => {
    const newPageSessionState: WebFormJobRequestsPageSessionState = {
      rowsPerPage,
      filterParams
    };
    window.sessionStorage.setItem(PAGE_SESSION_STATE_VAR_NAME, JSON.stringify(newPageSessionState));
  }, [rowsPerPage, filterParams]);

  const {
    data: jobRequests,
    isLoading,
    error,
    refetch,
    isFetching
  } = useQuery(
    [
      "listWebFormJobRequests",
      pageToken,
      rowsPerPage,
      filterParams?.requestType,
      filterParams?.status,
      filterParams?.requestId,
      filterParams?.group
    ],
    {
      queryFn: () =>
        JobRequestsService.listJobRequests({
          pageToken,
          pageSize: rowsPerPage,
          requestType: filterParams?.requestType,
          status: filterParams?.status,
          group: filterParams?.group,
          jobRequestId: filterParams?.requestId?.trim(),
          origination: JobRequestOrigination.WEBFORM
        }),
      onSuccess: collection => {
        setNextPageToken(collection.nextPageToken);
      },
      refetchInterval: 10000
    }
  );

  const requestedByCell = React.useCallback(
    (jobRequest: JobRequestSummary) =>
      `${jobRequest.requestorFirstName || ""} ${jobRequest.requestorLastName || ""} (${jobRequest.requestorEmail})`,
    []
  );

  const companyNameCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Box maxWidth={200}>
        <Typography noWrap={true} title={jobRequest.companyName} variant="body2">
          {jobRequest.companyName}
        </Typography>
      </Box>
    ),
    []
  );

  const projectNameCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Box maxWidth={250}>
        <Typography noWrap={true} title={jobRequest.name} variant="body2">
          <Link onClick={e => e.preventDefault()} href={`/quoteRequests/${jobRequest.id}`}>
            {jobRequest.name}
          </Link>
        </Typography>
      </Box>
    ),
    []
  );

  const priceCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Typography noWrap={true} variant="body2">
        {jobRequest.autoQuote?.price ? formatPrice(jobRequest.autoQuote.price) : ""}
      </Typography>
    ),
    []
  );

  const createTimeCell = React.useCallback((jobRequest: JobRequestSummary) => formatDate(jobRequest.createTime), []);

  const statusCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Stack direction="row" spacing={1}>
        <JobRequestStatusText status={jobRequest.status} />
        {jobRequest.status === JobRequestStatus.QUOTE_REJECTED && jobRequest.autoQuote?.rejectionReason && (
          <InfoIcon tooltip={jobRequest.autoQuote.rejectionReason} />
        )}
      </Stack>
    ),
    []
  );

  const onRowClick = React.useCallback(
    (jobRequest: JobRequestSummary) => {
      history.push(`${PagePath.quoteJobRequests}/${jobRequest.id}`);
    },
    [history]
  );

  const onDeleteJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeDeleteJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeDeleteJobRequestDialog, openSnackbarAlert, refetch]);

  const onArchiveJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeArchiveJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeArchiveJobRequestDialog, openSnackbarAlert, refetch]);

  const onDeleteJobRequestListSuccess = React.useCallback(() => {
    refetch();
    closeDeleteJobRequestListDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeDeleteJobRequestListDialog, openSnackbarAlert, refetch]);

  const onArchiveJobRequestListSuccess = React.useCallback(() => {
    refetch();
    closeArchiveJobRequestListDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeArchiveJobRequestListDialog, openSnackbarAlert, refetch]);

  const rowActions = React.useCallback(
    (jobRequest: JobRequestSummary): DataTableRowAction[] => [
      {
        title: "View Details",
        action: () => history.push(`${PagePath.quoteJobRequests}/${jobRequest.id}`)
      },
      {
        type: "DIVIDER",
        title: "divider1"
      },
      {
        title: "Archive Request",
        disabled: [JobRequestStatus.ANALYZING, JobRequestStatus.SUBMITTING_TO_XTRF, JobRequestStatus.ARCHIVED].includes(
          jobRequest.status
        ),
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openArchiveJobRequestDialog();
        }
      },
      {
        title: "Delete Request",
        disabled: ![
          JobRequestStatus.READY_FOR_INTERNAL_REVIEW,
          JobRequestStatus.QUOTE_READY_FOR_CLIENT_REVIEW,
          JobRequestStatus.QUOTE_ACCEPTED,
          JobRequestStatus.QUOTE_REJECTED,
          JobRequestStatus.COMPLETED,
          JobRequestStatus.FAILED,
          JobRequestStatus.REJECTED_BY_SCREENING,
          JobRequestStatus.ARCHIVED
        ].includes(jobRequest.status),
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openDeleteJobRequestDialog();
        },
        color: "error.main"
      }
    ],
    [history, closeSnackbarAlert, openArchiveJobRequestDialog, openDeleteJobRequestDialog]
  );

  const onFilterSearch = React.useCallback(
    (searchParams: JobRequestFilterSearchParams) => {
      setFilterParams(searchParams);
      paginatorResetRef.current();
      refetch();
    },
    [refetch]
  );

  return (
    <PageLayout
      selectedNavMenu={NavMenuOption.QUOTE_JOB_REQUESTS}
      title={
        <Toolbar disableGutters={true} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Quote Requests
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <Button
              disabled={selectedRows.length === 0}
              variant="outlined"
              onClick={() => {
                openArchiveJobRequestListDialog();
                closeSnackbarAlert();
              }}
            >
              Archive
            </Button>
            <Button
              disabled={selectedRows.length === 0}
              variant="outlined"
              color="error"
              onClick={() => {
                openDeleteJobRequestListDialog();
                closeSnackbarAlert();
              }}
            >
              Delete
            </Button>
            <Box width={30} height={30}>
              <IconButton onClick={() => refetch()}>
                {!isFetching && <RefreshIcon />}
                {isFetching && <CircularProgress size={20} />}
              </IconButton>
            </Box>
          </Stack>
        </Toolbar>
      }
    >
      <Box sx={{ mb: 1 }}>
        <JobRequestFilter
          initialValues={filterParams}
          onSearch={onFilterSearch}
          hideRequestTypeFilter={true}
          statuses={FILTER_STATUSES}
        />
      </Box>

      <DataTable
        columns={[
          {
            id: "requestedBy",
            title: "Requested By",
            cell: requestedByCell
          },
          {
            id: "companyName",
            title: "Company",
            cell: companyNameCell
          },
          {
            id: "name",
            title: "Project Name",
            cell: projectNameCell
          },
          {
            id: "cost",
            title: "Quote Amount",
            cell: priceCell
          },
          {
            id: "createTime",
            title: "Created",
            cell: createTimeCell
          },
          {
            id: "status",
            title: "Status",
            cell: statusCell
          }
        ]}
        data={jobRequests?.items || []}
        rowKey={(jobRequest: JobRequestSummary) => jobRequest.id}
        isLoading={isLoading}
        error={!!error && <ApiErrorMessage apiError={error} />}
        onRowClick={onRowClick}
        onSelectRows={setSelectedRows}
        rowActions={rowActions}
        dense={true}
      />
      <TokenBasedPaginator
        nextPageToken={nextPageToken}
        rowsPerPage={rowsPerPage}
        onPageTokenChange={setPageToken}
        currentPageDataRows={jobRequests?.items.length}
        onRowsPerPageChange={setRowsPerPage}
        resetRef={paginatorResetRef}
      />
      {deleteJobRequestDialogOpen && selectedJobRequest && (
        <DeleteJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onDeleteJobRequestSuccess}
          onClose={closeDeleteJobRequestDialog}
        />
      )}
      {archiveJobRequestDialogOpen && selectedJobRequest && (
        <ArchiveJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onArchiveJobRequestSuccess}
          onClose={closeArchiveJobRequestDialog}
        />
      )}
      {archiveJobRequestListDialogOpen && (
        <ArchiveJobRequestListDialog
          jobRequests={selectedRows}
          onSuccess={onArchiveJobRequestListSuccess}
          onClose={closeArchiveJobRequestListDialog}
        />
      )}
      {deleteJobRequestListDialogOpen && (
        <DeleteJobRequestListDialog
          jobRequests={selectedRows}
          onSuccess={onDeleteJobRequestListSuccess}
          onClose={closeDeleteJobRequestListDialog}
        />
      )}
      {snackbarAlert}
    </PageLayout>
  );
};

export { QuoteJobRequestsPage };
