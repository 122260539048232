import * as React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { ClientConfig } from "gen/clients";
import { MachineTranslationTab } from "./MachineTranslationTab";
import { ReportsTab } from "./ReportsTab/ReportsTab";
import { ApplicationId } from "types/ApplicationId";

interface Props {
  clientConfig: ClientConfig;
  enabledApplications: string[];
  onSaveSuccess: () => void;
}

const TabId = {
  machineTranslation: ApplicationId.machineTranslation as string,
  customReports: ApplicationId.reports as string
};
const orderedTabs = Object.values(TabId).sort((t1, t2) => t1.localeCompare(t2));

const tabLabels = {
  [TabId.machineTranslation]: "Machine Translation",
  [TabId.customReports]: "Custom Reports"
};

const ApplicationSettingsPanel: React.FC<Props> = ({ clientConfig, enabledApplications, onSaveSuccess }) => {
  const availableTabs = React.useMemo(
    () => orderedTabs.filter(tabId => enabledApplications.includes(tabId)),
    [enabledApplications]
  );

  const [selectedTabId, setSelectedTabId] = React.useState<string | null>(availableTabs[0] || null);

  const validSelectedTab = React.useMemo(
    () =>
      selectedTabId !== null && enabledApplications?.includes(selectedTabId) ? selectedTabId : availableTabs[0] || null,
    [availableTabs, enabledApplications, selectedTabId]
  );

  return (
    <Box sx={{ px: 3, flexGrow: 1 }}>
      <Tabs
        variant="scrollable"
        value={selectedTabId}
        onChange={(event, value) => setSelectedTabId(value)}
        sx={{ mb: 2 }}
      >
        {availableTabs.map(tabId => (
          <Tab key={tabId} value={tabId} label={tabLabels[tabId]} />
        ))}
      </Tabs>
      {validSelectedTab === TabId.machineTranslation && (
        <MachineTranslationTab clientConfig={clientConfig} onSaveSuccess={onSaveSuccess} />
      )}
      {validSelectedTab === TabId.customReports && (
        <ReportsTab clientId={clientConfig.id} onSaveSuccess={onSaveSuccess} />
      )}
    </Box>
  );
};

export { ApplicationSettingsPanel };
