import * as React from "react";
import {
  ClientConfig,
  ClientsService,
  LanguageGroup,
  LanguageGroupScope,
  LanguagesService,
  LltsLanguage
} from "gen/clients";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import { SelectManyAutocompleteField } from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { useMutation, useQuery } from "react-query";
import { DialogBody } from "components/DialogBody/DialogBody";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { SelectOption } from "@mui/base";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  clientConfig: ClientConfig;
  onClose: () => void;
  onSuccess: () => void;
}

enum FieldName {
  name = "name",
  languages = "languages"
}

interface FormValues {
  [FieldName.name]: string;
  [FieldName.languages]: SelectOption<string>[];
}

const AddLanguageGroupDialog: React.FC<Props> = ({ clientConfig, onClose, onSuccess }) => {
  const {
    data: languages,
    isLoading: areLanguagesLoading,
    error: languagesError
  } = useQuery(["listLltsLanguages"], {
    queryFn: () => LanguagesService.listLltsLanguages({})
  });

  const { mutateAsync, error: saveError } = useMutation(ClientsService.updateClientConfig);

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const selectedLltsLanguages: LltsLanguage[] = values[FieldName.languages]
        .map(l => languages?.find(lang => lang.id === l.value))
        .filter(l => l !== undefined) as LltsLanguage[];
      const newLanguageGroup: LanguageGroup = {
        name: values[FieldName.name],
        scope: LanguageGroupScope.ORGANIZATION,
        languages: selectedLltsLanguages
      };
      await mutateAsync(
        {
          id: clientConfig.id,
          requestBody: {
            ...clientConfig,
            languageGroups: [...(clientConfig.languageGroups || []), newLanguageGroup]
          }
        },
        { onSuccess }
      );
    },
    [clientConfig, languages, mutateAsync, onSuccess]
  );

  const languageOptions = React.useMemo(
    () => languages?.map(l => ({ label: l.name, value: `${l.id}` })).sort(SELECT_OPTION_COMPARATOR) || [],
    [languages]
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Add Language Group</DialogTitle>
      <DialogBody isLoading={areLanguagesLoading} error={languagesError}>
        <Formik
          initialValues={{
            name: "",
            languages: []
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form noValidate={true} autoComplete="off" autoCorrect="off">
              <DialogContent>
                <Stack spacing={2}>
                  <TextInputField
                    name={FieldName.name}
                    label="Language Group Name"
                    required={true}
                    variant="outlined"
                  />
                  <SelectManyAutocompleteField
                    name={FieldName.languages}
                    label="Languages"
                    options={languageOptions}
                    required={true}
                    variant="outlined"
                  />
                  {saveError && <ApiErrorMessage apiError={saveError} />}
                </Stack>
              </DialogContent>

              <DialogActions>
                <LoadingButton isLoading={isSubmitting}>Add</LoadingButton>
                <Button onClick={onClose}>Cancel</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogBody>
    </Dialog>
  );
};

export { AddLanguageGroupDialog };
