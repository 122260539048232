import * as React from "react";
import { DrawerHeader } from "../../../../../components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "../../../../../components/DrawerBody/DrawerBody";
import Drawer from "@mui/material/Drawer";
import { useMutation, useQuery } from "react-query";
import { AiProcessingService, ApiError, SystemSettings, SystemSettingsService } from "../../../../../gen/clients";
import { Form, Formik, FormikProps } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "../../../../../components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "../../../../../components/LoadingButton/LoadingButton";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  isAnalysis: boolean;
  onClose: () => void;
}

enum FieldNames {
  input = "input"
}

interface FormValues {
  [FieldNames.input]: string;
}

const TestInstructionsPanel: React.FC<Props> = ({ isAnalysis, onClose }) => {
  const [userInput, setUserInput] = React.useState<string>("");
  const [aiOutput, setAIOutput] = React.useState<string>("");
  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const {
    data: systemSettings,
    isLoading,
    error
  } = useQuery<SystemSettings, ApiError>(["getSystemSettings"], {
    queryFn: SystemSettingsService.getSystemSettings
  });

  const { mutateAsync: processByOpenAI, isLoading: isProcessing } = useMutation({
    onSuccess: v => {
      setAIOutput(v.output || "");
      formikRef.current?.resetForm();
    },
    mutationFn: AiProcessingService.processTextByOpenAi
  });

  const onSubmit = React.useCallback(
    async values => {
      setUserInput(values[FieldNames.input]);
      await processByOpenAI({
        requestBody: {
          text: values[FieldNames.input],
          instructions:
            (isAnalysis
              ? systemSettings?.emailProcessorSettings?.openAI?.prompts.emailAnalysis
              : systemSettings?.emailProcessorSettings?.openAI?.prompts.emailScreening) || ""
        }
      });
    },
    [isAnalysis, processByOpenAI, systemSettings?.emailProcessorSettings?.openAI]
  );

  const dialogTitle = React.useMemo(
    () => (isAnalysis ? "Test Analysis Instructions" : "Test Screening Instructions"),
    [isAnalysis]
  );

  if (isLoading || error) {
    return (
      <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: "50vw" } }}>
        <DrawerHeader title={dialogTitle} onClose={onClose} />
        <DrawerBody>
          {isLoading && <PageLoading />}
          {error && <ApiErrorMessage apiError={error} />}
        </DrawerBody>
      </Drawer>
    );
  }

  return (
    <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: "50vw" } }}>
      <DrawerHeader title={dialogTitle} onClose={onClose} />
      <DrawerBody>
        <Formik
          initialValues={{
            [FieldNames.input]: userInput
          }}
          innerRef={formikRef}
          validateOnMount={false}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          <Form noValidate={true}>
            <Stack spacing={3} mt={3}>
              <TextInputField
                name={FieldNames.input}
                label="Test input"
                helperText="Enter job request sample email text"
                variant="outlined"
                multiline={true}
                rows={10}
              />
              <TextField
                label="AI output"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                multiline={true}
                disabled
                rows={10}
                value={aiOutput}
              />
            </Stack>
            <Stack direction="row" spacing={1} mt={3}>
              <LoadingButton isLoading={isProcessing}>Test</LoadingButton>
              <Button color="secondary" onClick={onClose} disabled={isProcessing} sx={{ ml: 1 }}>
                Close
              </Button>
            </Stack>
          </Form>
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { TestInstructionsPanel };
