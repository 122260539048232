/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientMtUsageReport } from '../models/ClientMtUsageReport';
import type { ClientsByEnabledApplicationReport } from '../models/ClientsByEnabledApplicationReport';
import type { EnabledApplicationsByClientReport } from '../models/EnabledApplicationsByClientReport';
import type { QueryMetricResponse } from '../models/QueryMetricResponse';
import type { QuoteRejectionReasonsReport } from '../models/QuoteRejectionReasonsReport';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class MetricsService {

    /**
     * Endpoint for fetching machine translation request counts
     * @returns QueryMetricResponse successful operation
     * @throws ApiError
     */
    public static getRequestCounts({
        clientId,
        durationMinutes,
        startTime,
        endTime,
    }: {
        clientId: string,
        /** duration of time frame for search from now back n minutes **/
        durationMinutes?: number,
        /** start time of time range search in ISO-8601 format **/
        startTime?: string,
        /** end time of time range search in ISO-8601 format **/
        endTime?: string,
    }): CancelablePromise<QueryMetricResponse> {
        return __request({
            method: 'GET',
            path: `/metrics/machineTranslation/requestCounts`,
            query: {
                'clientId': clientId,
                'durationMinutes': durationMinutes,
                'startTime': startTime,
                'endTime': endTime,
            },
        });
    }

    /**
     * Endpoint for generating a report of applications enabled by a client
     * @returns EnabledApplicationsByClientReport successful operation
     * @throws ApiError
     */
    public static getEnabledApplicationsByClientReport({
        clientId,
        pageToken,
        pageSize,
    }: {
        clientId?: string,
        pageToken?: string,
        pageSize?: number,
    }): CancelablePromise<EnabledApplicationsByClientReport> {
        return __request({
            method: 'GET',
            path: `/metrics/applications/enabledApplicationsByClientReport`,
            query: {
                'clientId': clientId,
                'pageToken': pageToken,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Endpoint for generating a report of clients that have a provided application enabled
     * @returns ClientsByEnabledApplicationReport successful operation
     * @throws ApiError
     */
    public static getClientsByEnabledApplicationReport({
        applicationId,
        pageToken,
        pageSize,
    }: {
        applicationId: string,
        pageToken?: string,
        pageSize?: number,
    }): CancelablePromise<ClientsByEnabledApplicationReport> {
        return __request({
            method: 'GET',
            path: `/metrics/applications/clientsByEnabledApplicationReport`,
            query: {
                'applicationId': applicationId,
                'pageToken': pageToken,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Endpoint for fetching machine translation monthly report
     * @returns ClientMtUsageReport successful operation
     * @throws ApiError
     */
    public static getMtMonthlyReport({
        year,
        month,
        pageToken,
        pageSize,
    }: {
        year: number,
        month: number,
        pageToken?: string,
        pageSize?: number,
    }): CancelablePromise<ClientMtUsageReport> {
        return __request({
            method: 'GET',
            path: `/metrics/machineTranslation/monthlyReport`,
            query: {
                'year': year,
                'month': month,
                'pageToken': pageToken,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Endpoint for fetching machine translation report for clients
     * @returns ClientMtUsageReport successful operation
     * @throws ApiError
     */
    public static getClientMtUsageReport({
        fromDate,
        toDate,
    }: {
        fromDate: string,
        toDate?: string,
    }): CancelablePromise<ClientMtUsageReport> {
        return __request({
            method: 'GET',
            path: `/metrics/machineTranslation/clientMtUsageReport`,
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
            },
        });
    }

    /**
     * Endpoint for fetching quote rejection reasons report
     * @returns QuoteRejectionReasonsReport successful operation
     * @throws ApiError
     */
    public static getQuoteRejectionReasonsReport({
        fromDate,
        toDate,
    }: {
        fromDate: string,
        toDate?: string,
    }): CancelablePromise<QuoteRejectionReasonsReport> {
        return __request({
            method: 'GET',
            path: `/metrics/quotes/rejectionReasonsReport`,
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
            },
        });
    }

}