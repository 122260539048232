import * as React from "react";
import { JobRequest, JobRequestFile, JobRequestFileCategory, JobRequestType } from "gen/clients";
import Typography from "@mui/material/Typography";
import { LabelValue } from "components/LabelValue/LabelValue";
import { FileChips } from "../../ReviewJobRequestPanel/JobRequestForm/components/FileChips/FileChips";
import { PaymentPreferencesSection } from "./PaymentPreferencesSection";
import Stack from "@mui/material/Stack";
import { SourceLanguageLabel } from "../components/SourceLanguageLabel";
import { TargetLanguagesLabel } from "../components/TargetLanguagesLabel";
import { TotalCharactersChargedLabel } from "../components/TotalCharactersChargedLabel";

interface Props {
  jobRequest: JobRequest;
  hideSourceFiles?: boolean;
  hideStandardAndRushQuotesOption?: boolean;
}

const ProjectInformationSection: React.FC<Props> = ({
  jobRequest,
  hideSourceFiles,
  hideStandardAndRushQuotesOption
}) => {
  const sourceDocumentFiles = React.useMemo(
    () => jobRequest?.files?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT),
    [jobRequest?.files]
  );

  const referenceFiles = React.useMemo(
    () => jobRequest?.files?.filter(f => f.category === JobRequestFileCategory.REFERENCE),
    [jobRequest?.files]
  );

  return (
    <Stack spacing={1}>
      <Typography variant="h5">Project Information</Typography>
      <SourceLanguageLabel jobRequest={jobRequest} />
      <TargetLanguagesLabel jobRequest={jobRequest} />
      <TotalCharactersChargedLabel jobRequest={jobRequest} />
      {!hideSourceFiles && sourceDocumentFiles && sourceDocumentFiles.length > 0 && (
        <LabelValue
          label="Source Document Files"
          value={
            <FileChips
              isPreviewEnabled={true}
              jobRequestId={jobRequest.id}
              files={sourceDocumentFiles as JobRequestFile[]}
            />
          }
        />
      )}
      {referenceFiles && referenceFiles.length > 0 && (
        <LabelValue
          label="Reference Files"
          value={
            <FileChips
              isPreviewEnabled={true}
              jobRequestId={jobRequest.id}
              files={referenceFiles as JobRequestFile[]}
            />
          }
        />
      )}
      {jobRequest.requestType === JobRequestType.QUOTE_REQUEST && !hideStandardAndRushQuotesOption && (
        <LabelValue
          label="Provide Standard and Rush Quotes"
          value={jobRequest.provideStandardAndRushQuotes ? "Yes" : "No"}
          verticalAlignment="top"
        />
      )}
      {jobRequest.paymentPreferences && <PaymentPreferencesSection jobRequest={jobRequest} />}
    </Stack>
  );
};

export { ProjectInformationSection };
