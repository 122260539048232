import * as React from "react";
import { ApplicationsService, LanguagesService, LltsLanguage, MtBillingType } from "gen/clients";
import { useMutation, useQuery } from "react-query";
import { Button, Drawer, Stack } from "@mui/material";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { Form, Formik } from "formik";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { DrawerActions } from "components/DrawerActions/DrawerActions";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { PageLoading } from "components/PageLoading/PageLoading";
import { SelectManyAutocompleteField } from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { SelectOption } from "@mui/base";
import { SwitchField } from "../../../../../../components/formikFields/SwitchField/SwitchField";
import { LabelValue } from "../../../../../../components/LabelValue/LabelValue";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { mtBillingTypeOptions } from "../../utils/MtUtils";

interface Props {
  applicationId: string;
  onClose: () => void;
  onSuccess: () => void;
}

enum FieldName {
  enableTextTranslation = "enableTextTranslation",
  enableFileTranslation = "enableFileTranslation",
  enableHumanEscalation = "enableHumanEscalation",
  maxRequestsPerUserPerDay = "maxRequestsPerUserPerDay",
  maxCharactersPerRequest = "maxCharactersPerRequest",
  supportedLanguages = "supportedLanguages",
  maxFileSizeMB = "maxFileSize",
  maxFilesPerRequest = "maxFilesPerRequest",
  billingType = "billingType"
}

interface FormValues {
  [FieldName.enableTextTranslation]: boolean;
  [FieldName.enableFileTranslation]: boolean;
  [FieldName.enableHumanEscalation]: boolean;
  [FieldName.maxRequestsPerUserPerDay]: number;
  [FieldName.maxCharactersPerRequest]: number;
  [FieldName.supportedLanguages]: SelectOption<string>[];
  [FieldName.maxFileSizeMB]: number;
  [FieldName.maxFilesPerRequest]: number;
  [FieldName.billingType]: string;
}

const MachineTranslationSettingsPanel: React.FC<Props> = ({ applicationId, onClose, onSuccess }) => {
  const {
    data: application,
    isLoading: isApplicationLoading,
    isFetching: isApplicationFetching,
    error: applicationError
  } = useQuery(["getMachineTranslationSettings"], {
    queryFn: () => ApplicationsService.getApplication({ id: applicationId }),
    refetchOnMount: true
  });

  const {
    data: lltsLanguages,
    isLoading: areLltsLanguagesLoading,
    error: lltsLanguagesError
  } = useQuery(["getMtLltsLanguages"], {
    queryFn: () =>
      LanguagesService.listLltsLanguages({
        includeDisabled: false,
        intentoIdExists: true,
        azureIdExists: true
      })
  });

  const lltsLanguageById: Record<string, LltsLanguage> = React.useMemo(
    () =>
      (lltsLanguages || []).reduce(
        (prevValue, currentValue) => {
          // eslint-disable-next-line no-param-reassign
          prevValue[currentValue.id] = currentValue;
          return prevValue;
        },
        {} as Record<string, LltsLanguage>
      ),
    [lltsLanguages]
  );

  const languageOptions = React.useMemo(
    () =>
      (lltsLanguages?.map(l => ({ label: l.name, value: l.id })) || []).sort((o1, o2) =>
        o1.label.localeCompare(o2.label)
      ),
    [lltsLanguages]
  );

  const {
    mutateAsync,
    isLoading: isMutating,
    error: mutationError
  } = useMutation("updateApplication", {
    onSuccess,
    mutationFn: ApplicationsService.updateApplication
  });

  const initialValues = React.useMemo(
    () => ({
      [FieldName.billingType]: application?.settings?.billingType || MtBillingType.MONTHLY,
      [FieldName.enableTextTranslation]: !application?.settings?.disableTextTranslation,
      [FieldName.enableFileTranslation]: !application?.settings?.disableFileTranslation,
      [FieldName.enableHumanEscalation]: !application?.settings?.disableHumanEscalation,
      [FieldName.maxRequestsPerUserPerDay]: application?.settings?.maxRequestsPerUserPerDay || 0,
      [FieldName.maxCharactersPerRequest]: application?.settings?.maxCharactersPerRequest || 0,
      [FieldName.maxFileSizeMB]: application?.settings?.maxFileSizeMB || 0,
      [FieldName.maxFilesPerRequest]: application?.settings?.maxFilesPerRequest || 0,
      [FieldName.supportedLanguages]: (
        application?.settings?.supportedLltsLanguages
          .filter(langId => lltsLanguageById[langId] && !lltsLanguageById[langId].isDisabled)
          .map(langId => ({
            label: lltsLanguageById[langId]?.name || langId,
            value: langId
          })) || []
      ).sort((o1, o2) => o1.label.localeCompare(o2.label))
    }),
    [application, lltsLanguageById]
  );

  const validate = React.useCallback((formValues: FormValues) => {
    const errors: Record<string, string> = {};
    if (!formValues[FieldName.enableTextTranslation] && !formValues[FieldName.enableFileTranslation]) {
      errors[FieldName.enableTextTranslation] = "Either Text or File translation must be enabled";
      errors[FieldName.enableFileTranslation] = "Either Text or File translation must be enabled";
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    async (formValues: FormValues) => {
      if (!application) {
        return;
      }
      const selectedLanguageOptions = formValues[FieldName.supportedLanguages];
      const selectedLanguageIds = selectedLanguageOptions.map(lo => lo.value);
      window.console.debug("onSubmit", { billingType: formValues[FieldName.billingType] as MtBillingType });
      await mutateAsync({
        id: application.id,
        requestBody: {
          ...application,
          settings: {
            billingType: formValues[FieldName.billingType] as MtBillingType,
            disableTextTranslation: !formValues[FieldName.enableTextTranslation],
            disableFileTranslation: !formValues[FieldName.enableFileTranslation],
            disableHumanEscalation: !formValues[FieldName.enableHumanEscalation],
            maxRequestsPerUserPerDay: formValues[FieldName.maxRequestsPerUserPerDay],
            maxCharactersPerRequest: formValues[FieldName.maxCharactersPerRequest],
            supportedLltsLanguages: selectedLanguageIds,
            maxFileSizeMB: formValues[FieldName.maxFileSizeMB],
            maxFilesPerRequest: formValues[FieldName.maxFilesPerRequest]
          }
        }
      });
    },
    [application, mutateAsync]
  );

  const error = applicationError || lltsLanguagesError;
  const isLoading = isApplicationLoading || areLltsLanguagesLoading || isApplicationFetching;

  if (error) {
    return (
      <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
        <ApiErrorMessage apiError={error} />
      </Drawer>
    );
  }

  if (isLoading || !application) {
    return (
      <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
        <PageLoading />
      </Drawer>
    );
  }

  return (
    <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      {mutationError && <SnackbarApiError error={mutationError} />}
      <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
        {({ values }) => (
          <Form noValidate={true} autoComplete="off">
            <DrawerHeader title="Default Machine Translation Settings" onClose={onClose} />
            <DrawerBody>
              <Stack spacing={3}>
                <LabelValue
                  label="Text Translation"
                  value={
                    <SwitchField
                      name={FieldName.enableTextTranslation}
                      label={values[FieldName.enableTextTranslation] ? "Enabled" : "Disabled"}
                    />
                  }
                />
                <LabelValue
                  label="File Translation"
                  value={
                    <SwitchField
                      name={FieldName.enableFileTranslation}
                      label={values[FieldName.enableFileTranslation] ? "Enabled" : "Disabled"}
                    />
                  }
                />
                <LabelValue
                  label="Human Escalation"
                  value={
                    <SwitchField
                      name={FieldName.enableHumanEscalation}
                      label={values[FieldName.enableHumanEscalation] ? "Enabled" : "Disabled"}
                    />
                  }
                />
                <SelectOneField
                  name={FieldName.billingType}
                  label="MT Billing Type"
                  options={mtBillingTypeOptions}
                  required={true}
                />
                <TextInputField
                  name={FieldName.maxRequestsPerUserPerDay}
                  label="Maximum number of requests per user per day"
                  type="number"
                  required={true}
                />
                <TextInputField
                  name={FieldName.maxCharactersPerRequest}
                  label="Maximum characters per text translation request"
                  type="number"
                  required={true}
                />
                <TextInputField
                  name={FieldName.maxFileSizeMB}
                  label="Maximum file size (MB)"
                  type="number"
                  required={true}
                />
                <TextInputField
                  name={FieldName.maxFilesPerRequest}
                  label="Maximum number of files per request"
                  type="number"
                  required={true}
                />
                <SelectManyAutocompleteField
                  name={FieldName.supportedLanguages}
                  label="Supported Languages"
                  options={languageOptions}
                  required={true}
                />
              </Stack>
            </DrawerBody>
            <DrawerActions>
              <LoadingButton isLoading={isMutating}>Save</LoadingButton>
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </DrawerActions>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export { MachineTranslationSettingsPanel };
