import * as React from "react";
import { Field, useFormikContext } from "formik";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "react-query";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { SelectOneAutocompleteField } from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import { SelectManyAutocompleteField } from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { CustomField } from "components/formikFields/CustomField/CustomField";
import { CheckboxWithLabel } from "formik-mui";
import {
  ClientContactSummary,
  CustomFieldDefinition,
  JobRequest,
  JobRequestFile,
  JobRequestFileCategory,
  JobRequestFileLink,
  JobRequestsService,
  JobRequestType,
  UtilsService
} from "gen/clients";
import Stack from "@mui/material/Stack";
import { useDialogState } from "hooks/useDialogState";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { AddJobRequestFilesButton } from "./components/AddJobRequestFilesButton/AddJobRequestFilesButton";
import { FileChips } from "./components/FileChips/FileChips";
import { ClientCompanySelectField } from "components/ClientCompanySelectField/ClientCompanySelectField";
import { SelectOption } from "@mui/base";
import Button from "@mui/material/Button";
import { CreateClientContactDialog } from "./components/CreateClientContactDialog/CreateClientContactDialog";
import FormHelperText from "@mui/material/FormHelperText";
import FileLinksField from "./components/FileLinksField/FileLinksField";
import { Tab, Tabs } from "@mui/material";
import { useParams } from "react-router-dom";
import { CheckboxField } from "../../../../../components/formikFields/CheckboxField/CheckboxField";
import { SELECT_OPTION_COMPARATOR } from "../../../../../utils/stringUtils";

interface Props {
  jobRequest: JobRequest;
  onClientChange: (clientId: string) => void;
  onClientContactAdded: (clientContactId: number) => void;
  clientContacts?: ClientContactSummary[];
  allowedEmailDomains?: string[];
  projectCustomFieldDefinitions?: CustomFieldDefinition[];
  userCustomFieldDefinitions?: CustomFieldDefinition[];
  instructionalText?: string;
  footerMessage?: string;
}

export enum ContentInputMethod {
  FILES = "FILES",
  TEXT = "TEXT",
  FILELINKS = "FILELINKS"
}

export enum FieldNames {
  client = "client",
  projectName = "projectName",
  clientContactId = "clientContactId",
  sourceLanguage = "sourceLanguage",
  targetLanguages = "targetLanguages",
  inputMethod = "inputMethod",
  sourceDocumentFiles = "sourceDocumentFiles",
  textInput = "textInput",
  includeReferenceFiles = "includeReferenceFiles",
  referenceFiles = "referenceFiles",
  notes = "notes",
  provideStandardAndRushQuotes = "provideStandardAndRushQuotes",
  sourceFileLinks = "sourceDocumentFileLinks",
  xtrfService = "xtrfService"
}

export interface FormValues {
  [FieldNames.client]: SelectOption<string> | null;
  [FieldNames.projectName]: string;
  [FieldNames.clientContactId]: string | number;
  [FieldNames.sourceLanguage]: SelectOption<string | number> | null;
  [FieldNames.targetLanguages]: SelectOption<string | number>[];
  [FieldNames.inputMethod]: ContentInputMethod;
  [FieldNames.sourceDocumentFiles]: JobRequestFile[] | undefined;
  [FieldNames.textInput]: string;
  [FieldNames.includeReferenceFiles]: boolean;
  [FieldNames.referenceFiles]: JobRequestFile[] | undefined;
  [FieldNames.notes]: string;
  [FieldNames.provideStandardAndRushQuotes]: boolean;
  [FieldNames.sourceFileLinks]: JobRequestFileLink[] | undefined;
  [FieldNames.xtrfService]: string | number;
}

const JobRequestForm: React.FC<Props> = ({
  jobRequest,
  onClientChange,
  onClientContactAdded,
  clientContacts,
  allowedEmailDomains,
  projectCustomFieldDefinitions,
  userCustomFieldDefinitions,
  instructionalText,
  footerMessage
}) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext<FormValues>();
  const jobRequestId = jobRequest.id;

  const { tabId = jobRequest.fileLinks ? ContentInputMethod.FILELINKS : ContentInputMethod.FILES } = useParams<{
    tabId: string;
  }>();
  const [selectedTabId, setSelectedTabId] = React.useState(tabId);
  const [deleteFileConfirmationOpen, openDeleteFileConfirmation, closeDeleteFileConfirmation] = useDialogState();
  const [createClientContactDialogOpen, openCreateClientContactDialog, closeCreateClientContactDialog] =
    useDialogState();

  const [fileToDelete, setFileToDelete] = React.useState<JobRequestFile>();

  const {
    data: languages,
    isLoading: isLanguagesLoading,
    error: languagesError
  } = useQuery("listLanguages", {
    queryFn: UtilsService.listLanguages
  });

  const clientContactOptions: SelectOption<string>[] = React.useMemo(
    () =>
      clientContacts
        ?.map(cc => ({
          label: `${cc.firstName} ${cc.lastName} (${cc.email})`,
          value: `${cc.id}`
        }))
        .sort((o1, o2) => o1.label.localeCompare(o2.label)) || [],
    [clientContacts]
  );
  const languageOptions = React.useMemo(
    () =>
      languages?.sort((l1, l2) => l1.name.localeCompare(l2.name)).map(l => ({ label: l.name, value: `${l.id}` })) || [],
    [languages]
  );

  const {
    data: xtrfServices,
    isLoading: areXtrfServicesLoading,
    error: xtrfServicesError
  } = useQuery([], {
    queryFn: UtilsService.listXtrfServices
  });

  const xtrfServiceOpions = React.useMemo(
    () => [
      { label: "--- System Default ---", value: "" },
      ...(xtrfServices || [])
        .filter(s => s.active)
        .map(s => ({ label: s.name, value: s.id }))
        .sort(SELECT_OPTION_COMPARATOR)
    ],
    [xtrfServices]
  );

  const {
    mutate: deleteFile,
    error: deleteFileError,
    isLoading: isDeleteFileLoading
  } = useMutation({
    mutationFn: JobRequestsService.deleteFile
  });

  const onDeleteFileClick = React.useCallback(
    (file: JobRequestFile) => {
      setFileToDelete(file);
      openDeleteFileConfirmation();
      validateField(
        file.category === JobRequestFileCategory.SOURCE_DOCUMENT
          ? FieldNames.sourceDocumentFiles
          : FieldNames.referenceFiles
      );
    },
    [openDeleteFileConfirmation, validateField]
  );

  const sourceDocumentFiles = values[FieldNames.sourceDocumentFiles];
  const referenceFiles = values[FieldNames.referenceFiles];
  const onConfirmFileDelete = React.useCallback(() => {
    if (!fileToDelete) {
      window.console.error("File to delete is not set");
      return;
    }
    deleteFile(
      { jobRequestId, requestBody: fileToDelete },
      {
        onSuccess: () => {
          closeDeleteFileConfirmation();
          if (fileToDelete.category === JobRequestFileCategory.SOURCE_DOCUMENT) {
            setFieldValue(
              FieldNames.sourceDocumentFiles,
              ((sourceDocumentFiles || []) as JobRequestFile[]).filter(f => f.fileKey !== fileToDelete.fileKey)
            );
          } else if (fileToDelete.category === JobRequestFileCategory.REFERENCE) {
            setFieldValue(
              FieldNames.referenceFiles,
              ((referenceFiles || []) as JobRequestFile[]).filter(f => f.fileKey !== fileToDelete.fileKey)
            );
          }
        }
      }
    );
  }, [
    closeDeleteFileConfirmation,
    deleteFile,
    fileToDelete,
    jobRequestId,
    referenceFiles,
    setFieldValue,
    sourceDocumentFiles
  ]);

  const onAddSourceDocuments = React.useCallback(
    (jobRequestFiles: JobRequestFile[]) => {
      setFieldValue(FieldNames.sourceDocumentFiles, [
        ...((sourceDocumentFiles || []) as JobRequestFile[]),
        ...jobRequestFiles
      ]);
    },
    [setFieldValue, sourceDocumentFiles]
  );

  const onAddReferenceFiles = React.useCallback(
    (jobRequestFiles: JobRequestFile[]) => {
      setFieldValue(FieldNames.referenceFiles, [...((referenceFiles || []) as JobRequestFile[]), ...jobRequestFiles]);
    },
    [setFieldValue, referenceFiles]
  );

  const onCreateClientContactSuccess = React.useCallback(
    (clientContactId: number) => {
      setFieldValue(FieldNames.clientContactId, `${clientContactId}`);
      onClientContactAdded(clientContactId);
      closeCreateClientContactDialog();
    },
    [closeCreateClientContactDialog, onClientContactAdded, setFieldValue]
  );

  const helperTextTargetLanguages = React.useMemo(() => {
    if (jobRequest.aiAnalysis && jobRequest.aiAnalysis.targetLanguages?.length) {
      return `What language(s) would you like the documents translated into? \
        The following languages were found in text: ${jobRequest.aiAnalysis?.targetLanguages.map(l => l.name).join(", ")}`;
    }

    return "What language(s) would you like the documents translated into?";
  }, [jobRequest]);

  React.useEffect(() => {
    setFieldValue(FieldNames.inputMethod, selectedTabId);
  }, [setFieldValue, selectedTabId]);

  return (
    <>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h6" mb={1}>
          Client Information
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <ClientCompanySelectField name="client" onChange={onClientChange} />
          </Grid>
        </Grid>
        {values[FieldNames.client] && (
          <Grid container spacing={4} mt={0}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={0}>
                <SelectOneField
                  name={FieldNames.clientContactId}
                  options={clientContactOptions}
                  label="Client Contact"
                  required={true}
                />
                <FormHelperText>
                  Select an existing contact or
                  <Button onClick={openCreateClientContactDialog} sx={{ px: "3px" }}>
                    Create New Contact
                  </Button>
                  for: {jobRequest.requestorFirstName} {jobRequest.requestorLastName} ({jobRequest.requestorEmail}).
                </FormHelperText>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
      {instructionalText && (
        <Box mb={3}>
          <Alert severity="info">
            {instructionalText.split("\n").map(line => (
              <p key={line}>{line}</p>
            ))}
          </Alert>
        </Box>
      )}
      {/* Document info section */}
      <Box>
        <Typography variant="h6" mb={1}>
          Translation Content
        </Typography>

        <Tabs
          variant="scrollable"
          value={selectedTabId || undefined}
          onChange={(event, value) => {
            setSelectedTabId(value);
            // history.push(`${PagePath.systemSettings}/${value}`)
          }}
        >
          <Tab value={ContentInputMethod.FILES} label="Files" />
          <Tab value={ContentInputMethod.FILELINKS} label="File Links" />
        </Tabs>

        {selectedTabId === ContentInputMethod.FILES && (
          <>
            <Stack sx={{ mb: 3, mt: 1 }} spacing={2}>
              <Box>
                <FileChips
                  jobRequestId={jobRequestId}
                  files={sourceDocumentFiles as JobRequestFile[]}
                  isPreviewEnabled={true}
                  onDeleteClick={onDeleteFileClick}
                />
                {errors[FieldNames.sourceDocumentFiles] && (
                  <Box color="error.main" fontSize="0.75rem">
                    {errors[FieldNames.sourceDocumentFiles]}
                  </Box>
                )}
              </Box>
              <Box>
                <AddJobRequestFilesButton
                  jobRequestId={jobRequestId}
                  category={JobRequestFileCategory.SOURCE_DOCUMENT}
                  onSuccess={onAddSourceDocuments}
                />
              </Box>
            </Stack>
            <Box>
              <CheckboxField name={FieldNames.includeReferenceFiles} Label={{ label: "Upload reference files" }} />
              {values[FieldNames.includeReferenceFiles] && (
                <Stack spacing={2}>
                  <Box>
                    <FileChips files={referenceFiles as JobRequestFile[]} onDeleteClick={onDeleteFileClick} />
                    {errors[FieldNames.referenceFiles] && (
                      <Box color="error.main" fontSize="0.75rem">
                        {errors[FieldNames.referenceFiles]}
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <AddJobRequestFilesButton
                      jobRequestId={jobRequestId}
                      category={JobRequestFileCategory.REFERENCE}
                      onSuccess={onAddReferenceFiles}
                    />
                  </Box>
                </Stack>
              )}
            </Box>
          </>
        )}

        {selectedTabId === ContentInputMethod.FILELINKS && (
          <Box marginTop={1}>
            <FileLinksField name={FieldNames.sourceFileLinks} />
          </Box>
        )}
      </Box>

      {/* Languages section */}
      <Box marginTop={5}>
        <Typography variant="h6" marginBottom={1}>
          Project Information
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextInputField name={FieldNames.projectName} label="Project name" multiline={false} required={true} />
          </Grid>
          <Grid item xs={12}>
            <SelectOneAutocompleteField
              name={FieldNames.sourceLanguage}
              label="Source Language"
              helperText="What language are the documents written in?"
              options={languageOptions}
              isLoading={isLanguagesLoading}
              isError={!!languagesError}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectManyAutocompleteField
              name={FieldNames.targetLanguages}
              label="Target Languages"
              helperText={helperTextTargetLanguages}
              options={languageOptions}
              isLoading={isLanguagesLoading}
              isError={!!languagesError}
              required={true}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOneField
              name={FieldNames.xtrfService}
              label="XTRF Service"
              options={xtrfServiceOpions}
              isLoading={areXtrfServicesLoading}
              isError={!!xtrfServicesError}
            />
          </Grid>
          {/* Project custom fields */}
          {projectCustomFieldDefinitions &&
            projectCustomFieldDefinitions.map(customFieldDefinition => (
              <React.Fragment key={customFieldDefinition.name}>
                {customFieldDefinition.visible !== false && (
                  <Grid item xs={12}>
                    <CustomField customFieldDefinition={customFieldDefinition} />
                  </Grid>
                )}
              </React.Fragment>
            ))}
          {/* Notes */}
          <Grid item xs={12}>
            <TextInputField
              name={FieldNames.notes}
              label="Note/Comments"
              multiline={true}
              rows={8}
              variant="outlined"
            />
          </Grid>
          {jobRequest.requestType === JobRequestType.QUOTE_REQUEST && (
            <Grid item xs={12}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={FieldNames.provideStandardAndRushQuotes}
                helperText="Provide Standard and Rush Quotes"
                Label={{
                  label: "Provide Standard and Rush Quotes"
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      {footerMessage && (
        <Alert severity="warning" sx={{ mt: 5 }}>
          {footerMessage.split("\n").map(line => (
            <div key={line}>
              {line}
              <br />
            </div>
          ))}
        </Alert>
      )}
      {deleteFileConfirmationOpen && fileToDelete && (
        <ConfirmationDialog
          title="Delete File"
          content={`Are you sure that you want to delete ${fileToDelete.fileName} file?`}
          onClose={closeDeleteFileConfirmation}
          onConfirm={onConfirmFileDelete}
          isLoading={isDeleteFileLoading}
          error={!!deleteFileError && <ApiErrorMessage apiError={deleteFileError} />}
        />
      )}
      {createClientContactDialogOpen && values[FieldNames.client]?.value && (
        <CreateClientContactDialog
          clientId={values[FieldNames.client]!.value}
          email={jobRequest.requestorEmail}
          firstName={jobRequest.requestorFirstName}
          lastName={jobRequest.requestorLastName}
          address1={jobRequest.contactAddress?.address1}
          address2={jobRequest.contactAddress?.address2}
          city={jobRequest.contactAddress?.city}
          province={jobRequest.contactAddress?.province?.id}
          zip={jobRequest.contactAddress?.zipCode}
          country={jobRequest.contactAddress?.country?.id}
          phone={jobRequest.contactAddress?.phoneNumber}
          allowedEmailDomains={allowedEmailDomains}
          userCustomFieldDefinitions={userCustomFieldDefinitions}
          onSuccess={onCreateClientContactSuccess}
          onClose={closeCreateClientContactDialog}
        />
      )}
    </>
  );
};

export { JobRequestForm };
