import * as React from "react";
import { useMutation, useQuery } from "react-query";
import { ApiError, SystemSettings, SystemSettingsService } from "gen/clients";
import { Form, Formik } from "formik";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "../../../../components/LoadingButton/LoadingButton";
import { SnackbarApiError } from "../../../../components/SnackbarApiError/SnackbarApiError";
import { SnackbarAlert } from "../../../../components/SnackbarAlert/SnackbarAlert";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { OpenAISettingsCard } from "./components/OpenAISettingsCard";

enum FieldNames {
  pricePerPage = "pricePerPage",
  pageCountToEscalateToHuman = "pageCountToEscalateToHuman",
  quoteExpirationPeriod = "quoteExpirationPeriod"
}

interface FormValues {
  [FieldNames.pricePerPage]: string | number;
  [FieldNames.pageCountToEscalateToHuman]: string | number;
  [FieldNames.quoteExpirationPeriod]: string | number;
}

const DEFAULT_PRICE_PER_PAGE = 125;
const PAGE_COUNT_ESCALATION_THRESHOLD = 30;
const QUOTE_EXPIRATION_DAYS = 30;

const AutoQuoteSettingsTab: React.FC = () => {
  const {
    data: systemSettings,
    isLoading,
    error,
    refetch
  } = useQuery<SystemSettings, ApiError>(["getSystemSettings"], {
    queryFn: SystemSettingsService.getSystemSettings
  });

  const {
    mutateAsync: save,
    isLoading: isSaving,
    error: saveError,
    isSuccess: saveSuccess
  } = useMutation(SystemSettingsService.updateSystemSettings);

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      if (!systemSettings) {
        return;
      }

      await save({
        requestBody: {
          ...systemSettings,
          autoQuoteSettings: {
            pricePerPage: +values.pricePerPage,
            pageCountEscalationThreshold: +values.pageCountToEscalateToHuman,
            quoteExpiration: +values.quoteExpirationPeriod
          }
        }
      });
      refetch();
    },
    [refetch, save, systemSettings]
  );

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ApiErrorMessage apiError={error} />;
  }

  if (systemSettings) {
    return (
      <>
        <Formik
          initialValues={{
            [FieldNames.pricePerPage]: systemSettings?.autoQuoteSettings?.pricePerPage || DEFAULT_PRICE_PER_PAGE,
            [FieldNames.pageCountToEscalateToHuman]:
              systemSettings?.autoQuoteSettings?.pageCountEscalationThreshold || PAGE_COUNT_ESCALATION_THRESHOLD,
            [FieldNames.quoteExpirationPeriod]:
              systemSettings?.autoQuoteSettings?.quoteExpiration || QUOTE_EXPIRATION_DAYS
          }}
          onSubmit={onSubmit}
        >
          <Form>
            <Stack spacing={4} mt={2}>
              <Card sx={{ p: 2 }}>
                <FormControl fullWidth sx={{ p: 2 }}>
                  <FormGroup>
                    <Box mb={2}>
                      <TextInputField
                        name={FieldNames.pricePerPage}
                        label="Price Per Page"
                        helperText="Enter the cost per page in USD"
                        required
                      />
                    </Box>
                    <Box mb={2}>
                      <TextInputField
                        name={FieldNames.pageCountToEscalateToHuman}
                        label="Page Count To Escalate To Human"
                        helperText="Specify the number of pages at which a human review is required"
                        required
                      />
                    </Box>
                    <Box mb={2}>
                      <TextInputField
                        name={FieldNames.quoteExpirationPeriod}
                        label="Quote Expiration Period"
                        helperText="Quote expiration period in days"
                        required
                      />
                    </Box>
                  </FormGroup>
                </FormControl>
                <Box>
                  <LoadingButton isLoading={isSaving}>Save</LoadingButton>
                </Box>
              </Card>
            </Stack>
          </Form>
        </Formik>
        {saveError && <SnackbarApiError error={saveError} />}
        {saveSuccess && <SnackbarAlert message="Operation completed successfully" severity="success" />}

        <OpenAISettingsCard systemSettings={systemSettings} systemSettingsRefetch={refetch} />
      </>
    );
  }

  return <></>;
};

export { AutoQuoteSettingsTab };
