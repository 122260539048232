import * as React from "react";
import { useQuery } from "react-query";
import { ClientsContactsService, LinkedClient } from "gen/clients";
import { DataTable } from "components/DataTable/DataTable";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDialogState } from "hooks/useDialogState";
import { AddLinkedClientDialog } from "./AddLinkedClientDialog/AddLinkedClientDialog";
import { Alert, Box } from "@mui/material";
import { RemoveLinkedClientDialog } from "./RemoveLinkedClientDialog/RemoveLinkedClientDialog";

interface Props {
  clientContactId: number;
  clientId: string;
}

const LinkedClientsTab: React.FC<Props> = ({ clientContactId, clientId }) => {
  const [selectedRowIndex, setSelectedRowIndex] = React.useState<number | null>(null);
  const [isAddLinkedClientDialogOpen, openAddLinkedClientDialog, closeAddLinkedClientDialog] = useDialogState();
  const [isRemoveLinkedClientDialogOpen, openRemoveLinkedClientDialog, closeRemoveLinkedClientDialog] =
    useDialogState();
  const { data, isFetching, error, refetch } = useQuery(["listLinkedClients", clientContactId], {
    queryFn: () => ClientsContactsService.listLinkedClients({ clientContactId })
  });

  const onAddLinkedClientSuccess = React.useCallback(() => {
    refetch();
    closeAddLinkedClientDialog();
  }, [closeAddLinkedClientDialog, refetch]);

  const onRemoveLinkedClientSuccess = React.useCallback(() => {
    refetch();
    closeRemoveLinkedClientDialog();
  }, [closeRemoveLinkedClientDialog, refetch]);

  const linkedClients = React.useMemo(
    () => data?.sort((a, b) => (a.clientName || "").localeCompare(b.clientName || "")) || [],
    [data]
  );

  return (
    <>
      <Stack spacing={1}>
        <Alert severity="info">
          This page lists all clients that are linked to this client contact. Linking a client to a client contact will
          allow the client contact to submit projects for that client.
        </Alert>
        <Box>
          <Button variant="contained" onClick={openAddLinkedClientDialog}>
            Add Linked Client
          </Button>
        </Box>
        <DataTable
          data={linkedClients}
          rowKey={(row: LinkedClient) => row.clientId}
          isLoading={isFetching}
          error={!!error && <ApiErrorMessage apiError={error} />}
          columns={[
            {
              id: "clientId",
              title: "Client ID",
              cell: (row: LinkedClient) => row.clientId
            },
            {
              id: "clientName",
              title: "Client Name",
              cell: (row: LinkedClient) => row.clientName
            }
          ]}
          rowActions={[
            {
              title: "Remove",
              color: "error.main",
              action: (_, index) => {
                setSelectedRowIndex(index);
                openRemoveLinkedClientDialog();
              }
            }
          ]}
        />
      </Stack>
      {isAddLinkedClientDialogOpen && (
        <AddLinkedClientDialog
          linkedClients={linkedClients}
          clientContactId={clientContactId}
          clientId={clientId}
          onClose={closeAddLinkedClientDialog}
          onSuccess={onAddLinkedClientSuccess}
        />
      )}
      {isRemoveLinkedClientDialogOpen && selectedRowIndex !== null && (
        <RemoveLinkedClientDialog
          clientContactId={clientContactId}
          removeAtIndex={selectedRowIndex}
          linkedClients={linkedClients}
          onClose={closeRemoveLinkedClientDialog}
          onSuccess={onRemoveLinkedClientSuccess}
        />
      )}
    </>
  );
};

export { LinkedClientsTab };
