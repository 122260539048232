import * as React from "react";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import {
  ApplicationsService,
  ClientConfig,
  ClientsService,
  LanguagesService,
  LltsLanguage,
  MtBillingType
} from "gen/clients";
import { useMutation, useQuery } from "react-query";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { PageLoading } from "components/PageLoading/PageLoading";
import { SelectOption } from "@mui/base";
import { SelectManyAutocompleteField } from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { ApplicationId } from "types/ApplicationId";
import { LabelValue } from "../../../../../../components/LabelValue/LabelValue";
import { SwitchField } from "../../../../../../components/formikFields/SwitchField/SwitchField";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { mtBillingTypeOptions } from "pages/SystemSettingsPage/tabs/ApplcationsPage/utils/MtUtils";
import { FormFieldObserver } from "components/formikFields/FormFieldObserver/FormFieldObserver";
import { default as dayjs } from "dayjs";
import {
  DATE_TIME_PICKER_FORMAT,
  DateTimePickerField
} from "components/formikFields/DateTimePickerField/DateTimePickerField";
import { Box } from "@mui/material";

interface Props {
  clientConfig: ClientConfig;
  onSaveSuccess: () => void;
}

enum FieldName {
  enableTextTranslation = "enableTextTranslation",
  enableFileTranslation = "enableFileTranslation",
  enableHumanEscalation = "enableHumanEscalation",
  maxRequestsPerUserPerDay = "maxRequestsPerUserPerDay",
  maxCharactersPerRequest = "maxCharactersPerRequest",
  maxFileSizeMB = "maxFileSizeMB",
  maxFilesPerRequest = "maxFilesPerRequest",
  supportedLanguages = "supportedLanguages",
  billingType = "billingType",
  trialExpirationDate = "trialExpirationDate"
}

interface FormValues {
  [FieldName.enableTextTranslation]: boolean;
  [FieldName.enableFileTranslation]: boolean;
  [FieldName.enableHumanEscalation]: boolean;
  [FieldName.maxRequestsPerUserPerDay]: number;
  [FieldName.maxCharactersPerRequest]: number;
  [FieldName.maxFileSizeMB]: number;
  [FieldName.maxFilesPerRequest]: number;
  [FieldName.supportedLanguages]: SelectOption<string>[];
  [FieldName.billingType]: string;
  [FieldName.trialExpirationDate]?: string;
}

const MachineTranslationTab: React.FC<Props> = ({ clientConfig, onSaveSuccess }) => {
  const [isFreeTrial, setIsFreeTrial] = React.useState(false);

  const {
    data: mtApp,
    isLoading: isMtAppLoading,
    error: mtAppError
  } = useQuery(["getMtApp"], {
    enabled: !clientConfig.machineTranslationSettings,
    queryFn: () => ApplicationsService.getApplication({ id: ApplicationId.machineTranslation })
  });

  const {
    data: lltsLanguages,
    isLoading: areLlltsLanguagesLoading,
    error: lltsLanguagesError
  } = useQuery(["getMtLltsLanguages"], {
    queryFn: () =>
      LanguagesService.listLltsLanguages({
        intentoIdExists: true,
        azureIdExists: true
      })
  });

  const lltsLanguageById: Record<string, LltsLanguage> = React.useMemo(
    () =>
      (lltsLanguages || []).reduce(
        (prevValue, currentValue) => {
          // eslint-disable-next-line no-param-reassign
          prevValue[currentValue.id] = currentValue;
          return prevValue;
        },
        {} as Record<string, LltsLanguage>
      ),
    [lltsLanguages]
  );

  const languageOptions = React.useMemo(
    () =>
      (lltsLanguages?.map(l => ({ label: l.name, value: l.id })) || []).sort((o1, o2) =>
        o1.label.localeCompare(o2.label)
      ),
    [lltsLanguages]
  );

  const {
    mutateAsync,
    error: mutateError,
    isLoading: isMutating
  } = useMutation(ClientsService.updateClientConfig, { onSuccess: onSaveSuccess });

  const initialValues = React.useMemo(() => {
    const machineTranslationSettings = clientConfig.machineTranslationSettings || mtApp?.settings;
    return {
      [FieldName.billingType]: machineTranslationSettings?.billingType || MtBillingType.MONTHLY,
      [FieldName.trialExpirationDate]: machineTranslationSettings?.trialExpirationDate
        ? dayjs(machineTranslationSettings.trialExpirationDate).format(DATE_TIME_PICKER_FORMAT)
        : "",
      [FieldName.enableTextTranslation]: !machineTranslationSettings?.disableTextTranslation,
      [FieldName.enableFileTranslation]: !machineTranslationSettings?.disableFileTranslation,
      [FieldName.enableHumanEscalation]: !machineTranslationSettings?.disableHumanEscalation,
      [FieldName.maxRequestsPerUserPerDay]: machineTranslationSettings?.maxRequestsPerUserPerDay || 0,
      [FieldName.maxCharactersPerRequest]: machineTranslationSettings?.maxCharactersPerRequest || 0,
      [FieldName.maxFileSizeMB]: machineTranslationSettings?.maxFileSizeMB || 0,
      [FieldName.maxFilesPerRequest]: machineTranslationSettings?.maxFilesPerRequest || 0,
      [FieldName.supportedLanguages]: (
        machineTranslationSettings?.supportedLltsLanguages
          ?.filter(langId => lltsLanguageById[langId] && !lltsLanguageById[langId]?.isDisabled)
          .map(langId => ({
            label: lltsLanguageById[langId]?.name || langId,
            value: langId
          })) || []
      ).sort((o1, o2) => o1.label.localeCompare(o2.label))
    };
  }, [clientConfig.machineTranslationSettings, lltsLanguageById, mtApp?.settings]);

  const validate = React.useCallback((formValues: FormValues) => {
    const errors: Record<string, string> = {};
    if (!formValues[FieldName.enableTextTranslation] && !formValues[FieldName.enableFileTranslation]) {
      errors[FieldName.enableTextTranslation] = "Either Text or File translation must be enabled";
      errors[FieldName.enableFileTranslation] = "Either Text or File translation must be enabled";
    }

    if (formValues[FieldName.billingType] === MtBillingType.FREE_TRIAL) {
      const trialExpirationValue = dayjs(formValues[FieldName.trialExpirationDate], DATE_TIME_PICKER_FORMAT).toDate();
      if (!trialExpirationValue) {
        errors[FieldName.trialExpirationDate] = "Expiration time is required for Free Trial";
      } else if (trialExpirationValue <= new Date()) {
        errors[FieldName.trialExpirationDate] = "Expiration time cannot be in the past";
      }
    }
    return errors;
  }, []);

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const selectedLanguageOptions = values[FieldName.supportedLanguages];
      const selectedLanguageIds = selectedLanguageOptions.map(l => l.value);
      const trialExpirationValue =
        values[FieldName.billingType] === MtBillingType.FREE_TRIAL
          ? dayjs(values[FieldName.trialExpirationDate], DATE_TIME_PICKER_FORMAT).toDate()
          : undefined;
      await mutateAsync({
        id: clientConfig.id,
        requestBody: {
          ...clientConfig,
          machineTranslationSettings: {
            billingType: values[FieldName.billingType] as MtBillingType,
            trialExpirationDate: trialExpirationValue?.toISOString(),
            disableTextTranslation: !values[FieldName.enableTextTranslation],
            disableFileTranslation: !values[FieldName.enableFileTranslation],
            disableHumanEscalation: !values[FieldName.enableHumanEscalation],
            maxCharactersPerRequest: values[FieldName.maxCharactersPerRequest],
            maxRequestsPerUserPerDay: values[FieldName.maxRequestsPerUserPerDay],
            maxFileSizeMB: values[FieldName.maxFileSizeMB],
            maxFilesPerRequest: values[FieldName.maxFilesPerRequest],
            supportedLltsLanguages: selectedLanguageIds
          }
        }
      });
    },
    [clientConfig, mutateAsync]
  );

  const onResetToDefaultClick = React.useCallback(async () => {
    await mutateAsync({
      id: clientConfig.id,
      requestBody: {
        ...clientConfig,
        machineTranslationSettings: undefined
      }
    });
  }, [clientConfig, mutateAsync]);

  const handleBillingTypeChange = React.useCallback((billingType: string) => {
    setIsFreeTrial(billingType === MtBillingType.FREE_TRIAL);
  }, []);

  const isLoading = isMtAppLoading || areLlltsLanguagesLoading;
  const error = mtAppError || lltsLanguagesError;

  if (isLoading) {
    return <PageLoading />;
  }

  if (error) {
    return <ApiErrorMessage apiError={error} />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} enableReinitialize={true}>
      {({ isSubmitting, values }) => (
        <Form noValidate={true} autoComplete="off">
          {mutateError && <SnackbarApiError error={mutateError} />}
          <FormFieldObserver fieldName={FieldName.billingType} onChange={handleBillingTypeChange} />
          <Stack spacing={3}>
            <LabelValue
              label="Text Translation"
              value={
                <SwitchField
                  name={FieldName.enableTextTranslation}
                  label={values[FieldName.enableTextTranslation] ? "Enabled" : "Disabled"}
                />
              }
            />
            <LabelValue
              label="File Translation"
              value={
                <SwitchField
                  name={FieldName.enableFileTranslation}
                  label={values[FieldName.enableFileTranslation] ? "Enabled" : "Disabled"}
                />
              }
            />
            <LabelValue
              label="Human Escalation"
              value={
                <SwitchField
                  name={FieldName.enableHumanEscalation}
                  label={values[FieldName.enableHumanEscalation] ? "Enabled" : "Disabled"}
                />
              }
            />
            <SelectOneField
              name={FieldName.billingType}
              label="MT Billing Type"
              options={mtBillingTypeOptions}
              required={true}
            />
            {isFreeTrial && (
              <Box>
                <DateTimePickerField
                  name={FieldName.trialExpirationDate}
                  label="Trial Expiration Time"
                  required={true}
                  disablePast={true}
                />
              </Box>
            )}
            <TextInputField
              name={FieldName.maxRequestsPerUserPerDay}
              label="Maximum number of requests per user per day"
              type="number"
              required={true}
            />
            <TextInputField
              name={FieldName.maxCharactersPerRequest}
              label="Maximum characters per text translation request"
              type="number"
              required={true}
            />
            <TextInputField
              name={FieldName.maxFileSizeMB}
              label="Maximum file size (MB)"
              type="number"
              required={true}
            />
            <TextInputField
              name={FieldName.maxFilesPerRequest}
              label="Maximum number of files per request"
              type="number"
              required={true}
            />
            <SelectManyAutocompleteField
              name={FieldName.supportedLanguages}
              label="Supported Languages"
              options={languageOptions}
              required={true}
            />
            <Stack direction="row" spacing={1}>
              <LoadingButton isLoading={isSubmitting}>Save</LoadingButton>
              <LoadingButton
                isLoading={isMutating && !isSubmitting}
                color="secondary"
                onClick={onResetToDefaultClick}
                variant="text"
              >
                Reset to default
              </LoadingButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export { MachineTranslationTab };
