import * as React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  helperText?: string;
  validationRegex?: string;
  validationMessage?: string;
  variant?: "outlined" | "filled" | "standard";
}

const TextInputField: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  helperText,
  multiline,
  rows,
  minRows,
  maxRows,
  type,
  validationRegex,
  validationMessage,
  variant = "standard"
}) => {
  const validate = React.useCallback(
    (value: string | undefined) => {
      if (required && !value) {
        return "Required";
      }
      if (validationRegex && value && !value.match(new RegExp(validationRegex))) {
        return validationMessage || "Invalid format";
      }
      return undefined;
    },
    [required, validationRegex, validationMessage]
  );
  return (
    <Field
      name={name}
      component={TextField}
      placeholder={placeholder || label}
      label={label}
      variant={variant}
      required={required}
      disabled={disabled}
      type={type}
      multiline={multiline}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      fullWidth
      validate={validate}
      helperText={helperText}
    />
  );
};
export { TextInputField };
