import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { Form, Formik, FormikProps } from "formik";
import {
  ClientsService,
  CustomFieldDefinition,
  CustomFieldValue,
  JobRequestFileCategory,
  JobRequestOrigination,
  JobRequestsService,
  SubmitJobRequestToXtrfRequest
} from "gen/clients";
import { ContentInputMethod, FieldNames, FormValues, JobRequestForm } from "./JobRequestForm/JobRequestForm";
import { useMutation, useQuery } from "react-query";
import { ScrollToFieldError } from "components/formikFields/ScrollToFieldError/ScrollToFieldError";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import Box from "@mui/material/Box";
import { JobRequestFormValidator } from "./JobRequestForm/JobRequestFormValidator";
import { describeJobRequestSubmitAction } from "../../utils/JobRequestUtils";
import { SelectOption } from "@mui/base";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { sanitizeFieldName } from "components/formikFields/formikUtils";

interface Props {
  projectName?: string;
  jobRequestId: string;
  onClose: () => void;
  onSuccess: () => void;
  title?: string;
  suppressClientNotification?: boolean;
}

function makeTwoDigitNumber(value: number): string {
  return value < 10 ? `0${value}` : `${value}`;
}

/**
 * Generates project name based on email
 * @param contactEmail
 */
function createProjectName(contactEmail: string | undefined): string {
  const now = new Date();
  let projectNamePrefix = "";
  if (contactEmail) {
    projectNamePrefix = contactEmail.substring(0, contactEmail.indexOf("@"));
  }
  if (!projectNamePrefix) {
    projectNamePrefix = "project";
  }
  return `${projectNamePrefix}-${now.getFullYear()}${makeTwoDigitNumber(now.getMonth() + 1)}${makeTwoDigitNumber(
    now.getDate()
  )}-${makeTwoDigitNumber(now.getHours())}${makeTwoDigitNumber(now.getMinutes())}${makeTwoDigitNumber(
    now.getSeconds()
  )}`;
}

const ReviewJobRequestPanel: React.FC<Props> = ({
  jobRequestId,
  onClose,
  onSuccess,
  title,
  projectName,
  suppressClientNotification
}) => {
  const formikRef = React.useRef<FormikProps<FormValues>>(null);

  const {
    data: jobRequest,
    isLoading: isJobRequestLoading,
    error: jobRequestError,
    refetch: refetchJobRequest
  } = useQuery(["getJobRequest", jobRequestId], {
    queryFn: () => JobRequestsService.getJobRequest({ jobRequestId })
  });

  const [clientId, setClientId] = React.useState(jobRequest?.clientId);
  const sourceDocumentFiles = React.useMemo(
    () => jobRequest?.files?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT),
    [jobRequest?.files]
  );
  const referenceFiles = React.useMemo(
    () => jobRequest?.files?.filter(f => f.category === JobRequestFileCategory.REFERENCE),
    [jobRequest?.files]
  );
  const sourceFileLinks = React.useMemo(
    () => jobRequest?.fileLinks?.filter(f => f.category === JobRequestFileCategory.SOURCE_DOCUMENT),
    [jobRequest?.fileLinks]
  );
  const {
    data: client,
    error: clientError,
    isLoading: isClientLoading
  } = useQuery(["getClient", clientId, jobRequestId], {
    // jobRequestId is required to force the form to refresh for the same client
    enabled: !!clientId,
    queryFn: () => ClientsService.getClient({ clientId: clientId || "" }),
    cacheTime: 0
  });
  const {
    data: clientConfig,
    error: clientConfigError,
    isLoading: isClientConfigLoading
  } = useQuery(["getClientConfig", client?.id], {
    enabled: !!client?.id,
    queryFn: () => ClientsService.getClientConfig({ id: client?.id || "" })
  });
  const {
    data: customFieldDefinitions,
    isLoading: customFieldDefinitionsLoading,
    error: customFieldDefinitionsError
  } = useQuery(["getCustomFieldDefinitions", clientConfig?.id], {
    enabled: !!clientConfig?.id,
    queryFn: () =>
      ClientsService.listCustomFieldDefinitions({
        clientId: clientConfig?.id || ""
      })
  });
  const {
    data: clientContacts,
    isLoading: clientContactsLoading,
    error: clientContactsError,
    refetch: refetchClientContacts
  } = useQuery(["listClientContacts", clientId], {
    enabled: !!clientId,
    queryFn: () => ClientsService.listClientContacts({ clientId: clientId || "" })
  });
  // Set client ID once job request loads.
  React.useEffect(() => setClientId(jobRequest?.clientId), [jobRequest]);

  const projectCustomFieldDefinitions = React.useMemo(
    () => customFieldDefinitions?.filter(c => c.category === CustomFieldDefinition.category.PROJECT),
    [customFieldDefinitions]
  );

  const userCustomFieldDefinitions = React.useMemo(
    () => customFieldDefinitions?.filter(c => c.category === CustomFieldDefinition.category.USER),
    [customFieldDefinitions]
  );

  const matchingClientContact = React.useMemo(
    () => clientContacts?.items.find(contact => contact.email === jobRequest?.requestorEmail),
    [clientContacts, jobRequest]
  );

  const defaultProjectName = React.useMemo(() => {
    if (projectName) {
      return projectName;
    }
    return createProjectName(jobRequest?.requestorEmail);
  }, [projectName, jobRequest?.requestorEmail]);

  const isLoading =
    isJobRequestLoading ||
    isClientLoading ||
    isClientConfigLoading ||
    customFieldDefinitionsLoading ||
    clientContactsLoading;
  const apiError =
    jobRequestError || clientError || clientConfigError || customFieldDefinitionsError || clientContactsError;

  const initialValues: FormValues = React.useMemo(() => {
    const standardFieldValues = {
      [FieldNames.client]: client ? { label: client.companyName, value: client.id } : null,
      [FieldNames.clientContactId]: matchingClientContact ? `${matchingClientContact.id}` : "",
      [FieldNames.sourceLanguage]: jobRequest?.sourceLanguage
        ? { label: jobRequest.sourceLanguage.name, value: `${jobRequest.sourceLanguage.id}` }
        : null,
      [FieldNames.targetLanguages]:
        jobRequest?.targetLanguages?.map((l: { id: number | string; name: string }) => ({
          label: l.name,
          value: `${l.id}`
        })) || [],
      [FieldNames.notes]:
        jobRequest?.origination === JobRequestOrigination.EMAIL
          ? jobRequest?.description || ""
          : jobRequest?.notes || "",
      [FieldNames.sourceDocumentFiles]: sourceDocumentFiles,
      [FieldNames.sourceFileLinks]: sourceFileLinks,
      [FieldNames.textInput]: "",
      [FieldNames.includeReferenceFiles]: (referenceFiles?.length || 0) > 0,
      [FieldNames.referenceFiles]: referenceFiles,
      [FieldNames.projectName]: defaultProjectName,
      [FieldNames.provideStandardAndRushQuotes]:
        jobRequest?.provideStandardAndRushQuotes !== undefined ? jobRequest?.provideStandardAndRushQuotes : false,
      [FieldNames.inputMethod]: ContentInputMethod.FILES,
      [FieldNames.xtrfService]: clientConfig?.xtrfServiceId || ""
    };
    const customFieldValues: Record<string, string | string[]> = {};
    projectCustomFieldDefinitions?.forEach((customFieldDefinition: CustomFieldDefinition) => {
      customFieldValues[sanitizeFieldName(customFieldDefinition.name)] = customFieldDefinition.defaultValue || "";
    });
    jobRequest?.customFieldValues?.forEach((customFieldValue: CustomFieldValue) => {
      customFieldValues[sanitizeFieldName(customFieldValue.fieldName)] = customFieldValue.value || "";
    });
    return {
      ...standardFieldValues,
      ...customFieldValues
    };
  }, [
    jobRequest,
    client,
    projectCustomFieldDefinitions,
    sourceDocumentFiles,
    referenceFiles,
    matchingClientContact,
    defaultProjectName,
    sourceFileLinks,
    clientConfig?.xtrfServiceId
  ]);

  const validate = React.useCallback(values => JobRequestFormValidator.validate(values), []);

  const {
    mutateAsync: submitToXtrf,
    isLoading: isSubmittingToXtrf,
    error: submitToXtrfError
  } = useMutation(JobRequestsService.submitToXtrf, { onSuccess });

  const onSubmit = React.useCallback(
    async formValues => {
      if (!jobRequest || !jobRequest.requestType) {
        return;
      }

      const customFieldValues: CustomFieldValue[] | undefined = projectCustomFieldDefinitions?.map(fieldDefinition => ({
        fieldName: fieldDefinition.name,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        mapping: fieldDefinition.mapping!,
        value: formValues[sanitizeFieldName(fieldDefinition.name)]
      }));

      const xtrfServiceId = formValues[FieldNames.xtrfService] ? +formValues[FieldNames.xtrfService] : undefined;

      await submitToXtrf({
        jobRequestId,
        requestBody: {
          projectName: formValues[FieldNames.projectName],
          clientId: formValues[FieldNames.client].value,
          clientContactId: +formValues[FieldNames.clientContactId],
          jobRequestType: jobRequest.requestType as unknown as SubmitJobRequestToXtrfRequest.jobRequestType,
          requestorEmail: jobRequest.requestorEmail,
          sourceLanguage: {
            id: +formValues[FieldNames.sourceLanguage].value,
            name: formValues[FieldNames.sourceLanguage].label
          },
          targetLanguages: formValues[FieldNames.targetLanguages].map((option: SelectOption<string>) => ({
            id: +option.value,
            name: option.label
          })),
          notes: formValues[FieldNames.notes],
          fileLinks: formValues[FieldNames.sourceFileLinks],
          customFieldValues,
          provideStandardAndRushQuotes: formValues[FieldNames.provideStandardAndRushQuotes],
          suppressClientNotification,
          xtrfServiceId
        }
      });
    },
    [jobRequest, projectCustomFieldDefinitions, submitToXtrf, jobRequestId, suppressClientNotification]
  );

  const onClientChange = React.useCallback(
    (newClientId: string) => {
      setClientId(newClientId);
      formikRef.current?.resetForm();
      refetchJobRequest();
    },
    [refetchJobRequest]
  );

  const onClientContactAdded = React.useCallback(() => {
    refetchClientContacts();
  }, [refetchClientContacts]);

  const submitButtonText = React.useMemo(
    () => (jobRequest?.requestType ? describeJobRequestSubmitAction(jobRequest.requestType) : "Submit"),
    [jobRequest?.requestType]
  );

  return (
    <Drawer open={true} onClose={onClose} anchor="right" PaperProps={{ sx: { width: "80vw" } }}>
      <DrawerHeader onClose={onClose} title={title || "Review Job Request"} />
      <DrawerBody>
        <Box sx={{ px: 1 }}>
          {isLoading && <PageLoading />}
          {apiError && <ApiErrorMessage apiError={apiError} />}
          {!isLoading && !apiError && jobRequest && (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate}
              validateOnMount={true}
              innerRef={formikRef}
            >
              <Form noValidate={true} autoComplete="off" autoCorrect="off">
                <ScrollToFieldError />
                <JobRequestForm
                  jobRequest={jobRequest}
                  onClientChange={onClientChange}
                  onClientContactAdded={onClientContactAdded}
                  clientContacts={clientContacts?.items}
                  projectCustomFieldDefinitions={projectCustomFieldDefinitions}
                  userCustomFieldDefinitions={userCustomFieldDefinitions}
                  instructionalText={clientConfig?.instructionalText}
                  footerMessage={clientConfig?.projectSubmissionFooterMessage}
                />
                {submitToXtrfError && <SnackbarApiError error={submitToXtrfError} />}
                <Stack direction="row" spacing={1} justifyContent="center" my={5}>
                  <LoadingButton color="primary" variant="contained" type="submit" isLoading={isSubmittingToXtrf}>
                    {submitButtonText}
                  </LoadingButton>
                  <Button onClick={onClose}>Cancel</Button>
                </Stack>
              </Form>
            </Formik>
          )}
        </Box>
      </DrawerBody>
    </Drawer>
  );
};

export { ReviewJobRequestPanel };
