import * as React from "react";
import { NavMenuOption, PageLayout } from "../../components/PageLayout/PageLayout";
import { Divider, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Avatar from "@mui/material/Avatar";
import { useHistory } from "react-router-dom";
import InsightsIcon from "@mui/icons-material/Insights";

const metrics = [
  {
    id: "machineTranslationClientUsageAnalytics",
    name: "Machine Translation Usage Analytics",
    description: "Client usage of machine translation"
  },
  {
    id: "machineTranslationRequestCount",
    name: "Machine Translation Requests by Client Contact",
    description: "Statistics for Machine Translation requests by client contact"
  },
  {
    id: "machineTranslationKibanaDashboard",
    name: "Machine Translation Kibana Dashboard",
    description: "Kibana Dashboard with machine translation related statistics",
    href: process.env.REACT_APP_MT_KIBANA_DASHBOARD_URL
  },
  {
    id: "enabledApplicationsAnalytics",
    name: "Enabled Applications",
    description: "Report on enabled applications"
  },
  {
    id: "quoteRejectionReasonsAnalytics",
    name: "Auto-Quote Rejection Reasons Analytics",
    description: "Report on auto-quote rejection reasons"
  }
];

const AnalyticsPage: React.FC = () => {
  const history = useHistory();
  const onViewMetricClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      history.push(`/analytics/${event.currentTarget.id}`);
    },
    [history]
  );

  return (
    <PageLayout selectedNavMenu={NavMenuOption.ANALYTICS} title="Analytics">
      <List sx={{ width: "100%", bgcolor: "background.paper" }} disablePadding={true}>
        {metrics.map((metric, index) => (
          <React.Fragment key={metric.id}>
            {index > 0 && <Divider component="li" />}
            <ListItem disablePadding>
              <ListItemButton
                id={metric.id}
                onClick={e => {
                  if (metric.href) {
                    window.open(metric.href, "_blank");
                  } else {
                    onViewMetricClick(e);
                  }
                }}
                sx={{ minHeight: 80 }}
              >
                <ListItemIcon>
                  <Avatar>
                    <InsightsIcon />
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={metric.name}
                  primaryTypographyProps={{ variant: "button" }}
                  secondary={metric.description}
                />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </PageLayout>
  );
};

export { AnalyticsPage };
