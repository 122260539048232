import * as React from "react";
import { ClientConfig, ClientsService, WorkflowOption } from "gen/clients";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { useMutation } from "react-query";
import { ApiErrorMessage } from "../../../../../components/ApiErrorMessage/ApiErrorMessage";

interface Props {
  workflow: WorkflowOption;
  clientConfig: ClientConfig;
  onClose: () => void;
  onSuccess: (workflow: WorkflowOption) => void;
}

const DeleteWorkflowDialog: React.FC<Props> = ({ workflow, clientConfig, onClose, onSuccess }) => {
  const { mutateAsync, isLoading, error } = useMutation({
    mutationFn: ClientsService.updateClientConfig,
    onSuccess
  });

  const onDeleteClick = React.useCallback(() => {
    mutateAsync({
      id: clientConfig.id,
      requestBody: {
        ...clientConfig,
        selectableWorkflows: clientConfig.selectableWorkflows?.filter(w => w.label !== workflow.label)
      }
    });
  }, [clientConfig, mutateAsync, workflow.label]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Delete Workflow</DialogTitle>

      <DialogContent>
        <DialogContentText>
          <Stack spacing={2}>
            {error && <ApiErrorMessage apiError={error} />}
            <div>
              Are you sure you want to delete workflow: <strong>{workflow.label}</strong>?
            </div>
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <LoadingButton color="error" onClick={onDeleteClick} isLoading={isLoading}>
          Delete
        </LoadingButton>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export { DeleteWorkflowDialog };
