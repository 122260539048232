import * as React from "react";
import { NavMenuOption, PageLayout } from "../../../components/PageLayout/PageLayout";
import { Box, Grid, Typography, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { BreadcrumbItem } from "../../../components/Breadcrumbs/Breadcrumbs";
import { Formik } from "formik";
import { ApplicationSelect } from "./components/ApplicationSelect";
import { SelectOption } from "@mui/base";
import { EnabledApplicationsByClientReportTable } from "./components/EnabledApplicationsByClientReportTable";
import { ClientsByEnabledApplicationReportTable } from "./components/ClientsByEnabledApplicationsReportTable";
import { ClientCompanySelect } from "./components/ClientCompanySelect";

const breadcrumbs: BreadcrumbItem[] = [
  {
    path: "/analytics",
    label: "Analytics"
  },
  {
    path: "/analytics/applicationUsage",
    label: "Enabled Applications",
    active: true
  }
];

enum SEARCH_BY {
  CLIENT = "CLIENT",
  APPLICATION = "APPLICATION"
}

const EnabledApplicationAnalyticsPage: React.FC = () => {
  const [searchOption, setSearchOption] = React.useState<SEARCH_BY>(SEARCH_BY.CLIENT);
  const [application, setApplication] = React.useState<SelectOption<string>>();
  const [client, setClient] = React.useState<SelectOption<string>>();

  const handleSearchOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOption(event.target.value as SEARCH_BY);
  };

  return (
    <PageLayout
      selectedNavMenu={NavMenuOption.ANALYTICS}
      title={
        <Typography variant="h6" sx={{ mb: 2 }}>
          Enabled Applications
        </Typography>
      }
      breadcrumbs={breadcrumbs}
    >
      <RadioGroup
        aria-label="searchOption"
        name="searchOption"
        value={searchOption}
        onChange={handleSearchOptionChange}
        row
      >
        <FormControlLabel value={SEARCH_BY.CLIENT} control={<Radio />} label="Search by Client" />
        <FormControlLabel value={SEARCH_BY.APPLICATION} control={<Radio />} label="Search by Application" />
      </RadioGroup>

      {searchOption === SEARCH_BY.CLIENT && (
        <>
          <Formik
            initialValues={{ clientId: client?.label || "" }}
            onSubmit={() => {
              window.console.log("onSubmit for Search by Client");
            }}
          >
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} sm={6}>
                <ClientCompanySelect name="clientId" onChange={setClient} />
              </Grid>
            </Grid>
          </Formik>
          <Box sx={{ my: 2 }}>
            <EnabledApplicationsByClientReportTable clientId={client?.value} />
          </Box>
        </>
      )}

      {searchOption === SEARCH_BY.APPLICATION && (
        <>
          <Formik
            initialValues={{ applicationId: application?.label || "" }}
            onSubmit={() => {
              window.console.log("onSubmit for Search by Application");
            }}
          >
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} sm={6}>
                <ApplicationSelect name="applicationId" onChange={setApplication} />
              </Grid>
            </Grid>
          </Formik>
          <Box sx={{ my: 2 }}>
            <ClientsByEnabledApplicationReportTable applicationId={application?.value} />
          </Box>
        </>
      )}
    </PageLayout>
  );
};

export { EnabledApplicationAnalyticsPage };
