/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Client } from '../models/Client';
import type { ClientConfig } from '../models/ClientConfig';
import type { ClientContactCollection } from '../models/ClientContactCollection';
import type { ClientDataType } from '../models/ClientDataType';
import type { ClientSummaryCollection } from '../models/ClientSummaryCollection';
import type { ContentUploadUrl } from '../models/ContentUploadUrl';
import type { CustomFieldDefinition } from '../models/CustomFieldDefinition';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ClientsService {

    /**
     * Lists clients
     * @returns ClientSummaryCollection A 200 response.
     * @throws ApiError
     */
    public static listClients({
        companyName,
        status,
        page,
        limit,
    }: {
        /** company name **/
        companyName?: string,
        /** Client status **/
        status?: 'ACTIVE' | 'INACTIVE' | 'POTENTIAL',
        /** Sets current page index **/
        page?: number,
        /** Sets the page size **/
        limit?: number,
    }): CancelablePromise<ClientSummaryCollection> {
        return __request({
            method: 'GET',
            path: `/clients`,
            query: {
                'companyName': companyName,
                'status': status,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches a client by ID
     * @returns Client successful operation
     * @throws ApiError
     */
    public static getClient({
        clientId,
    }: {
        /** ID of the client that is being fetched **/
        clientId: string,
    }): CancelablePromise<Client> {
        return __request({
            method: 'GET',
            path: `/clients/${clientId}`,
            errors: {
                400: `Invalid parameters`,
                404: `Client config not found`,
            },
        });
    }

    /**
     * Fetches client contacts for a given client
     * @returns ClientContactCollection successful operation
     * @throws ApiError
     */
    public static listClientContacts({
        clientId,
    }: {
        /** client ID **/
        clientId: string,
    }): CancelablePromise<ClientContactCollection> {
        return __request({
            method: 'GET',
            path: `/clients/${clientId}/clientContacts`,
            errors: {
                400: `Invalid parameters`,
                404: `Client not found`,
            },
        });
    }

    /**
     * Lists custom fields definitions for a client
     * @returns CustomFieldDefinition successful operation
     * @throws ApiError
     */
    public static listCustomFieldDefinitions({
        clientId,
    }: {
        /** client ID **/
        clientId: string,
    }): CancelablePromise<Array<CustomFieldDefinition>> {
        return __request({
            method: 'GET',
            path: `/clients/${clientId}/customFieldDefinitions`,
            errors: {
                400: `Invalid parameters`,
                404: `Client not found`,
            },
        });
    }

    /**
     * Update custom field definitions for a client
     * @returns any successful operation
     * @throws ApiError
     */
    public static updateCustomFieldDefinitions({
        clientId,
        requestBody,
    }: {
        /** client ID **/
        clientId: string,
        /** updated custom fields **/
        requestBody?: Array<CustomFieldDefinition>,
    }): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/clients/${clientId}/customFieldDefinitions`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

    /**
     * Creates client configuration
     * @returns any successful operation
     * @throws ApiError
     */
    public static createClientConfig({
        requestBody,
    }: {
        /** New client **/
        requestBody?: ClientConfig,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/clientConfigs`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

    /**
     * Fetches client config by client ID
     * @returns ClientConfig successful operation
     * @throws ApiError
     */
    public static getClientConfig({
        id,
    }: {
        /** ID of the client that is being fetched **/
        id: string,
    }): CancelablePromise<ClientConfig> {
        return __request({
            method: 'GET',
            path: `/clientConfigs/${id}`,
            errors: {
                400: `Invalid parameters`,
                404: `Client config not found`,
            },
        });
    }

    /**
     * Updates client
     * @returns any successful operation
     * @throws ApiError
     */
    public static updateClientConfig({
        id,
        requestBody,
    }: {
        /** client ID **/
        id: string,
        /** Updated client config **/
        requestBody?: ClientConfig,
    }): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/clientConfigs/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
                404: `Client not found`,
            },
        });
    }

    /**
     * Returns a signed URL for uploading static content, such as images, to client configuration.
     * @returns ContentUploadUrl successful operation
     * @throws ApiError
     */
    public static getClientContentUploadUrl({
        id,
        dataType,
        fileName,
        mimeType,
    }: {
        /** Client ID **/
        id: string,
        /** Type of the uploaded data.  Supported values: BANNER, LOGO **/
        dataType: ClientDataType,
        /** Name of the file that will be uploaded **/
        fileName: string,
        /** MIME type of the uploaded file **/
        mimeType?: string,
    }): CancelablePromise<ContentUploadUrl> {
        return __request({
            method: 'GET',
            path: `/clientConfigs/${id}/contentUploadUrl`,
            query: {
                'dataType': dataType,
                'fileName': fileName,
                'mimeType': mimeType,
            },
            errors: {
                400: `Invalid parameters`,
                404: `Client config not found`,
            },
        });
    }

    /**
     * Updates ddb client configs using xtrf data
     * @returns any successful operation
     * @throws ApiError
     */
    public static syncClientConfigWithXtrf({
        id,
    }: {
        /** Client ID **/
        id: string,
    }): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/clientConfigs/${id}/actions/syncWithXtrf`,
            errors: {
                400: `Invalid parameters`,
                404: `Client config not found`,
            },
        });
    }

}