import * as React from "react";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { AlertDialog } from "../AlertDialog/AlertDialog";
import { Box, LinearProgress, Stack, ButtonProps as MuiButtonProps } from "@mui/material";
import { useMutation } from "react-query";
import * as csvWriter from "csv-writer";
import { SnackbarApiError } from "../SnackbarApiError/SnackbarApiError";

interface Props<T> extends MuiButtonProps {
  filename: string;
  columnHeaders: Record<keyof T, string>;
  getExportData: () => Promise<T[]>;
  exportingMessage?: string;
  alwaysQuote?: boolean;
  children?: React.ReactNode;
}

const ExportButton = <T,>({
  filename,
  getExportData,
  columnHeaders,
  exportingMessage,
  alwaysQuote,
  children,
  ...ButtonProps
}: Props<T>): React.ReactElement => {
  const saveDataAsCsv = React.useCallback(
    (data: T[]) => {
      const writer = csvWriter.createObjectCsvStringifier({
        header: Object.keys(columnHeaders).map(id => ({ id, title: columnHeaders[id as keyof T] })),
        alwaysQuote
      });
      const header = writer.getHeaderString();
      const records = writer.stringifyRecords(data);
      const csvData = `${header || ""}${records}`;
      const csvBlob = new Blob([csvData], { type: "text/csv;charset=utf-8" });

      const a = document.createElement("a");
      a.setAttribute("style", "display:none;");
      document.body.appendChild(a);

      const url = window.URL.createObjectURL(csvBlob);
      a.href = url;
      a.download = filename;
      a.click();
    },
    [alwaysQuote, columnHeaders, filename]
  );

  const { mutate, isLoading, error } = useMutation(getExportData, {
    onSuccess: saveDataAsCsv,
    retry: 3
  });

  return (
    <>
      {error && <SnackbarApiError error={error} />}
      <Button startIcon={<SaveAltIcon />} {...ButtonProps} onClick={() => mutate()}>
        {children || "Export to CSV"}
      </Button>
      {isLoading && (
        <AlertDialog title="Exporting...">
          <Stack spacing={1}>
            <Box>{exportingMessage || "Exporting data. Please wait."}</Box>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </Stack>
        </AlertDialog>
      )}
    </>
  );
};

export { ExportButton };
