/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LltsLanguage } from '../models/LltsLanguage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class LanguagesService {

    /**
     * Returns array of LLTS languages
     * @returns LltsLanguage successful operation
     * @throws ApiError
     */
    public static listLltsLanguages({
        xtrfIdExists,
        intentoIdExists,
        smartMateIdExists,
        azureIdExists,
        includeDisabled,
    }: {
        /** is Xtrf mapping configured **/
        xtrfIdExists?: boolean,
        /** is Intento mapping configured **/
        intentoIdExists?: boolean,
        /** is SmartMate mapping configured **/
        smartMateIdExists?: boolean,
        /** is Azure Translator mapping configured **/
        azureIdExists?: boolean,
        /** include disabled languages **/
        includeDisabled?: boolean,
    }): CancelablePromise<Array<LltsLanguage>> {
        return __request({
            method: 'GET',
            path: `/lltsLanguages`,
            query: {
                'xtrfIdExists': xtrfIdExists,
                'intentoIdExists': intentoIdExists,
                'smartMateIdExists': smartMateIdExists,
                'azureIdExists': azureIdExists,
                'includeDisabled': includeDisabled,
            },
        });
    }

    /**
     * Creates a new Llts language
     * @returns LltsLanguage successful operation
     * @throws ApiError
     */
    public static createLltsLanguage({
        requestBody,
    }: {
        requestBody?: LltsLanguage,
    }): CancelablePromise<LltsLanguage> {
        return __request({
            method: 'POST',
            path: `/lltsLanguages`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates a Llts language
     * @returns void
     * @throws ApiError
     */
    public static updateLltsLanguage({
        languageId,
        requestBody,
    }: {
        /** Llts Language identifier **/
        languageId: string,
        requestBody?: LltsLanguage,
    }): CancelablePromise<void> {
        return __request({
            method: 'PUT',
            path: `/lltsLanguages/${languageId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

    /**
     * Deletes a Llts language
     * @returns void
     * @throws ApiError
     */
    public static deleteLltsLanguage({
        languageId,
    }: {
        /** Llts Language identifier **/
        languageId: string,
    }): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/lltsLanguages/${languageId}`,
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

}