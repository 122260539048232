/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CognitoUser } from '../models/CognitoUser';
import type { CognitoUserChangeGroupsRequest } from '../models/CognitoUserChangeGroupsRequest';
import type { CognitoUserChangeStateRequest } from '../models/CognitoUserChangeStateRequest';
import type { CognitoUserSetGroupsRequest } from '../models/CognitoUserSetGroupsRequest';
import type { CognitoUserSummaryCollection } from '../models/CognitoUserSummaryCollection';
import type { CreateCognitoUserPayload } from '../models/CreateCognitoUserPayload';
import type { SetCognitoUserPasswordRequest } from '../models/SetCognitoUserPasswordRequest';
import type { UpdateCognitoUserAttributesRequest } from '../models/UpdateCognitoUserAttributesRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserAccountsService {

    /**
     * Returns a list of Cognito users
     * @returns CognitoUserSummaryCollection successful operation
     * @throws ApiError
     */
    public static listCognitoUsers({
        email,
        pageToken,
        pageSize,
    }: {
        /** contact email filter **/
        email: string,
        pageToken?: string,
        pageSize?: number,
    }): CancelablePromise<CognitoUserSummaryCollection> {
        return __request({
            method: 'GET',
            path: `/cognitoUsers`,
            query: {
                'email': email,
                'pageToken': pageToken,
                'pageSize': pageSize,
            },
        });
    }

    /**
     * Create a Cognito user
     * @returns any successful operation
     * @throws ApiError
     */
    public static createCognitoUser({
        requestBody,
    }: {
        /** Cognito user to create **/
        requestBody?: CreateCognitoUserPayload,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/cognitoUsers`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid parameters`,
            },
        });
    }

    /**
     * Returns details of a Cognito user account
     * @returns CognitoUser successful operation
     * @throws ApiError
     */
    public static getCognitoUser({
        cognitoUsername,
    }: {
        cognitoUsername: string,
    }): CancelablePromise<CognitoUser> {
        return __request({
            method: 'GET',
            path: `/cognitoUsers/${cognitoUsername}`,
        });
    }

    /**
     * Update account state of Cognito user
     * @returns any successful operation
     * @throws ApiError
     */
    public static changeCognitoUserState({
        cognitoUsername,
        requestBody,
    }: {
        cognitoUsername: string,
        requestBody?: CognitoUserChangeStateRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/cognitoUsers/${cognitoUsername}/actions/changeState`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Set groups of Cognito user
     * @returns any successful operation
     * @throws ApiError
     */
    public static setCognitoUserGroups({
        cognitoUsername,
        requestBody,
    }: {
        cognitoUsername: string,
        requestBody?: CognitoUserSetGroupsRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/cognitoUsers/${cognitoUsername}/actions/setGroups`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Change groups of Cognito user (groups from the removeGroups are removed first before groups from addGroups are added)
     * @returns any successful operation
     * @throws ApiError
     */
    public static changeCognitoUserGroups({
        cognitoUsername,
        requestBody,
    }: {
        cognitoUsername: string,
        requestBody?: CognitoUserChangeGroupsRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/cognitoUsers/${cognitoUsername}/actions/changeGroups`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Updates attributes of a cognito user
     * @returns any successful operation
     * @throws ApiError
     */
    public static updateCognitoUserAttributes({
        cognitoUsername,
        requestBody,
    }: {
        cognitoUsername: string,
        requestBody?: UpdateCognitoUserAttributesRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'PATCH',
            path: `/cognitoUsers/${cognitoUsername}/actions/updateAttributes`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sets user status to CONFIRMED
     * @returns any successful operation
     * @throws ApiError
     */
    public static confirmCognitoUserSignUp({
        cognitoUsername,
    }: {
        cognitoUsername: string,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/cognitoUsers/${cognitoUsername}/actions/confirmSignUp`,
        });
    }

    /**
     * Sets user password
     * @returns any successful operation
     * @throws ApiError
     */
    public static setCognitoUserPassword({
        cognitoUsername,
        requestBody,
    }: {
        cognitoUsername: string,
        requestBody?: SetCognitoUserPasswordRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/cognitoUsers/${cognitoUsername}/actions/setPassword`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Disables MFA for a user
     * @returns any successful operation
     * @throws ApiError
     */
    public static disableCognitoUserMfa({
        cognitoUsername,
    }: {
        cognitoUsername: string,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/cognitoUsers/${cognitoUsername}/actions/disableMfa`,
        });
    }

}