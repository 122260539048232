import * as React from "react";
import { JobRequest, JobRequestStatus } from "gen/clients";
import { LabelValue } from "components/LabelValue/LabelValue";
import { Typography } from "@mui/material";

interface Props {
  jobRequest: JobRequest;
}

const TargetLanguagesLabel: React.FC<Props> = ({ jobRequest }) => {
  if (!jobRequest.targetLanguages && !jobRequest.aiAnalysis) {
    return <span />;
  }

  const useAIValue = [JobRequestStatus.READY_FOR_INTERNAL_REVIEW].includes(jobRequest.status) && jobRequest.aiAnalysis;

  const aiTargetLanguages = jobRequest.aiAnalysis?.targetLanguages;

  return (
    <LabelValue
      label="Target Languages"
      value={
        <>
          {!useAIValue && (
            <Typography>
              {jobRequest.targetLanguages
                ?.map(l => l.name)
                .sort()
                .join(", ")}
            </Typography>
          )}
          {useAIValue && aiTargetLanguages && (
            <>
              {aiTargetLanguages?.map((l, index) => (
                <React.Fragment key={l.name}>
                  <Typography
                    component="span"
                    sx={theme => ({ color: l.xtrfLangId ? theme.palette.success.main : theme.palette.error.main })}
                  >
                    {l.name}
                  </Typography>
                  {aiTargetLanguages && index !== aiTargetLanguages.length - 1 && ", "}
                </React.Fragment>
              ))}
            </>
          )}
        </>
      }
      verticalAlignment="top"
    />
  );
};

export { TargetLanguagesLabel };
