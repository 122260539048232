import * as React from "react";
import { JobRequestStatus } from "gen/clients";
import Typography from "@mui/material/Typography";
import { Variant } from "@mui/material/styles/createTypography";

interface Props {
  status: JobRequestStatus;
  variant?: Variant;
}

const JobRequestStatusText: React.FC<Props> = ({ status, variant }) => {
  switch (status) {
    case JobRequestStatus.ANALYZING:
      return <Typography variant={variant}>Analyzing</Typography>;
    case JobRequestStatus.TRANSLATING:
      return <Typography variant={variant}>Translating</Typography>;
    case JobRequestStatus.COMPLETED:
      return (
        <Typography variant={variant} color="success.main">
          Completed
        </Typography>
      );
    case JobRequestStatus.CREATED:
      return (
        <Typography variant={variant} color="info.main">
          Created
        </Typography>
      );
    case JobRequestStatus.ARCHIVED:
      return (
        <Typography variant={variant} color="gray">
          Archived
        </Typography>
      );
    case JobRequestStatus.READY_FOR_INTERNAL_REVIEW:
      return (
        <Typography variant={variant} color="success.main">
          Waiting for internal review
        </Typography>
      );
    case JobRequestStatus.QUOTE_READY_FOR_CLIENT_REVIEW:
      return (
        <Typography variant={variant} color="info.main">
          Waiting for client review
        </Typography>
      );
    case JobRequestStatus.QUOTE_ACCEPTED:
      return (
        <Typography variant={variant} color="success.main">
          Quote accepted
        </Typography>
      );
    case JobRequestStatus.QUOTE_REJECTED:
      return (
        <Typography variant={variant} color="error">
          Quote rejected
        </Typography>
      );
    case JobRequestStatus.QUOTE_EXPIRED:
      return (
        <Typography variant={variant} color="gray">
          Quote expired
        </Typography>
      );
    case JobRequestStatus.SCREENING:
      return <Typography variant={variant}>Screening</Typography>;
    case JobRequestStatus.REJECTED_BY_SCREENING:
      return (
        <Typography variant={variant} color="error">
          Rejected by screening
        </Typography>
      );
    case JobRequestStatus.SUBMITTING_TO_XTRF:
      return (
        <Typography variant={variant} color="info.main">
          Submitting to XTRF
        </Typography>
      );
    case JobRequestStatus.SUBMITTED_TO_XTRF:
      return (
        <Typography variant={variant} color="gray">
          Submitted to XTRF
        </Typography>
      );
    case JobRequestStatus.FAILED:
      return (
        <Typography variant={variant} color="error">
          Failed
        </Typography>
      );
    case JobRequestStatus.CANCELLING:
      return (
        <Typography variant={variant} color="gray">
          Cancelling
        </Typography>
      );
    case JobRequestStatus.CANCELLED:
      return (
        <Typography variant={variant} color="gray">
          Cancelled
        </Typography>
      );
    default:
      return <Typography variant={variant}>{status}</Typography>;
  }
};

export { JobRequestStatusText };
