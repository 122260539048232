import * as React from "react";
import { useMutation } from "react-query";
import { ClientsService, CustomFieldDefinition } from "gen/clients";
import * as Yup from "yup";
import { Formik } from "formik";
import { DrawerHeader } from "../../../../../components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "../../../../../components/DrawerBody/DrawerBody";
import Drawer from "@mui/material/Drawer";
import { CustomFieldForm } from "./components/CustomFieldForm/CustomFieldForm";
import { SelectOption, SelectOptionGroup } from "@mui/base";
import { SnackbarApiError } from "../../../../../components/SnackbarApiError/SnackbarApiError";

interface Props {
  clientId: string;
  category: CustomFieldDefinition.category;
  customFieldDefinitions: CustomFieldDefinition[];
  open: boolean;
  mappingOptions: SelectOptionGroup<string>[];
  onClose: () => void;
  onSuccess: () => void;
}

const CreateCustomFieldPanel: React.FC<Props> = ({
  clientId,
  category,
  customFieldDefinitions,
  open,
  mappingOptions,
  onSuccess,
  onClose
}) => {
  const { mutate, error } = useMutation({
    onSuccess,
    mutationFn: ClientsService.updateCustomFieldDefinitions
  });
  const [options, setOptions] = React.useState<SelectOption<string>[]>([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Required"),
    mapping: Yup.string().trim().required("Required")
  });

  const validate = React.useCallback(
    formValues => {
      const errors: Record<string, string> = {};
      if (customFieldDefinitions.find(cfd => cfd.name === formValues.name.trim())) {
        errors.name =
          "A field with this name already exists. Name must be unique across all Project and User custom fields.";
      }
      return errors;
    },
    [customFieldDefinitions]
  );

  const onSubmit = React.useCallback(
    formValues => {
      const projectCustomFieldDefinitions: CustomFieldDefinition[] = customFieldDefinitions || [];
      projectCustomFieldDefinitions.push({
        category,
        name: formValues.name.trim(),
        type: formValues.type,
        required: formValues.required,
        options: options.length > 0 ? options.map(o => ({ label: o.label as string, value: o.value })) : undefined,
        defaultValue: formValues.defaultValue.trim() || undefined,
        mapping: formValues.mapping.trim() || undefined,
        visible: formValues.visible,
        validationRegex: formValues.validationRegex.trim() || undefined,
        validationMessage: formValues.validationMessage.trim() || undefined,
        description: formValues.description.trim() || undefined
      });
      mutate({
        clientId,
        requestBody: projectCustomFieldDefinitions
      });
    },
    [customFieldDefinitions, category, options, mutate, clientId]
  );

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader onClose={onClose} title="Add Custom Field" />
      <DrawerBody>
        {!!error && <SnackbarApiError error={error} />}
        <Formik
          initialValues={{
            name: "",
            type: "INPUT_TEXT",
            required: false,
            defaultValue: "",
            mapping: "",
            visible: true,
            validationRegex: "",
            validationMessage: "",
            description: ""
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validate={validate}
        >
          <CustomFieldForm
            options={options}
            setOptions={setOptions}
            onCancel={onClose}
            mappingOptions={mappingOptions}
            allowTextArea={category === CustomFieldDefinition.category.PROJECT}
          />
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { CreateCustomFieldPanel };
