import * as React from "react";
import { JobRequest } from "gen/clients";
import { LabelValue } from "components/LabelValue/LabelValue";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

interface Props {
  jobRequest: JobRequest;
}

const PaymentPreferencesSection: React.FC<Props> = ({ jobRequest }) => {
  const { t } = useTranslation();
  const { paymentPreferences } = jobRequest;
  if (!paymentPreferences) {
    return <span />;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h5" mt={2}>
        Payment Preferences
      </Typography>
      <LabelValue label="Payment type" value={t(`jobRequests.paymentTypes.${paymentPreferences.paymentType}`)} />
      {paymentPreferences.billingAccount?.clientIdNumber && (
        <LabelValue label="Client ID Number" value={paymentPreferences.billingAccount.clientIdNumber} />
      )}
      {paymentPreferences.billingAccount?.personalCode && (
        <LabelValue label="Personal Code" value={paymentPreferences.billingAccount.personalCode} />
      )}
      {paymentPreferences.billingContact?.email && (
        <LabelValue label="Email" value={paymentPreferences.billingContact?.email} />
      )}
      {paymentPreferences.billingContact?.phoneNumber && (
        <LabelValue label="Phone Number" value={paymentPreferences.billingContact?.phoneNumber} />
      )}
      {paymentPreferences.billingContact?.address1 && (
        <LabelValue label="Address 1" value={paymentPreferences.billingContact.address1} />
      )}
      {paymentPreferences.billingContact?.address2 && (
        <LabelValue label="Address 2" value={paymentPreferences.billingContact.address2} />
      )}
      {paymentPreferences.billingContact?.city && (
        <LabelValue label="City" value={paymentPreferences.billingContact.city} />
      )}
      {paymentPreferences.billingContact?.zipCode && (
        <LabelValue label="Zip / Postal Code" value={paymentPreferences.billingContact.zipCode} />
      )}
      {paymentPreferences.billingContact?.province && (
        <LabelValue label="State" value={paymentPreferences.billingContact.province.name} />
      )}
      {paymentPreferences.billingContact?.country && (
        <LabelValue label="Country" value={paymentPreferences.billingContact.country.name} />
      )}
    </Stack>
  );
};

export { PaymentPreferencesSection };
