import * as React from "react";
import { Field, Form, Formik, FormikValues } from "formik";
import { LabelValue } from "components/LabelValue/LabelValue";
import {
  AdditionalProjectContact,
  ApiError,
  Client,
  ClientConfig,
  ClientsService,
  SystemSettingsService
} from "gen/clients";
import { useMutation, useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { TextInputField } from "../../../../../components/formikFields/TextInputField/TextInputField";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { ProjectContactsPanel } from "./ProjectContactsPanel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isValidEmail } from "utils/emailUtils";
import { CheckboxWithLabel } from "formik-mui";

interface Props {
  client: Client;
  clientConfig: ClientConfig;
  onSaveSuccess: () => void;
}

enum FieldName {
  emailDomains = "emailDomains",
  bccEmailNotifications = "bccEmailNotifications",
  disableSelfRegistration = "disableSelfRegistration",
  isOrgManagementEnabled = "isOrgManagementEnabled",
  isMfaEnabled = "isMfaEnabled"
}

interface FormValues {
  [FieldName.emailDomains]: string;
  [FieldName.bccEmailNotifications]: string;
  [FieldName.disableSelfRegistration]: boolean;
  [FieldName.isOrgManagementEnabled]: boolean;
  [FieldName.isMfaEnabled]: boolean;
}

const GeneralInformationTab: React.FC<Props> = ({ client, clientConfig, onSaveSuccess }) => {
  const [additionalProjectContacts, setAdditionalProjectContacts] = React.useState<AdditionalProjectContact[]>(
    clientConfig.additionalProjectContacts || []
  );

  const { data: systemSettings } = useQuery("getSystemSettings", {
    queryFn: SystemSettingsService.getSystemSettings
  });

  const {
    mutate,
    isError: isMutateError,
    error: mutateError,
    isLoading: isMutating
  } = useMutation("updateClientConfig", {
    mutationFn: clientConfig ? ClientsService.updateClientConfig : ClientsService.createClientConfig,
    onSuccess: onSaveSuccess
  });

  const validate = React.useCallback(
    (formValues: FormikValues) => {
      const errors: Record<string, string> = {};
      if (formValues.emailDomains) {
        const domains = (formValues.emailDomains as string).split(/\s*,\s*/);
        domains.forEach(domain => {
          if (!domain.includes(".")) {
            errors.emailDomains = `Invalid domain name "${domain}"`;
          }
          if (systemSettings?.notAllowedEmailDomains?.includes(domain)) {
            errors.emailDomains = `Domain ${domain} is not allowed`;
          }
        });
      }
      if (formValues.bccEmailNotifications) {
        const bccEmails = (formValues.bccEmailNotifications as string).split(/\s*,\s*/);
        bccEmails.forEach(email => {
          if (!isValidEmail(email)) {
            errors.bccEmailNotifications = `Invalid email "${email}"`;
          }
        });
      }
      return errors;
    },
    [systemSettings]
  );

  const onSubmit = React.useCallback(
    async (formValues: FormValues) => {
      const clientConfigUpdate: ClientConfig = clientConfig
        ? { ...clientConfig, additionalProjectContacts }
        : { id: client.id };
      const emailDomains = formValues[FieldName.emailDomains].split(/\s*,\s*/).filter(d => d.length > 0);
      clientConfigUpdate.allowedEmailDomains = emailDomains.length > 0 ? emailDomains : undefined;
      const bccEmailAddresses = formValues[FieldName.bccEmailNotifications].split(/\s*,\s*/).filter(d => d.length > 0);
      clientConfigUpdate.bccEmailNotifications = bccEmailAddresses.length > 0 ? bccEmailAddresses : undefined;
      clientConfigUpdate.disableSelfRegistration = formValues[FieldName.disableSelfRegistration];
      clientConfigUpdate.isOrgManagementEnabled = formValues[FieldName.isOrgManagementEnabled];
      clientConfigUpdate.isMfaEnabled = formValues[FieldName.isMfaEnabled];
      await mutate({
        id: `${client.id}`,
        requestBody: clientConfigUpdate
      });
    },
    [clientConfig, client.id, additionalProjectContacts, mutate]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          [FieldName.emailDomains]: clientConfig?.allowedEmailDomains?.join(", ") || "",
          [FieldName.bccEmailNotifications]: clientConfig?.bccEmailNotifications?.join(", ") || "",
          [FieldName.disableSelfRegistration]: clientConfig.disableSelfRegistration || false,
          [FieldName.isOrgManagementEnabled]: clientConfig.isOrgManagementEnabled || false,
          [FieldName.isMfaEnabled]: clientConfig.isMfaEnabled || false
        }}
        onSubmit={onSubmit}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={true}
      >
        <Form>
          <Stack spacing={2}>
            <LabelValue label="Client Name" value={clientConfig.companyName} />
            <LabelValue label="Client ID" value={client.id} />
            <LabelValue label="Status" value={client.status} />
            <Stack direction="row" spacing={4}>
              <LabelValue
                label="Disable Self-Registration"
                value={<Field name={FieldName.disableSelfRegistration} type="checkbox" component={CheckboxWithLabel} />}
              />
              <LabelValue
                label="Enable User Management by Org Admins"
                value={<Field name={FieldName.isOrgManagementEnabled} type="checkbox" component={CheckboxWithLabel} />}
              />
              <LabelValue
                label="Enable MFA"
                value={<Field name={FieldName.isMfaEnabled} type="checkbox" component={CheckboxWithLabel} />}
              />
            </Stack>
            <LabelValue
              label="Allowed Email Domains"
              value={
                <TextInputField
                  name={FieldName.emailDomains}
                  label=""
                  helperText="Comma-separated list of domains. Example: llts.com, chase.com"
                />
              }
            />
            <LabelValue
              label="BCC Client Email Notifications"
              value={
                <TextInputField
                  name={FieldName.bccEmailNotifications}
                  label=""
                  helperText="Please enter a comma separated list of email addresses that will be blind-copied on all email notifications sent to the client"
                />
              }
            />
            <Box sx={{ pt: 2 }}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack>
                    <Typography variant="body1" fontWeight="bold" sx={{ mb: 1 }}>
                      Additional Project Contacts
                    </Typography>
                    <Typography variant="body2">
                      Configure client contacts that will be notified about all changes to the client&apos;s projects
                      and quotes
                    </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <ProjectContactsPanel
                    client={client}
                    contactList={additionalProjectContacts}
                    setContactList={setAdditionalProjectContacts}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
          <Box sx={{ mt: 2 }}>
            {isMutateError && <ApiErrorMessage apiError={mutateError as ApiError} />}
            <LoadingButton isLoading={isMutating}>Save</LoadingButton>
          </Box>
        </Form>
      </Formik>
    </>
  );
};
export { GeneralInformationTab };
