import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LabelValue } from "components/LabelValue/LabelValue";
import { Box, Button, Toolbar } from "@mui/material";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { CognitoStateSwitch } from "./components/CognitoStateSwitch";
import { EmailVerifiedSwitch } from "./components/EmailVerifiedSwitch";
import { formatEpoch } from "utils/dateUtils";
import { AdminRoleSwitch } from "./components/AdminRoleSwitch";
import { EditAttributesDialog } from "./components/EditAttributesDialog";
import { SetPasswordDialog } from "./components/SetPasswordDialog";
import { CreateCognitoUserDialog } from "./components/CreateCognitoUserDialog";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { useDialogState } from "hooks/useDialogState";
import { useSnackbarAlert } from "hooks/useSnackbarAlert";
import { useMutation, useQuery } from "react-query";
import { ClientContactSummary, UserAccountsService } from "gen/clients";
import { PageLoading } from "components/PageLoading/PageLoading";
import { MfaEnabledSwitch } from "./components/MfaEnabledSwitch";

interface Props {
  clientContact: ClientContactSummary;
}

const AccountSettingsTab: React.FC<Props> = ({ clientContact }) => {
  const [isCreateCognitoUserDialogOpen, openCreateCognitoUserDialog, closeCreateCognitoUserDialog] = useDialogState();
  const [isSetPasswordDialogOpen, openSetPasswordDialog, closeSetPasswordDialog] = useDialogState();
  const [isEditAttributesDialogOpen, openEditAttributesDialog, closeEditAttributesDialog] = useDialogState();
  const { snackbarAlert, openSnackbarAlert, closeSnackbarAlert } = useSnackbarAlert({
    autoHideDuration: 5000,
    vertical: "bottom",
    horizontal: "left"
  });

  const {
    data: cognitoUserCollection,
    refetch: refetchCognitoUserCollection,
    isLoading: isCognitoUserSummaryLoading
  } = useQuery(["listCognitoUsers", clientContact.email], {
    queryFn: () => UserAccountsService.listCognitoUsers({ email: clientContact.email })
  });

  const cognitoUserSummary = React.useMemo(() => cognitoUserCollection?.items[0], [cognitoUserCollection]);

  const {
    data: cognitoUser,
    refetch: refetchCognitoUser,
    isLoading: isCognitoUserLoading
  } = useQuery(["getCognitoUser", cognitoUserSummary?.username], {
    queryFn: () =>
      UserAccountsService.getCognitoUser({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        cognitoUsername: encodeURIComponent(cognitoUserSummary!.username)
      }),
    enabled: cognitoUserSummary?.username !== undefined
  });

  const {
    mutate: confirmUserAccount,
    isLoading: isConfirmUserAccountLoading,
    error: confirmUserAccountError
  } = useMutation(UserAccountsService.confirmCognitoUserSignUp, { onSuccess: refetchCognitoUser });

  const onEditAttributesSuccess = React.useCallback(() => {
    closeEditAttributesDialog();
    openSnackbarAlert({ message: "Operation successful", severity: "success" });
    refetchCognitoUser();
  }, [closeEditAttributesDialog, openSnackbarAlert, refetchCognitoUser]);

  const onSetPasswordSuccess = React.useCallback(() => {
    closeSetPasswordDialog();
    openSnackbarAlert({ message: "Operation successful", severity: "success" });
    refetchCognitoUser();
  }, [closeSetPasswordDialog, openSnackbarAlert, refetchCognitoUser]);

  const onCreateCognitoUserSuccess = React.useCallback(() => {
    closeCreateCognitoUserDialog();
    openSnackbarAlert({ message: "Operation successful", severity: "success" });
    refetchCognitoUserCollection();
  }, [closeCreateCognitoUserDialog, refetchCognitoUserCollection, openSnackbarAlert]);

  if (isCognitoUserSummaryLoading || isCognitoUserLoading) {
    return <PageLoading />;
  }

  return (
    <>
      {snackbarAlert}
      <Stack spacing={1}>
        <Typography variant="h6">XTRF Account</Typography>
        <LabelValue label="Client ID" value={clientContact.clientId} />
        <LabelValue label="Client Contact ID" value={clientContact.id} />
        <LabelValue label="First Name" value={clientContact.firstName} />
        <LabelValue label="Last Name" value={clientContact.lastName} />
        <LabelValue label="Email" value={clientContact.email} />
        {clientContact.xtrfAccountStatus !== undefined && (
          <LabelValue label="Enabled" value={clientContact.xtrfAccountStatus ? "Yes" : "No"} />
        )}
      </Stack>

      {cognitoUser && (
        <>
          <Stack mt={4} spacing={1}>
            <Toolbar disableGutters={true} variant="dense">
              <Typography variant="h6">Cognito Account</Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openSetPasswordDialog();
                  }}
                >
                  Set Password
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openEditAttributesDialog();
                  }}
                >
                  Edit Attributes
                </Button>
                <LoadingButton
                  variant="outlined"
                  disabled={cognitoUser.userStatus !== "UNCONFIRMED"}
                  onClick={() =>
                    confirmUserAccount({
                      cognitoUsername: encodeURIComponent(cognitoUser.username)
                    })
                  }
                  isLoading={isConfirmUserAccountLoading}
                  type="button"
                >
                  Confirm User Account
                </LoadingButton>
              </Stack>
            </Toolbar>
            <LabelValue label="Username" value={cognitoUser.username} />
            <LabelValue
              label="Enabled"
              value={<CognitoStateSwitch cognitoUser={cognitoUser} onSuccess={refetchCognitoUser} />}
            />
            <LabelValue
              label="Email Verified"
              value={<EmailVerifiedSwitch cognitoUser={cognitoUser} onSuccess={refetchCognitoUser} />}
            />
            <LabelValue
              label="MFA Enabled"
              value={<MfaEnabledSwitch cognitoUser={cognitoUser} onSuccess={refetchCognitoUser} />}
            />
            {cognitoUser.userStatus && <LabelValue label="Status" value={cognitoUser.userStatus} />}
            {cognitoUser?.lastVisit && <LabelValue label="Last Visit" value={formatEpoch(cognitoUser?.lastVisit)} />}
            <LabelValue
              label="Attributes"
              verticalAlignment="top"
              value={
                <Stack spacing={1}>
                  <LabelValue
                    label="xtrfClientId"
                    value={
                      <Typography
                        sx={theme =>
                          cognitoUser.xtrfClientId !== `${clientContact.clientId}`
                            ? {
                                color: theme.palette.error.main,
                                fontWeight: "bold"
                              }
                            : {}
                        }
                      >
                        {cognitoUser.xtrfClientId}
                      </Typography>
                    }
                  />
                  <LabelValue
                    label="xtrfClientContactId"
                    value={
                      <Typography
                        sx={theme =>
                          cognitoUser.xtrfClientContactId !== `${clientContact.id}`
                            ? {
                                color: theme.palette.error.main,
                                fontWeight: "bold"
                              }
                            : {}
                        }
                      >
                        {cognitoUser.xtrfClientContactId}
                      </Typography>
                    }
                  />
                  {cognitoUser.firstName && (
                    <LabelValue
                      label="First Name"
                      value={
                        <Typography
                          sx={theme =>
                            cognitoUser.firstName !== `${clientContact.firstName}`
                              ? {
                                  color: theme.palette.error.main,
                                  fontWeight: "bold"
                                }
                              : {}
                          }
                        >
                          {cognitoUser.firstName}
                        </Typography>
                      }
                    />
                  )}
                  {cognitoUser.lastName && (
                    <LabelValue
                      label="Last Name"
                      value={
                        <Typography
                          sx={theme =>
                            cognitoUser.lastName !== `${clientContact.lastName}`
                              ? {
                                  color: theme.palette.error.main,
                                  fontWeight: "bold"
                                }
                              : {}
                          }
                        >
                          {cognitoUser.lastName}
                        </Typography>
                      }
                    />
                  )}
                  {cognitoUser.email && (
                    <LabelValue
                      label="Email"
                      value={
                        <Typography
                          sx={theme =>
                            cognitoUser.email !== `${clientContact.email}`
                              ? {
                                  color: theme.palette.error.main,
                                  fontWeight: "bold"
                                }
                              : {}
                          }
                        >
                          {cognitoUser.email}
                        </Typography>
                      }
                    />
                  )}
                </Stack>
              }
            />
          </Stack>
          <Stack spacing={1} mt={4}>
            <Typography variant="h6">Permissions</Typography>
            <Stack direction="row">
              <LabelValue
                label="Is Org Admin?"
                value={<AdminRoleSwitch cognitoUser={cognitoUser} onSuccess={refetchCognitoUser} />}
              />
            </Stack>
          </Stack>
        </>
      )}

      {!cognitoUser && (
        <>
          <Stack mt={4} spacing={1}>
            <Toolbar disableGutters={true} variant="dense">
              <Typography variant="h6">Cognito Information</Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openCreateCognitoUserDialog();
                  }}
                >
                  Create Cognito Account
                </Button>
              </Stack>
            </Toolbar>
          </Stack>
        </>
      )}

      {isEditAttributesDialogOpen && cognitoUser?.username && (
        <EditAttributesDialog
          username={cognitoUser.username}
          xtrfClientId={cognitoUser.xtrfClientId}
          xtrfClientContactId={cognitoUser.xtrfClientContactId}
          firstName={cognitoUser.firstName}
          lastName={cognitoUser.lastName}
          email={cognitoUser.email}
          onSuccess={onEditAttributesSuccess}
          onClose={closeEditAttributesDialog}
        />
      )}

      {isSetPasswordDialogOpen && cognitoUser?.username && (
        <SetPasswordDialog
          username={cognitoUser.username}
          onSuccess={onSetPasswordSuccess}
          onClose={closeSetPasswordDialog}
        />
      )}

      {isCreateCognitoUserDialogOpen && (
        <CreateCognitoUserDialog
          username={clientContact.email}
          firstName={clientContact.firstName}
          lastName={clientContact.lastName}
          email={clientContact.email}
          xtrfClientId={clientContact.clientId}
          xtrfClientContactId={`${clientContact.id}`}
          onSuccess={onCreateCognitoUserSuccess}
          onClose={closeCreateCognitoUserDialog}
        />
      )}

      {confirmUserAccountError && <SnackbarApiError error={confirmUserAccountError} />}
    </>
  );
};

export { AccountSettingsTab };
