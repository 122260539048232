import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

interface Props {
  message?: string;
}

const LoadingDialog: React.FC<Props> = ({ message = "Loading..." }) => (
  <Dialog open={true} fullWidth={true} maxWidth="xs">
    <DialogContent>
      <Stack justifyContent="center" justifyItems="center" my={5} spacing={3} textAlign="center">
        <Typography variant="h6">{message}</Typography>
        <Box sx={{ display: "flex" }} justifyContent="center">
          <CircularProgress />
        </Box>
      </Stack>
    </DialogContent>
  </Dialog>
);

export { LoadingDialog };
