import * as React from "react";
import { JobRequestAutoQuote } from "../../../../../../gen/clients";
import Typography from "@mui/material/Typography";

interface Props {
  status: JobRequestAutoQuote.acceptanceStatus;
}

const AutoQuoteStatus: React.FC<Props> = ({ status }) => {
  switch (status) {
    case JobRequestAutoQuote.acceptanceStatus.ACCEPTED:
      return <Typography color="success.main">Accepted</Typography>;
    case JobRequestAutoQuote.acceptanceStatus.REJECTED:
      return <Typography color="error.main">Rejected</Typography>;
    default:
      return <span>Unknown</span>;
  }
};

export { AutoQuoteStatus };
