import * as React from "react";
import { JobRequest, JobRequestsService } from "../../../../gen/clients";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { useMutation } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import Stack from "@mui/material/Stack";

interface Props {
  jobRequest: JobRequest;
  onSuccess: () => void;
  onClose: () => void;
}

const DeleteJobRequestDialog: React.FC<Props> = ({ jobRequest, onSuccess, onClose }) => {
  const { mutate, isLoading, error, isSuccess } = useMutation("deleteJobRequest", {
    mutationFn: JobRequestsService.deleteJobRequest
  });

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const onSubmit = React.useCallback(() => {
    mutate({
      jobRequestId: jobRequest.id
    });
  }, [jobRequest.id, mutate]);

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <Formik initialValues={{}} onSubmit={onSubmit} validateOnMount={false}>
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <DialogTitle>Delete Job Request</DialogTitle>
          <DialogContent>
            {error && <ApiErrorMessage apiError={error} />}
            <Stack spacing={2}>
              <Box>Are you sure you want to delete this job request?</Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton color="error" variant="contained" isLoading={isLoading}>
              Delete
            </LoadingButton>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { DeleteJobRequestDialog };
