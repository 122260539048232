// noinspection ES6UnusedImports

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import App from "App";
import { QueryClient, QueryClientProvider } from "react-query";
import { OpenAPI } from "./gen/clients";
import reportWebVitals from "./reportWebVitals";
import initI18n from "./i18n";

const initThis = async () => {
  // Check if user is signed in.  If the user is not signed in, then immediately redirect to login page.
  let isUserAuthenticated = false;
  try {
    await Auth.currentAuthenticatedUser();
    isUserAuthenticated = true;
  } catch (e) {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Cognito });
  }
  if (!isUserAuthenticated) {
    return;
  }

  await initI18n();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: true,
        refetchOnWindowFocus: false
      }
    }
  });

  // Set API client token
  OpenAPI.TOKEN = async () => {
    const session = await Auth.currentSession();
    return session?.getIdToken().getJwtToken();
  };

  OpenAPI.BASE = process.env.REACT_APP_API_URL_OVERRIDE || OpenAPI.BASE;

  // eslint-disable-next-line
  // import i18n from './i18n';

  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback="...is loading">
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};
initThis();
