import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { SelectOption } from "@mui/base";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { Client, ClientContactSummary, ClientsService } from "gen/clients";
import { Form, Formik } from "formik";
import { useQuery } from "react-query";

interface Props {
  client: Client;
  usedContactIds: number[];
  onClose: () => void;
  onSuccess: (contact: ClientContactSummary) => void;
}

enum FieldNames {
  clientContact = "clientContact"
}

const AddContactDialog: React.FC<Props> = ({ client, usedContactIds, onSuccess, onClose }) => {
  const { data: clientContacts } = useQuery(["listClientContacts", client.id], {
    enabled: !!client.id,
    queryFn: () => ClientsService.listClientContacts({ clientId: client.id })
  });

  const availableClientContacts = React.useMemo(
    () => clientContacts?.items.filter(cc => !usedContactIds.includes(cc.id)),
    [clientContacts, usedContactIds]
  );

  const clientContactOptions: SelectOption<string>[] = React.useMemo(
    () =>
      availableClientContacts
        ?.map(cc => ({
          label: `${cc.firstName} ${cc.lastName} (${cc.email})`,
          value: `${cc.id}`
        }))
        .sort((o1, o2) => o1.label.localeCompare(o2.label)) || [],
    [availableClientContacts]
  );

  const onSubmit = React.useCallback(
    values => {
      const selectedContact: string = values[FieldNames.clientContact];
      const addedContact = clientContacts?.items.find(cc => cc.id === +selectedContact);
      if (addedContact) {
        onSuccess(addedContact);
      } else {
        onClose();
      }
    },
    [clientContacts, onSuccess, onClose]
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <Formik
        onSubmit={onSubmit}
        initialValues={{
          [FieldNames.clientContact]: ""
        }}
      >
        <Form>
          <DialogTitle>Add Project Contact</DialogTitle>
          <DialogContent>
            <SelectOneField
              name={FieldNames.clientContact}
              options={clientContactOptions}
              label="Client Contact"
              required={true}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" type="submit">
              Add
            </Button>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { AddContactDialog };
