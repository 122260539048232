import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";

interface Props {
  json: unknown;
  onClose: () => void;
}

const ViewJsonDialog: React.FC<Props> = ({ json, onClose }) => {
  const jsonStr = JSON.stringify(json, null, 2);
  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="lg">
      <DialogTitle>View JSON</DialogTitle>
      <TextareaAutosize defaultValue={jsonStr} maxRows={30} style={{ width: "100%" }} disabled={true} />
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export { ViewJsonDialog };
