import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { BreadcrumbItem } from "components/Breadcrumbs/Breadcrumbs";
import Box from "@mui/material/Box";
import { StandardMetricTimeRange, TimeRangeSelector } from "./components/TimeRangeSelector";
import { Formik } from "formik";
import { ClientContactMtRequestsTable } from "./ClientContactMtRequestsTable/ClientContactMtRequestsTable";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useSessionStorage } from "usehooks-ts";
import { SelectOption } from "@mui/base";
import { ClientCompanySelect } from "./components/ClientCompanySelect";

const breadcrumbs: BreadcrumbItem[] = [
  {
    path: "/analytics",
    label: "Analytics"
  },
  {
    path: "/analytics/machineTranslation",
    label: "Machine Translation Requests by Client Contact",
    active: true
  }
];

const MT_ANALYTICS_PAGE_SESSION_STATE_VAR_NAME = "mtAnalyticsPageStateV1";

const MachineTranslationAnalyticsPage: React.FC = () => {
  const [timeRangeHours, setTimeRangeHours] = React.useState<StandardMetricTimeRange>(744);
  const [client, setClient] = useSessionStorage<SelectOption<string>>(MT_ANALYTICS_PAGE_SESSION_STATE_VAR_NAME, {
    label: "",
    value: ""
  });

  return (
    <PageLayout
      selectedNavMenu={NavMenuOption.ANALYTICS}
      title={<Typography variant="h6">Machine Translation Requests by Client Contact</Typography>}
      breadcrumbs={breadcrumbs}
    >
      <Formik
        initialValues={{
          client
        }}
        onSubmit={() => {
          window.console.log("onSubmit");
        }}
        enableReinitialize={true}
      >
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={6}>
            <ClientCompanySelect name="client" onChange={setClient} />
          </Grid>
          <Grid item={true} xs={12} sm={6} textAlign="right">
            <TimeRangeSelector onStandardRangeChange={setTimeRangeHours} standardRange={timeRangeHours} />
          </Grid>
        </Grid>
      </Formik>

      {client && (
        <Box sx={{ my: 2 }}>
          <ClientContactMtRequestsTable clientId={client.value} durationMinutes={timeRangeHours * 60} />
        </Box>
      )}
    </PageLayout>
  );
};

export { MachineTranslationAnalyticsPage };
