import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ClientsService, JobRequest, JobRequestsService, JobRequestStatus } from "gen/clients";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { LabelValue } from "components/LabelValue/LabelValue";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useDialogState } from "hooks/useDialogState";
import { ViewJsonDialog } from "../../components/ViewJsonDialog/ViewJsonDialog";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbarAlert } from "hooks/useSnackbarAlert";
import { DeleteJobRequestDialog } from "../../components/DeleteJobRequestDialog/DeleteJobRequestDialog";
import { ArchiveJobRequestDialog } from "../../components/ArchiveJobRequestDialog/ArchiveJobRequestDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import { PagePath } from "types/PagePath";
import { GeneralInformationSection } from "../../components/jobRequestDetails/sections/GeneralInformationSection";
import { ProjectInformationSection } from "../../components/jobRequestDetails/sections/ProjectInformationSection";
import { FilesSection } from "./FilesSection";
import { CancelJobRequestDialog } from "pages/JobRequests/components/CancelJobRequestDialog/CancelJobRequestDialog";
import { FINISHED_JOBREQUEST_STATUSES } from "../../utils/JobRequestUtils";

interface PathParams {
  jobRequestId: string;
}

const isRefreshRequired = (jobRequest?: JobRequest) => {
  if (!jobRequest) {
    return false;
  }

  switch (jobRequest.status) {
    case JobRequestStatus.CREATED:
    case JobRequestStatus.SUBMITTING_TO_XTRF:
      return true;
    default:
      return false;
  }
};

const MTJobRequestDetailsPage: React.FC = () => {
  const history = useHistory();
  const { jobRequestId } = useParams<PathParams>();
  const [viewJsonDialogOpen, openViewJsonDialog, closeViewJsonDialog] = useDialogState();
  const [deleteJobRequestDialogOpen, openDeleteJobRequestDialog, closeDeleteJobRequestDialog] = useDialogState();
  const [archiveJobRequestDialogOpen, openArchiveJobRequestDialog, closeArchiveJobRequestDialog] = useDialogState();
  const [cancelJobRequestDialogOpen, openCancelJobRequestDialog, closeCancelJobRequestDialog] = useDialogState();
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const { snackbarAlert, openSnackbarAlert, closeSnackbarAlert } = useSnackbarAlert({
    autoHideDuration: 5000,
    vertical: "bottom",
    horizontal: "left"
  });

  const {
    data: jobRequest,
    isLoading,
    isFetching,
    error,
    refetch
  } = useQuery(["getJobRequest", jobRequestId], {
    queryFn: () => JobRequestsService.getJobRequest({ jobRequestId }),
    refetchInterval: data => (isRefreshRequired(data) ? 10000 : false)
  });

  const { data: client } = useQuery(["getClient", jobRequest?.clientId], {
    enabled: !!jobRequest?.clientId,
    queryFn: () => ClientsService.getClient({ clientId: jobRequest?.clientId || "" })
  });

  const onDeleteJobRequestSuccess = React.useCallback(() => {
    closeDeleteJobRequestDialog();
    history.push(PagePath.mtJobRequests);
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeDeleteJobRequestDialog, history, openSnackbarAlert, refetch]);

  const onArchiveJobRequestSuccess = React.useCallback(() => {
    closeArchiveJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeArchiveJobRequestDialog, openSnackbarAlert, refetch]);

  const onCancelJobRequestSuccess = React.useCallback(() => {
    closeCancelJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeCancelJobRequestDialog, openSnackbarAlert, refetch]);

  return (
    <PageLayout
      title="Job Request Details"
      selectedNavMenu={NavMenuOption.MT_JOB_REQUESTS}
      breadcrumbs={[
        {
          label: "MT Job Requests",
          path: PagePath.mtJobRequests
        },
        {
          label: "Job Request Details",
          active: true
        }
      ]}
    >
      {isLoading && <PageLoading />}
      {error && <ApiErrorMessage apiError={error} />}
      {!isLoading && !error && jobRequest && (
        <Stack>
          <Paper sx={{ px: 2, pb: 2 }}>
            <Toolbar disableGutters={true} variant="dense">
              <Box flexGrow={1}>
                <LabelValue label="Job Request ID" value={jobRequestId} />
              </Box>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openCancelJobRequestDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                  color="error"
                  disabled={jobRequest.status !== JobRequestStatus.TRANSLATING}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openArchiveJobRequestDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                  disabled={!FINISHED_JOBREQUEST_STATUSES.includes(jobRequest.status)}
                >
                  Archive
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openDeleteJobRequestDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                  color="error"
                  disabled={![...FINISHED_JOBREQUEST_STATUSES, JobRequestStatus.ARCHIVED].includes(jobRequest.status)}
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={event => setMoreActionsMenuAnchorEl(event.currentTarget)}
                >
                  More Actions ...
                </Button>

                <Menu
                  anchorEl={moreActionsMenuAnchorEl}
                  open={!!moreActionsMenuAnchorEl}
                  onClose={() => setMoreActionsMenuAnchorEl(null)}
                  PaperProps={{
                    style: {
                      minWidth: 200
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      openViewJsonDialog();
                      setMoreActionsMenuAnchorEl(null);
                    }}
                  >
                    View JSON
                  </MenuItem>
                </Menu>

                <Box width={30} height={30}>
                  <IconButton onClick={() => refetch()}>
                    {!isFetching && <RefreshIcon />}
                    {isFetching && <CircularProgress size={20} />}
                  </IconButton>
                </Box>
              </Stack>
            </Toolbar>
            <Divider sx={{ mb: 2 }} />
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12} sm={6}>
                <GeneralInformationSection jobRequest={jobRequest} client={client} />
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <ProjectInformationSection jobRequest={jobRequest} hideSourceFiles={true} />
              </Grid>
            </Grid>
          </Paper>

          <FilesSection jobRequest={jobRequest} />
        </Stack>
      )}

      {viewJsonDialogOpen && <ViewJsonDialog json={jobRequest} onClose={closeViewJsonDialog} />}
      {jobRequest && deleteJobRequestDialogOpen && (
        <DeleteJobRequestDialog
          jobRequest={jobRequest}
          onSuccess={onDeleteJobRequestSuccess}
          onClose={closeDeleteJobRequestDialog}
        />
      )}
      {jobRequest && archiveJobRequestDialogOpen && (
        <ArchiveJobRequestDialog
          jobRequest={jobRequest}
          onSuccess={onArchiveJobRequestSuccess}
          onClose={closeArchiveJobRequestDialog}
        />
      )}
      {jobRequest && cancelJobRequestDialogOpen && (
        <CancelJobRequestDialog
          jobRequest={jobRequest}
          onSuccess={onCancelJobRequestSuccess}
          onClose={closeCancelJobRequestDialog}
        />
      )}
      {snackbarAlert}
    </PageLayout>
  );
};

export { MTJobRequestDetailsPage };
