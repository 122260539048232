import * as React from "react";
import { JobRequest, JobRequestsService } from "../../../../gen/clients";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { jobRequestTypeOptions } from "../../utils/JobRequestUtils";
import { useMutation } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import Stack from "@mui/material/Stack";

interface Props {
  jobRequest: JobRequest;
  onSuccess: () => void;
  onClose: () => void;
}

enum FieldNames {
  requestType = "requestType"
}

const ChangeRequestTypeDialog: React.FC<Props> = ({ jobRequest, onSuccess, onClose }) => {
  const { mutate, isLoading, error, isSuccess } = useMutation("changeJobRequestType", {
    mutationFn: JobRequestsService.changeJobRequestType
  });

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const onSubmit = React.useCallback(
    values => {
      mutate({
        jobRequestId: jobRequest.id,
        requestBody: { jobRequestType: values[FieldNames.requestType] }
      });
    },
    [jobRequest.id, mutate]
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <Formik
        initialValues={{
          [FieldNames.requestType]: jobRequest.requestType
        }}
        onSubmit={onSubmit}
        validateOnMount={false}
      >
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <DialogTitle>Change Request Type</DialogTitle>
          <DialogContent>
            {error && <ApiErrorMessage apiError={error} />}
            <Stack spacing={2}>
              <Box>Enter Request Type value:</Box>
              <SelectOneField
                name={FieldNames.requestType}
                required={true}
                variant="outlined"
                options={jobRequestTypeOptions}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton color="primary" variant="contained" isLoading={isLoading}>
              Apply
            </LoadingButton>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { ChangeRequestTypeDialog };
