/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */
import * as React from "react";
import Box from "@mui/material/Box";

interface Props {
  apiError: any;
}

export const parseApiErrorMessage = (apiError: any) => apiError.body?.message || apiError.message || "Unexpected error";

const ApiErrorMessage: React.FC<Props> = ({ apiError }) => (
  <Box color="error.main">{parseApiErrorMessage(apiError)}</Box>
);

export { ApiErrorMessage };
