import * as React from "react";
import { CheckboxWithLabel } from "formik-mui";
import { Field } from "formik";
import { FormControl, FormGroup, FormHelperText, FormLabel } from "@mui/material";
import { SelectOption } from "@mui/base";

interface Props {
  name: string;
  label?: React.ReactNode;
  options: SelectOption<string | number>[];
  disabled?: boolean;
  helperText?: string;
}

const CheckboxesField: React.FC<Props> = ({ name, label, options, disabled, helperText }) => (
  <FormControl>
    {label && <FormLabel>{label}</FormLabel>}
    <FormGroup>
      {options.map(opt => (
        <Field
          type="checkbox"
          component={CheckboxWithLabel}
          name={name}
          key={opt.value}
          value={opt.value}
          disabled={disabled || opt.disabled}
          Label={{ label: opt.label }}
        />
      ))}
    </FormGroup>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);

export { CheckboxesField };
