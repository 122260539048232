import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { DataTable } from "components/DataTable/DataTable";
import { JobRequestOrigination, JobRequestsService, JobRequestStatus, JobRequestSummary } from "gen/clients";
import { useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/dateUtils";
import { TokenBasedPaginator } from "components/TokenBasedPaginator/TokenBasedPaginator";
import CircularProgress from "@mui/material/CircularProgress";
import { JobRequestStatusText } from "../components/JobRequestStatusText/JobRequestStatusText";
import { FINISHED_JOBREQUEST_STATUSES } from "../utils/JobRequestUtils";
import Stack from "@mui/material/Stack";
import { useDialogState } from "hooks/useDialogState";
import { DataTableRowAction } from "components/DataTable/types";
import { useSnackbarAlert } from "hooks/useSnackbarAlert";
import { DeleteJobRequestDialog } from "../components/DeleteJobRequestDialog/DeleteJobRequestDialog";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { ArchiveJobRequestDialog } from "../components/ArchiveJobRequestDialog/ArchiveJobRequestDialog";
import { PagePath } from "../../../types/PagePath";
import {
  DEFAULT_JOB_REQEST_GROUP,
  JobRequestFilter,
  JobRequestFilterSearchParams
} from "../components/JobRequestFilter/JobRequestFilter";
import { ArchiveJobRequestListDialog } from "../components/ArchiveJobRequestListDialog/ArchiveJobRequestListDialog";
import { DeleteJobRequestListDialog } from "../components/DeleteJobRequestListDialog/DeleteJobRequestListDialog";
import { CancelJobRequestDialog } from "../components/CancelJobRequestDialog/CancelJobRequestDialog";
import { useSessionStorage } from "usehooks-ts";
import Link from "@mui/material/Link";

export enum FieldNames {
  searchRequestById = "searchRequestById"
}

interface MTJobRequestsPageSessionState {
  filterParams?: JobRequestFilterSearchParams;
  rowsPerPage?: number;
}

const PAGE_SESSION_STATE_VAR_NAME = "mtJobRequestsPageStateV1";

const MTJobRequestsPage: React.FC = () => {
  const history = useHistory();
  const [selectedJobRequest, setSelectedJobRequest] = React.useState<JobRequestSummary>();
  const [deleteJobRequestDialogOpen, openDeleteJobRequestDialog, closeDeleteJobRequestDialog] = useDialogState();
  const [cancelJobRequestDialogOpen, openCancelJobRequestDialog, closeCancelJobRequestDialog] = useDialogState();
  const [deleteJobRequestListDialogOpen, openDeleteJobRequestListDialog, closeDeleteJobRequestListDialog] =
    useDialogState();
  const [archiveJobRequestDialogOpen, openArchiveJobRequestDialog, closeArchiveJobRequestDialog] = useDialogState();
  const [archiveJobRequestListDialogOpen, openArchiveJobRequestListDialog, closeArchiveJobRequestListDialog] =
    useDialogState();

  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });

  const { snackbarAlert, openSnackbarAlert, closeSnackbarAlert } = useSnackbarAlert({
    autoHideDuration: 5000,
    vertical: "bottom",
    horizontal: "left"
  });
  const pageSessionState: MTJobRequestsPageSessionState | undefined = React.useMemo(
    () =>
      window.sessionStorage.getItem(PAGE_SESSION_STATE_VAR_NAME)
        ? JSON.parse(window.sessionStorage.getItem(PAGE_SESSION_STATE_VAR_NAME) as string)
        : undefined,
    []
  );
  const [rowsPerPage, setRowsPerPage] = useSessionStorage<number>(
    "mtJobRequests.rowsPerPage.1",
    pageSessionState?.rowsPerPage || 10
  );
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const [selectedRows, setSelectedRows] = React.useState<JobRequestSummary[]>([]);
  const [filterParams, setFilterParams] = React.useState<JobRequestFilterSearchParams>(
    pageSessionState?.filterParams || { group: DEFAULT_JOB_REQEST_GROUP }
  );

  // Keep session state of the page updated
  React.useEffect(() => {
    const newPageSessionState: MTJobRequestsPageSessionState = {
      rowsPerPage,
      filterParams
    };
    window.sessionStorage.setItem(PAGE_SESSION_STATE_VAR_NAME, JSON.stringify(newPageSessionState));
  }, [rowsPerPage, filterParams]);

  const {
    data: jobRequests,
    isLoading,
    error,
    refetch,
    isFetching
  } = useQuery(
    [
      "listMTJobRequests",
      pageToken,
      rowsPerPage,
      filterParams?.requestType,
      filterParams?.status,
      filterParams?.requestId,
      filterParams?.group
    ],
    {
      queryFn: () =>
        JobRequestsService.listJobRequests({
          pageToken,
          pageSize: rowsPerPage,
          requestType: filterParams?.requestType,
          status: filterParams?.status,
          origination: JobRequestOrigination.MT,
          group: "INBOX",
          jobRequestId: filterParams?.requestId?.trim()
        }),
      onSuccess: collection => {
        setNextPageToken(collection.nextPageToken);
      },
      refetchInterval: 10000
    }
  );

  const requestedByCell = React.useCallback(
    (jobRequest: JobRequestSummary) =>
      `${jobRequest.requestorFirstName || ""} ${jobRequest.requestorLastName || ""} (${jobRequest.requestorEmail})`,
    []
  );

  const nameCell = React.useCallback(
    (jobRequest: JobRequestSummary) => (
      <Box maxWidth={300}>
        <Typography noWrap={true} title={jobRequest.name} variant="body2">
          <Link onClick={e => e.preventDefault()} href={`/mtJobRequests/${jobRequest.id}`}>
            {jobRequest.name}
          </Link>
        </Typography>
      </Box>
    ),
    []
  );

  const createTimeCell = React.useCallback((jobRequest: JobRequestSummary) => formatDate(jobRequest.createTime), []);

  const statusCell = React.useCallback(
    (jobRequest: JobRequestSummary) => <JobRequestStatusText status={jobRequest.status} />,
    []
  );

  const onRowClick = React.useCallback(
    (jobRequest: JobRequestSummary) => {
      history.push(`${PagePath.mtJobRequests}/${jobRequest.id}`);
    },
    [history]
  );

  const onRowsPerPageChange = React.useCallback(
    (newRowsPerPage: number) => {
      setRowsPerPage(newRowsPerPage);
      paginatorResetRef.current();
      refetch();
    },
    [refetch, setRowsPerPage]
  );

  const onCancelJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeCancelJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeCancelJobRequestDialog, openSnackbarAlert, refetch]);

  const onDeleteJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeDeleteJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeDeleteJobRequestDialog, openSnackbarAlert, refetch]);

  const onDeleteJobRequestListSuccess = React.useCallback(() => {
    refetch();
    closeDeleteJobRequestListDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeDeleteJobRequestListDialog, openSnackbarAlert, refetch]);

  const onArchiveJobRequestSuccess = React.useCallback(() => {
    refetch();
    closeArchiveJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeArchiveJobRequestDialog, openSnackbarAlert, refetch]);

  const onArchiveJobRequestListSuccess = React.useCallback(() => {
    refetch();
    closeArchiveJobRequestListDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
  }, [closeArchiveJobRequestListDialog, openSnackbarAlert, refetch]);

  const rowActions = React.useCallback(
    (jobRequest: JobRequestSummary): DataTableRowAction[] => [
      {
        title: "View Details",
        action: () => history.push(`${PagePath.mtJobRequests}/${jobRequest.id}`)
      },
      {
        type: "DIVIDER",
        title: "divider1"
      },
      {
        title: "Cancel",
        disabled: jobRequest.status !== JobRequestStatus.TRANSLATING,
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openCancelJobRequestDialog();
        },
        color: "error.main"
      },
      {
        title: "Archive",
        disabled: !FINISHED_JOBREQUEST_STATUSES.includes(jobRequest.status),
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openArchiveJobRequestDialog();
        }
      },
      {
        title: "Delete",
        disabled: ![...FINISHED_JOBREQUEST_STATUSES, JobRequestStatus.ARCHIVED].includes(jobRequest.status),
        action: () => {
          closeSnackbarAlert();
          setSelectedJobRequest(jobRequest);
          openDeleteJobRequestDialog();
        },
        color: "error.main"
      }
    ],
    [history, closeSnackbarAlert, openArchiveJobRequestDialog, openDeleteJobRequestDialog, openCancelJobRequestDialog]
  );

  const onFilterSearch = React.useCallback(
    (searchParams: JobRequestFilterSearchParams) => {
      setFilterParams(searchParams);
      paginatorResetRef.current();
      refetch();
    },
    [refetch]
  );

  return (
    <PageLayout
      selectedNavMenu={NavMenuOption.MT_JOB_REQUESTS}
      title={
        <Toolbar disableGutters={true} variant="dense">
          <Box flexGrow={1}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Machine Translation Job Requests
            </Typography>
          </Box>
          <Stack direction="row" spacing={1}>
            <Button
              disabled={
                selectedRows.length === 0 || selectedRows.some(r => !FINISHED_JOBREQUEST_STATUSES.includes(r.status))
              }
              variant="outlined"
              onClick={() => {
                openArchiveJobRequestListDialog();
                closeSnackbarAlert();
              }}
            >
              Archive
            </Button>
            <Button
              disabled={selectedRows.length === 0}
              variant="outlined"
              color="error"
              onClick={() => {
                openDeleteJobRequestListDialog();
                closeSnackbarAlert();
              }}
            >
              Delete
            </Button>
            <Box width={30} height={30}>
              <IconButton onClick={() => refetch()}>
                {!isFetching && <RefreshIcon />}
                {isFetching && <CircularProgress size={20} />}
              </IconButton>
            </Box>
          </Stack>
        </Toolbar>
      }
    >
      <Box sx={{ mb: 1 }}>
        <JobRequestFilter
          initialValues={filterParams}
          onSearch={onFilterSearch}
          hideRequestTypeFilter={true}
          statuses={[
            JobRequestStatus.CREATED,
            JobRequestStatus.TRANSLATING,
            JobRequestStatus.FAILED,
            JobRequestStatus.SUBMITTED_TO_XTRF,
            JobRequestStatus.SUBMITTING_TO_XTRF,
            JobRequestStatus.CANCELLED,
            JobRequestStatus.COMPLETED,
            JobRequestStatus.ARCHIVED
          ]}
        />
      </Box>

      <DataTable
        columns={[
          {
            id: "name",
            title: "Name",
            cell: nameCell
          },
          {
            id: "requestedBy",
            title: "Requested By",
            cell: requestedByCell
          },
          {
            id: "createTime",
            title: "Created",
            cell: createTimeCell
          },
          {
            id: "status",
            title: "Status",
            cell: statusCell
          }
        ]}
        data={jobRequests?.items || []}
        rowKey={(jobRequest: JobRequestSummary) => jobRequest.id}
        isLoading={isLoading}
        error={!!error && <ApiErrorMessage apiError={error} />}
        onRowClick={onRowClick}
        onSelectRows={setSelectedRows}
        rowActions={rowActions}
        dense={true}
      />
      <TokenBasedPaginator
        nextPageToken={nextPageToken}
        rowsPerPage={rowsPerPage}
        onPageTokenChange={setPageToken}
        currentPageDataRows={jobRequests?.items.length}
        onRowsPerPageChange={onRowsPerPageChange}
        resetRef={paginatorResetRef}
      />
      {archiveJobRequestListDialogOpen && (
        <ArchiveJobRequestListDialog
          jobRequests={selectedRows}
          onSuccess={onArchiveJobRequestListSuccess}
          onClose={closeArchiveJobRequestListDialog}
        />
      )}
      {deleteJobRequestDialogOpen && selectedJobRequest && (
        <DeleteJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onDeleteJobRequestSuccess}
          onClose={closeDeleteJobRequestDialog}
        />
      )}
      {deleteJobRequestListDialogOpen && (
        <DeleteJobRequestListDialog
          jobRequests={selectedRows}
          onSuccess={onDeleteJobRequestListSuccess}
          onClose={closeDeleteJobRequestListDialog}
        />
      )}
      {cancelJobRequestDialogOpen && selectedJobRequest && (
        <CancelJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onCancelJobRequestSuccess}
          onClose={closeCancelJobRequestDialog}
        />
      )}
      {archiveJobRequestDialogOpen && selectedJobRequest && (
        <ArchiveJobRequestDialog
          jobRequest={selectedJobRequest}
          onSuccess={onArchiveJobRequestSuccess}
          onClose={closeArchiveJobRequestDialog}
        />
      )}
      {snackbarAlert}
    </PageLayout>
  );
};

export { MTJobRequestsPage };
