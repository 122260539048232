import * as React from "react";
import { ClientsService, CustomFieldDefinition } from "gen/clients";
import { useDialogState } from "hooks/useDialogState";
import { useMutation, useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { CreateCustomFieldPanel } from "./CreateCustomFieldPanel";
import { EditCustomFieldPanel } from "./EditCustomFieldPanel";
import { SnackbarApiError } from "../../../../../components/SnackbarApiError/SnackbarApiError";
import { DataTable } from "../../../../../components/DataTable/DataTable";
import { DataTableRowAction } from "../../../../../components/DataTable/types";
import { Button, DialogContentText } from "@mui/material";
import { ConfirmationDialog } from "../../../../../components/ConfirmationDialog/ConfirmationDialog";
import { SelectOption, SelectOptionGroup } from "@mui/base";

interface Props {
  clientId: string;
  category: CustomFieldDefinition.category;
  onSaveSuccess: () => void;
}

const STANDARD_FIELD_PREFIX = "STANDARD_FIELD#";

const PROJECT_MAPPING_OPTIONS: SelectOptionGroup<string>[] = [
  {
    label: "Custom XTRF Fields",
    options: [
      { label: "Client Billing Number", value: "Client Billing Number" },
      { label: "Client Custom ID 1", value: "Client Custom ID 1" },
      { label: "Client Custom ID 2", value: "Client Custom ID 2" },
      { label: "Client Custom ID 3", value: "Client Custom ID 3" },
      { label: "Client Custom ID 4", value: "Client Custom ID 4" },
      { label: "Client Custom ID 5", value: "Client Custom ID 5" },
      { label: "Client Custom ID 6", value: "Client Custom ID 6" },
      { label: "Timeline", value: "Timeline" },
      { label: "Notes and Assumptions", value: "Notes and Assumptions" },
      { label: "Estimated Turnaround Time", value: "Estimated Turnaround Time" },
      { label: "Special Billing Instructions", value: "Client PO Number" }
    ]
  },
  {
    label: "Standard XTRF Fields",
    options: [
      {
        label: "Client Reference Number",
        value: `${STANDARD_FIELD_PREFIX}CLIENT_REFERENCE_NUMBER`
      },
      { label: "Volume", value: `${STANDARD_FIELD_PREFIX}VOLUME` },
      { label: "Vendor Instructions", value: `${STANDARD_FIELD_PREFIX}VENDOR_INSTRUCTIONS` },
      { label: "Internal Notes", value: `${STANDARD_FIELD_PREFIX}INTERNAL_NOTES` }
    ]
  }
];

const USER_MAPPING_OPTIONS: SelectOptionGroup<string>[] = [
  {
    label: "Custom XTRF Fields",
    options: [
      {
        label: "Special Billing Instructions_Client Contact",
        value: "Special Billing Instructions_Client Contact"
      },
      {
        label: "Other Special Instructions",
        value: "Other Special Instructions"
      },
      {
        label: "Client Billing Number_Client Contact",
        value: "Client Billing Number_Client Contact"
      },
      {
        label: "Client Contact_Custom ID 1",
        value: "Client Contact_Custom ID 1"
      },
      {
        label: "Client Contact_Custom ID 2",
        value: "Client Contact_Custom ID 2"
      },
      {
        label: "Client Contact_Custom ID 3",
        value: "Client Contact_Custom ID 3"
      }
    ]
  }
];

const CustomFieldsTab: React.FC<Props> = ({ clientId, category, onSaveSuccess }) => {
  const [isCreateCustomFieldPanelOpen, openCreateCustomFieldPanel, closeCreateCustomFieldPanel] = useDialogState();
  const [isEditCustomFieldPanelOpen, openEditCustomFieldPanel, closeEditCustomFieldPanel] = useDialogState();
  const [isConfirmDeleteOpen, openConfirmDelete, closeConfirmDelete] = useDialogState();
  const [selectedFieldDefinition, setSelectedFieldDefinition] = React.useState<CustomFieldDefinition>();

  const {
    data: customFieldDefinitions,
    isLoading: isCustomFieldDefinitionssLoading,
    error: customFieldDefinitionsError,
    refetch: refetchCustomFieldDefinitions
  } = useQuery(["listCustomFieldDefinitions", clientId], {
    queryFn: () => ClientsService.listCustomFieldDefinitions({ clientId })
  });

  const sortedCustomFieldDefinitions = React.useMemo(
    () => (customFieldDefinitions || []).sort((f1, f2) => f1.category.localeCompare(f2.category)),
    [customFieldDefinitions]
  );

  const customFieldDefinitionsOfCategory = React.useMemo(() => {
    window.console.log("OK");
    return (customFieldDefinitions || []).filter(f => f.category === category);
  }, [customFieldDefinitions, category]);

  const { mutate, error: mutationError } = useMutation("updateCustomFieldDefinitions", {
    onSuccess: onSaveSuccess,
    mutationFn: ClientsService.updateCustomFieldDefinitions
  });

  const onDeleteMenuClick = React.useCallback(
    (fieldDefinition: CustomFieldDefinition) => {
      setSelectedFieldDefinition(fieldDefinition);
      openConfirmDelete();
    },
    [openConfirmDelete]
  );

  const deleteSelectedCustomFieldDefinition = React.useCallback(() => {
    const modifiedFieldDefinitions = sortedCustomFieldDefinitions.filter(
      f => !(f.name === selectedFieldDefinition?.name && f.category === category)
    );
    mutate(
      {
        clientId,
        requestBody: modifiedFieldDefinitions
      },
      {
        onSuccess: () => {
          setSelectedFieldDefinition(undefined);
          closeConfirmDelete();
          refetchCustomFieldDefinitions();
        }
      }
    );
  }, [
    sortedCustomFieldDefinitions,
    mutate,
    clientId,
    selectedFieldDefinition?.name,
    category,
    closeConfirmDelete,
    refetchCustomFieldDefinitions
  ]);

  const onMoveUpClick = React.useCallback(
    (fieldDefinition: CustomFieldDefinition) => {
      const fieldDefinitions = [...sortedCustomFieldDefinitions];
      const oldIndex = fieldDefinitions.findIndex(f => f.name === fieldDefinition?.name);
      fieldDefinitions.splice(oldIndex, 1);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fieldDefinitions.splice(oldIndex - 1, 0, fieldDefinition!);
      mutate(
        {
          clientId,
          requestBody: fieldDefinitions
        },
        {
          onSuccess: () => refetchCustomFieldDefinitions()
        }
      );
    },
    [sortedCustomFieldDefinitions, mutate, clientId, refetchCustomFieldDefinitions]
  );

  const onMoveDownClick = React.useCallback(
    (fieldDefinition: CustomFieldDefinition) => {
      const fieldDefinitions = [...sortedCustomFieldDefinitions];
      const oldIndex = fieldDefinitions.findIndex(f => f.name === fieldDefinition?.name);
      fieldDefinitions.splice(oldIndex, 1);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      fieldDefinitions.splice(oldIndex + 1, 0, fieldDefinition!);
      mutate(
        {
          clientId,
          requestBody: fieldDefinitions
        },
        {
          onSuccess: () => refetchCustomFieldDefinitions()
        }
      );
    },
    [sortedCustomFieldDefinitions, mutate, clientId, refetchCustomFieldDefinitions]
  );

  const rowKey = React.useCallback((field: CustomFieldDefinition) => field.name, []);
  const fieldNameCell = React.useCallback((field: CustomFieldDefinition) => field.name, []);
  const typeCell = React.useCallback((field: CustomFieldDefinition) => field.type, []);
  const mappingCell = React.useCallback(
    (field: CustomFieldDefinition) =>
      (category === CustomFieldDefinition.category.PROJECT ? PROJECT_MAPPING_OPTIONS : USER_MAPPING_OPTIONS)
        .flatMap(item => item.options)
        .find(option => (option as SelectOption<string>).value === field.mapping)?.label,
    [category]
  );
  const requiredCell = React.useCallback((field: CustomFieldDefinition) => (field.required ? "Yes" : "No"), []);

  const onEditClick = React.useCallback(
    (field: CustomFieldDefinition) => {
      setSelectedFieldDefinition(field);
      openEditCustomFieldPanel();
    },
    [openEditCustomFieldPanel]
  );

  const rowActions = React.useCallback(
    (field: CustomFieldDefinition, rowIndex: number): DataTableRowAction[] => {
      const actions: DataTableRowAction[] = [
        {
          title: "Edit",
          action: onEditClick
        }
      ];
      if (rowIndex > 0) {
        actions.push({
          title: "Move Up",
          action: onMoveUpClick
        });
      }
      if (rowIndex < (customFieldDefinitionsOfCategory || []).length - 1) {
        actions.push({
          title: "Move Down",
          action: onMoveDownClick
        });
      }
      actions.push({
        title: "Delete",
        action: onDeleteMenuClick,
        color: "error.light"
      });
      return actions;
    },
    [onEditClick, customFieldDefinitionsOfCategory, onDeleteMenuClick, onMoveDownClick, onMoveUpClick]
  );

  return (
    <>
      {mutationError && <SnackbarApiError error={mutationError} />}
      <Button color="primary" variant="contained" size="large" onClick={openCreateCustomFieldPanel} sx={{ my: 2 }}>
        Add Custom Field
      </Button>
      <DataTable
        columns={[
          {
            id: "fieldName",
            title: "Field Name",
            cell: fieldNameCell
          },
          {
            id: "type",
            title: "Type",
            cell: typeCell
          },
          {
            id: "mapping",
            title: "Mapping",
            cell: mappingCell
          },
          {
            id: "required",
            title: "Required",
            cell: requiredCell
          }
        ]}
        data={customFieldDefinitionsOfCategory || []}
        rowKey={rowKey}
        rowActions={rowActions}
        isLoading={isCustomFieldDefinitionssLoading}
        error={!!customFieldDefinitionsError && <ApiErrorMessage apiError={customFieldDefinitionsError} />}
      />

      {isCreateCustomFieldPanelOpen && (
        <CreateCustomFieldPanel
          clientId={clientId}
          category={category}
          customFieldDefinitions={sortedCustomFieldDefinitions}
          open={isCreateCustomFieldPanelOpen}
          onClose={closeCreateCustomFieldPanel}
          onSuccess={() => {
            closeCreateCustomFieldPanel();
            onSaveSuccess();
            refetchCustomFieldDefinitions();
          }}
          mappingOptions={
            category === CustomFieldDefinition.category.PROJECT ? PROJECT_MAPPING_OPTIONS : USER_MAPPING_OPTIONS
          }
        />
      )}

      {selectedFieldDefinition && isEditCustomFieldPanelOpen && (
        <EditCustomFieldPanel
          clientId={clientId}
          customFieldDefinitions={sortedCustomFieldDefinitions}
          editedFieldIndex={customFieldDefinitions?.findIndex(c => c.name === selectedFieldDefinition.name) || 0}
          open={isEditCustomFieldPanelOpen}
          onClose={closeEditCustomFieldPanel}
          onSuccess={() => {
            closeEditCustomFieldPanel();
            onSaveSuccess();
            refetchCustomFieldDefinitions();
          }}
          mappingOptions={
            category === CustomFieldDefinition.category.PROJECT ? PROJECT_MAPPING_OPTIONS : USER_MAPPING_OPTIONS
          }
        />
      )}
      {isConfirmDeleteOpen && (
        <ConfirmationDialog
          title="Delete Custom Field Definition?"
          content={<DialogContentText>Are you sure you want to delete this custom field definition?</DialogContentText>}
          onClose={closeConfirmDelete}
          onConfirm={deleteSelectedCustomFieldDefinition}
        />
      )}
    </>
  );
};

export { CustomFieldsTab };
