import { Card, InputLabel, Grid, Stack, Button, TextField } from "@mui/material";
import { SystemSettings } from "gen/clients";
import { useDialogState } from "hooks/useDialogState";
import { EditInstructionsPanel } from "./EditInstructionsPanel";
import { TestInstructionsPanel } from "./TestInstructionsPanel";
import React from "react";

interface Props {
  systemSettings: SystemSettings;
  systemSettingsRefetch: () => void;
}

const OpenAISettingsCard: React.FC<Props> = ({ systemSettings, systemSettingsRefetch }) => {
  const [isEditPanelOpen, openEditPanel, closeEditPanel] = useDialogState();
  const [isTestPanelOpen, openTestPanel, closeTestPanel] = useDialogState();

  const onInstructionsUpdated = React.useCallback(() => {
    systemSettingsRefetch();
    closeEditPanel();
  }, [closeEditPanel, systemSettingsRefetch]);

  if (systemSettings) {
    return (
      <>
        <Card sx={{ mt: 2, p: 2 }}>
          <InputLabel>Auto-Quote Rejection Reasons Analytics Instructions"</InputLabel>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12}>
              <TextField
                label="Auto-Quote Rejection Reasons Analytics Instructions"
                helperText="Instructions for AI about how to gather analytics on auto-quote rejection reasons"
                variant="outlined"
                value={systemSettings.autoQuoteSettings?.rejectedReasonsAnalyticsAIPrompt || ""}
                multiline
                disabled
                rows={10}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="primary" onClick={openEditPanel}>
                  Edit
                </Button>
                <Button variant="outlined" color="primary" onClick={openTestPanel}>
                  Test
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {isTestPanelOpen && <TestInstructionsPanel onClose={closeTestPanel} />}
          {isEditPanelOpen && (
            <EditInstructionsPanel
              systemSettings={systemSettings}
              onSuccess={() => onInstructionsUpdated()}
              onClose={closeEditPanel}
            />
          )}
        </Card>
      </>
    );
  }
  return <></>;
};

export { OpenAISettingsCard };
