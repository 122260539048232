import * as React from "react";
import Link from "@mui/material/Link";
import { JobRequestType } from "gen/clients";

interface Props {
  type?: JobRequestType;
  xtrfId: string;
  name: string;
}

const XtrfProjectOrQuoteLink: React.FC<Props> = ({ type, xtrfId, name }) => {
  const linkUrl = React.useMemo(() => {
    if (type === JobRequestType.TRANSLATION_REQUEST) {
      return `${process.env.REACT_APP_XTRF_HOME_PORTAL_URL}/xtrf/faces/projectAssistant/projects/project.seam?assistedProjectId=${xtrfId}`;
    }
    if (type === JobRequestType.QUOTE_REQUEST) {
      return `${process.env.REACT_APP_XTRF_HOME_PORTAL_URL}/xtrf/faces/projectAssistant/quotes/quote.seam?assistedProjectId=${xtrfId}`;
    }
    return "";
  }, [type, xtrfId]);

  return (
    <Link href={linkUrl} target="_blank">
      {name}
    </Link>
  );
};

export { XtrfProjectOrQuoteLink };
