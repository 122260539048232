import * as React from "react";
import { ClientsService, ReportDefinition } from "gen/clients";
import { useDialogState } from "hooks/useDialogState";
import { useMutation, useQuery } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableRowAction } from "components/DataTable/types";
import { Button, DialogContentText } from "@mui/material";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { CreateReportPanel } from "./CreateReportPanel";
import { EditReportPanel } from "./EditReportPanel";

interface Props {
  clientId: string;
  onSaveSuccess: () => void;
}

function reportTypeString(reportType: ReportDefinition.type) {
  switch (reportType) {
    case ReportDefinition.type.PROJECT:
      return "Project Report";
    case ReportDefinition.type.QUOTE:
      return "Quote Report";
    case ReportDefinition.type.CLIENT_CONTACT:
      return "Client Contact Report";
    case ReportDefinition.type.TASK:
      return "Task Report";
    default:
      return "";
  }
}

const ReportsTab: React.FC<Props> = ({ clientId, onSaveSuccess }) => {
  const [isCreateReportPanelOpen, openCreateReportPanel, closeCreateReportPanel] = useDialogState();
  const [isEditReportPanelOpen, openEditReportPanel, closeEditReportPanel] = useDialogState();
  const [isConfirmDeleteOpen, openConfirmDelete, closeConfirmDelete] = useDialogState();
  const [selectedReportDefinition, setSelectedReportDefinition] = React.useState<ReportDefinition>();

  const {
    data: clientConfig,
    isLoading,
    error,
    refetch
  } = useQuery([clientId], {
    queryFn: () => ClientsService.getClientConfig({ id: clientId })
  });

  const reportDefinitions = React.useMemo(() => clientConfig?.reports || [], [clientConfig]);

  const { mutate, error: mutationError } = useMutation({
    onSuccess: onSaveSuccess,
    mutationFn: ClientsService.updateClientConfig
  });

  const onDeleteMenuClick = React.useCallback(
    (reportDefinition: ReportDefinition) => {
      setSelectedReportDefinition(reportDefinition);
      openConfirmDelete();
    },
    [openConfirmDelete]
  );

  const deleteSelectedReportDefinition = React.useCallback(() => {
    if (!clientConfig) {
      return;
    }
    const modifiedReportDefinitions = reportDefinitions.filter(f => !(f.name === selectedReportDefinition?.name));
    const clientConfigUpdate = { ...clientConfig, reports: modifiedReportDefinitions };
    mutate(
      {
        id: clientConfig.id,
        requestBody: clientConfigUpdate
      },
      {
        onSuccess: () => {
          setSelectedReportDefinition(undefined);
          refetch();
        }
      }
    );
  }, [reportDefinitions, mutate, selectedReportDefinition?.name, clientConfig, refetch]);

  const onMoveUpClick = React.useCallback(
    (reportDefinition: ReportDefinition) => {
      if (!clientConfig) {
        return;
      }
      const modifiedReportDefinitions = [...reportDefinitions];
      const oldIndex = modifiedReportDefinitions.findIndex(f => f.id === reportDefinition?.id);
      modifiedReportDefinitions.splice(oldIndex, 1);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      modifiedReportDefinitions.splice(oldIndex - 1, 0, reportDefinition!);
      const clientConfigUpdate = { ...clientConfig, reports: modifiedReportDefinitions };
      mutate(
        {
          id: clientConfig.id,
          requestBody: clientConfigUpdate
        },
        {
          onSuccess: () => refetch()
        }
      );
    },
    [reportDefinitions, mutate, refetch, clientConfig]
  );

  const onMoveDownClick = React.useCallback(
    (reportDefinition: ReportDefinition) => {
      if (!clientConfig) {
        return;
      }
      const modifiedReportDefinitions = [...reportDefinitions];
      const oldIndex = modifiedReportDefinitions.findIndex(f => f.id === reportDefinition?.id);
      modifiedReportDefinitions.splice(oldIndex, 1);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      modifiedReportDefinitions.splice(oldIndex + 1, 0, reportDefinition!);
      const clientConfigUpdate = { ...clientConfig, reports: modifiedReportDefinitions };
      mutate(
        {
          id: clientConfig.id,
          requestBody: clientConfigUpdate
        },
        {
          onSuccess: () => refetch()
        }
      );
    },
    [reportDefinitions, mutate, clientConfig, refetch]
  );

  const rowKey = React.useCallback((reportDefinition: ReportDefinition) => reportDefinition.id, []);
  const reportNameCell = React.useCallback((reportDefinition: ReportDefinition) => reportDefinition.name, []);
  const typeCell = React.useCallback(
    (reportDefinition: ReportDefinition) => reportTypeString(reportDefinition.type),
    []
  );
  const viewIdCell = React.useCallback((reportDefinition: ReportDefinition) => reportDefinition.viewId, []);
  const descriptionCell = React.useCallback((reportDefinition: ReportDefinition) => reportDefinition.description, []);

  const onEditClick = React.useCallback(
    (reportDefinition: ReportDefinition) => {
      setSelectedReportDefinition(reportDefinition);
      openEditReportPanel();
    },
    [openEditReportPanel]
  );

  const rowActions = React.useCallback(
    (report: ReportDefinition, rowIndex: number): DataTableRowAction[] => {
      const actions: DataTableRowAction[] = [
        {
          title: "Edit",
          action: onEditClick
        }
      ];
      if (rowIndex > 0) {
        actions.push({
          title: "Move Up",
          action: onMoveUpClick
        });
      }
      if (rowIndex < (reportDefinitions || []).length - 1) {
        actions.push({
          title: "Move Down",
          action: onMoveDownClick
        });
      }
      actions.push({
        title: "Delete",
        action: onDeleteMenuClick,
        color: "error.light"
      });
      return actions;
    },
    [onEditClick, reportDefinitions, onDeleteMenuClick, onMoveDownClick, onMoveUpClick]
  );

  return (
    <>
      {mutationError && <SnackbarApiError error={mutationError} />}
      <Button color="primary" variant="contained" size="large" onClick={openCreateReportPanel} sx={{ my: 2 }}>
        Add Report
      </Button>
      <DataTable
        columns={[
          {
            id: "reportName",
            title: "Report Name",
            cell: reportNameCell
          },
          {
            id: "type",
            title: "Type",
            cell: typeCell
          },
          {
            id: "viewId",
            title: "View ID",
            cell: viewIdCell
          },
          {
            id: "description",
            title: "Description",
            cell: descriptionCell
          }
        ]}
        data={reportDefinitions || []}
        rowKey={rowKey}
        rowActions={rowActions}
        isLoading={isLoading}
        error={!!error && <ApiErrorMessage apiError={error} />}
      />

      {isCreateReportPanelOpen && clientConfig && (
        <CreateReportPanel
          clientConfig={clientConfig}
          reportDefinitions={reportDefinitions}
          open={isCreateReportPanelOpen}
          onClose={closeCreateReportPanel}
          onSuccess={() => {
            closeCreateReportPanel();
            onSaveSuccess();
            refetch();
          }}
        />
      )}

      {selectedReportDefinition && isEditReportPanelOpen && clientConfig && (
        <EditReportPanel
          clientConfig={clientConfig}
          reportDefinitions={reportDefinitions}
          editedReportIndex={reportDefinitions?.findIndex(c => c.name === selectedReportDefinition.name) || 0}
          open={isEditReportPanelOpen}
          onClose={closeEditReportPanel}
          onSuccess={() => {
            closeEditReportPanel();
            onSaveSuccess();
            refetch();
          }}
        />
      )}
      {isConfirmDeleteOpen && (
        <ConfirmationDialog
          title="Delete Report?"
          content={<DialogContentText>Are you sure you want to delete this report?</DialogContentText>}
          onClose={closeConfirmDelete}
          onConfirm={deleteSelectedReportDefinition}
        />
      )}
    </>
  );
};

export { ReportsTab };
