import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ClientsService, JobRequest, JobRequestsService, JobRequestStatus } from "gen/clients";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { LabelValue } from "components/LabelValue/LabelValue";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useDialogState } from "hooks/useDialogState";
import { ViewJsonDialog } from "../../components/ViewJsonDialog/ViewJsonDialog";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { useSnackbarAlert } from "hooks/useSnackbarAlert";
import { DeleteJobRequestDialog } from "../../components/DeleteJobRequestDialog/DeleteJobRequestDialog";
import { ArchiveJobRequestDialog } from "../../components/ArchiveJobRequestDialog/ArchiveJobRequestDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import { PagePath } from "types/PagePath";
import { GeneralInformationSection } from "../../components/jobRequestDetails/sections/GeneralInformationSection";
import { ProjectInformationSection } from "../../components/jobRequestDetails/sections/ProjectInformationSection";
import { FilesTab } from "./tabs/FilesTab/FilesTab";
import { Card, Tab, Tabs } from "@mui/material";
import { AutoQuoteTab } from "./tabs/AutoQuoteTab/AutoQuoteTab";
import { ContactDetailsSection } from "../../components/jobRequestDetails/sections/ContactDetailsSection";
import { ReviewJobRequestPanel } from "../../components/ReviewJobRequestPanel/ReviewJobRequestPanel";

interface PathParams {
  jobRequestId: string;
}

enum TabIds {
  SOURCE_FILES = "SOURCE_FILES",
  AUTO_QUOTE = "AUTO_QUOTE"
}

const isRefreshRequired = (jobRequest?: JobRequest) => {
  if (!jobRequest) {
    return false;
  }

  switch (jobRequest.status) {
    case JobRequestStatus.CREATED:
    case JobRequestStatus.SUBMITTING_TO_XTRF:
      return true;
    default:
      return false;
  }
};

const QuoteJobRequestDetailsPage: React.FC = () => {
  const history = useHistory();
  const { jobRequestId } = useParams<PathParams>();
  const [selectedTab, setSelectedTab] = React.useState<string>(TabIds.SOURCE_FILES);
  const [viewJsonDialogOpen, openViewJsonDialog, closeViewJsonDialog] = useDialogState();
  const [deleteJobRequestDialogOpen, openDeleteJobRequestDialog, closeDeleteJobRequestDialog] = useDialogState();
  const [archiveJobRequestDialogOpen, openArchiveJobRequestDialog, closeArchiveJobRequestDialog] = useDialogState();
  const [reviewJobRequestPanelOpen, openReviewJobRequestPanel, closeReviewJobRequestPanel] = useDialogState();
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const { snackbarAlert, openSnackbarAlert, closeSnackbarAlert } = useSnackbarAlert({
    autoHideDuration: 5000,
    vertical: "bottom",
    horizontal: "left"
  });

  const {
    data: jobRequest,
    isLoading,
    isFetching,
    error,
    refetch
  } = useQuery(["getJobRequest", jobRequestId], {
    queryFn: () => JobRequestsService.getJobRequest({ jobRequestId }),
    refetchInterval: data => (isRefreshRequired(data) ? 10000 : false)
  });

  const { data: client } = useQuery(["getClient", jobRequest?.clientId], {
    enabled: !!jobRequest?.clientId,
    queryFn: () => ClientsService.getClient({ clientId: jobRequest?.clientId || "" })
  });

  const onDeleteJobRequestSuccess = React.useCallback(() => {
    closeDeleteJobRequestDialog();
    history.push(PagePath.quoteJobRequests);
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeDeleteJobRequestDialog, history, openSnackbarAlert, refetch]);

  const onArchiveJobRequestSuccess = React.useCallback(() => {
    closeArchiveJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeArchiveJobRequestDialog, openSnackbarAlert, refetch]);

  const onReviewSuccess = React.useCallback(() => {
    closeReviewJobRequestPanel();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeReviewJobRequestPanel, openSnackbarAlert, refetch]);

  return (
    <PageLayout
      title="Quote Request Details"
      selectedNavMenu={NavMenuOption.QUOTE_JOB_REQUESTS}
      breadcrumbs={[
        {
          label: "Quote Requests",
          path: PagePath.quoteJobRequests
        },
        {
          label: "Quote Request Details",
          active: true
        }
      ]}
    >
      {isLoading && <PageLoading />}
      {error && <ApiErrorMessage apiError={error} />}
      {!isLoading && !error && jobRequest && (
        <Stack spacing={3}>
          <Paper sx={{ px: 2, pb: 2 }}>
            <Toolbar disableGutters={true} variant="dense">
              <Box flexGrow={1}>
                <LabelValue label="Job Request ID" value={jobRequestId} />
              </Box>
              <Stack direction="row" spacing={1}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={openReviewJobRequestPanel}
                  disabled={
                    ![JobRequestStatus.READY_FOR_INTERNAL_REVIEW, JobRequestStatus.QUOTE_ACCEPTED].includes(
                      jobRequest.status
                    )
                  }
                >
                  Submit to XTRF
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openArchiveJobRequestDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                >
                  Archive
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeSnackbarAlert();
                    openDeleteJobRequestDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                  color="error"
                >
                  Delete
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={event => setMoreActionsMenuAnchorEl(event.currentTarget)}
                >
                  More Actions ...
                </Button>

                <Menu
                  anchorEl={moreActionsMenuAnchorEl}
                  open={!!moreActionsMenuAnchorEl}
                  onClose={() => setMoreActionsMenuAnchorEl(null)}
                  PaperProps={{
                    style: {
                      minWidth: 200
                    }
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      openViewJsonDialog();
                      setMoreActionsMenuAnchorEl(null);
                    }}
                  >
                    View JSON
                  </MenuItem>
                </Menu>

                <Box width={30} height={30}>
                  <IconButton onClick={() => refetch()}>
                    {!isFetching && <RefreshIcon />}
                    {isFetching && <CircularProgress size={20} />}
                  </IconButton>
                </Box>
              </Stack>
            </Toolbar>
            <Divider sx={{ mb: 2 }} />
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12} sm={6}>
                <Stack>
                  <GeneralInformationSection jobRequest={jobRequest} client={client} notesMinRows={3} />
                  <ContactDetailsSection jobRequest={jobRequest} />
                </Stack>
              </Grid>
              <Grid item={true} xs={12} sm={6}>
                <ProjectInformationSection
                  jobRequest={jobRequest}
                  hideSourceFiles={true}
                  hideStandardAndRushQuotesOption={true}
                />
              </Grid>
            </Grid>
          </Paper>

          <Card>
            <Tabs value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
              <Tab value={TabIds.SOURCE_FILES} label="Source Files" />
              <Tab value={TabIds.AUTO_QUOTE} label="Auto-Quote" />
            </Tabs>
            <Divider />
            {selectedTab === TabIds.SOURCE_FILES && <FilesTab jobRequest={jobRequest} />}
            {selectedTab === TabIds.AUTO_QUOTE && <AutoQuoteTab jobRequest={jobRequest} />}
          </Card>
        </Stack>
      )}

      {viewJsonDialogOpen && <ViewJsonDialog json={jobRequest} onClose={closeViewJsonDialog} />}
      {jobRequest && deleteJobRequestDialogOpen && (
        <DeleteJobRequestDialog
          jobRequest={jobRequest}
          onSuccess={onDeleteJobRequestSuccess}
          onClose={closeDeleteJobRequestDialog}
        />
      )}
      {jobRequest && archiveJobRequestDialogOpen && (
        <ArchiveJobRequestDialog
          jobRequest={jobRequest}
          onSuccess={onArchiveJobRequestSuccess}
          onClose={closeArchiveJobRequestDialog}
        />
      )}
      {jobRequest && reviewJobRequestPanelOpen && (
        <ReviewJobRequestPanel
          jobRequestId={jobRequest.id}
          onClose={closeReviewJobRequestPanel}
          onSuccess={onReviewSuccess}
          title="Submit to XTRF"
          projectName={jobRequest.name}
          suppressClientNotification={true}
        />
      )}
      {snackbarAlert}
    </PageLayout>
  );
};

export { QuoteJobRequestDetailsPage };
