import * as React from "react";
import { Button, Drawer, Stack } from "@mui/material";
import { SelectOption } from "@mui/base";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { ClientConfig, ClientsService, WorkflowOption } from "gen/clients";
import { Form, Formik, FormikValues } from "formik";
import { DrawerHeader } from "components/DrawerHeader/DrawerHeader";
import { DrawerBody } from "components/DrawerBody/DrawerBody";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { useMutation } from "react-query";
import { ApiErrorMessage } from "../../../../../components/ApiErrorMessage/ApiErrorMessage";
import { LoadingButton } from "../../../../../components/LoadingButton/LoadingButton";

interface Props {
  workflow: WorkflowOption;
  clientConfig: ClientConfig;
  open: boolean;
  xtrfServices: SelectOption<number>[];
  onClose: () => void;
  onSuccess: (workflow: WorkflowOption) => void;
}

enum FieldNames {
  name = "workflowName",
  xtrfService = "workflowXtrfService"
}

const EditWorkflowPanel: React.FC<Props> = ({ workflow, clientConfig, open, xtrfServices, onSuccess, onClose }) => {
  const { mutateAsync, error } = useMutation({
    mutationFn: ClientsService.updateClientConfig,
    onSuccess
  });

  const onSubmit = React.useCallback(
    async values => {
      const label = values[FieldNames.name];
      const xtrfServiceId = values[FieldNames.xtrfService];
      const { selectableWorkflows } = clientConfig;
      if (!selectableWorkflows) {
        throw new Error("selectableWorkflows is undefined"); // should never happen
      }
      const workflowIndex = selectableWorkflows.findIndex(w => w.label === workflow.label);
      // replace workflow at workflowIndex
      const updatedWorkflow = {
        ...workflow,
        label,
        xtrfServiceId
      };
      selectableWorkflows.splice(workflowIndex, 1, updatedWorkflow);
      await mutateAsync(
        {
          id: clientConfig.id,
          requestBody: {
            ...clientConfig,
            selectableWorkflows
          }
        },
        { onSuccess }
      );
    },
    [clientConfig, workflow, mutateAsync, onSuccess]
  );

  const validate = React.useCallback(
    (formValues: FormikValues) => {
      const errors: Record<string, string> = {};
      const name = formValues[FieldNames.name];
      if (name && name !== workflow.label && clientConfig.selectableWorkflows?.some(w => w.label === name)) {
        errors[FieldNames.name] = "Workflow name should be unique";
      }
      return errors;
    },
    [clientConfig.selectableWorkflows, workflow.label]
  );

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { width: 800 } }}>
      <DrawerHeader title="Update Workflow" onClose={onClose} />
      <DrawerBody>
        <Formik
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            [FieldNames.name]: workflow.label,
            [FieldNames.xtrfService]: workflow.xtrfServiceId
          }}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off" autoCorrect="off" noValidate={true}>
              <Stack spacing={3}>
                {error && <ApiErrorMessage apiError={error} />}
                <TextInputField name={FieldNames.name} label="Workflow Name" required={true} />
                <SelectOneField
                  name={FieldNames.xtrfService}
                  options={xtrfServices}
                  label="XTRF Service"
                  required={true}
                />
              </Stack>
              <Stack spacing={1} direction="row" marginTop={4}>
                <LoadingButton isLoading={isSubmitting} color="primary" variant="contained" type="submit">
                  Submit
                </LoadingButton>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </DrawerBody>
    </Drawer>
  );
};

export { EditWorkflowPanel };
