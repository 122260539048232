import { JobRequestFile, JobRequestsService, JobRequestStatus, JobRequestType } from "gen/clients";

export function describeRequestType(requestType: JobRequestType): string {
  switch (requestType) {
    case JobRequestType.JUNK_REQUEST:
      return "Junk Request";
    case JobRequestType.TRANSLATION_REQUEST:
      return "Translation Request";
    case JobRequestType.QUOTE_REQUEST:
      return "Quote Request";
    case JobRequestType.OTHER_REQUEST:
      return "Other Request";
    default:
      return "Unknown Request";
  }
}

export function describeJobRequestSubmitAction(requestType: JobRequestType): string {
  switch (requestType) {
    case JobRequestType.TRANSLATION_REQUEST:
      return "Submit Translation Project to XTRF";
    case JobRequestType.QUOTE_REQUEST:
      return "Submit Quote to XTRF";
    default:
      throw new Error(`Unsupported job request type: ${requestType}`);
  }
}

export function describeRequestStatus(status: JobRequestStatus): string {
  switch (status) {
    case JobRequestStatus.ANALYZING:
      return "Analyzing";
    case JobRequestStatus.TRANSLATING:
      return "Translating";
    case JobRequestStatus.COMPLETED:
      return "Completed";
    case JobRequestStatus.CREATED:
      return "Created";
    case JobRequestStatus.ARCHIVED:
      return "Archived";
    case JobRequestStatus.READY_FOR_INTERNAL_REVIEW:
      return "Waiting for internal review";
    case JobRequestStatus.SCREENING:
      return "Screening";
    case JobRequestStatus.REJECTED_BY_SCREENING:
      return "Rejected by screening";
    case JobRequestStatus.SUBMITTING_TO_XTRF:
      return "Submitting to XTRF";
    case JobRequestStatus.SUBMITTED_TO_XTRF:
      return "Submitted to XTRF";
    case JobRequestStatus.FAILED:
      return "Failed";
    case JobRequestStatus.CANCELLED:
      return "Cancelled";
    case JobRequestStatus.CANCELLING:
      return "Cancelling";
    case JobRequestStatus.QUOTE_READY_FOR_CLIENT_REVIEW:
      return "Waiting for client review";
    case JobRequestStatus.QUOTE_ACCEPTED:
      return "Quote accepted";
    case JobRequestStatus.QUOTE_REJECTED:
      return "Quote rejected";
    case JobRequestStatus.QUOTE_EXPIRED:
      return "Quote expired";
    default:
      return "Unknown status";
  }
}

export const jobRequestTypeOptions = Object.keys(JobRequestType)
  .map(type => ({
    label: describeRequestType(type as JobRequestType),
    value: type
  }))
  .sort((o1, o2) => o1.label.localeCompare(o2.label));

export const FINISHED_JOBREQUEST_STATUSES = [
  JobRequestStatus.CANCELLED,
  JobRequestStatus.FAILED,
  JobRequestStatus.COMPLETED,
  JobRequestStatus.READY_FOR_INTERNAL_REVIEW,
  JobRequestStatus.REJECTED_BY_SCREENING
];

const getFileDownloadUrl = async (jobRequestId: string, file: JobRequestFile): Promise<string | undefined> => {
  if (jobRequestId) {
    const response = await JobRequestsService.getJobRequestFileDownloadUrl({
      jobRequestId,
      fileKey: encodeURIComponent(file.fileKey)
    });
    return response.downloadUrl;
  }
  return undefined;
};

export const downloadJobRequestFile = async (jobRequestId: string, file: JobRequestFile): Promise<void> => {
  const downloadUrl = await getFileDownloadUrl(jobRequestId, file);
  if (downloadUrl) {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.target = "_blank";
    link.click();
  }
};
