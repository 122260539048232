import * as React from "react";
import { NavMenuOption, PageLayout } from "components/PageLayout/PageLayout";
import { PageLoading } from "components/PageLoading/PageLoading";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { ClientsService, JobRequest, JobRequestOrigination, JobRequestsService, JobRequestStatus } from "gen/clients";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { LabelValue } from "components/LabelValue/LabelValue";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useDialogState } from "hooks/useDialogState";
import { ViewJsonDialog } from "../../components/ViewJsonDialog/ViewJsonDialog";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import { ReviewJobRequestPanel } from "../../components/ReviewJobRequestPanel/ReviewJobRequestPanel";
import { ForwardOriginalEmailDialog } from "../../components/ForwardOriginalEmailDialog/ForwardOriginalEmailDialog";
import { ChangeRequestTypeDialog } from "../../components/ChangeRequestTypeDialog/ChangeRequestTypeDialog";
import { useSnackbarAlert } from "hooks/useSnackbarAlert";
import { DeleteJobRequestDialog } from "../../components/DeleteJobRequestDialog/DeleteJobRequestDialog";
import { ArchiveJobRequestDialog } from "../../components/ArchiveJobRequestDialog/ArchiveJobRequestDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import { PagePath } from "types/PagePath";
import { GeneralInformationSection } from "../../components/jobRequestDetails/sections/GeneralInformationSection";
import { ProjectInformationSection } from "../../components/jobRequestDetails/sections/ProjectInformationSection";

interface Props {
  origination: JobRequestOrigination;
}

interface PathParams {
  jobRequestId: string;
}

const isRefreshRequired = (jobRequest?: JobRequest) => {
  if (!jobRequest) {
    return false;
  }

  switch (jobRequest.status) {
    case JobRequestStatus.ANALYZING:
    case JobRequestStatus.SCREENING:
    case JobRequestStatus.SUBMITTING_TO_XTRF:
      return true;
    default:
      return false;
  }
};

const EmailJobRequestDetailsPage: React.FC<Props> = ({ origination }) => {
  const history = useHistory();
  const { jobRequestId } = useParams<PathParams>();
  const [viewJsonDialogOpen, openViewJsonDialog, closeViewJsonDialog] = useDialogState();
  const [forwardOriginalEmailDialogOpen, openForwardOriginalEmailDialog, closeForwardOriginalEmailDialog] =
    useDialogState();
  const [changeRequestTypeDialogOpen, openChangeRequestTypeDialog, closeChangeRequestTypeDialog] = useDialogState();
  const [reviewJobRequestPanelOpen, openReviewJobRequestPanel, closeReviewJobRequestPanel] = useDialogState();
  const [deleteJobRequestDialogOpen, openDeleteJobRequestDialog, closeDeleteJobRequestDialog] = useDialogState();
  const [archiveJobRequestDialogOpen, openArchiveJobRequestDialog, closeArchiveJobRequestDialog] = useDialogState();
  const [moreActionsMenuAnchorEl, setMoreActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const { snackbarAlert, openSnackbarAlert, closeSnackbarAlert } = useSnackbarAlert({
    autoHideDuration: 5000,
    vertical: "bottom",
    horizontal: "left"
  });

  const {
    data: jobRequest,
    isLoading,
    isFetching,
    error,
    refetch
  } = useQuery(["getJobRequest", jobRequestId], {
    queryFn: () => JobRequestsService.getJobRequest({ jobRequestId }),
    refetchInterval: data => (isRefreshRequired(data) ? 10000 : false)
  });

  const { data: client } = useQuery(["getClient", jobRequest?.clientId], {
    enabled: !!jobRequest?.clientId,
    queryFn: () => ClientsService.getClient({ clientId: jobRequest?.clientId || "" })
  });

  const onChangeRequestTypeSuccess = React.useCallback(() => {
    closeChangeRequestTypeDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeChangeRequestTypeDialog, openSnackbarAlert, refetch]);

  const onForwardOriginalEmailSuccess = React.useCallback(() => {
    closeForwardOriginalEmailDialog();
    openSnackbarAlert({ message: "Email sent", severity: "success" });
  }, [closeForwardOriginalEmailDialog, openSnackbarAlert]);

  const onDeleteJobRequestSuccess = React.useCallback(() => {
    closeDeleteJobRequestDialog();
    history.push(origination === JobRequestOrigination.EMAIL ? PagePath.emailJobRequests : PagePath.quoteJobRequests);
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeDeleteJobRequestDialog, history, origination, openSnackbarAlert, refetch]);

  const onArchiveJobRequestSuccess = React.useCallback(() => {
    closeArchiveJobRequestDialog();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeArchiveJobRequestDialog, openSnackbarAlert, refetch]);

  const onReviewSuccess = React.useCallback(() => {
    closeReviewJobRequestPanel();
    openSnackbarAlert({ message: "Operation completed successfully", severity: "success" });
    refetch();
  }, [closeReviewJobRequestPanel, openSnackbarAlert, refetch]);

  return (
    <PageLayout
      title="Job Request Details"
      selectedNavMenu={
        origination === JobRequestOrigination.EMAIL
          ? NavMenuOption.EMAIL_JOB_REQUESTS
          : NavMenuOption.QUOTE_JOB_REQUESTS
      }
      breadcrumbs={[
        {
          label: origination === JobRequestOrigination.EMAIL ? "Email Job Requests" : "Web Form Job Requests",
          path: origination === JobRequestOrigination.EMAIL ? PagePath.emailJobRequests : PagePath.quoteJobRequests
        },
        {
          label: "Job Request Details",
          active: true
        }
      ]}
    >
      {isLoading && <PageLoading />}
      {error && <ApiErrorMessage apiError={error} />}
      {!isLoading && !error && jobRequest && (
        <Paper sx={{ px: 2, pb: 2 }}>
          <Toolbar disableGutters={true} variant="dense">
            <Box flexGrow={1}>
              <LabelValue label="Job Request ID" value={jobRequestId} />
            </Box>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={openReviewJobRequestPanel}
                disabled={
                  jobRequest.status !== JobRequestStatus.READY_FOR_INTERNAL_REVIEW ||
                  (jobRequest.requestType && !["TRANSLATION_REQUEST", "QUOTE_REQUEST"].includes(jobRequest.requestType))
                }
              >
                Review Job Request
              </Button>
              <Button
                onClick={() => {
                  closeSnackbarAlert();
                  openChangeRequestTypeDialog();
                }}
                variant="outlined"
                disabled={
                  jobRequest.status === JobRequestStatus.COMPLETED ||
                  jobRequest.status === JobRequestStatus.ARCHIVED ||
                  jobRequest.status === JobRequestStatus.SUBMITTING_TO_XTRF
                }
              >
                Change Request Type
              </Button>
              <Button
                variant="outlined"
                disableElevation
                endIcon={<KeyboardArrowDownIcon />}
                onClick={event => setMoreActionsMenuAnchorEl(event.currentTarget)}
              >
                More Actions ...
              </Button>

              <Menu
                anchorEl={moreActionsMenuAnchorEl}
                open={!!moreActionsMenuAnchorEl}
                onClose={() => setMoreActionsMenuAnchorEl(null)}
                PaperProps={{
                  style: {
                    minWidth: 200
                  }
                }}
              >
                {origination === JobRequestOrigination.EMAIL && (
                  <>
                    <MenuItem
                      onClick={() => {
                        closeSnackbarAlert();
                        openForwardOriginalEmailDialog();
                        setMoreActionsMenuAnchorEl(null);
                      }}
                    >
                      Forward Original Email
                    </MenuItem>
                    <Divider />
                  </>
                )}
                <MenuItem
                  onClick={() => {
                    closeSnackbarAlert();
                    openArchiveJobRequestDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                  disabled={[
                    JobRequestStatus.ANALYZING,
                    JobRequestStatus.SUBMITTING_TO_XTRF,
                    JobRequestStatus.ARCHIVED
                  ].includes(jobRequest.status)}
                >
                  Archive
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    closeSnackbarAlert();
                    openDeleteJobRequestDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                >
                  <Typography color="error.main">Delete</Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    openViewJsonDialog();
                    setMoreActionsMenuAnchorEl(null);
                  }}
                >
                  View JSON
                </MenuItem>
              </Menu>

              <Box width={30} height={30}>
                <IconButton onClick={() => refetch()}>
                  {!isFetching && <RefreshIcon />}
                  {isFetching && <CircularProgress size={20} />}
                </IconButton>
              </Box>
            </Stack>
          </Toolbar>
          <Divider sx={{ mb: 2 }} />
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} sm={6}>
              <GeneralInformationSection jobRequest={jobRequest} client={client} />
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <ProjectInformationSection jobRequest={jobRequest} />
            </Grid>
          </Grid>
        </Paper>
      )}

      {viewJsonDialogOpen && <ViewJsonDialog json={jobRequest} onClose={closeViewJsonDialog} />}
      {forwardOriginalEmailDialogOpen && (
        <ForwardOriginalEmailDialog
          jobRequestId={jobRequestId}
          onClose={closeForwardOriginalEmailDialog}
          onSuccess={onForwardOriginalEmailSuccess}
        />
      )}
      {jobRequest && changeRequestTypeDialogOpen && (
        <ChangeRequestTypeDialog
          jobRequest={jobRequest}
          onSuccess={onChangeRequestTypeSuccess}
          onClose={closeChangeRequestTypeDialog}
        />
      )}
      {jobRequest && deleteJobRequestDialogOpen && (
        <DeleteJobRequestDialog
          jobRequest={jobRequest}
          onSuccess={onDeleteJobRequestSuccess}
          onClose={closeDeleteJobRequestDialog}
        />
      )}
      {jobRequest && archiveJobRequestDialogOpen && (
        <ArchiveJobRequestDialog
          jobRequest={jobRequest}
          onSuccess={onArchiveJobRequestSuccess}
          onClose={closeArchiveJobRequestDialog}
        />
      )}
      {jobRequest && reviewJobRequestPanelOpen && (
        <ReviewJobRequestPanel
          jobRequestId={jobRequest.id}
          onClose={closeReviewJobRequestPanel}
          onSuccess={onReviewSuccess}
        />
      )}
      {snackbarAlert}
    </PageLayout>
  );
};

export { EmailJobRequestDetailsPage };
