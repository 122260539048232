import { FormControlLabel, Switch } from "@mui/material";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import { ApiError, CognitoUser, UserAccountsService, UserGroup } from "gen/clients";
import * as React from "react";
import { useMutation } from "react-query";

interface Props {
  cognitoUser?: CognitoUser;
  onSuccess?: () => void;
}

function hasAdminGroup(user?: CognitoUser) {
  return user?.groups?.some(g => g === UserGroup.CLIENT);
}

const AdminRoleSwitch: React.FC<Props> = ({ cognitoUser, onSuccess }) => {
  window.console.log("AdminRoleSwitch", { cognitoUser });

  const {
    mutate,
    isError: isMutateError,
    isLoading: isMutateLoading,
    error: mutateError
  } = useMutation({
    mutationFn: UserAccountsService.changeCognitoUserGroups,
    onSuccess
  });

  const onClick = React.useCallback(
    event => {
      if (cognitoUser) {
        mutate({
          cognitoUsername: encodeURIComponent(cognitoUser.username),
          requestBody: {
            addGroups: [(event.target.checked ? UserGroup.CLIENT : UserGroup.CLIENT_EMPLOYEE) as string],
            removeGroups: [(event.target.checked ? UserGroup.CLIENT_EMPLOYEE : UserGroup.CLIENT) as string]
          }
        });
      }
    },
    [mutate, cognitoUser]
  );

  const isOrgAdmin = hasAdminGroup(cognitoUser);
  return (
    <>
      {isMutateError && <ApiErrorMessage apiError={mutateError as ApiError} />}
      <FormControlLabel
        control={
          <Switch
            checked={isOrgAdmin}
            onChange={onClick}
            disabled={isMutateLoading || cognitoUser === undefined}
            size="small"
          />
        }
        label={isOrgAdmin ? "Yes" : "No"}
        labelPlacement="end"
        sx={{ ml: 1 }}
      />
    </>
  );
};

export { AdminRoleSwitch };
