// Define the expected structure
export const defaultInput = (isAnalysis: boolean): string => {
  if (isAnalysis) {
    return `Please translate following documents from English into Spanish and Canadian French.
     Thank you
     John Smith
    `;
  }

  return "I'm Nigerian prince, click on following link and earn all gold of the world";
};

// Define the expected output structure
export const expectedOutput = (isAnalysis: boolean): Record<string, unknown> => {
  if (isAnalysis) {
    return {
      category: "TRANSLATION_REQUEST" || "QUOTE_REQUEST" || "OTHER_REQUEST",
      reason: "",
      summary: "",
      sourceLanguage_optional: {
        name: "",
        iso6391: "",
        iso6392: "",
        regionalCode: ""
      },
      targetLanguages_optional: [
        {
          name: "",
          iso6391: "",
          iso6392: "",
          regionalCode: ""
        }
      ],
      sender_optional: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      isRush_optional: ""
    };
  }

  return {
    isJunk: "",
    reason: ""
  };
};

function isOptionalKey(key: string): boolean {
  // Define your logic here to determine if a key is optional based on your requirements.
  return key.endsWith("_optional");
}

export function matchingObjectStructure<T>(actual: T, expected: T): { match: boolean; missingKeys: string[] } {
  const actualKeys = Object.keys(actual);
  const expectedKeys = Object.keys(expected);
  const missingKeys: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const key of expectedKeys) {
    if (!actualKeys.includes(key) && !isOptionalKey(key)) {
      missingKeys.push(key);
    }
  }

  return {
    match: missingKeys.length === 0,
    missingKeys
  };
}
