import * as React from "react";
import { JobRequestsService } from "../../../../gen/clients";
import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form, Formik } from "formik";
import Box from "@mui/material/Box";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { useMutation } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import Stack from "@mui/material/Stack";
import { SelectOption } from "@mui/base";

interface Props {
  groupOptions: SelectOption<string>[];
  currentGroup: string;
  jobRequestIds: string[];
  onSuccess: () => void;
  onClose: () => void;
}

enum FieldNames {
  group = "group"
}

const MoveJobRequestDialog: React.FC<Props> = ({ groupOptions, currentGroup, jobRequestIds, onSuccess, onClose }) => {
  const { mutate, isLoading, error, isSuccess } = useMutation("changeJobRequestType", {
    mutationFn: JobRequestsService.assignJobRequestsGroup
  });

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const destGroupOptions = React.useMemo(
    () => groupOptions.filter(o => o.value !== currentGroup),
    [currentGroup, groupOptions]
  );

  const onSubmit = React.useCallback(
    values => {
      mutate({
        requestBody: {
          jobRequestIds,
          group: values.group
        }
      });
    },
    [jobRequestIds, mutate]
  );

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="sm">
      <Formik
        initialValues={{
          [FieldNames.group]: ""
        }}
        onSubmit={onSubmit}
        validateOnMount={false}
      >
        <Form noValidate={true} autoComplete="off" autoCorrect="off">
          <DialogTitle>Move to Folder</DialogTitle>
          <DialogContent>
            {error && <ApiErrorMessage apiError={error} />}
            <Stack spacing={2}>
              <Box>Select a destination folder:</Box>
              <SelectOneField name={FieldNames.group} required={true} variant="outlined" options={destGroupOptions} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton color="primary" variant="contained" isLoading={isLoading}>
              OK
            </LoadingButton>
            <Button color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export { MoveJobRequestDialog };
