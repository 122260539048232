import * as React from "react";
import { Box, Button, ButtonProps, Snackbar, Alert } from "@mui/material";
import { useDialogState } from "../../hooks/useDialogState";

interface Props extends ButtonProps {
  textToCopy: string;
  successMessage?: string;
}

const CopyToClipboardButton: React.FC<Props> = ({ textToCopy, successMessage, children, ...other }) => {
  const [isSuccessMessageOpen, openSuccessMessage, closeSuccessMessage] = useDialogState();
  const onClick = React.useCallback(() => {
    window.navigator.clipboard.writeText(textToCopy);
    openSuccessMessage();
  }, [textToCopy, openSuccessMessage]);

  return (
    <>
      <Button onClick={onClick} {...other}>
        {children}
      </Button>
      <Snackbar open={isSuccessMessageOpen} autoHideDuration={5000} onClose={closeSuccessMessage}>
        <Alert onClose={closeSuccessMessage} severity="success" sx={{ width: "100%" }}>
          <Box>{successMessage || "Copied to clipboard"}</Box>
        </Alert>
      </Snackbar>
    </>
  );
};

export { CopyToClipboardButton };
