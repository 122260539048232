import * as React from "react";
import { Checkbox, FormControl, MenuItem } from "@mui/material";
import { Field, useField } from "formik";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import { SelectOption } from "@mui/base";

interface Props {
  name: string;
  label?: string;
  options: SelectOption<string | number>[];
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  helperText?: string;
  variant?: string;
  size?: string;
}

const SelectManyField: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  disabled,
  isLoading,
  isError,
  helperText,
  variant,
  size
}) => {
  const { t } = useTranslation();
  const [field] = useField<string[]>({ name }); // Access the form field's value and props

  return (
    <FormControl fullWidth>
      <Field
        component={TextField}
        type="text"
        name={name}
        label={label}
        helperText={helperText}
        placeholder={isLoading ? "Loading..." : (isError && "Error") || label}
        select={true}
        variant={variant}
        disabled={disabled || isLoading || isError}
        SelectProps={{
          multiple: true,
          renderValue: (selected: string[]) => {
            const selectedLabels = selected
              .map(value => options.find(option => option.value === value)?.label) // Map values to labels
              .filter(Boolean); // Remove undefined entries if value has no corresponding label
            return selectedLabels.join(", ");
          },
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null // Ensures menu opens aligned to the anchor
          }
        }}
        required={required}
        validate={(value: string[] | undefined) =>
          required && (!value || value.length === 0) ? t("common.validation.required") : undefined
        }
        size={size}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
            <Checkbox
              checked={field.value.includes(String(option.value))} // Accessing form field value
              disabled={option.disabled}
            />
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export { SelectManyField };
