import * as React from "react";
import { JobRequestFile, JobRequestFileCategory, JobRequestsService } from "gen/clients";
import { UploadedFile, UploadFilesButton } from "components/UploadFilesButton/UploadFilesButton";
import { useMutation } from "react-query";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { LoadingDialog } from "components/LoadingDialog/LoadingDialog";

interface Props {
  jobRequestId: string;
  category: JobRequestFileCategory;
  onSuccess: (files: JobRequestFile[]) => void;
}

const AddJobRequestFilesButton: React.FC<Props> = ({ jobRequestId, category, onSuccess }) => {
  const { mutate, isLoading, error, data } = useMutation({
    mutationFn: JobRequestsService.addFiles
  });

  const onUploadSuccess = React.useCallback(
    (uploadedFiles: UploadedFile[]) => {
      mutate({
        jobRequestId,
        requestBody: {
          files: uploadedFiles.map(f => ({ fileName: f.file.name, fileKey: f.fileKey, category }))
        }
      });
    },
    [category, jobRequestId, mutate]
  );

  React.useEffect(() => {
    if (data) {
      onSuccess(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <UploadFilesButton buttonText="Add Files" onSuccess={onUploadSuccess} />
      {!!error && <SnackbarApiError error={error} />}
      {isLoading && <LoadingDialog message="Refreshing..." />}
    </>
  );
};

export { AddJobRequestFilesButton };
