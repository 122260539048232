/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddJobRequestFilesRequest } from '../models/AddJobRequestFilesRequest';
import type { AddJobRequestFilesResponse } from '../models/AddJobRequestFilesResponse';
import type { ArchiveJobRequestPayload } from '../models/ArchiveJobRequestPayload';
import type { GetJobRequestFileDownloadUrlResponse } from '../models/GetJobRequestFileDownloadUrlResponse';
import type { JobRequest } from '../models/JobRequest';
import type { JobRequestChangeTypeRequest } from '../models/JobRequestChangeTypeRequest';
import type { JobRequestCollection } from '../models/JobRequestCollection';
import type { JobRequestFile } from '../models/JobRequestFile';
import type { JobRequestForwardEmail } from '../models/JobRequestForwardEmail';
import type { JobRequestListChangeTypeRequest } from '../models/JobRequestListChangeTypeRequest';
import type { JobRequestOrigination } from '../models/JobRequestOrigination';
import type { JobRequestsArchiveRequest } from '../models/JobRequestsArchiveRequest';
import type { JobRequestsAssignGroupRequest } from '../models/JobRequestsAssignGroupRequest';
import type { JobRequestsDeleteRequest } from '../models/JobRequestsDeleteRequest';
import type { JobRequestType } from '../models/JobRequestType';
import type { SubmitJobRequestToXtrfRequest } from '../models/SubmitJobRequestToXtrfRequest';
import type { ZipJobRequestSourceFilesStatusResponse } from '../models/ZipJobRequestSourceFilesStatusResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class JobRequestsService {

    /**
     * Returns a list of job requests
     * @returns JobRequestCollection successful operation
     * @throws ApiError
     */
    public static listJobRequests({
        pageToken,
        pageSize,
        origination,
        requestType,
        status,
        jobRequestId,
        group,
    }: {
        pageToken?: string,
        pageSize?: number,
        origination?: JobRequestOrigination,
        requestType?: JobRequestType,
        status?: string,
        jobRequestId?: string,
        group?: string,
    }): CancelablePromise<JobRequestCollection> {
        return __request({
            method: 'GET',
            path: `/jobRequests`,
            query: {
                'pageToken': pageToken,
                'pageSize': pageSize,
                'origination': origination,
                'requestType': requestType,
                'status': status,
                'jobRequestId': jobRequestId,
                'group': group,
            },
        });
    }

    /**
     * Change type of job requests
     * @returns void
     * @throws ApiError
     */
    public static changeJobRequestListType({
        requestBody,
    }: {
        /** Job request change type parameters **/
        requestBody?: JobRequestListChangeTypeRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/actions/changeRequestTypes`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request origination`,
            },
        });
    }

    /**
     * Archive Job Requests
     * @returns void
     * @throws ApiError
     */
    public static archiveJobRequests({
        requestBody,
    }: {
        /** Job request list archive parameters **/
        requestBody?: JobRequestsArchiveRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/actions/archive`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request origination`,
            },
        });
    }

    /**
     * Delete Job Requests
     * @returns void
     * @throws ApiError
     */
    public static deleteJobRequests({
        requestBody,
    }: {
        /** Job requests delete parameters **/
        requestBody?: JobRequestsDeleteRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/actions/delete`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request origination`,
            },
        });
    }

    /**
     * Assign Job Requests group
     * @returns void
     * @throws ApiError
     */
    public static assignJobRequestsGroup({
        requestBody,
    }: {
        /** Job requests group assign parameters **/
        requestBody?: JobRequestsAssignGroupRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/actions/assignGroup`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request origination`,
            },
        });
    }

    /**
     * Returns a job request by id
     * @returns JobRequest successful operation
     * @throws ApiError
     */
    public static getJobRequest({
        jobRequestId,
    }: {
        jobRequestId: string,
    }): CancelablePromise<JobRequest> {
        return __request({
            method: 'GET',
            path: `/jobRequests/${jobRequestId}`,
        });
    }

    /**
     * Deletes the job request
     * @returns any successful operation
     * @throws ApiError
     */
    public static deleteJobRequest({
        jobRequestId,
    }: {
        jobRequestId: string,
    }): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/jobRequests/${jobRequestId}`,
            errors: {
                400: `Invalid parameters`,
                409: `The Job Request is not in a deletable state`,
            },
        });
    }

    /**
     * Forward original email of a job request to a specified email address
     * @returns void
     * @throws ApiError
     */
    public static forwardJobRequestEmail({
        jobRequestId,
        requestBody,
    }: {
        jobRequestId: string,
        /** email address to forward to **/
        requestBody?: JobRequestForwardEmail,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/forwardEmail`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request origination`,
                404: `Job request not found`,
            },
        });
    }

    /**
     * Archive the job request and optionally forward original email to specified email
     * @returns void
     * @throws ApiError
     */
    public static archiveJobRequest({
        jobRequestId,
        requestBody,
    }: {
        jobRequestId: string,
        /** Job request archive parameters **/
        requestBody?: ArchiveJobRequestPayload,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/archive`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request origination`,
                404: `Job request not found`,
            },
        });
    }

    /**
     * Change the job request type
     * @returns void
     * @throws ApiError
     */
    public static changeJobRequestType({
        jobRequestId,
        requestBody,
    }: {
        jobRequestId: string,
        /** Job request change type parameters **/
        requestBody?: JobRequestChangeTypeRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/changeType`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid request origination`,
                404: `Job request not found`,
            },
        });
    }

    /**
     * Delete file from job request
     * @returns void
     * @throws ApiError
     */
    public static deleteFile({
        jobRequestId,
        requestBody,
    }: {
        jobRequestId: string,
        /** File to delete **/
        requestBody?: JobRequestFile,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/deleteFile`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Job request not found`,
            },
        });
    }

    /**
     * Add files to job request
     * @returns AddJobRequestFilesResponse successful operation
     * @throws ApiError
     */
    public static addFiles({
        jobRequestId,
        requestBody,
    }: {
        jobRequestId: string,
        /** Files to add **/
        requestBody?: AddJobRequestFilesRequest,
    }): CancelablePromise<AddJobRequestFilesResponse> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/addFiles`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Job request not found`,
            },
        });
    }

    /**
     * Translate job request source files
     * @returns any Successful operation
     * @throws ApiError
     */
    public static translateFiles({
        jobRequestId,
    }: {
        jobRequestId: string,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/translateFiles`,
            errors: {
                404: `Not found`,
            },
        });
    }

    /**
     * Submit job request to Xtrf
     * @returns any Successful operation
     * @throws ApiError
     */
    public static submitToXtrf({
        jobRequestId,
        requestBody,
    }: {
        jobRequestId: string,
        /** Job request details **/
        requestBody?: SubmitJobRequestToXtrfRequest,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/submitToXtrf`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not found`,
                413: `Data too large`,
            },
        });
    }

    /**
     * Cancel job request
     * @returns any Successfuly accepted request
     * @throws ApiError
     */
    public static cancelJobRequest({
        jobRequestId,
    }: {
        /** Job request ID **/
        jobRequestId: any,
    }): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/cancel`,
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

    /**
     * Return temporary download url of job request file
     * @returns GetJobRequestFileDownloadUrlResponse successful operation
     * @throws ApiError
     */
    public static getJobRequestFileDownloadUrl({
        jobRequestId,
        fileKey,
    }: {
        jobRequestId: string,
        fileKey: string,
    }): CancelablePromise<GetJobRequestFileDownloadUrlResponse> {
        return __request({
            method: 'GET',
            path: `/jobRequests/${jobRequestId}/files/${fileKey}/downloadUrl`,
            errors: {
                400: `Invalid request`,
                404: `Not found`,
            },
        });
    }

    /**
     * Zip job request source files
     * @returns void
     * @throws ApiError
     */
    public static zipJobRequestSourceFiles({
        jobRequestId,
        zipFileName,
    }: {
        /** Job request ID **/
        jobRequestId: any,
        /** Zip file name **/
        zipFileName: string,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/jobRequests/${jobRequestId}/actions/zipSourceFiles`,
            query: {
                'zipFileName': zipFileName,
            },
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

    /**
     * Get status of job request source files zipping operation
     * @returns ZipJobRequestSourceFilesStatusResponse Successful operation
     * @throws ApiError
     */
    public static getZipSourceFilesStatus({
        jobRequestId,
    }: {
        /** Job request ID **/
        jobRequestId: any,
    }): CancelablePromise<ZipJobRequestSourceFilesStatusResponse> {
        return __request({
            method: 'GET',
            path: `/jobRequests/${jobRequestId}/actions/zipSourceFiles/status`,
            errors: {
                403: `Forbidden`,
                404: `Not found`,
            },
        });
    }

}