import * as React from "react";
import { FormControl, MenuItem, TextFieldProps } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";
import { SelectOption } from "@mui/base";

interface LocalProps {
  name: string;
  options: SelectOption<string | number>[];
  required?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  onChange?: (newValue: string) => void;
}

type Props = Omit<TextFieldProps, "onChange"> & LocalProps;

const SelectOneField: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  disabled,
  isLoading,
  isError,
  helperText,
  variant = "standard",
  onChange,
  ...others
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  return (
    <FormControl fullWidth>
      <Field
        {...others}
        component={TextField}
        type="text"
        name={name}
        label={label}
        helperText={helperText}
        placeholder={isLoading ? "Loading..." : (isError && "Error") || ""}
        select={true}
        variant={variant}
        disabled={disabled || isLoading || isError}
        required={required}
        validate={(value: string) => (required && !value ? t("common.validation.required") : undefined)}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          setFieldValue(name, event.target.value);
          if (onChange) {
            onChange(event.target.value);
          }
        }}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </FormControl>
  );
};

export { SelectOneField };
