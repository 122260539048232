import * as React from "react";
import { Client, JobRequest, JobRequestType } from "gen/clients";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { LabelValue } from "components/LabelValue/LabelValue";
import { describeRequestType } from "../../../utils/JobRequestUtils";
import { JobRequestStatusText } from "../../JobRequestStatusText/JobRequestStatusText";
import { XtrfProjectOrQuoteLink } from "../../XtrfProjectOrQuoteLink/XtrfProjectOrQuoteLink";
import { formatDate } from "utils/dateUtils";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormHelperText from "@mui/material/FormHelperText";
import { InfoIcon } from "components/InfoIcon/InfoIcon";

interface Props {
  jobRequest: JobRequest;
  client?: Client;
  notesMinRows?: number;
}

const GeneralInformationSection: React.FC<Props> = ({ jobRequest, client, notesMinRows }) => (
  <Stack spacing={1}>
    <Typography variant="h5">General Information</Typography>
    {jobRequest.name && <LabelValue label="Name" value={jobRequest.name} />}
    {jobRequest.requestType && (
      <Stack direction="row" spacing={1}>
        <LabelValue label="Request Type" value={describeRequestType(jobRequest.requestType)} />
      </Stack>
    )}
    <LabelValue
      label="Status"
      value={
        <Stack direction="row" spacing={1}>
          <JobRequestStatusText status={jobRequest.status} />
          {jobRequest.statusDetails && <InfoIcon tooltip={jobRequest.statusDetails} />}
        </Stack>
      }
    />
    {jobRequest.xtrfName && jobRequest.xtrfId && (
      <LabelValue
        label={jobRequest.requestType === JobRequestType.TRANSLATION_REQUEST ? "XTRF Project" : "XTRF Quote"}
        value={
          <XtrfProjectOrQuoteLink type={jobRequest.requestType} xtrfId={jobRequest.xtrfId} name={jobRequest.xtrfName} />
        }
      />
    )}
    <LabelValue
      label="Requested By"
      value={`${jobRequest.requestorFirstName || ""} ${jobRequest.requestorLastName || ""} (${jobRequest.requestorEmail})`}
    />
    {client && <LabelValue label="Client" value={client.companyName} />}
    <LabelValue label="Created" value={formatDate(jobRequest.createTime)} />
    {jobRequest.subject && <LabelValue label="Subject" value={jobRequest.subject} />}
    {jobRequest.description && (
      <LabelValue
        verticalAlignment="top"
        label="Content"
        value={
          <>
            <TextareaAutosize
              defaultValue={jobRequest.description}
              minRows={10}
              maxRows={20}
              style={{ width: "100%" }}
              disabled={true}
            />
            {jobRequest.aiAnalysis?.summary && (
              <FormHelperText>Summary: {jobRequest.aiAnalysis.summary}</FormHelperText>
            )}
          </>
        }
      />
    )}
    {jobRequest.notes && jobRequest.description !== jobRequest.notes && (
      <LabelValue
        verticalAlignment="top"
        label="Notes"
        value={
          <TextareaAutosize
            defaultValue={jobRequest.notes}
            minRows={notesMinRows || 10}
            style={{ width: "100%" }}
            disabled={true}
          />
        }
      />
    )}
  </Stack>
);

export { GeneralInformationSection };
