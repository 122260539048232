import { SelectOption } from "@mui/base";

/**
 * Checks if a variable is a string
 * @param str
 */
export function isString(str: unknown): boolean {
  return typeof str === "string" || str instanceof String;
}

/**
 * Validates email format.
 * @param email
 * @return true if email is in a valid email format.
 */
export function validateEmailFormat(email: string): boolean {
  return !!email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

/**
 * Validates multiple emails.
 * @param emails
 * @return true if all emails are valid. Returns false if at least one is invalid.
 */
export function validateEmails(emails: string[]): boolean {
  return emails.every(email => validateEmailFormat(email.trim()));
}

/**
 * Validates URL format.
 * @param url
 * @return true if URL is in a valid URL format.
 */
export function validateURLFormat(url: string): boolean {
  // Regular expression pattern to match URL format
  const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/;
  // Test the URL against the pattern
  return urlPattern.test(url);
}

export const SELECT_OPTION_COMPARATOR = (o1: SelectOption<any>, o2: SelectOption<any>): number =>
  (o1.label as string).localeCompare(o2.label as string);
